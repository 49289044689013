import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { GeneralService } from 'src/app/general/general.service';
import { CreditoService } from '../../credito.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import  moment from 'moment';
import { OperacionSiNoComponent } from 'src/app/shared/operacion-si-no/operacion-si-no.component';

import { Store } from '@ngxs/store';
import { AuthState } from 'src/app/store/auth/state';

@Component({
  selector: 'app-credito-impactar',
  templateUrl: './credito-impactar.component.html',
  styleUrls: ['./credito-impactar.component.css']
})
export class CreditoImpactarComponent implements OnInit,OnDestroy {

  @ViewChild('agGridCreditosImputar', { static: true }) agGridCreditosImputar: any;

  @ViewChild(OperacionSiNoComponent, { static: true })
  operacionSiNoComponent: OperacionSiNoComponent;


  destroy$: Subject<boolean> = new Subject<boolean>();
  ultimoPeriodoActual:string
  periodo1:string
  periodo2:string
  creditosAimputar=[]

  estaProcesando:boolean =false

  columnDefs = [
    { headerName: 'Cod Socio', field: 'codSocio', width:100  },
    { headerName: 'SubCod Socio', field: 'subCodSocio', width:100  },
    { headerName: 'Crédito a Imputar', field: 'credito_imputado', width:130 },
    { headerName: 'Periodo', field: 'periodo', width:100 },
    { headerName: 'Monto máximo a imputar por Cuadro Tarifario', field: 'total_factura_cuadro_tarifario'}
  ];

  rowData: any[]=[];

  constructor(private store:Store,private gs:GeneralService, private cs:CreditoService ) {
  }

  ngOnInit() {
    this.gs.getProximosPeriodosDeFacturacion().pipe(takeUntil(this.destroy$)).
      subscribe(
      periodos=>{
        if(periodos.length>0){
          this.ultimoPeriodoActual= periodos[0]
          this.periodo1 = periodos[1]
          this.periodo2 = periodos[2]
        }
      }
    )
  }

  obtenerListadoAImputar(){
   this.creditosAimputar=[]
this.cs.getListadoCreditoAImputar().pipe(takeUntil(this.destroy$)).subscribe(
  listado=>  this.rowData = listado
)
  }


  onBtExport(){
    this.agGridCreditosImputar.api.exportDataAsCsv({ fileName: 'creditos_a_imputar_'+moment().format("YYYY-MM-DD"),columnSeparator:';' })
   }

   imputar(){
    this.operacionSiNoComponent.open();
   }

   retornoOperacion(retorno){

    if(retorno){
      this.estaProcesando = true
      const self = this;
      setTimeout(function(){
          self.procesar()
      }, 2000)
    }

  }

  procesar(){
    const usuario = this.store.selectSnapshot(AuthState.usuario);
/* this.cs.imputarCreditos(usuario).subscribe(
  data=>{
    this.estaProcesando = false
    this.notifier.notify( 'success', 'Los Créditos han sido imputados con éxito. Proceda a realizar la facturación para notar los cambios!' );
    this.rowData = []
  }
) */
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }


}
