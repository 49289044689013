
export class GetBarrios{
  static readonly type = '[Barrio] GetBarrios'
  constructor(){}
}


export class SaveBarrio{
  static readonly type = '[Barrio] SaveBarrio'
  constructor(public barrio:string){}
}

export class EditBarrio{
  static readonly type = '[Barrio] EditBarrio'
  constructor(public idBarrio:number,public barrio:string){}
}


export class DeleteBarrio{
  static readonly type = '[Barrio] DeleteBarrio'
  constructor(public idBarrio:number,public barrio:string){}
}
