import { Component, OnInit, ViewChild, Output, EventEmitter, AfterViewInit, Input } from '@angular/core';
declare var $:any;

@Component({
  selector: 'app-visor-impresion',
  templateUrl: './visor-impresion.component.html',
  styleUrls: ['./visor-impresion.component.css']
})
export class VisorImpresionComponent implements OnInit,AfterViewInit {
  @ViewChild('pdfViewerAutoLoad', { static: true }) pdfViewerAutoLoad;
  @Output() closeEvent = new EventEmitter();

  @Input() id:string = "visor_impresion"


  constructor() { }

  ngOnInit(): void {


  }

  ngAfterViewInit(){
   /*  $( `#${this.id}`).draggable({
      handle: ".modal-header"
  }); */
  }

  imprimir(src,zoom=null){
    $( `#${this.id}`).modal('show')
   this.pdfViewerAutoLoad.pdfSrc = src

    
    if(zoom){
      this.pdfViewerAutoLoad.zoom = zoom
    }

   this.pdfViewerAutoLoad.refresh();
  }

  cerrar(){
    $( `#${this.id}`).modal('hide')
    this.closeEvent.emit()
  }





}
