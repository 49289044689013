<div class="card">
  <div class="card-header card-header-info">
    Nuevo convenio de Pago
  </div>
  <div class="card-body">
    <form [formGroup]="convenioForm" (keydown.enter)="$event.preventDefault()" novalidate>
      <div class="row">
        <div class="col-md-8">
          <div class="card">
            <div class="card-body" style="padding-bottom: 5px;">
              <h6 class="card-subtitle mb-2 text-muted">Datos del convenio de pago</h6>


              <div class="form-group row">
                <label for="fechaConvenioInput" class="col-sm-5 col-form-label col-form-label-sm valorEditable">Fecha
                  del convenio</label>
                <div class="col-sm-6">
                  <input class="form-control form-control-sm" id="fechaConvenioInput" type="date"
                    formControlName="fechaConvenio"
                    [ngClass]="{ 'is-invalid': ((convenioForm.controls['fechaConvenio'].errors)  ) }">
                  <div *ngIf="convenioForm.controls['fechaConvenio'].errors" class="invalid-feedback">
                    <small>Debe ingresar una fecha </small>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <label for="fechaCajaInput" class="col-sm-5 col-form-label col-form-label-sm valorEditable">Fecha de
                  caja</label>
                <div class="col-sm-6">
                  <input class="form-control form-control-sm" id="fechaCajaInput" type="date"
                    formControlName="fechaCaja"
                    [ngClass]="{ 'is-invalid': ((convenioForm.controls['fechaCaja'].errors)  ) }">
                  <div *ngIf="convenioForm.controls['fechaCaja'].errors" class="invalid-feedback">
                    <small>Debe ingresar una fecha </small>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <label for="cajaInput" class="col-sm-5 col-form-label col-form-label-sm valorEditable">Registrar en
                  caja</label>
                <div class="col-sm-6">
                  <select class="custom-select custom-select-sm" id="cajaInput" formControlName="caja"
                    (change)="controlCaja($event)" [value]='4'
                    [ngClass]="{ 'is-invalid': ((convenioForm.controls['caja'].errors)  ) }">
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="4">4</option>
                    <option value="7">7</option>
                  </select>
                </div>
              </div>
              <hr>
              <div class="form-group row">
                <label for="facturasNoCanceladasInput" class="col-sm-6 col-form-label col-form-label-sm"
                  style="padding: 7px;">Facturas no canceladas sin rec.</label>
                <div class="col-sm-4">
                  <input class="form-control form-control-sm valorFijoPositivo input-right"
                    id="facturasNoCanceladasInput" type="text" formControlName="montoFacturasNoCanceladas"
                    [ngClass]="{ 'is-invalid': ((convenioForm.controls['montoFacturasNoCanceladas'].errors)  ) }">
                </div>
                <div class="col-sm-2 col-form-label col-form-label-sm mt-1">(+)</div>
              </div>
              <div class="form-group row">
                <label for="montoRecargoFacturasInput" class="col-sm-6 col-form-label col-form-label-sm">Recargo
                  facturas</label>
                <div class="col-sm-4">
                  <input class="form-control form-control-sm valorFijoPositivo input-right"
                    id="montoRecargoFacturasInput" type="text" formControlName="montoRecargoFacturas"
                    [ngClass]="{ 'is-invalid': ((convenioForm.controls['montoRecargoFacturas'].errors)  ) }">
                </div>
                <div class="col-sm-2 col-form-label col-form-label-sm mt-1">(+)</div>
              </div>
              <div class="form-group row">
                <label for="conveniosNoCanceladosInput" class="col-sm-6 col-form-label col-form-label-sm">Convenios no
                  cancelados</label>
                <div class="col-sm-4">
                  <input class="form-control form-control-sm valorFijoPositivo input-right"
                    id="conveniosNoCanceladosInput" type="text" formControlName="montoConveniosNoCancelados"
                    [ngClass]="{ 'is-invalid': ((convenioForm.controls['montoConveniosNoCancelados'].errors)  ) }">
                </div>
                <div class="col-sm-2 col-form-label col-form-label-sm mt-1">(+)</div>
              </div>
              <div class="form-group row">
                <label for="conveniosNoVolcadosInput"
                  class="col-sm-6 col-form-label col-form-label-sm valorEditable">Convenios no volcados</label>
                <div class="col-sm-4">
                  <input class="form-control form-control-sm input-right" id="conveniosNoVolcadosInput"
                    autocomplete="off" maxlength="10" type="text" formControlName="montoConveniosNoVolcados"
                    (change)="actualizarConvenioNoVolcado($event)"
                    [ngClass]="{ 'is-invalid': ((convenioForm.controls['montoConveniosNoVolcados'].errors)  ) }"
                    appNumberDecimalPositiveOnly>
                  <div *ngIf="convenioForm.controls['montoConveniosNoVolcados'].errors" class="invalid-feedback">
                    <small>Debe ingresar el monto </small>
                  </div>
                </div>
                <div class="col-sm-2 col-form-label col-form-label-sm mt-1">(+)</div>
              </div>
              <div class="form-group row">
                <label for="costoFinancieroInput" class="col-sm-6 col-form-label col-form-label-sm"
                  style="padding: 7px;">{{tituloCostoFinanciero}}</label>
                <div class="col-sm-4">
                  <input class="form-control form-control-sm valorFijoPositivo input-right" id="costoFinancieroInput"
                    type="text" formControlName="montoCostoFinanciero"
                    [ngClass]="{ 'is-invalid': ((convenioForm.controls['montoCostoFinanciero'].errors)  ) }">
                </div>
                <div class="col-sm-2 col-form-label col-form-label-sm mt-1">(+)</div>
              </div>
              <div class="form-group row">
                <label for="gastosAdministrativosInput"
                  class="col-sm-6 col-form-label col-form-label-sm valorEditable">Gastos administrativos</label>
                <div class="col-sm-4">
                  <input class="form-control form-control-sm input-right" id="gastosAdministrativosInput"
                    autocomplete="off" maxlength="10" type="text" formControlName="montoGastosAdministrativos"
                    (change)="actualizarGastosAdministrativos($event)"
                    [ngClass]="{ 'is-invalid': ((convenioForm.controls['montoGastosAdministrativos'].errors)  ) }"
                    appNumberDecimalPositiveOnly>
                  <div *ngIf="convenioForm.controls['montoGastosAdministrativos'].errors" class="invalid-feedback">
                    <small>Debe ingresar el monto </small>
                  </div>
                </div>
                <div class="col-sm-2 col-form-label col-form-label-sm mt-1">(+)</div>
              </div>
              <div class="form-group row">
                <label for="reconexionInput"
                  class="col-sm-6 col-form-label col-form-label-sm valorEditable">Reconexión</label>
                <div class="col-sm-4">
                  <input class="form-control form-control-sm input-right" id="reconexionInput" type="text"
                    autocomplete="off" maxlength="10" formControlName="montoReconexion"
                    (change)="actualizarReconexion($event)"
                    [ngClass]="{ 'is-invalid': ((convenioForm.controls['montoReconexion'].errors)  ) } "
                    appNumberDecimalPositiveOnly>
                  <div *ngIf="convenioForm.controls['montoReconexion'].errors" class="invalid-feedback">
                    <small>Debe ingresar el monto </small>
                  </div>
                </div>
                <div class="col-sm-2 col-form-label col-form-label-sm mt-1">(+)</div>
              </div>
              <div class="form-group row">
                <label for="canonMunicipalInput" class="col-sm-6 col-form-label col-form-label-sm valorNegativo">Canon
                  municipal</label>
                <div class="col-sm-4">
                  <input class="form-control form-control-sm valorFijoNegativo input-right" id="canonMunicipalInput"
                    type="text" formControlName="montoCanonMunicipal"
                    [ngClass]="{ 'is-invalid': ((convenioForm.controls['montoCanonMunicipal'].errors)  ) }">
                </div>
                <div class="col-sm-2 col-form-label col-form-label-sm mt-1">(-)</div>
              </div>
              <div class="form-group row">
                <label for="totalInput" class="col-sm-6 col-form-label col-form-label-sm"><b>Importe total</b></label>
                <div class="col-sm-4">
                  <input class="form-control form-control-sm inputTotal input-right" id="totalInput" type="text"
                    formControlName="montoTotal"
                    [ngClass]="{ 'is-invalid': ((convenioForm.controls['montoTotal'].errors)  ) }">
                </div>
              </div>
              <hr>
              <div class="form-group row">
                <label for="costoIntimacionInput" class="col-sm-6 col-form-label col-form-label-sm valorNegativo ">Costo
                  de intimación (recibo)</label>
                <div class="col-sm-4">
                  <input class="form-control form-control-sm valorFijoNegativo input-right" id="costoIntimacionInput"
                    type="text" formControlName="montoCostoIntimacion"
                    [ngClass]="{ 'is-invalid': ((convenioForm.controls['montoCostoIntimacion'].errors)  ) }">
                </div>
              </div>
              <hr>
              <div class="form-group row">
                <label for="cuotasInput" class="col-sm-6 col-form-label col-form-label-sm valorEditable">Cuotas</label>
                <div class="col-sm-4">
                  <input class="form-control form-control-sm" id="cuotasInput" type="number" min="1" max="99"
                    maxlength="2" formControlName="cuotas" (change)="actualizarCuotas($event)"
                    [ngClass]="{ 'is-invalid': ((convenioForm.controls['cuotas'].errors)  ) }"
                    appNumberEnteroNoCeroOnly>
                  <div *ngIf="convenioForm.controls['cuotas'].errors" class="invalid-feedback">
                    <small>Debe ingresar un valor entre 1 y 99 </small>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <label for="valorCuotaInput" class="col-sm-6 col-form-label col-form-label-sm">Valor
                  cuota</label>
                <div class="col-sm-4">
                  <input class="form-control form-control-sm input-right" id="valorCuotaInput" type="text"
                    formControlName="montoValorCuotas"
                    [ngClass]="{ 'is-invalid': ((convenioForm.controls['montoValorCuotas'].errors)  ) }">
                </div>
              </div>
              <div class="form-group row">
                <label for="cuotasAbonadasInput" class="col-sm-6 col-form-label col-form-label-sm valorEditable">Cuotas
                  abonadas</label>
                <div class="col-sm-4">
                  <input class="form-control form-control-sm" id="cuotasAbonadasInput" type="number" min="0" max="99"
                    maxlength="2" formControlName="cuotasAbonadas"
                    [ngClass]="{ 'is-invalid': ((convenioForm.controls['cuotasAbonadas'].errors)  ) }"
                    appNumberEnteroOnly>
                  <div *ngIf="convenioForm.controls['cuotasAbonadas'].errors" class="invalid-feedback">
                    <small>Debe ingresar un valor entre 1 y 99 </small>
                  </div>
                </div>
              </div>

            </div>

            <div class="card-footer">
              <div class="col-md-12 text-center"><button type="button" class="btn btn-outline-info btn-sm float-center"
                  style="font-size: 13px;" (click)="crearConvenioDePago()" [disabled]="!estaHabilitadoNuevoConvenio">Crear convenio de pago</button></div>
            </div>


          </div>
        </div>
        <div class="col-md-4">

          <div class="row">
            <div class="card">
              <div class="card-body" style="padding-bottom: 5px;">
                <div class="row">
                  <div class="col-md-12">
                    <h6 class="card-subtitle mb-2 text-muted">Facturas</h6>
                  </div>
                </div>
                <div class="row">

                  <div class="col-md-12"><button type="button" class="btn btn-outline-info btn-sm float-right"
                      style="font-size: 13px; padding: inherit" (click)="seleccionarFacturas()" [disabled]="!estaHabilitadoNuevoConvenio">Seleccionar
                      facturas</button></div>
                </div>
                <div class="row mt-3">

                  <select class="custom-select custom-select-sm facturas-convenio ml-2" size="13" readonly>
                    <ng-container *ngFor="let factura of facturasNuevoConvenio">
                      <option>{{factura.Factura}} - {{factura.Periodo}}</option>
                    </ng-container>
                  </select>

                </div>
                <div class="row mt-2">
                  <label class="col-form-label col-form-label-sm">Total facturas adeudadas:
                    {{nuevoConvenio.totalFacturasConRecargo|formatCurrency}}</label>
                </div>
              </div>
            </div>
          </div>


          <div class="row mt-2">

            <div class="card">
              <div class="card-body" style="padding-bottom: 5px;">
                <div class="row">
                  <div class="col-md-12">
                    <h6 class="card-subtitle mb-2 text-muted">Tipo de convenio</h6>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12"><button type="button" class="btn btn-outline-info btn-sm float-right"
                      style="font-size: 13px; padding: inherit">Nota</button></div>
                </div>
                <fieldset class="form-group">
                  <div class="row">
                    <div class="col-sm-12">
                      <div class="form-check">
                        <input class="form-check-input" type="radio" id="normalTipo" [value]="0"
                          formControlName="tipoConvenio" (change)="actualizarTipoConvenio($event)" [checked]="true">
                        <label class="form-check-label" for="normalTipo">
                          Normal
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="radio" id="sinInteresTipo" [value]="1"
                          formControlName="tipoConvenio" (change)="actualizarTipoConvenio($event)">
                        <label class="form-check-label" for="sinInteresTipo">
                          Cobro sin interés
                        </label>
                      </div>
                      <div class="form-check disabled">
                        <input class="form-check-input" type="radio" id="conDescuentoTipo" [value]="2"
                          formControlName="tipoConvenio" (change)="actualizarTipoConvenio($event)">
                        <label class="form-check-label" for="conDescuentoTipo">
                          Cobro con descuentos
                        </label>
                      </div>
                    </div>
                  </div>
                </fieldset>
                <div class="form-group row">
                  <div class="col-sm-12">
                    <input class="form-control form-control-sm" autocomplete="off" maxlength="10"
                      formControlName="descuentoSobreIntereses" id="descuentoInput" type="text" maxlength="2"
                      (blur)="onBlurPorcentaje()"
                      [ngClass]="{ 'is-invalid': ((convenioForm.controls['descuentoSobreIntereses'].errors)  ) }"
                      appNumberDecimalPositiveOnly>
                    <div *ngIf="convenioForm.controls['descuentoSobreIntereses'].errors" class="invalid-feedback">
                      <small>Debe ingresar un valor entre a 0 y 99 </small>
                    </div>
                    <small>% sobre intereses rec. facturas</small>
                  </div>

                </div>
                <div class="form-group form-check">
                  <input type="checkbox" class="form-check-input" formControlName="convenioJudicial"
                    id="convenioAbogado" (change)="convenioJudicialControl($event)">
                  <label class="form-check-label" for="convenioAbogado">Convenio por abogado</label>
                </div>



              </div>
              <div class="card-footer">
                <button class="btn btn-sm btn-outline-danger" (click)="cancelar()">Cancelar</button>
                &nbsp; <button [disabled]="!convenioForm.valid || estaEnProceso" type="submit" class="btn btn-sm btn-success"
                  (click)="aceptarConvenio()">Aceptar</button>
              </div>




            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>

<app-operacion-informacion [titulo]="'Nuevo Convenio'" [contenidoMensaje]="mensajeError"
  (aceptarMensaje)="retornoOperacionInformacion($event)" #informacionNuevoConvenio [id]="'informacionNuevoConvenio'">
</app-operacion-informacion>

<app-operacion-si-no [titulo]="'Nuevo Convenio'" [contenidoMensaje]="mensajeConsulta" [id]="'siNoNuevoConvenio'"
  (retornoOperacion)="retornoOperacionConsultaSiNo($event)" #siNoNuevoConvenio></app-operacion-si-no>

<app-autorizacion
  [mensajeAutorizacion]="'Los convenios superiores a 12 cuotas, el cobro de convenio sin interes y el descuento sobre los intereses requieren autorizacion'"
  (retornoOperacion)="retornoAutorizacion($event)"></app-autorizacion>



