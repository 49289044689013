import { Component, OnInit } from '@angular/core';
import { SrcImg } from 'src/app/shared/src-img';
import { TipoPermisos } from 'src/app/store/roles-permisos/model';
import { ModoChequeoRol, PermisosModoRequerido } from 'src/app/shared/role.directive';
declare var $: any;

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.css']
})
export class TopBarComponent implements OnInit {

  srcimg: SrcImg = new SrcImg();
 

  permisoModoRequeridoProcesoDeFacturacion:PermisosModoRequerido={permisosRequerido:[TipoPermisos.ProcesoDeFacturacion],
    modoRequerido:ModoChequeoRol.RequiereTodosLosPermisos}

    permisoModoRequeridoProcesoDeFacturacionMensual:PermisosModoRequerido={permisosRequerido:[TipoPermisos.ProcesoDeFacturacionMensual],
      modoRequerido:ModoChequeoRol.RequiereTodosLosPermisos}

      permisoModoRequeridoGeneracionReciboSemestral:PermisosModoRequerido={permisosRequerido:[TipoPermisos.GeneracionReciboSemestral],
        modoRequerido:ModoChequeoRol.RequiereTodosLosPermisos}

        permisoModoRequeridoGeneracionNotasDeCreditos:PermisosModoRequerido={permisosRequerido:[TipoPermisos.GeneracionNotasDeCreditos],
          modoRequerido:ModoChequeoRol.RequiereTodosLosPermisos}

  permisoModoRequeridoImpresionDeuda:PermisosModoRequerido={permisosRequerido:[TipoPermisos.ImpresionListadoDeudaUsuarios,TipoPermisos.ImpresionListadoDeDeudaPagoPorPeriodo],
    modoRequerido:ModoChequeoRol.RequiereAlgunPermiso}
  
    permisoModoRequeridoImpresionListadoDeudaUsuarios:PermisosModoRequerido={permisosRequerido:[TipoPermisos.ImpresionListadoDeudaUsuarios],
   modoRequerido:ModoChequeoRol.RequiereTodosLosPermisos}
  
  permisoModoRequeridoImpresionListadoDeDeudaPagoPorPeriodo:PermisosModoRequerido={permisosRequerido:[TipoPermisos.ImpresionListadoDeDeudaPagoPorPeriodo],
    modoRequerido:ModoChequeoRol.RequiereTodosLosPermisos}

    permisoModoRequeridoOpcionesAdministracion:PermisosModoRequerido={permisosRequerido:[TipoPermisos.OpcionesAdministracion],
      modoRequerido:ModoChequeoRol.RequiereTodosLosPermisos}

      permisoModoRequeridoOpcionesABMUsuarios:PermisosModoRequerido={permisosRequerido:[TipoPermisos.ABMUsuarios],
        modoRequerido:ModoChequeoRol.RequiereTodosLosPermisos}

        permisoModoRequeridoOpcionesABMSituacionEspecialConVencimiento:PermisosModoRequerido={permisosRequerido:[TipoPermisos.ABMSituacionEspecialConVencimiento],
          modoRequerido:ModoChequeoRol.RequiereTodosLosPermisos}
  
        
    

  constructor() { }

  ngOnInit() {
   
  }


  ngAfterViewInit(){
 
    $('.dropdown-menu a.dropdown-toggle').on('click', function(e) {
      if (!$(this).next().hasClass('show')) {
          $(this).parents('.dropdown-menu').first().find('.show').removeClass("show");
      }
      var $subMenu = $(this).next(".dropdown-menu");
      $subMenu.toggleClass('show');
      $(this).parents('li.nav-item.dropdown.show').on('hidden.bs.dropdown', function(e) {
          $('.dropdown-submenu .show').removeClass("show");
      });
      return false;
  });
    
  }
  


  


}
