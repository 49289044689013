<div class="card">
  <div class="card-header card-header-info">
    <i class="fas fa-comments"></i> Observaciones
    <div class="float-right">
      <span *ngIf="enProcesoJudicial" class="badge bg-danger">En Proceso Judicial</span>
      <span *ngIf="enCODENE" class="badge bg-success ml-2">En CODENE</span>
      <span *ngIf="enVERAZ" class="badge bg-warning ml-2">En VERAZ</span>
    </div>
  </div>
  <div class="card-body">
    <div class="col-12 p-3" style="max-height: 250px; overflow-y: auto; border: solid 1px #ddd; border-radius: 5px ;     font-size: 13px;">
        <ul class="list-unstyled list-observaciones" *ngFor="let observacion of observaciones">
            <li class="media">
              <div class="media-body">
                <i class="far fa-comment-dots" color="green"></i> &nbsp; {{observacion}}
              </div>
            </li>

        </ul>
    </div>
    <br>
    <div class="col-12">
      <div class="row">
        <div class="col-md-6">
          <button type="button" class="btn btn-sm btn-outline-info" (click)="agregarObservacion()" > <i class="fas fa-plus"></i> Agregar nota a observaciones</button>
        </div>
        <div class="col-md-6">
          <div class="float-right">
            <button *ngIf="!enProcesoJudicial; else elseProcesoJudicial" type="button" class="btn btn-sm btn-outline-danger" (click)="enviarProcesoJudicial()" ><i class="fas fa-plus"></i> Enviar a proceso judicial</button>
            <ng-template #elseProcesoJudicial><button type="button" class="btn btn-sm btn-outline-danger" (click)="retirarProcesoJudicial()" ><i class="far fa-trash-alt"></i> Retirar de proceso judicial</button></ng-template>
            <button *ngIf="!enCODENE; else elseCODENE" type="button" class="btn btn-sm btn-outline-success ml-2 " (click)="enviarCodene()" ><i class="fas fa-plus"></i> Enviar a CODENE</button>
            <ng-template #elseCODENE><button type="button" class="btn btn-sm btn-outline-success ml-2" (click)="retirarCodene()" ><i class="far fa-trash-alt"></i> Retirar de CODENE</button></ng-template>
            <button *ngIf="!enVERAZ; else elseVERAZ" type="button" class="btn btn-sm btn-outline-warning ml-2" (click)="enviarVeraz()"><i class="fas fa-plus"></i> Enviar a VERAZ</button>
            <ng-template #elseVERAZ><button type="button" class="btn btn-sm btn-outline-warning ml-2" (click)="retirarVeraz()" ><i class="far fa-trash-alt"></i> Retirar de VERAZ</button></ng-template>
          </div>

        </div>

      </div>
    </div>
  </div>
</div>

<app-operacion-si-no-observacion [titulo]="'Nueva Observación'" [contenidoMensaje]="''"
(retornoOperacion)="retornoOperacionSiNo($event)"></app-operacion-si-no-observacion>


