import {  Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { OperacionesABM } from 'src/app/shared/enums/enums';
import { ItemsLista } from 'src/app/store/general/models';
import { SocioDetalle } from 'src/app/store/socio/models';
import { Time } from 'src/app/utils/time';
import { ValidacionFormularioSocio } from '../validacion-formulario-socio';

@Component({
  selector: 'app-socio-info',
  templateUrl: './socio-info.component.html',
  styleUrls: ['./socio-info.component.css']

})
export class SocioInfoComponent implements OnInit {

  @Input() modoOperacion: OperacionesABM;
  @Input() socio: SocioDetalle;
  @Input() condicionesDeRegistro: ItemsLista[];
  @Input() tiposDni: ItemsLista[];
  @Input() tiposIva: ItemsLista[];
  @Input() categorias: ItemsLista[];
  @Input() errorSocioUsuarioRegistrado:boolean = false

  socioForm: UntypedFormGroup
  constructor( private fb: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.socioForm = this.initSocioFormModel();
    this.setearSocioForm();
  }

  initSocioFormModel(){

    let dataCuit = this.socio.cuit?.split("-") || [];
    let tipoCuit = "";
    let numeroCuit = "";
    let verificadorCuit = "";

    if (dataCuit.length == 3) {
      tipoCuit = dataCuit[0];
      numeroCuit = dataCuit[1];
      verificadorCuit = dataCuit[2];
    } else {
      numeroCuit = this.socio.cuit;
    }

    return this.fb.group({
      condicionRegistro: [this.socio.id_condicionRegistro, Validators.required],
      codigo: [this.socio.codSocio, Validators.required],
      subcodigo: [this.socio.subCodSocio, Validators.required],
      apellidoNombre: [this.socio.nombre, Validators.required],
      tipoDocumento: [this.socio.tipoDocumento, Validators.required],
      documento: [this.socio.documento, Validators.required],
      tipoCuilCuit: [tipoCuit],
      numeroCuilCuit: [numeroCuit],
      digitoVerificadorCuilCuit: [verificadorCuit],
      iva: [this.socio.id_iva, Validators.required],
      categoria: [this.socio.id_categoria, Validators.required],
      telefonoFijo: [this.socio.telefonoFijo],
      celular: [this.socio.telefonoCelular],
      email: [this.socio.email, Validators.email],
      facturaElectronica: [this.socio.facturaElectronica],
      fechaDeAlta: [ Time.formatDate(this.socio.fechaAlta,"YYYY-MM-DD")]
    })
  }

  setearSocioForm(){
    this.socioForm.get("codigo").valueChanges.subscribe((value) => {
      if (this.errorSocioUsuarioRegistrado) {
        this.errorSocioUsuarioRegistrado = false;
      }
    });

    this.socioForm.get("subcodigo").valueChanges.subscribe((value) => {
      if (this.errorSocioUsuarioRegistrado) {
        this.errorSocioUsuarioRegistrado = false;
      }
    });

    if (this.modoOperacion == OperacionesABM.editar) {
      this.socioForm.get("codigo").disable();
      this.socioForm.get("subcodigo").disable();
      if (this.socioForm.get("facturaElectronica").value) {
        this.socioForm
          .get("email")
          .setValidators([Validators.required, Validators.email]);
        }
    }

    if (this.modoOperacion == OperacionesABM.alta) {
      this.socioForm.get("fechaDeAlta").setValidators(Validators.required);
      this.socioForm.get("fechaDeAlta").setValue(Time.currentDateToStandar());
    }

  }

  get condicionesRegistroField(){
    return this.socioForm.controls.condicionRegistro
  }

  get isInvalidCondicionesRegistroField(){
    return this.condicionesRegistroField.touched && this.condicionesRegistroField.invalid
  }


  get codigoField(){
    return this.socioForm.controls.codigo
  }

  get isInvalidCodigoField(){
    return this.codigoField.touched && this.codigoField.invalid
  }

  get subcodigoField(){
    return this.socioForm.controls.subcodigo
  }

  get isInvalidSubcodigoField(){
    return this.subcodigoField.touched && this.subcodigoField.invalid
  }


  get apellidoNombreField(){
    return this.socioForm.controls.apellidoNombre
  }

  get isInvalidApellidoNombreField(){
    return this.apellidoNombreField.touched && this.apellidoNombreField.invalid
  }

  get tipoDocumentoField(){
    return this.socioForm.controls.tipoDocumento
  }

  get isInvalidTipoDocumentoField(){
    return this.tipoDocumentoField.touched && this.tipoDocumentoField.invalid
  }

  get documentoField(){
    return this.socioForm.controls.documento
  }

  get isInvalidDocumentoField(){
    return this.documentoField.touched && this.documentoField.invalid
  }

  get ivaField(){
    return this.socioForm.controls.iva
  }

  get isInvalidIvaField(){
    return this.ivaField.touched && this.ivaField.invalid
  }

  get categoriaField(){
    return this.socioForm.controls.categoria
  }

  get isInvalidCategoriaField(){
    return this.categoriaField.touched && this.categoriaField.invalid
  }

  get emailField(){
    return this.socioForm.controls.email
  }

  get isInvalidEmailField(){
    return this.emailField.touched && this.emailField.invalid
  }

  get fechaDeAltaField(){
    return this.socioForm.controls.fechaDeAlta
  }

  get isInvalidFechaDeAltaField(){
    return this.fechaDeAltaField.touched && this.fechaDeAltaField.invalid
  }

/**
 * Validaciones de campos
 *  Si el usuario selecciona la adhesion a la factura electronica el campo email es obligatorio
 */
  onSelectionFacturaElectronicaChange(event) {
    if (this.socioForm.get('facturaElectronica').value) {
      this.socioForm.get('email').setValidators([Validators.required, Validators.email]);
    } else {
      this.socioForm.get('email').setValidators(Validators.email);
    }

    this.socioForm.get('email').updateValueAndValidity()
  }

  get isValidForm(){
    return this.socioForm.valid
  }

  showInvalidControl(){
    ValidacionFormularioSocio.setErrorsInForm(this.socioForm);
  }

  get formValue(){
    return this.socioForm.getRawValue()
  }

}
