import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';
import { GetDocumentacionPlazosControl, StartBusquedaSocioDetalle, GetSocioDetalle } from 'src/app/store/socio/actions';
import { AgButtonRenderComponent } from 'src/app/shared/ag-button-render/ag-button-render.component';
import { ActualizarEmailComponent } from '../../abm-socio/actualizar-email/actualizar-email.component';
import { Router } from '@angular/router';
import { OperacionInformacionComponent } from 'src/app/shared/operacion-informacion/operacion-informacion.component';
import { SocioGestionService } from '../../socio-gestion.service';
import { concat } from 'rxjs';
import { finalize } from 'rxjs/operators';


import moment from  'moment'
import Swal from 'sweetalert2';


@Component({
  selector: 'app-control-cisterna-planos',
  templateUrl: './control-cisterna-planos.component.html',
  styleUrls: ['./control-cisterna-planos.component.css']
})
export class ControlCisternaPlanosComponent implements OnInit, OnDestroy {



  @ViewChild(ActualizarEmailComponent, { static: true })
  actualizarEmailComponent: ActualizarEmailComponent;

  @ViewChild('informacionControlPlazos', { static: true })
  operacionInformacionComponent: OperacionInformacionComponent;

  filtroForm: UntypedFormGroup;
  estaProcesando:boolean =false
  estaProcesandoNotificacion:boolean =false

  columnDefs:any = [
    {
      cellRenderer: 'buttonRenderer',
      editable:false,
      width: 70,
      cellRendererParams: {
        onClick: this.irACLiente.bind(this),
        label: 'ir a socio',
        classBtn: 'primary'
      }
    },
    {
      cellRenderer: 'buttonRenderer',
      editable:false,
      width: 70,
      cellRendererParams: {
        onClick: this.setearEmail.bind(this),
        label: 'modificar email',
        classBtn: 'email'
      }
    },
    {headerName: 'Socio', field: 'cliente',filter: true, headerCheckboxSelection: true,
    checkboxSelection: true, width:150 },
    {headerName: 'Email', field: 'email', resizable:true },
    {headerName: 'Tipo Documentación', field: 'tipo_documentacion', width:150,resizable:true  },
    {headerName: 'Días para vencimiento', field:'dias_para_vencimiento', width:150,
    cellStyle: params => params.value <= 7 || params.value== 'Vencido'  ? {color: 'red'} : null, sortable: true,
    },
    {headerName: 'Inicio/Presentación', field: 'fecha_desde', width:150,resizable:true },
    {headerName: 'Fin', field: 'fecha_hasta', width:150 },
    {headerName: 'Días plazo',field: 'dias_plazo' },
    {headerName: 'Notificaciones por mail',field: 'notificacion_por_mail',filter: true,resizable: true},
    {headerName: 'Observación',field: 'observacion',resizable: true },
    {headerName: 'Nombre', field: 'nombre',width:150,resizable:true },
    {headerName: 'Tel. Fijo', field: 'telefonoFijo', width:150,resizable:true  },
    {headerName: 'Celular', field: 'telefonoCelular', width:150,resizable:true  },
    {field: 'id',hide:true },
    {field: 'id_cliente',hide:true },
    {field: 'codsocio',hide:true },
    {field: 'subcodsocio',hide:true }


  ];

  rowData:Array<any>=[]
  gridApi:any;
  totalSeleccionadas:number=0;

  plazosControl$:Subscription
  deshabilitarDiasVencimiento:boolean=false

  frameworkComponents: any;

  mensaje:string
  habilitarNotificacionMail:boolean=true



  constructor(private formBuilder: UntypedFormBuilder, private sgs:SocioGestionService, private store:Store, private router:Router) {
    this.frameworkComponents = {
      buttonRenderer: AgButtonRenderComponent,
    }
   }

  ngOnInit(): void {

    this.plazosControl$ = this.store.select(state=> state.socio.socio_plazos_control).subscribe(
      data=> {this.rowData = data; this.estaProcesando=false}
    )

    this.initForm()
  }


  initForm() {

    this.filtroForm = this.formBuilder.group({
      tipoDocumentacion: [0] ,
      estadoDocumentacion:['sinPresentar'],
      diasVencimiento: [7]
    });
  }

  onSubmit(){
    this.estaProcesando= true
    this.store.dispatch(new GetDocumentacionPlazosControl(this.filtroForm.get('tipoDocumentacion').value,this.filtroForm.get('estadoDocumentacion').value,this.filtroForm.get('diasVencimiento').value))
  }


  onGridReady(params) {
    this.gridApi = params.api;

  }

  onSelectionChanged(event){

  this.totalSeleccionadas = this.gridApi.getSelectedRows().length

  }

  habilitarDesabilitarVencimiento(event){
    this.rowData =[]
    if(event.target.value=='presentada'){
      this.filtroForm.get('diasVencimiento').disable()
      this.habilitarNotificacionMail = false
    }else{
      this.filtroForm.get('diasVencimiento').enable()
      this.habilitarNotificacionMail = true
    }
    this.filtroForm.get('estadoDocumentacion').setValue(event.target.value)
  }

  setearEmail(e){
    this.actualizarEmailComponent.setCliente({cliente:e.rowData.id_cliente,email:e.rowData.email} )
    this.actualizarEmailComponent.open()
  }

  retornoOperacionActualizarEmail(retorno){
    if(retorno){
      this.estaProcesando= true
      this.store.dispatch(new GetDocumentacionPlazosControl(this.filtroForm.get('tipoDocumentacion').value,this.filtroForm.get('estadoDocumentacion').value,this.filtroForm.get('diasVencimiento').value))
    }
  }

  irACLiente(e){
    this.store.dispatch(new StartBusquedaSocioDetalle())
    this.store.dispatch(new GetSocioDetalle(e.rowData.codsocio,e.rowData.subcodsocio))

    //debemos rutear a socio detalle
    this.router.navigate(['/socio']);
  }

  notificarViaEmail(){
    let observableBatch = [];
    let notificaciones=this.gridApi.getSelectedRows()


    if(notificaciones.length==0){
      this.mensaje = 'Debe seleccionar al menos un plazo para realizar la notificación!'
      this.operacionInformacionComponent.open()
      return
    }

    if (notificaciones.filter(elem=>{return !elem.email || elem.email==""}).length>0){
      this.mensaje = 'Existen plazos seleccionados sin el respectivo email cargado. Deberá cargarlos antes de poder continuar!'
      this.operacionInformacionComponent.open()
      return
    }

    this.estaProcesandoNotificacion = true
    notificaciones.forEach(notificacion => observableBatch.push(this.sgs.enviarNotificacionDocumentacionPlazos(notificacion.id,notificacion.nombre,notificacion.email,notificacion.tipo_documentacion,notificacion.fecha_hasta)));

    concat(...observableBatch).pipe(
      finalize(() =>  this.finalizarNotificaciones())
    ).subscribe()
  }

  finalizarNotificaciones(){
    this.estaProcesandoNotificacion=false

    Swal.fire(
      '','Las notificaciones fueron enviadas con éxito!',
      'success'
    )
    let self = this
    setTimeout(function(){
      self.onSubmit()
  }, 3000)
  }

  onBtExportListado(){
    this.gridApi.exportDataAsCsv({ fileName: 'control_cisternas_planos_'+this.filtroForm.get('estadoDocumentacion').value+'_' + moment().format("YYYY-MM-DD"), columnSeparator: ';' })
  }


  ngOnDestroy(){
    this.plazosControl$.unsubscribe()
  }



}
