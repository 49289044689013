import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { OperacionesABM } from 'src/app/shared/enums/enums';
import { Barrio } from 'src/app/store/barrio/models';
import { ItemsLista } from 'src/app/store/general/models';
import { SocioDetalle } from 'src/app/store/socio/models';
import { ValidacionFormularioSocio } from '../validacion-formulario-socio';

@Component({
  selector: 'app-domicilio-legal-info',
  templateUrl: './domicilio-legal-info.component.html',
  styleUrls: ['./domicilio-legal-info.component.css']

})
export class DomicilioLegalInfoComponent implements OnInit {

  @Input() modoOperacion: OperacionesABM;
  @Input() socio: SocioDetalle;
  @Input() provincias: ItemsLista[];
  @Input() barrios: Barrio[];



  public validadoresFormularioSocio: ValidacionFormularioSocio = new ValidacionFormularioSocio();

  socioForm: UntypedFormGroup
  constructor( private fb: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.validadoresFormularioSocio.setBarrios(this.barrios);
    this.socioForm = this.initSocioFormModel();
    this.setearSocioForm();
  }

  initSocioFormModel(){
    return this.fb.group({
      provinciaDomicilioLegal: [
        this.socio.id_provinciaLegal,
        Validators.required,
      ],
      localidadDomicilioLegal: [
        this.socio.localidadLegal,
        Validators.required,
      ],
      codigoPostalDomicilioLegal: [
        this.socio.codPostalLegal,
        Validators.required,
      ],
      barrioDomicilioLegal: [this.socio.barrioLegal, Validators.required],
      id_barrioDomicilioLegal: [this.socio.id_barrioLegal],
      calleDomicilioLegal: [this.socio.calleLegal, Validators.required],
      numeroCalleDomicilioLegal: [this.socio.nroLegal, Validators.required],
      pisoDepartamentoDomicilioLegal: [this.socio.adicDomicLegal],
    })
  }

  setearSocioForm() {
    if (this.modoOperacion == OperacionesABM.editar) {
      // habilito la seleccion de barrios si la localidad es plottier
      if (this.socio.localidadLegal?.toLocaleLowerCase() == "plottier") {
        this.socioForm
          .get("barrioDomicilioLegal")
          .setValidators([
            Validators.required,
            this.validadoresFormularioSocio.esBarrioPlottierValido(),
          ]);
      } else {
        this.socioForm.get("barrioDomicilioLegal").disable();
      }
    }

  }


  get provinciaDomicilioLegalField(){
    return this.socioForm.controls.provinciaDomicilioLegal
  }

  get isInvalidProvinciaDomicilioLegalField(){
    return this.provinciaDomicilioLegalField.touched && this.provinciaDomicilioLegalField.invalid
  }

  get localidadDomicilioLegalField(){
    return this.socioForm.controls.localidadDomicilioLegal
  }

  get isInvalidLocalidadDomicilioLegalField(){
    return this.localidadDomicilioLegalField.touched && this.localidadDomicilioLegalField.invalid
  }

  get codigoPostalDomicilioLegalField(){
    return this.socioForm.controls.codigoPostalDomicilioLegal
  }

  get isInvalidCodigoPostalDomicilioLegalField(){
    return this.codigoPostalDomicilioLegalField.touched && this.codigoPostalDomicilioLegalField.invalid
  }

  get barrioDomicilioLegalField(){
    return this.socioForm.controls.barrioDomicilioLegal
  }

  get isInvalidBarrioDomicilioLegalField(){
    return this.barrioDomicilioLegalField.touched && this.barrioDomicilioLegalField.invalid
  }

  get calleDomicilioLegalField(){
    return this.socioForm.controls.calleDomicilioLegal
  }

  get isInvalidCalleDomicilioLegalField(){
    return this.calleDomicilioLegalField.touched && this.calleDomicilioLegalField.invalid
  }

  get numeroCalleDomicilioLegalField(){
    return this.socioForm.controls.numeroCalleDomicilioLegal
  }

  get isInvalidNumeroCalleDomicilioLegalField(){
    return this.numeroCalleDomicilioLegalField.touched && this.numeroCalleDomicilioLegalField.invalid
  }

  verificarLocalidadLegal(event) {

    if (event.target.value.toLowerCase() == 'plottier') {
      this.barrioDomicilioLegalField.enable();
      this.barrioDomicilioLegalField.setValidators([Validators.required, this.validadoresFormularioSocio.esBarrioPlottierValido()]);
    } else {
      this.barrioDomicilioLegalField.setValue("");
      this.barrioDomicilioLegalField.disable();
    }

  }

  onSelectionBarrioChange(event, control) {
    const barrioSeleccion = this.barrios.filter(
      (barrio) => barrio.barrio == event.target.value
    );

    if (barrioSeleccion.length > 0) {
      this.socioForm.get(control).setValue(barrioSeleccion[0].id);
    }
  }

  get isValidForm(){
    return this.socioForm.valid
  }

  showInvalidControl(){
    ValidacionFormularioSocio.setErrorsInForm(this.socioForm);
  }

  get formValue(){
    return this.socioForm.getRawValue()
  }

}
