import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Acciones } from '../store/acciones/model';
import { Subscription } from 'rxjs';
import { Store } from '@ngxs/store';
import { OperacionSiNoObservacionComponent } from '../shared/operacion-si-no-observacion/operacion-si-no-observacion.component';
import { AutorizacionComponent } from '../autorizacion/autorizacion.component';
import { DeleteAccionesCliente, GetAccionesCliente  } from '../store/acciones/actions';
import { SocioState } from '../store/socio/states';
import { mergeMap } from 'rxjs/operators';
import { GetObservaciones } from '../store/observacion/actions';
import Swal from 'sweetalert2'




@Component({
  selector: 'app-acciones',
  templateUrl: './acciones.component.html',
  styleUrls: ['./acciones.component.css']
})
export class AccionesComponent implements OnInit,OnDestroy {

  @ViewChild('agGridAcciones', { static: true }) agGridAcciones: any;
  @ViewChild(OperacionSiNoObservacionComponent, { static: true })
  operacionSiNoObservacionComponent: OperacionSiNoObservacionComponent;

  @ViewChild(AutorizacionComponent, { static: true })
  autorizacionComponent: AutorizacionComponent;

  rowData:Acciones[]

  columnDefs=[
    {checkboxSelection: true, width:15},
    {field:'id_accion', hide:true},
    {headerName:"Fecha",field:'fecha', width:110,resizable:true},
    {headerName:"Importe",field:'importe', width:95,resizable:true},
    {headerName:"Cuotas",field:'cuotas', width:75},
    {headerName:"Valor Cuota",field:'valorcuota', width:95,resizable:true},
    {headerName:"Facturadas",field:'facturadas', width:95},
    {headerName:"Terminado",field:'terminado', width:95}
  ]

  accionescliente:Subscription

  nuevaSubscipcionHabilitada:boolean=false
  observacionEliminacion:string

  accionEditar:Acciones
  modoEdicion:Boolean=false
  observacionEdicion:string

  tituloOperacionSino:string
  leyenda1OperacionSino:string
  leyenda2OperacionSino:string

  cliente:number



  constructor(private store:Store) {
   }

  ngOnInit(): void {

     this.cliente = this.store.selectSnapshot(SocioState.cliente)
    this.store.dispatch(new GetAccionesCliente(this.cliente,false))

    this.accionescliente = this.store.select(state=> state.acciones.acciones).subscribe(
      data => this.rowData = data
    )
  }

  returnNewSubscripcion(e){
    this.nuevaSubscipcionHabilitada=false
  }

  nuevaSubscripcion(){
    this.nuevaSubscipcionHabilitada=true
    this.modoEdicion = false
  }

  eliminarSubscripcion(){
    if(this.agGridAcciones.api.getSelectedRows().length>0){
      this.tituloOperacionSino = "Eliminar subscripcion de acción"
      this.leyenda1OperacionSino= "Desea eliminar la subscripción? Si dá de baja la suscripción de acciones, esto se registrará en la base de datos de seguridad."
      this.leyenda2OperacionSino= "Tenga en cuenta que la baja de la suscripción de acciones es permanente por lo que luego deberá volver a dar de alta la suscripción."
      this.modoEdicion = false
      this.operacionSiNoObservacionComponent.open()
    }
  }

  editarSubscripcion(){
    if(this.agGridAcciones.api.getSelectedRows().length>0){
      this.tituloOperacionSino = "Modificar suscripción"
      this.leyenda1OperacionSino= "Desea modificar la subscripción? Si efectúa cambios en la suscripción de acciones se registrará en la base de datos de seguridad."
      this.leyenda2OperacionSino= "Además, tenga en cuenta que ¡¡¡ LOS CAMBIOS NO SE PUEDEN DESHACER !!! Está seguro de querer continuar ?"
      this.modoEdicion = true
      this.operacionSiNoObservacionComponent.open()
    }

  }

  retornoOperacionSiNo(retorno){
    if (retorno.opcion_seleccion) {
      if(this.modoEdicion){
        this.accionEditar = this.agGridAcciones.api.getSelectedRows()[0]
        this.nuevaSubscipcionHabilitada=true
        this.observacionEdicion = retorno.observacion
      }else{
        this.observacionEliminacion =   retorno.observacion
        this.autorizacionComponent.open()
      }

    }
  }


 retornoAutorizacion(retorno){
    if(retorno.habilita){
        let accion = this.agGridAcciones.api.getSelectedRows()[0].id_accion

        this.store.dispatch(new DeleteAccionesCliente(this.cliente,accion,retorno.usuarioAutorizante,this.observacionEliminacion))
        .pipe(
          mergeMap(()=>   this.store.dispatch(new GetObservaciones(this.cliente,true)))
        )
        .subscribe(
          ()=>{
            Swal.fire(
              'Eliminar acción','La operación ha sido realizada con éxito!',
              'success'
            )
          }
        )

    }
  }


  ngOnDestroy() {
    this.accionescliente.unsubscribe()
  }

}
