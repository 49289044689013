import { AsyncPipe, NgIf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { LoadingService } from '../loading.service';
import { RouteConfigLoadEnd, RouteConfigLoadStart, Router } from '@angular/router';
import { Observable, tap } from 'rxjs';

@Component({
  selector: 'app-loading-indicator',
  standalone: true,
  imports: [ AsyncPipe, NgIf],
  templateUrl: './loading-indicator.component.html',
  styleUrl: './loading-indicator.component.css'
})

  export class LoadingIndicatorComponent implements OnInit {

    loading$: Observable<boolean>;
  
    @Input()
    detectRouteTransitions = false;
  
   
  
    constructor(
    private loadingService: LoadingService, 
    private router: Router) {
      this.loading$ = this.loadingService.loading$;
    }
  
    ngOnInit() {
      if (this.detectRouteTransitions) {
        this.router.events
          .pipe(
            tap((event) => {
              if (event instanceof RouteConfigLoadStart) {
                this.loadingService.loadingOn();
              } else if (event instanceof RouteConfigLoadEnd) {
                this.loadingService.loadingOff();
              }
            })
          )
          .subscribe();
      }
    }
  }

