<ng-container [formGroup]="socioForm">
  <div id="datosConexion"></div>
  <div class="card shadow mt-4" >
    <div class="card-body">
        <p style="border-left: solid 5px #17a2b8; padding-left: 5px"><b> Datos de conexión</b></p>
        <hr>
        <div class="form-group row">
            <label for="inputEstadoConexion" class="col-sm-3 col-form-label col-form-label-sm  text-right">Estado de conex.:</label>
            <div class="col">
              <select  class="form-control form-control-sm" id="inputEstadoConexion" formControlName="estadoConexion" [ngClass]="{ 'is-invalid':isInvalidEstadoConexionField }">
                <option  *ngFor="let estado of estados" [value]="estado.id" [disabled]="estado.id==6">{{estado.detalle}}</option>
            </select>
              <div *ngIf="isInvalidEstadoConexionField" class="invalid-feedback">
              Campo obligatorio
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label for="inputFinEstadoConexion" class="col-sm-3 col-form-label col-form-label-sm  text-right">Finaliza en:</label>
            <div class="col">
              <input type="text" class="form-control form-control-sm"  formControlName="finEstadoConexion" id="inputFinEstadoConexion" placeholder=""  appNumberEnteroOnly>
            </div>
          </div>
          <div class="form-group row">
            <label for="inputTarifa" class="col-sm-3 col-form-label col-form-label-sm  text-right">Tarifa</label>
            <div class="col">
              <select  class="form-control form-control-sm" id="inputTarifa" formControlName="tarifa" [ngClass]="{ 'is-invalid': isInvalidTarifaField }" >
                <option *ngFor="let tarifa of tarifas" [value]="tarifa.id">{{tarifa.detalle}}</option>
            </select>
              <div *ngIf="isInvalidTarifaField" class="invalid-feedback">
                Campo obligatorio
              </div>
            </div>
          </div>

          <div class="form-group row">
              <label for="inputZona" class="col-sm-3 col-form-label col-form-label-sm  text-right">Zona facturación</label>
              <div class="col">
               <select  class="form-control form-control-sm" id="inputZona" formControlName="zona" [ngClass]="{ 'is-invalid':isInvalidZonaField}">
                <option *ngFor="let zona of zonas" [value]="zona.id">{{zona.detalle}}</option>
            </select>
                <div *ngIf="isInvalidZonaField" class="invalid-feedback">
                 Campo obligatorio
                </div>
              </div>
            </div>
            <div class="form-group row">
                <label for="inputSituacionEspecial" class="col-sm-3 col-form-label col-form-label-sm  text-right">Situación especial</label>
                <div class="col">
                 <select  class="form-control form-control-sm" id="inputSituacionEspecial" formControlName="situacionEspecial" [ngClass]="{ 'is-invalid': isInvalidSituacionEspecialField}">
                  <option *ngFor="let situacionEspecialItem of situacionesEspecial" [value]="situacionEspecialItem.id">{{situacionEspecialItem.detalle}}</option>
              </select>
                <div *ngIf=" isInvalidSituacionEspecialField" class="invalid-feedback">
                 Campo obligatorio
                </div>
                </div>
              </div>
              <div class="form-group row">
                <label for="inputInicioSituacionEspecial" class="col-sm-3 col-form-label col-form-label-sm  text-right">Inicio Situación especial</label>
                <div class="col-4">
                  <input type="date" class="form-control form-control-sm" formControlName="fechaInicioSituacionEspecial" id="inputInicioSituacionEspecial"  [ngClass]="{ 'is-invalid': isInvalidFechaInicioSituacionEspecialField }">
                <div *ngIf="isInvalidFechaInicioSituacionEspecialField" class="invalid-feedback">
                  Campo obligatorio
                </div>
                </div>
                <div class="col-4">
                  <span  class="col-form-label col-form-label-sm">Fecha fin: <span class="text-info">

                      {{ fechaFinSituacionEspecialField.value | date:'dd/MM/yyyy'}}


                   </span></span>
                </div>

              </div>
    </div>
  </div>
</ng-container>
