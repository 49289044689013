<div class="card">
  <div class="card-header card-header-info">
    <i class="far fa-file"></i> Acciones
  </div>
  <div class="card-body">

    <div class="row">
      <div class="col-md-7">
        <div class="row">
          <div class="col-md-12">
            <ag-grid-angular
            #agGridAcciones
            style="width: 100%; height: 200px;"
            class="ag-theme-alpine"
            [rowData]="rowData"
            [columnDefs]="columnDefs"
            [colResizeDefault]="'fit'"
            [rowSelection]= "'single'"

            [rowHeight]="25"
            ></ag-grid-angular>
          </div>
        </div>
      <div class="row mt-3">
        <div class="col-md-12">
          <button type="button" title="generar nueva suscipción de acciones" class="btn btn-sm btn-outline-primary" [disabled]="nuevaSubscipcionHabilitada" (click)="nuevaSubscripcion()"><i class="fas fa-plus"></i> Nueva suscripción de acciones</button>
          <button type="button" title="editar suscipción" class="btn btn-sm btn-outline-primary ml-2" (click)="editarSubscripcion()" [disabled]="nuevaSubscipcionHabilitada"><i class="far fa-edit"></i> Editar suscripción</button>
          <button type="button" title="eliminar suscipción" class="btn btn-sm btn-outline-danger ml-2" (click)="eliminarSubscripcion()" [disabled]="nuevaSubscipcionHabilitada"><i class="far fa-trash-alt"></i> Eliminar suscripción</button>
        </div>
      </div>

      </div>

      <div class="col-md-5">
        <app-nueva-accion *ngIf="nuevaSubscipcionHabilitada" [modoEdicion]="modoEdicion" [accionAEditar]="accionEditar" [observacionEdicion]="observacionEdicion" (newSubscripcionReturn)="returnNewSubscripcion($event)"></app-nueva-accion>
      </div>
    </div>

  </div>
</div>

<app-operacion-si-no-observacion [id]="'operacion_si_no_observacion_acciones'" [titulo]="tituloOperacionSino" [contenidoMensaje]="leyenda1OperacionSino"
[observacionPorDefecto]="leyenda2OperacionSino"
(retornoOperacion)="retornoOperacionSiNo($event)"></app-operacion-si-no-observacion>

<app-autorizacion (retornoOperacion)="retornoAutorizacion($event)"></app-autorizacion>


