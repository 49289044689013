
    <div class="side-menu">
      <ul>
        <li class="menu-item text-center"><a  routerLink="/socio/gestion" title="Socios"><i class="fa fa-users fa-2x"
              aria-hidden="true"></i><br>Socios</a>
        </li>
        <li class="menu-item text-center"><a  routerLink="/cobranzas/factura" title="Caja"><i class="fa fa-usd fa-2x"
              aria-hidden="true"></i><br>Caja</a></li>
        <li class="menu-item text-center"><a  routerLink="/recibos/nuevo" title="Recibos"><i class="fa fa-registered fa-2x"
              aria-hidden="true"></i><br>Recibos</a></li>
        <li class="menu-item text-center"><a routerLink="/manuales" title="Manuales"><i class="fa fa-book fa-2x"
              aria-hidden="true"></i><br>Manuales</a>
        </li>
        <li class="menu-item text-center my-3"><i class="fas fa-fw fa-user-circle fa-2x" aria-hidden="true"></i>
          <br>
          {{usuario}}
          <br>
          <a [routerLink]="[]" (click)="logout()" style="color:#ff9900" title="Salir">Salir</a>

        </li>
      </ul>
    </div>
   
  