import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';

import { FacturaService } from 'src/app/factura/factura.service';

import { OperacionExitosaComponent } from 'src/app/shared/operacion-exitosa/operacion-exitosa.component';
import { ExportarArchivo } from 'src/app/shared/exportar';
import { Imprimir } from 'src/app/shared/imprimir';
import { HttpResponse } from '@angular/common/http';
import { OperacionSiNoComponent } from 'src/app/shared/operacion-si-no/operacion-si-no.component';
import { OperacionLoadingComponent } from 'src/app/shared/operacion-loading/operacion-loading.component';
import { FacturaDetalle } from 'src/app/store/factura/models';
import { Store } from '@ngxs/store';
import { PagarFacturas } from 'src/app/store/factura/actions';
import { SocioDetalle } from 'src/app/store/socio/models';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, tap } from 'rxjs/operators';

import { Utils } from 'src/app/factura/utils';
import { InformacionCajaComponent } from 'src/app/factura/informacion-caja/informacion-caja.component';

import { SocioState } from 'src/app/store/socio/states';
import { GetObservaciones } from 'src/app/store/observacion/actions';
import { GetCuentaCorriente } from 'src/app/store/cuentacorriente/actions';

import Swal from 'sweetalert2'






@Component({
  selector: 'app-cobranza-multiple',
  templateUrl: './cobranza-multiple.component.html',
  styleUrls: ['./cobranza-multiple.component.css']
})
export class CobranzaMultipleComponent implements OnInit,OnDestroy {

  @ViewChild(InformacionCajaComponent, { static: true })
  informacionCajaComponent: InformacionCajaComponent;


  @ViewChild(OperacionSiNoComponent, { static: true })
  operacionSiNoComponent: OperacionSiNoComponent;

  @ViewChild(OperacionLoadingComponent, { static: true })
  operacionLoadingComponent: OperacionLoadingComponent;


public facturas:FacturaDetalle[]=[];
public facturasSeleccionadas:FacturaDetalle[]=[];

utils:Utils = new Utils()
seleccionadasAPagar = 0
totalAPagarEnFacturas = 0
recargoAPagar= 0
totalACobrar = 0
descuentoSobreRecargo = 0

public numeroDeComprobante: string;


public pdf = new ExportarArchivo();
public imprimir = new Imprimir();


socio:SocioDetalle = null;
destroy$: Subject<boolean> = new Subject<boolean>();
  constructor(private fs:FacturaService, private store:Store) { }

  ngOnInit() {

    this.store.select(state=>state.factura.numeroComprobantePagoGenerado).pipe(takeUntil(this.destroy$)).subscribe(
      numeroDeComprobante => {this.numeroDeComprobante = numeroDeComprobante}
    )

    this.store.select(state=>state.socio.socio_detalle).pipe(takeUntil(this.destroy$)).subscribe(
      socio => {this.socio = socio}
    )

  }

  onSeleccionFacturas(event){

    if(!event){
      this.facturasSeleccionadas =[]
    }else{
      this.facturasSeleccionadas =  event
    }

    const {montoTotal,montoRecargo} = this.utils.calcularResumenListado(this.facturasSeleccionadas)
    this.seleccionadasAPagar = this.facturasSeleccionadas.length
    this.totalAPagarEnFacturas=montoTotal
    this.recargoAPagar=montoRecargo
    this.totalACobrar=montoTotal
    this.descuentoSobreRecargo = 0
  }

  onDescuentoSobreRecargo(event){
    this.descuentoSobreRecargo = event
    this.totalACobrar= +(this.totalAPagarEnFacturas - (this.recargoAPagar * this.descuentoSobreRecargo / 100)).toFixed(2);
  }



  pagar(event){
    this.operacionSiNoComponent.open();
  }


  retornoOperacionSiNo(retorno){

    if(retorno){


    // this.operacionLoadingComponent.open();

     let opcionesCaja=this.informacionCajaComponent.getOpcionesPago()

     let facturasAPagar:any[] = this.facturasSeleccionadas.map(factura=>{ return {'Factura':factura.Factura,'Total':factura.Total,'Neto':factura.Neto,'IVA':factura.IVA,'Recargo':factura.Recargo,'IVARec':factura.IVARec}})


     const cliente = this.store.selectSnapshot(SocioState.cliente);


    this.store.dispatch(new PagarFacturas(facturasAPagar,cliente,this.totalAPagarEnFacturas,
      this.totalACobrar,this.descuentoSobreRecargo,opcionesCaja.caja,opcionesCaja.fechaCobranza,opcionesCaja.banco,opcionesCaja.numeroTransferencia)).subscribe(
       () => {




         Swal.fire(
          'El Cobro ha sido realizado con éxito!',
          'Al aceptar aguarde un instante mientras se genera el comprobante de pago!',
          'success'
        ).then(
          () => this.retornoOperacionExitosa()
        )
        }


     )

    }

  }

  retornoOperacionExitosa(){

    this.operacionLoadingComponent.loadingTitle="Generando comprobante de pago";
    this.operacionLoadingComponent.open();


    this.fs.getComprobantePagoFacturaMultiple(this.numeroDeComprobante,this.socio.id_socio)
    .pipe(
      tap((contenido: HttpResponse<Blob>)=>{
        this.imprimir.imprimir(contenido);
        this.operacionLoadingComponent.close();
       }),
      mergeMap(()=> this.store.dispatch(new GetObservaciones(this.socio.id_socio,true))),
      mergeMap(()=>this.store.dispatch(new GetCuentaCorriente(this.socio.id_socio,true)))
    )
    .subscribe()
  }


  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }



}
