import { Validators, UntypedFormBuilder } from '@angular/forms';
import  dayjs from 'dayjs'
import { OperacionInformacionComponent } from 'src/app/shared/operacion-informacion/operacion-informacion.component';


export class NuevoConvenio {

    private formBuilder: UntypedFormBuilder = new UntypedFormBuilder()
    dateNow = dayjs().format("YYYY-MM-DD");

    public convenioFormGroup = this.formBuilder.group({
        fechaConvenio: [this.dateNow, Validators.required],
        fechaCaja: [this.dateNow, Validators.required],
        caja: [4, Validators.required],
        montoFacturasNoCanceladas: [{ value: 0.00, disabled: true }],
        montoRecargoFacturas: [{ value: 0.00, disabled: true }],
        montoConveniosNoCancelados: [{ value: 0.00, disabled: true }],
        montoConveniosNoVolcados: [0.00, Validators.required],
        montoCostoFinanciero: [{ value: 0.00, disabled: true }],
        montoGastosAdministrativos: [0.00, Validators.required],
        montoReconexion: [0.00, Validators.required],
        montoCanonMunicipal: [{ value: 0.00, disabled: true }],
        montoTotal: [{ value: 0.00, disabled: true }],
        montoCostoIntimacion: [{ value: 0.00, disabled: true }],
        cuotas: [1, [Validators.required,Validators.min(1),Validators.max(99)]],
        montoValorCuotas: [{ value: 0.00, disabled: true }],
        cuotasAbonadas: [0, [Validators.required,Validators.min(0),Validators.max(99)]],
        tipoConvenio: [0], // 0-Con interés(normal) , 1-Sin Interés, 2-Con Descuento
        descuentoSobreIntereses: [{ value: 0.00, disabled: true }, [Validators.required, Validators.min(0), Validators.max(99)]],
        convenioJudicial: [false]
    });

    tituloCostoFinanciero = "Costo financiero (0%)"

    recargoDiario = 1;
    valorMinimoCuota = 0;
    totalFacturasConRecargo = 0;

    // totalImporteTotalEnFacturas + totalRecargo + totalIvaRecargo = totalFacturasConRecargo
    totalRecargo = 0;
    totalIvaRecargo = 0;
    totalImporteTotalEnFacturas = 0;
//
    totalFacturasSinRecargo = 0;

    totalCanonMunicipal = 0;
    pseudoConvenio:boolean;
    canonCobrado:boolean;
    costoIntimacionCobrado:boolean;

    mensajeInformacion: OperacionInformacionComponent = null

    public setMensajeInformacion(mensaje: OperacionInformacionComponent) {
        this.mensajeInformacion = mensaje
    }

    public setTotalFacturasConRecargo(total: number) {
        this.totalFacturasConRecargo = total
    }

    public setTotalFacturasSinRecargo(total: number) {
        this.totalFacturasSinRecargo = total
    }

    public setRecargoDiario(total: number) {
        this.recargoDiario = total
    }

    public setValorMinimoCuota(total: number) {
        this.valorMinimoCuota = total
    }

    public setTotalIntimacion(total: number) {
        this.convenioFormGroup.get("montoCostoIntimacion").setValue(+total.toFixed(2))
    }

    public setTotalConveniosAdeudados(total: number) {
        this.convenioFormGroup.get("montoConveniosNoCancelados").setValue(+total.toFixed(2))
    }

    public setTotalCanonMunicipal(total: number) {
        this.convenioFormGroup.get("montoCanonMunicipal").setValue(+total.toFixed(2))
    }

    public setCanonCobrado(valorCanonCobrado: boolean) {
        this.canonCobrado = valorCanonCobrado
    }

    public setCostoIntimacionCobrado(valorCostoIntimacionCobrado: boolean) {
        this.costoIntimacionCobrado = valorCostoIntimacionCobrado
    }

    public setValoresRecargoImporteTotalEnFacturas(recargos:number,ivarecargos:number,importesTotales:number){
      this.totalRecargo = recargos
      this.totalIvaRecargo = ivarecargos
      this.totalImporteTotalEnFacturas = importesTotales
    }


    public actualizarTotalesConvenio() {


      this.convenioFormGroup.get("montoFacturasNoCanceladas").setValue(+(this.totalFacturasSinRecargo.toFixed(2)))

        this.convenioFormGroup.get("montoCostoFinanciero").setValue(0)
        this.tituloCostoFinanciero = 'Costo financiero (0%)'

        if ([0, 2].includes(+this.convenioFormGroup.get("tipoConvenio").value)) { // Con interés o Con Descuento
            this.calculoConInteresOConDescuento()
        }
        else {// sin interes
            this.calculoSinInteres()
        }

        let totalValorCuota = Number((+this.convenioFormGroup.get("montoTotal").value / +this.convenioFormGroup.get("cuotas").value).toFixed(2))
        this.convenioFormGroup.get("montoValorCuotas").setValue(totalValorCuota)


        //El valor de la cuota no puede ser menor al valor minimo de cuota
        let cantCuotas
        if (this.convenioFormGroup.get("cuotas").value > 1 && totalValorCuota !== 0 && totalValorCuota < this.valorMinimoCuota) {
            //Calcula la cantidad de cuotas dependiendo del valor mínimo de cuota
            cantCuotas = this.convenioFormGroup.get("montoTotal").value / this.valorMinimoCuota
            // Redondea hacia abajo siempre
            cantCuotas = Math.trunc(cantCuotas)
            // Si la cantidad de cuotas es 0 es porque el valor total del
            // convenio es inferior al valor de la cuota mínima
            if (cantCuotas == 0) {
                // Establece la cantidad en 1 cuota (mínimo posible)
                cantCuotas = 1
            } else {
                // Muestra el mensaje de error por la cantidad de cuotas
                this.mensajeInformacion.contenidoMensaje = `El valor mínimo de cuota para este socio debe ser igual o superior a $${this.valorMinimoCuota}`
                this.mensajeInformacion.open()
            }

            this.convenioFormGroup.get("cuotas").setValue(cantCuotas)
            this.actualizarTotalesConvenio()

        }



    }



    private calculoConInteresOConDescuento() {
        let totalDeuda = 0
        let cantidadDiasCuotas = 0
        let totalCostoFinanciero = 0
        let totalImporteCovenio = 0


        let totalFacturaDeudaConRecargo = this.obtenerTotalFacturaDeuda()

        if (+this.convenioFormGroup.get("cuotas").value > 1) { //más de 12 cuotas, se debe ingresar el codigo de autorizacion
            totalDeuda = totalFacturaDeudaConRecargo + this.convenioFormGroup.get("montoConveniosNoCancelados").value + +this.convenioFormGroup.get("montoConveniosNoVolcados").value
            cantidadDiasCuotas = this.calcularDiasEnCuotas(+this.convenioFormGroup.get("cuotas").value)
            totalCostoFinanciero = ((totalDeuda * this.recargoDiario * cantidadDiasCuotas) / 2) * 0.01
            this.convenioFormGroup.get("montoCostoFinanciero").setValue(+(totalCostoFinanciero.toFixed(2)))
            this.tituloCostoFinanciero = `Costo financiero (${this.recargoDiario.toFixed(2)}%) diario`
        }

        totalImporteCovenio = (totalFacturaDeudaConRecargo + this.convenioFormGroup.get("montoConveniosNoCancelados").value + +this.convenioFormGroup.get("montoConveniosNoVolcados").value + +this.convenioFormGroup.get("montoGastosAdministrativos").value
            + +this.convenioFormGroup.get("montoReconexion").value + this.convenioFormGroup.get("montoCostoFinanciero").value) - this.convenioFormGroup.get("montoCanonMunicipal").value

        this.convenioFormGroup.get("montoTotal").setValue(+(totalImporteCovenio.toFixed(2)))

    }

    private obtenerTotalFacturaDeuda(){
      let totalFacturaDeudaConRecargo
      let totalIntereses
      if(+this.convenioFormGroup.get("tipoConvenio").value==0){
        totalFacturaDeudaConRecargo = this.totalFacturasConRecargo
        totalIntereses = +(this.totalRecargo + this.totalIvaRecargo).toFixed(2)
      }else{
        let descuento = this.convenioFormGroup.get("descuentoSobreIntereses").value / 100
        let ivaRecargo = this.totalIvaRecargo - (this.totalIvaRecargo * descuento)
        let recargo = this.totalRecargo - (this.totalRecargo * descuento)
        totalFacturaDeudaConRecargo = +(this.totalFacturasSinRecargo + ivaRecargo + recargo).toFixed(2)
        totalIntereses = +(recargo + ivaRecargo).toFixed(2)
      }

      this.convenioFormGroup.get("montoRecargoFacturas").setValue(totalIntereses)

      return totalFacturaDeudaConRecargo

    }


    private calculoSinInteres() {
        let totalImporteCovenio = 0
        totalImporteCovenio = (this.totalFacturasSinRecargo + this.convenioFormGroup.get("montoConveniosNoCancelados").value + +this.convenioFormGroup.get("montoConveniosNoVolcados").value + +this.convenioFormGroup.get("montoGastosAdministrativos").value
            + +this.convenioFormGroup.get("montoReconexion").value + this.convenioFormGroup.get("montoCostoFinanciero").value) - this.convenioFormGroup.get("montoCanonMunicipal").value

        this.convenioFormGroup.get("montoTotal").setValue(+(totalImporteCovenio.toFixed(2)))

        this.convenioFormGroup.get("montoRecargoFacturas").setValue(0)

    }


    private calcularDiasEnCuotas(cuotas: number) {

        let fechahasta = dayjs().add(cuotas, 'month')
        return Number(fechahasta.diff(dayjs(), 'day', true).toFixed(0))

    }


    public controlCaja() {
        if (+this.convenioFormGroup.get("caja").value == 1) {
            this.mensajeInformacion.contenidoMensaje = `Se permite registrar cobranza de facturas en cantidad bajo las siguientes condiciones:<br><ol><li>La caja debe ser 1</li>
            <li>La fecha de caja debe ser la fecha del día</li>
            <li>La cantidad de cuotas debe ser 1</li>
            <li>La cantidad de cuotas abonadas debe ser 1</li>
            <li>No debe haber deuda de convenio</li>
            <li>El tipo de convenio debe figurar como 'Normal' o 'Sin intereses'</li>
            <li>Debe informar el número de recibo emitido (debe estar confirmado)</li>
            </ol>`
            this.mensajeInformacion.open()
        }
    }

    public verificarConvenio(){
        return this.controlSeleccionFacturas()
     }

     private controlSeleccionFacturas(){
         if (+this.convenioFormGroup.get("montoTotal").value == 0){
             this.mensajeInformacion.contenidoMensaje = "Debe seleccionar las facturas a incluír en el convenio!"
             this.mensajeInformacion.open()
             return false
            }else{
            return this.controlCaja1()
            }
     }

     private controlCaja1(){
        this.pseudoConvenio=false
         if(+this.convenioFormGroup.get("caja").value==1){
             //Verifica si la cantidad de cuotas es 1
             if(+this.convenioFormGroup.get("cuotas").value!==1){
               this.mensajeInformacion.contenidoMensaje = "Al registrar el convenio en la caja 1, recuerde que la <b>cantidad de cuotas</b> debe ser 1"
               this.mensajeInformacion.open()
               return false
             }

             //Verifica que las cuotas abonadas sea 1
             if(+this.convenioFormGroup.get("cuotasAbonadas").value!==1){
               this.mensajeInformacion.contenidoMensaje = "Al registrar el convenio en la caja 1, recuerde que la <b>cantidad de cuotas abonadas</b> debe ser 1"
               this.mensajeInformacion.open()
               return false
             }

             //Verifica que el tipo de convenio sea 'Normal' o 'Cobro sin interés'
             if(![0,1].includes(+this.convenioFormGroup.get("tipoConvenio").value)){
               this.mensajeInformacion.contenidoMensaje = "Al registrar el convenio en la caja 1, recuerde que el <b>tipo de convenio</b> debe ser <b>Normal</b> o <b>Cobro sin interés</b>"
               this.mensajeInformacion.open()
               return false
             }

             //Verifica que la fecha de caja sea la fecha del día
             if(this.convenioFormGroup.get("fechaCaja").value!==this.dateNow){
               this.mensajeInformacion.contenidoMensaje = "Al registrar el convenio en la caja 1, recuerde que la <b>fecha de caja</b> debe ser la fecha del día"
               this.mensajeInformacion.open()
               return false
             }

             if(+this.convenioFormGroup.get("montoConveniosNoCancelados").value!==0 ||+this.convenioFormGroup.get("montoConveniosNoVolcados").value!==0){
               this.mensajeInformacion.contenidoMensaje = "Existe deuda de convenio, el convenio no puede registrarse en la caja 1"
               this.mensajeInformacion.open()
               return false
             }

             this.pseudoConvenio=true
           }

           return this.controlFechas()
     }

    private controlFechas(){
        //Verifica que la fecha del convenio no sea posterior a la fecha de caja
        if(this.convenioFormGroup.get("fechaConvenio").value>this.convenioFormGroup.get("fechaCaja").value){
            this.mensajeInformacion.contenidoMensaje = "La <b>fecha de firma del convenio</b> no puede ser posterior a la <b>fecha de caja</b>"
            this.mensajeInformacion.open()
            return false
          }

          return this.controlCuotas()
    }

    private controlCuotas(){
        // Verifica las cuotas abonadas
        if(+this.convenioFormGroup.get("cuotasAbonadas").value>this.convenioFormGroup.get("cuotas").value){
            this.mensajeInformacion.contenidoMensaje = "La <b>cantidad de cuotas abonadas</b> no puede ser superior al <b>total de cuotas</b>"
            this.mensajeInformacion.open()
            return false
          }

          return this.controlFirmaConvenio()
    }

    private controlFirmaConvenio(){
        if (!this.pseudoConvenio){
            // Verifica que se haya cobrado el canon municipal si existe el concepto en las facturas seleccionadas
            if(+this.convenioFormGroup.get("montoCanonMunicipal").value>0 && !this.canonCobrado){
                this.mensajeInformacion.contenidoMensaje = "Debe cobrar el <b>canon municipal</b> ANTES de poder registrar el convenio de pago"
                this.mensajeInformacion.open()
                return false
            }
             //  Verifica que se haya cobrado el costo de las intimaciones por carta documentos, si existen intimaciones pendientes de pago
             if(+this.convenioFormGroup.get("montoCostoIntimacion").value>0 && !this.costoIntimacionCobrado){
                this.mensajeInformacion.contenidoMensaje = "Debe cobrar el <b>costo de las intimaciones</b> por carta documento ANTES de poder registrar el convenio de pago"
                this.mensajeInformacion.open()
                return false
            }
        }

        return true;
    }

    deshabilitarNuevoConvenio(){
      this.convenioFormGroup.controls.fechaConvenio.disable()
      this.convenioFormGroup.controls.fechaCaja.disable()
      this.convenioFormGroup.controls.caja.disable()
      this.convenioFormGroup.controls.montoConveniosNoVolcados.disable()
      this.convenioFormGroup.controls.montoGastosAdministrativos.disable()
      this.convenioFormGroup.controls.montoReconexion.disable()
      this.convenioFormGroup.controls.cuotas.disable()
      this.convenioFormGroup.controls.cuotasAbonadas.disable()
      this.convenioFormGroup.controls.tipoConvenio.disable()
      this.convenioFormGroup.controls.descuentoSobreIntereses.disable()
      this.convenioFormGroup.controls.convenioJudicial.disable()
    }

    habilitarNuevoConvenio(){
      this.convenioFormGroup.controls.fechaConvenio.enable()
      this.convenioFormGroup.controls.fechaCaja.enable()
      this.convenioFormGroup.controls.caja.enable()
      this.convenioFormGroup.controls.montoConveniosNoVolcados.enable()
      this.convenioFormGroup.controls.montoGastosAdministrativos.enable()
      this.convenioFormGroup.controls.montoReconexion.enable()
      this.convenioFormGroup.controls.cuotas.enable()
      this.convenioFormGroup.controls.cuotasAbonadas.enable()
      this.convenioFormGroup.controls.tipoConvenio.enable()
      this.convenioFormGroup.controls.descuentoSobreIntereses.enable()
      this.convenioFormGroup.controls.convenioJudicial.enable()
    }
}
