import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter, Input } from '@angular/core';
import { Store } from '@ngxs/store';
import { fromEvent, Subject } from 'rxjs';
import { map, filter, debounceTime, distinctUntilChanged, mergeMap, takeUntil } from 'rxjs/operators';
import { GetBusquedaSocios, StartGetBusquedaSocios } from 'src/app/store/socio/actions';
import { VisualizarButtonComponent } from '../visualizar-button/visualizar-button.component';
declare var $:any;

@Component({
  selector: 'app-busqueda-avanzada',
  templateUrl: './busqueda-avanzada.component.html',
  styleUrls: ['./busqueda-avanzada.component.css']
})
export class BusquedaAvanzadaComponent implements OnInit {

  @ViewChild('agGridSocios', { static: true }) agGridSocios: any;

  @ViewChild('sociosSearchInput', { static: true }) sociosSearchInput: ElementRef;

  @Input() id:string= "openBusquedaAvanzada"
  @Output() clienteSeleccionado = new EventEmitter();



radioSelected:string="Nombre";

camposBusqueda=['CodSocio','SubCod','Nombre','Documento','Zona','Barrio','Manzana','Lote','Calle','Ruta','Orden','Nomenclatura','Medidor','CUIT','Conyuge_Nombre','Conyuge_Documento','Observaciones','Identificador']

  rowData: any;


  columnDefs = [

    {
      headerName: 'Ver',
      cellRenderer: 'buttonRenderer',
      width: 68,
      cellRendererParams: {
        onClick: this.socioSeleccionado.bind(this),
        label: 'ver'
      }
    },
    {headerName: 'Código', field: 'codigo',sortable: true, filter: true, width:100},
    {headerName: 'SubCódigo', field: 'subCodigo',sortable: true, filter: true , width:120},
    {headerName: 'Nombre', field:         'nombre',sortable: true, filter: true,resizable: true },
    {headerName: 'Documento', field: 'documento',sortable: true, filter: true },
    {headerName: 'Zona', field: 'zona',sortable: true, filter: true,resizable: true },
    {headerName: 'Barrio', field: 'barrio',sortable: true, filter: true,resizable: true },
    {headerName: 'manzana', field: 'manzana',sortable: true, filter: true,resizable: true },
    {headerName: 'Lote', field: 'lote',sortable: true, filter: true,resizable: true },
    {headerName: 'Calle', field: 'calle',sortable: true, filter: true,resizable: true },
    {headerName: 'Nro', field: 'nro',sortable: true, filter: true,resizable: true },
    {headerName: 'Obs. Domicilio', field: 'observacionConexion',sortable: true, filter: true,resizable: true },
    {headerName: 'Ruta', field: 'ruta',sortable: true, filter: true,resizable: true },
    {headerName: 'Orden', field: 'orden',sortable: true, filter: true,resizable: true },
    {headerName: 'Nomenclatura', field: 'nomenclatura',sortable: true, filter: true,resizable: true },
    {headerName: 'Medidor', field: 'medidor',sortable: true, filter: true,resizable: true },
    {headerName: 'Cuit', field: 'cuit',sortable: true, filter: true,resizable: true },
    {headerName: 'Conyugue_nombre', field: 'conyugue_nombre',sortable: true, filter: true,resizable: true },
    {headerName: 'Conyuge_documento', field: 'conyuge_documento',sortable: true, filter: true,resizable: true },
    {headerName: 'Observaciones', field: 'observaciones',sortable: true, filter: true,resizable: true },
    {field: 'id_socio',hide: true }

  ];

  loading_socios_busqueda_anvanzada:boolean;

  destroy$: Subject<boolean> = new Subject<boolean>();
  frameworkComponents: any;

  constructor(private store:Store) {
    this.frameworkComponents = {
      buttonRenderer: VisualizarButtonComponent,
    }
  }

  ngOnInit(): void {
    this.store.select(state => state.socio.socios_busqueda_avanzada).pipe(takeUntil(this.destroy$)).subscribe(
      data => this.rowData = data
    )

    this.store.select(state => state.socio.socios_busqueda_avanzada_loading).pipe(takeUntil(this.destroy$)).subscribe(
      data => this.loading_socios_busqueda_anvanzada = data
    )

    fromEvent(this.sociosSearchInput.nativeElement, 'keyup').pipe(
       // get value
       map((event: any) => {
        return event.target.value;
      })
      // if character length greater then 2
      , filter(res => res.length > 2)
      // Time in milliseconds between key events
      , debounceTime(500)
       // If previous query is diffent from current
       , distinctUntilChanged()
       // subscription for response
       ,mergeMap((text: string) =>this.store.dispatch(new GetBusquedaSocios(text,this.radioSelected)))
       ,mergeMap((_) =>this.store.dispatch(new StartGetBusquedaSocios()))
    ).subscribe()
  }

  onItemChange(){
    this.sociosSearchInput.nativeElement.value = '';
    this.rowData=[]
  }

  socioSeleccionado(e){
    let  socio = e.rowData
    $(`#${this.id}`).modal('hide')
    this.clienteSeleccionado.emit(socio)
  }

  onRowDoubleClicked(e){
    let  socio = e.data
    $(`#${this.id}`).modal('hide')
    this.clienteSeleccionado.emit(socio)
  }

  openBusquedaAvanzada(){

    $(`#${this.id}`).modal('show')
  }


}
