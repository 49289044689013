<div class="card">
  <div class="card-header" style="background-color:#17a2b8; color: white">Recibo Semestral - Generar</div>
  <div class="card-body">

  <div class="row">
    <div class="col-md-12">
      <div class="alert alert-info text-informacion" role="alert">
        Antes de continuar deberá chequear que los valores finales de las tarifas sean correctos (ver tarifas).<br>
        En esta etapa se le solicitará que ingrese los datos generales del recibo semestral. Dicha información será utilizada por el sistema para el cálculo estimado de los conceptos semestrales, conceptos e importes, los cuales podrá modificar y permitirán generar los recibos semestrales.
      </div>
    </div>

  </div>



    <form  [formGroup]="reciboSemestralForm" (ngSubmit)="onGenerarConceptosSemestrales($event)" novalidate>
      <div class="row">
        <div class="col-md-12">
          <fieldset class="p-2" style="border: solid 1px #ddd">
            <legend style="font-size: 13px"><span class="badge badge-pill badge-info">1</span> Indique los datos generales del recibo semestral ... </legend>
            <div class="form-group row">
              <label for="selectSemestre" class="col-2 col-form-label col-form-label-sm">Semestre</label>

             <div class="col-sm-3">
                     <select class="form-control form-control-sm " id="selectSemestre" formControlName="semestre"
                     [ngClass]="{ 'is-invalid': isInvalidSemestreValue }">
                             <option value="" disabled>Seleccione un semestre</option>
                             <option value="1">1er Semestre</option>
                             <option value="2">2do Semestre</option>
                         </select>

                         <div *ngIf="isInvalidSemestreValue" class="invalid-feedback">
                          <small>Valor requerido</small>
                        </div>
             </div>

         </div>
         <div class="form-group row">
                 <label for="fechaEmisionInput" class="col-2 col-form-label col-form-label-sm ">Fecha de Emisión</label>

                 <div class="col-sm-3">
                 <input id="fechaEmisionInput" type="date" [attr.value]="fechaActual" formControlName="fechaEmision" class="form-control form-control-sm "
                 [ngClass]="{ 'is-invalid': isInvalidFechaEmisionValue  || reciboSemestralForm.hasError('esMenorFecha') }">
                 <div *ngIf="isInvalidFechaEmisionValue" class="invalid-feedback">
                  <small>Valor requerido</small>
                </div>
             </div>
         </div>
         <div class="form-group row">
                 <label for="fechaVencimientoInput" class="col-2 col-form-label col-form-label-sm">Fecha de Vencimiento</label>

                     <div class="col-sm-3">
                     <input id="fechaVencimientoInput" type="date" [attr.value]="fechaActual" formControlName="fechaVencimiento" class="form-control form-control-sm "
                     [ngClass]="{ 'is-invalid': isInvalidFechaVencimientoValue || reciboSemestralForm.hasError('esMenorFecha') }">
                     <div *ngIf="isInvalidFechaVencimientoValue" class="invalid-feedback">
                      <small>Valor requerido</small>
                    </div>
                 </div>
             </div>

             <div class="form-group row">
              <div class="col-md-3">
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="checkbox" id="aplicaDescuentoControl"  formControlName="aplicaDescuento">
                    <label class="form-check-label col-form-label-sm" for="aplicaDescuentoControl">Aplicar porcentaje de descuento?
                      <span [ngClass]="reciboSemestralForm.get('aplicaDescuento').value?'descuentoSi':'descuentoNo'">{{reciboSemestralForm.get('aplicaDescuento').value?'SI':'NO'}}</span>
                    </label>
                  </div>
            </div>
             </div>



             <div class="form-group row">
                     <label for="porcentajeInput" class="col-2 col-form-label col-form-label-sm">Porcentaje de descuento %</label>

                         <div class="col-sm-3">
                         <input id="porcentajeInput" type="text" type="text" formControlName="descuento" class="form-control form-control-sm " maxlength="2" appNumberEnteroOnly
                         [ngClass]="{ 'is-invalid': isInvalidDescuentoValue  }" autocomplete="off">
                         <div *ngIf="isInvalidDescuentoValue" class="invalid-feedback">
                          <small>Valor requerido</small>
                        </div>
                     </div>
                 </div>
                 <div class="row">
                  <div class="col-md-12">
                    <div *ngIf="reciboSemestralForm.hasError('esMenorFecha')" class="invalid-feedback col-3">
                      <small>La Fecha de Emisión debe ser menor a la Fecha de Vencimiento</small>
                    </div>
                  </div>
                </div>

          </fieldset>
        </div>


      </div>

      <div class="row mt-4">
        <div class="col-md-12 text-right">
          <button type="submit" class="btn btn-primary" [disabled]="reciboSemestralForm.invalid || isProcesando || mostrarListaConceptos" >
            <span *ngIf="isProcesando" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            Generar conceptos semestrales</button>
        </div>
      </div>
    </form>


    <div class="row mt-4" *ngIf="mostrarListaConceptos">
      <div class="col-md-12">
        <fieldset class="p-2" style="border: solid 1px #ddd">
          <legend style="font-size: 13px"><span class="badge badge-pill badge-info">2</span> Chequee los datos del recibo semestral y los conceptos obtenidos para cada una de las tarifas. En caso de ser correctos, procesada a generar los recibos semestrales ... </legend>
          <app-lista-conceptos [reciboSemetral]="reciboSemetral"
          [isGenerandoReciboSemestral]="isGenerandoReciboSemestral"
          (cancelarEvent)="onCancelar()"
          (generarRecibosEvent)="onGenerar($event)"
          ></app-lista-conceptos>


        </fieldset>
      </div>


    </div>





  </div>
</div>

<app-autorizacion (retornoOperacion)="retornoAutorizacion($event)"></app-autorizacion>
