
import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';


@Component({
  selector: 'app-button-renderer',
  template: `
    <button type="button" class="btn btn-sm" 
    [ngClass]="{
        'btn-outline-warning':classBtn === 'warning',
        'btn-outline-primary':classBtn === 'primary',
        'btn-outline-danger':classBtn === 'danger',
        'btn-outline-success':classBtn === 'success'        
      }"
     (click)="onClick($event)"><i class="fas"
     [ngClass]="{
        'fa-download':classBtn === 'warning',
        'fa-eye':classBtn === 'primary',
        'fa-trash-alt':classBtn === 'danger',
        'fa-print':classBtn === 'success'        
      }"
     ></i></button>    
    `
})

export class ButtonRendererComponent implements ICellRendererAngularComp {

  params;
  label: string;
  classBtn: string;

  agInit(params): void {
    this.params = params;
    this.label = this.params.label || null;
    this.classBtn = this.params.classBtn || null;
  }

  refresh(params?: any): boolean {
    return true;
  }

  onClick($event) {
    if (this.params.onClick instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: $event,
        rowData: this.params.node.data
        // ...something
      }
      this.params.onClick(params);

    }
  }
}