import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { SistemaConfiguracionService } from './sistema-configuracion.service';
import packageInfo from 'package.json'
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SistemaVersionGuard  {

  versionNumero= packageInfo.version

  constructor( private scs:SistemaConfiguracionService,private router: Router){}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {


  return  this.scs.getVersionSistema().pipe(
    map( versionData=> {
      if (versionData.version_sistema == this.versionNumero){
        return true
      }else{
        this.router.navigate(['/actualizar-sistema']);
        return false;
      }
    }

          )
  )



  }

}
