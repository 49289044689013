
export class GetConvenioPagoList{
  static readonly type = '[ConvenioPago] GetConvenioPagoList'
  constructor(){}
}



export class GetConvenioPagoDetail{
  static readonly type = '[ConvenioPago] GetConvenioPagoDetail'
  constructor(public convenio:number){}
}

export class NewConvenioPago{
    static readonly type = '[ConvenioPago] NewConvenioPago'
    constructor( public socio:number, public concepto:number, public importe:number, public cuotas:number, public valorCuota:number, public fecha:Date){}
}

export class CobranzaConvenioPago{
  static readonly type = '[ConvenioPago] CobranzaConvenioPago'
  constructor( public recibo:number, public convenio:number){}
}




