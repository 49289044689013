
<div class="row">
  <div class="col-md-5">
    <div class="input-group input-group-sm mb-3">
      <div class="input-group-prepend">
        <span class="input-group-text" id="inputGroup-sizing-sm"><i class="fa fa-search" aria-hidden="true"></i>
        </span>
      </div>
      <input type="text" id="busquedaSearchInput" #busquedaSearchInput class="form-control form-control-sm" placeholder="Ingrese el valor a buscar" />
    </div>
  </div>
  <div class="col-md-6" *ngIf="displayButtonExport">
    <button type="button" class="btn btn-sm btn-outline-success float-right ml-3" (click)="onBtExport()" title="Exportar a Excel"><i class="fas fa-file-excel"></i></button>
  </div>
</div>

<div [ngStyle]="style">
  <ag-grid-angular
  #agGridListView
  [style.height]="height"
  class="ag-theme-alpine"
  [rowData]="dataGrid"
  [colResizeDefault]="'fit'"
  [rowSelection]= "'single'"
  [rowHeight]="25"
  (selectionChanged)="onSelectionChanged()"
  >
  </ag-grid-angular>
</div>

