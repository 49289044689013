<div class="card">
    <div class="card-header" style="background-color:#17a2b8; color: white">Listado de Socios</div>
    <div class="card-body">

        <div class="row">
            <div class="col-md-6"><span><b>Total registros: {{filas}}</b></span></div>
<div class="col-md-6 ">
  <div class="float-right"><button type="button" class="btn btn-sm btn-outline-danger" (click)="clearFilter()" title="Quitar filtros"><i class="fas fa-eraser"></i></button>
    &nbsp;<button type="button" class="btn btn-sm btn-outline-success" (click)="onBtExport()" title="Exportar a Excel"><i class="fas fa-file-excel"></i></button></div>

</div>
        </div>

        <br>
    <div class="row">
        <div class="col-md-12">
            <ag-grid-angular
            #agGridSocios
            style="width: 100%; height: 500px;"
            class="ag-theme-alpine"
            [rowData]="rowData"
            [columnDefs]="columnDefs"
            [colResizeDefault]="'fit'"
            [rowSelection]= "'single'"
            [rowHeight]="25"
            (filterChanged)="onFilterChanged()"
            >
        </ag-grid-angular>
        </div>

      </div>

    </div>
  </div>
