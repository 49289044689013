<div class="card">
  <div class="card-header card-header-info">
    Crear convenio de pago
  </div>
  <div class="card-body">
    <div class="form-row">
      <div class="col-6">
        <label class="col-form-label-sm"><span class="campo-convenio">Código Socio:</span>
          {{socio.codSocio}}/{{socio.subCodSocio}}</label>
      </div>
      <div class="col-6">
        <label class="col-form-label-sm"><span class="campo-convenio">Fecha convenio:</span>
          {{fechaConvenio| date:'dd/MM/yyyy'}}</label>
      </div>
    </div>
    <div class="form-row">
      <div class="col-12">
        <label class="col-form-label-sm"><span class="campo-convenio">Nombre:</span> {{socio.nombre}}</label>
      </div>
    </div>
    <div class="form-row">
      <div class="col-6">
        <label class="col-form-label-sm"><span class="campo-convenio">Tipo Documento:</span>
          {{socio.tipoDocumentoDetalle}}</label>
      </div>
      <div class="col-6">
        <label class="col-form-label-sm"><span class="campo-convenio">Documento Nº:</span> {{socio.documento}}</label>
      </div>
    </div>
    <hr>
    <div class="form-row">
      <div class="col-7">
        <label class="col-form-label-sm" style="margin-bottom: 0;"><span class="campo-convenio">Domicilio
            real:</span></label>
        <input class="form-control form-control-sm" type="text" [(ngModel)]="domicilioReal">
      </div>
      <div class="col-5">
        <label class="col-form-label-sm" style="margin-bottom: 0;"><span class="campo-convenio">Ciudad:</span></label>
        <input class="form-control form-control-sm" type="text" [(ngModel)]="ciudadReal">
      </div>
    </div>
    <div class="form-row">
      <div class="col-7">
        <label class="col-form-label-sm" style="margin-bottom: 0;"><span class="campo-convenio">Domicilio
            legal:</span></label>
        <input class="form-control form-control-sm" type="text" [(ngModel)]="domicilioLegal">
      </div>
      <div class="col-5">
        <label class="col-form-label-sm" style="margin-bottom: 0;"><span class="campo-convenio">Ciudad:</span></label>
        <input class="form-control form-control-sm" type="text" [(ngModel)]="ciudadLegal">
      </div>
    </div>
    <div class="form-row">
      <div class="col-2">
        <label class="col-form-label-sm"><span class="campo-convenio">Lote:</span> {{socio.lote}}</label>
      </div>
      <div class="col-4">
        <label class="col-form-label-sm"><span class="campo-convenio">Manzana:</span> {{socio.manzana}}</label>
      </div>
      <div class="col-6">
        <label class="col-form-label-sm" style="margin-bottom: 0;"><span class="campo-convenio">Barrio:</span></label>
        <input class="form-control form-control-sm" type="text" [(ngModel)]="barrio">
      </div>
    </div>
    <hr>
    <div class="form-row">
      <div class="col-5">
        <label class="col-form-label-sm"><span class="campo-convenio">Importe deuda:</span> ${{importeDeuda}}</label>
      </div>
      <div class="col-3">
        <label class="col-form-label-sm"><span class="campo-convenio">Cuotas:</span> {{cantidadCuotas}}</label>
      </div>
      <div class="col-4">
        <label class="col-form-label-sm"><span class="campo-convenio">Valor cuota:</span> ${{valorCuota}}</label>
      </div>
    </div>
    <div class="form-row">
      <div class="col-6">
        <label class="col-form-label-sm"><span class="campo-convenio">Desde:</span> {{fechaDesdeConvenio}}</label>
      </div>
      <div class="col-6">
        <label class="col-form-label-sm"><span class="campo-convenio">Hasta:</span> {{fechaHastaConvenio}}</label>
      </div>
    </div>
    <div class="form-row">
      <div class="col-12">
        <label class="col-form-label-sm"><span class="campo-convenio">Importe correspondiente a Canon Municipal:</span>
          ${{importeCanonMunicipal}}</label>
      </div>
      <div class="col-12">
        <button *ngIf="!( (ocultarImpresionReciboCanonMunicipal$|async) || importeCanonMunicipal==0)" type="button"
          class="btn btn-outline-info btn-sm float-right" style="font-size: 13px; padding: inherit"
          (click)="imprimirReciboCanonMunicipal()">         
          Recibo Canon Municipal</button>
      </div>
    </div>
    <div class="form-row">
      <div class="col-12">
        <label class="col-form-label-sm"><span class="campo-convenio">Importe correspondiente a Costo de
            Intimación:</span> ${{importeIntimacion}}</label>
      </div>
      <div class="col-12">
        <button *ngIf="!( (ocultarImpresionReciboCostoIntimacion$|async) ||importeIntimacion==0)" type="button"
          class="btn btn-outline-info btn-sm float-right" style="font-size: 13px; padding: inherit"
          (click)="imprimirReciboCostoIntimacion()">Recibo Costo Intimación</button>
      </div>
    </div>
  </div>
  <div class="card-footer">
    <div class="float-right">
      <button class="btn btn-sm btn-outline-danger mr-5" (click)="salir()">Salir</button>
      <button type="button" class="btn  btn-sm btn-outline-info"
        (click)="imprimirConvenio()">
        <span *ngIf="isLoadingImpresionConvenio()" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        Imprimir convenio</button>
      &nbsp;
      <button type="button" class="btn btn-sm btn-outline-info"
        (click)="imprimirPagare()">
        <span *ngIf="isLoadingImpresionPagare()" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        Imprimir pagaré</button>
    </div>

  </div>
</div>

<app-visor-impresion #visorImpresion></app-visor-impresion>

<app-operacion-si-no [titulo]="'Nuevo Convenio'" [contenidoMensaje]="mensaje"
  (retornoOperacion)="retornoOperacionConsultaRecibo($event)" #siNoDatosConvenio [id]="'siNoDatosConvenio'"></app-operacion-si-no>

<app-operacion-informacion [titulo]="'Nuevo Convenio'"
  [contenidoMensaje]="'Debe cobrar el importe del '+mensajeOperacionConsulta+'Antes de imprimir el recibo ya que una vez impreso se registra automáticamente.'"
  (aceptarMensaje)="retornoOperacionInformacion($event)" #informacionDatosConvenio [id]="'informacionDatosConvenio'"></app-operacion-informacion>
