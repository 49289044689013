export class GetReciboSemestralHabilitado{
    static readonly type = '[ConfiguracionSistema] GetReciboSemestralHabilitado'
    constructor(){}
}


export class GetInformacionRecargos{
  static readonly type = '[ConfiguracionSistema] GetInformacionRecargos'
  constructor(){}
}

export class GetProximoNumeroPagare{
  static readonly type = '[ConfiguracionSistema] GetProximoNumeroPagare'
  constructor(){}
}
export class UpdateProximoNumeroPagare{
  static readonly type = '[ConfiguracionSistema] UpdateProximoNumeroPagare'
  constructor(){}
}


export class GetLeyendas{
  static readonly type = '[ConfiguracionSistema] GetLeyendas'
  constructor(){}
}

export class SaveLeyendas{
  static readonly type = '[ConfiguracionSistema] SaveLeyendas'
  constructor(public leyendas:string){}
}

