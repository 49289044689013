import { FiltroFecha, FiltroSocio, FiltroNumeroComprobante } from 'src/app/shared/modelos/filtro-fecha.model';

export class GetControlCobranza{
    static readonly type = '[Cobranza] GetControlCobranza'
    constructor(public fecha:FiltroFecha){}
}


export class GetComprobanteCobranzaPorNumeroComprobante{
    static readonly type = '[Cobranza] GetComprobanteCobranzaPorNumeroComprobante'
    constructor(public payload: FiltroNumeroComprobante){}
}

export class GetComprobanteCobranzaPorSocio{
    static readonly type = '[Cobranza] GetComprobanteCobranzaPorSocio'
    constructor(public payload: FiltroSocio){}
}

export class GetComprobanteCobranzaPorFecha{
    static readonly type = '[Cobranza] GetComprobanteCobranzaPorFecha'
    constructor(public fecha:FiltroFecha ){}
}

export class StartBusquedaComprobante{
    static readonly type = '[Cobranza] StartBusquedaComprobante'
    constructor(){}
}


