import { Injectable, computed,  inject, signal } from '@angular/core';
import { Notificaciones } from './notificaciones';
import { NotificacionesService } from 'src/app/notificaciones/notificaciones.service';
import {  tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificacionesStateService {


  private _ns = inject(NotificacionesService)

  private notificaciones = signal<Notificaciones[] | null>(null);

  private isLoading = signal<boolean>(false);

  private totalNotificaciones = computed (()=>{
    return this.notificaciones()?.length;
  })



  constructor() { }

  updateNotificaciones(){
    this.loadNotificaciones();
  }

  loadNotificaciones() {
    this.isLoading.set(true);
    this._ns.getNotificaciones().pipe(
      tap(() => this.isLoading.set(false))      
    ).subscribe(
      notificaciones => {
        this.notificaciones.set(notificaciones);    
      }
    )
  }

  getNotificaciones() {
    if(this.notificaciones() === null) this.loadNotificaciones();
    return this.notificaciones;
  }

  getTotalNotificaciones() {
    return this.totalNotificaciones;
  }

  getIsLoading() {
    return this.isLoading;
  }


  clearState(){  
    this.notificaciones.set(null);      
    this.isLoading.set(false); 
  
     }

}
