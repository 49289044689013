<div class="card">
  <div class="card-header card-header-info"><i class="fa fa-info-circle "></i> Información general
      <a href="javascript:;" title="editar información" class=" float-right" (click)="editar()"><i class="fas fa-user-edit"></i></a>
     </div>
  <div class="card-body"  *ngIf="!socio==false">
      <ul class="nav nav-tabs" id="myTab" role="tablist">
          <li class="nav-item">
            <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Domicilio conexión</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" id="profile-tab" data-toggle="tab" href="#domLegal" role="tab" aria-controls="domLegal" aria-selected="false">Domicilio legal</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" id="varios-tab" data-toggle="tab" href="#varios" role="tab" aria-controls="varios" aria-selected="false">Varios</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" id="lote-tab" data-toggle="tab" href="#lote-section" role="tab" aria-controls="lote-section" aria-selected="false">Lote</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" id="datosTec-tab" data-toggle="tab" href="#datosTec" role="tab" aria-controls="datosTec" aria-selected="false">Datos técnicos</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" id="debAut-tab" data-toggle="tab" href="#debAut" role="tab" aria-controls="debAut" aria-selected="false">Débito Automático</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" id="cisterna-tab" data-toggle="tab" href="#cisterna" role="tab" aria-controls="cisterna" aria-selected="false">Cisterna</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" id="planos-tab" data-toggle="tab" href="#planos" role="tab" aria-controls="planos" aria-selected="false">Planos</a>
          </li>
        </ul>
        <div class="tab-content mt-3" id="myTabContent">
          <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab"><!--inicio tab DOMICILIO DE CONEXION-->
              <form>
                  <div class="form-group row">
                    <label for="inputBarrio" class="col-sm-1 col-form-label col-form-label-sm text-right">Barrio</label>
                    <div class="col-sm-4">
                      <input type="text" class="form-control form-control-sm" id="inputBarrio" placeholder="" [value]="socio.barrio" readonly>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="codigo" class="col-sm-1 col-form-label col-form-label-sm text-right">Calle</label>
                    <div class="col-sm-1">
                      <input type="text" class="form-control form-control-sm" id="codigo" placeholder="" [value]="socio.codCalle" readonly>
                    </div>
                    <div class="col">
                      <input type="text" class="form-control form-control-sm" id="callenom" placeholder="" [value]="socio.calle" readonly>
                    </div>
                    <label for="nro" class="col-sm-1 col-form-label col-form-label-sm text-right">Nro.</label>
                    <div class="col-sm-1">
                      <input type="text" class="form-control form-control-sm" id="nro" placeholder="" [value]="socio.numero" readonly>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="adicDomic" class="col-sm-2 col-form-label col-form-label-sm">Piso, Depto, etc.</label>
                    <div class="col-sm-4">
                      <input type="text" class="form-control form-control-sm" id="adicDomic" placeholder="" [value]="socio.adicDomic" readonly>
                    </div>
                    <label for="distrito" class="col-sm-2 col-form-label col-form-label-sm text-right">Distrito Elect.</label>
                    <div class="col-sm-1">
                      <input type="text" class="form-control form-control-sm" id="distrito" placeholder="" [value]="socio.distrito" readonly>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="observacionConexion" class="col-sm-2 col-form-label col-form-label-sm text-right">Observación</label>
                    <div class="col">
                      <input type="text" class="form-control form-control-sm" id="observacionConexion" placeholder="" [value]="socio.observacionConexion" readonly>
                    </div>
                  </div>
                </form>
          </div>
          <div class="tab-pane fade" id="domLegal" role="tabpanel" aria-labelledby="profile-tab"><!--inicio tab DOMICLIO LEGAL-->
            <form>
              <div class="form-group row">
                <label for="pcia" class="col-sm-1 col-form-label col-form-label-sm text-right">Provincia</label>
                <div class="col">
                  <input type="text" class="form-control form-control-sm" id="pcia" placeholder="" [value]="socio.provinciaLegal" readonly>
                </div>
                <label for="localidadLegal" class="col-sm-1 col-form-label col-form-label-sm text-right">Localidad</label>
                <div class="col">
                  <input type="text" class="form-control form-control-sm" id="localidadLegal" placeholder="" [value]="socio.localidadLegal" readonly>
                </div>
                <label for="codPostal" class="col-sm-1 col-form-label col-form-label-sm text-right">Cód. Postal</label>
                <div class="col-sm-2">
                  <input type="text" class="form-control form-control-sm" id="codPostal" placeholder="" [value]="socio.codPostalLegal" readonly>
                </div>
              </div>
              <div class="form-group row">
                <label for="barrioLegal" class="col-sm-1 col-form-label col-form-label-sm text-right">Barrio</label>
                <div class="col-sm-4">
                  <input type="text" class="form-control form-control-sm" id="barrioLegal" placeholder="" [value]="socio.barrioLegal" readonly>
                </div>
              </div>
              <div class="form-group row">
                <label for="codigoCalleLegal" class="col-sm-1 col-form-label col-form-label-sm text-right">Calle</label>
                <div class="col-sm-1">
                  <input type="text" class="form-control form-control-sm" id="codigoCalleLegal" placeholder="" [value]="socio.codCalleLegal" readonly>
                </div>
                <div class="col">
                  <input type="text" class="form-control form-control-sm" id="calleLegal" placeholder="" [value]="socio.calleLegal" readonly>
                </div>
                <label for="nroLegal" class="col-sm-1 col-form-label col-form-label-sm text-right">Nro.</label>
                <div class="col-sm-1">
                  <input type="text" class="form-control form-control-sm" id="nroLegal" placeholder="" [value]="socio.nroLegal" readonly>
                </div>
              </div>
              <div class="form-group row">
                <label for="adicDomicLegal" class="col-sm-2 col-form-label col-form-label-sm">Piso, Depto, etc.</label>
                <div class="col-sm-4">
                  <input type="text" class="form-control form-control-sm" id="adicDomicLegal" placeholder="" [value]="socio.adicDomicLegal" readonly>
                </div>
              </div>
            </form>
          </div>
          <div class="tab-pane fade" id="varios" role="tabpanel" aria-labelledby="varios-tab"><!--inicio tab VARIOS-->
            <div class="row">
            <div class="col-sm-6">
              <div class="form-group row">
                <label for="profesion" class="col-sm-2 col-form-label col-form-label-sm text-right">Profesión</label>
                <div class="col">
                  <input type="text" class="form-control form-control-sm" id="profesion" placeholder="" [value]="socio.profesion" readonly>
                </div>
              </div>

              <fieldset class="p-2" style="border: solid 1px #ddd">
                  <legend >Conyuge</legend>
                <div class="form-group row">
                  <label for="conyuge_nombre" class="col-sm-2 col-form-label col-form-label-sm text-right">Nombre</label>
                  <div class="col">
                    <input type="text" class="form-control form-control-sm" id="conyuge_nombre" placeholder="" [value]="socio.conyuge_nombre" readonly>
                  </div>
                </div>
                <div class="form-group row">
                    <label for="conyuge_documento" class="col-sm-3 col-form-label col-form-label-sm">Documento</label>
                    <div class="col-sm-3">
                      <input type="text" class="form-control form-control-sm" id="conyuge_documento" placeholder="" [value]="socio.conyuge_documento" readonly>
                    </div>
                    <div class="col">
                        <input type="text" class="form-control form-control-sm" id="conyuge_tipodoc" placeholder="" [value]="socio.conyuge_tipodocDetalle" readonly>
                      </div>
                  </div>
              </fieldset>
            </div>
            <div class="col-sm-5">
              <br>
                <fieldset class="p-2" style="border: solid 1px #ddd">
                    <legend >Convenio Coop. de Luz</legend>
                  <div class="form-group row">
                    <label for="id_coopluz" class="col col-form-label col-form-label-sm text-right">Nro. Socio en Coop. de Luz</label>
                    <div class="col">
                      <input type="text" class="form-control form-control-sm" id="id_coopluz" placeholder="" [value]="socio.id_coopluz" readonly>
                    </div>
                  </div>
                  <div class="form-group row">
                      <label for="fechaConvCoopLuz" class="col col-form-label col-form-label-sm text-right">Fecha de alta del convenio</label>
                      <div class="col">
                        <input type="text" class="form-control form-control-sm" id="fechaConvCoopLuz" placeholder="" [value]="socio.fechaConvCoopLuz | date: 'dd/MM/yyyy'" readonly>
                      </div>
                  </div>
                </fieldset>
            </div>
          </div>
          </div>
          <div class="tab-pane fade" id="lote-section" role="tabpanel" aria-labelledby="lote-tab"><!--comienzo tab LOTE-->
            <div class="row">
                <div class="col-7">
                  <fieldset class="p-2" style="border: solid 1px #ddd">
                      <legend >Información catastral</legend>
                    <div class="form-group row">
                      <label for="nomenclatura" class="col-sm-3 col-form-label col-form-label-sm text-right">Nomenclatura</label>
                      <div class="col">
                        <input type="text" class="form-control form-control-sm" id="nomenclatura" placeholder="" [value]="socio.nomenclatura" readonly>
                      </div>
                    </div>
                    <div class="form-group row">
                        <label for="manzana" class="col-sm-3 col-form-label col-form-label-sm text-right">Manzana</label>
                        <div class="col">
                          <input type="text" class="form-control form-control-sm" id="manzana" placeholder="" [value]="socio.manzana" readonly>
                        </div>
                        <label for="lote" class="col-sm-2 col-form-label col-form-label-sm text-right">Lote</label>
                        <div class="col">
                            <input type="text" class="form-control form-control-sm" id="lote" placeholder="" [value]="socio.lote" readonly>
                          </div>
                      </div>
                  </fieldset>
                </div>
                <div class="col-5">
                    <fieldset class="p-2" style="border: solid 1px #ddd">
                        <legend >Ruta</legend>
                      <div class="form-group row">
                        <label for="ruta" class="col-sm-3 col-form-label col-form-label-sm text-right">Ruta Nro.</label>
                        <div class="col">
                          <input type="text" class="form-control form-control-sm" id="ruta" placeholder="" [value]="socio.ruta" readonly>
                        </div>
                      </div>
                      <div class="form-group row">
                          <label for="orden" class="col-sm-3 col-form-label col-form-label-sm text-right">Orden</label>
                          <div class="col">
                            <input type="text" class="form-control form-control-sm" id="orden" placeholder="" [value]="socio.orden" readonly>
                          </div>
                      </div>
                    </fieldset>
                </div>
          </div>
          </div>
          <div class="tab-pane fade" id="datosTec" role="tabpanel" aria-labelledby="datosTec-tab"><!--comienzo de DATOS TECNICOS-->
            <div class="row">
                <div class="col-4">
                    <fieldset class="p-2" style="border: solid 1px #ddd">
                      <legend >Medidor</legend>
                      <div class="form-group row">
                        <label for="medidor" class="col-sm-5 col-form-label col-form-label-sm text-right">Número</label>
                        <div class="col">
                          <input type="text" class="form-control form-control-sm" id="medidor" placeholder="" [value]="socio.medidor" readonly>
                        </div>
                      </div>
                      <div class="form-group row">
                          <label for="marcaMedidor" class="col-sm-5 col-form-label col-form-label-sm text-right">Marca</label>
                          <div class="col">
                            <input type="text" class="form-control form-control-sm" id="marcaMedidor" placeholder="" [value]="socio.marcaMedidor" readonly>
                          </div>
                      </div>
                      <div class="form-group row">
                          <label for="lecturaAnt" class="col-sm-5 col-form-label col-form-label-sm text-right">Estado</label>
                          <div class="col">
                            <input type="text" class="form-control form-control-sm" id="lecturaAnt" placeholder="" [value]="socio.lecturaAnt" readonly>
                          </div>
                      </div>
                      <div class="form-group row">
                          <label for="fLecturaAnt" class="col-sm-5 col-form-label col-form-label-sm text-right">Última lect.</label>
                          <div class="col">
                            <input type="text" class="form-control form-control-sm" id="fLecturaAnt" placeholder="" [value]="socio.fLecturaAnt | date: 'dd/MM/yyyy'" readonly>
                          </div>
                      </div>
                    </fieldset>
                </div>
                <div class="col-4">
                    <fieldset class="p-2" style="border: solid 1px #ddd">
                        <legend >Ubicación servicio domiciliario</legend>
                        <div class="form-group row">
                          <label for="ubicServicio" class="col-sm-5 col-form-label col-form-label-sm text-right">Dist. a medianera</label>
                          <div class="col">
                            <input type="text" class="form-control form-control-sm" id="ubicServicio" placeholder="" [value]="socio.ubicServicio" readonly>
                          </div>
                        </div>
                        <div class="form-group row">
                            <label for="medianera" class="col-sm-5 col-form-label col-form-label-sm text-right">Medianera</label>
                            <div class="col">
                              <input type="text" class="form-control form-control-sm" id="medianera" placeholder="" [value]="socio.detalle_medianera" readonly>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="tipoConexion" class="col-sm-5 col-form-label col-form-label-sm text-right">Tipo conexión</label>
                            <div class="col">
                              <input type="text" class="form-control form-control-sm" id="tipoConexion" placeholder="" [value]="socio.detalle_conexion" readonly>
                            </div>
                        </div>
                      </fieldset>
                </div>
                  <div class="col-4">
                      <fieldset class="p-2" style="border: solid 1px #ddd">
                          <legend >Red troncal</legend>
                          <div class="form-group row">
                            <label for="distanciaARed" class="col-sm-5 col-form-label col-form-label-sm text-right">Dist a linea municip.</label>
                            <div class="col">
                              <input type="text" class="form-control form-control-sm" id="distanciaARed" placeholder="" [value]="socio.distanciaARed" readonly>
                            </div>
                          </div>
                          <div class="form-group row">
                              <label for="profundidadRed" class="col-sm-5 col-form-label col-form-label-sm text-right">Profundidad</label>
                              <div class="col">
                                <input type="text" class="form-control form-control-sm" id="profundidadRed" placeholder="" [value]="socio.profundidadRed" readonly>
                              </div>
                          </div>
                          <div class="form-group row">
                              <label for="tipoMaterial" class="col-sm-5 col-form-label col-form-label-sm text-right">Tipo de material</label>
                              <div class="col">
                                <input type="text" class="form-control form-control-sm" id="tipoMaterial" placeholder="" [value]="socio.tipoMaterial" readonly>
                              </div>
                          </div>
                          <div class="form-group row">
                              <label for="diametroRed" class="col-sm-5 col-form-label col-form-label-sm text-right">Diámetro (en mm)</label>
                              <div class="col">
                                <input type="text" class="form-control form-control-sm" id="diametroRed" placeholder="" [value]="socio.diametroRed" readonly>
                              </div>
                          </div>
                        </fieldset>
                </div>
            </div>
          </div>
          <div class="tab-pane fade" id="debAut" role="tabpanel" aria-labelledby="debAut-tab">
            <div class="row">
                <div class="col-md-6">
                    <fieldset class="p-2" style="border: solid 1px #ddd">
                        <legend >Información bancaria</legend>
                        <div class="form-group row">
                          <label for="debAutPrestador" class="col-sm-4 col-form-label col-form-label-sm text-right">Adherido al débito automático</label>
                          <div class="col">
                            <input type="text" class="form-control form-control-sm" id="debAutPrestador" placeholder="" [value]="socio.debitoAutomatico?'Si':'No'" readonly>
                          </div>
                        </div>
                        <div class="form-group row">
                            <label for="banco" class="col-sm-4 col-form-label col-form-label-sm text-right">Banco</label>
                            <div class="col">
                              <input type="text" class="form-control form-control-sm" id="banco" placeholder="" [value]="socio.banco" readonly>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="CBU" class="col-sm-4 col-form-label col-form-label-sm text-right">CBU/Cuenta</label>
                            <div class="col">
                              <input type="text" class="form-control form-control-sm" id="CBU" placeholder="" [value]="socio.CBU" readonly>
                            </div>
                        </div>
                      </fieldset>
                </div>
                <div class="col-6">
                    <fieldset class="p-2" style="border: solid 1px #ddd">
                        <legend >Tarjeta Naranja</legend>
                        <div class="form-group row">
                          <label for="nroTarjeta_TN" class="col-sm-4 col-form-label col-form-label-sm text-right">Número de tarjeta</label>
                          <div class="col">
                            <input type="text" class="form-control form-control-sm" id="nroTarjeta_TN" placeholder="" [value]="socio.nroTarjeta_TN" readonly>
                          </div>
                        </div>
                        <div class="form-group row">
                            <label for="fechaAlta_TN" class="col-sm-4 col-form-label col-form-label-sm text-right">Fecha de alta</label>
                            <div class="col">
                              <input type="text" class="form-control form-control-sm" id="fechaAlta_TN" placeholder="" [value]="socio.fechaAlta_TN | date: 'dd/MM/yyyy'" readonly>
                            </div>
                        </div>
                      </fieldset>
                </div>
            </div>
          </div>

          <div class="tab-pane fade" id="cisterna" role="tabpanel" aria-labelledby="cisterna-tab">
            <app-cisterna></app-cisterna>
          </div>

          <div class="tab-pane fade" id="planos" role="tabpanel" aria-labelledby="cisterna-tab">
            <app-planos></app-planos>
          </div>

  </div>
</div>

