import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Barrio } from "src/app/store/barrio/models";
import { ItemsLista } from "src/app/store/general/models";
import { SocioDetalle } from "src/app/store/socio/models";
import { ValidacionFormularioSocio } from "../validacion-formulario-socio";

@Component({
  selector: "app-domicilio-conexion-info",
  templateUrl: "./domicilio-conexion-info.component.html",
  styleUrls: ["./domicilio-conexion-info.component.css"]
})
export class DomicilioConexionInfoComponent implements OnInit {

  @Input() socio: SocioDetalle;
  @Input() barrios: Barrio[];
  @Input() calles: ItemsLista[];

  @Output() nuevaCalleEvent = new EventEmitter<any>();

  public validadoresFormularioSocio: ValidacionFormularioSocio = new ValidacionFormularioSocio();

  socioForm: UntypedFormGroup
  constructor( private fb: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.validadoresFormularioSocio.setCalles(this.calles);
    this.validadoresFormularioSocio.setBarrios(this.barrios);

    this.socioForm = this.initSocioFormModel();
  }

  initSocioFormModel(){

    return this.fb.group({
      barrioConexion: [
        this.socio.barrio,
        [
          Validators.required,
          this.validadoresFormularioSocio.esBarrioPlottierValido(),
        ],
      ],
      id_barrioConexion: [this.socio.id_barrio],
      codCalleConexion: [this.socio.codCalle],
      calleConexion: [
        this.socio.calle,
        [
          Validators.required,
          this.validadoresFormularioSocio.esCallePlottierValida() ,
        ],
      ],
      numeroCalleConexion: [this.socio.numero],
      pisoDepartamentoConexion: [this.socio.adicDomic],
      distritoElectoral: [this.socio.distrito, Validators.required],
      observacionConexion: [this.socio.observacionConexion],
    })}

  get barrioConexionField() {
    return this.socioForm.controls.barrioConexion;
  }

  get isInvalidBarrioConexionField() {
    return this.barrioConexionField.touched && this.barrioConexionField.invalid;
  }

  get calleConexionField() {
    return this.socioForm.controls.calleConexion;
  }

  get isInvalidCalleConexionField() {
    return this.calleConexionField.touched && this.calleConexionField.invalid;
  }

  get distritoElectoralField() {
    return this.socioForm.controls.distritoElectoral;
  }

  get isInvalidDistritoElectoralField() {
    return (
      this.distritoElectoralField.touched && this.distritoElectoralField.invalid
    );
  }

  onSelectionBarrioChange(event, control) {
    const barrioSeleccion = this.barrios.filter(
      (barrio) => barrio.barrio == event.target.value
    );

    if (barrioSeleccion.length > 0) {
      this.socioForm.get(control).setValue(barrioSeleccion[0].id);
    }
  }

  onSelectionCalleConexion(event) {
    const calleSeleccion = this.calles.filter(
      (calle) => calle.detalle == event.target.value
    );

    if (calleSeleccion.length > 0) {
      this.socioForm.get("codCalleConexion").setValue(calleSeleccion[0].id);
    }
  }

  nuevaCalle() {
    this.nuevaCalleEvent.emit();
  }

  get isValidForm(){
    return this.socioForm.valid
  }

  showInvalidControl(){
    ValidacionFormularioSocio.setErrorsInForm(this.socioForm);
  }

  get formValue(){
    return this.socioForm.getRawValue()
  }
}
