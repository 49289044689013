import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import {  tap, retry } from 'rxjs/operators';
import * as Sentry from "@sentry/angular-ivy";
import { Router } from '@angular/router';
import { AuthState } from 'src/app/store/auth/state';
import { Logout } from 'src/app/store/auth/actions';
import { environment } from 'src/environments/environment';





@Injectable()

export class AuthInterceptorService implements HttpInterceptor{

  constructor( private store: Store) { }

  intercept(req: HttpRequest<any>,
    next: HttpHandler): Observable<HttpEvent<any>> {


  const token = this.store.selectSnapshot(AuthState.token);
      if (token !== undefined){
        const cloned = req.clone({
        headers: req.headers.set("Authorization", token),
            },
          );

            return next.handle(cloned);

      }else{
         return next.handle(req);
      }
  }
}



@Injectable()
export class AuthInterceptorServiceResponse implements HttpInterceptor{

  constructor( private store: Store,private router: Router, ) { }

  intercept(req: HttpRequest<any>,
    next: HttpHandler): Observable<HttpEvent<any>> {


      return next.handle(req)
      .pipe(
        retry(1),
        tap(
          // Succeeds when there is a response; ignore other events
          event => {},
          // Operation failed; error is an HttpErrorResponse
          error => {
            if (error instanceof HttpErrorResponse) {

                

            if (error.status === 401) {
              //sesión expirada
              this.store.dispatch(new Logout());
              this.router.navigate(['/login'])
              if (environment.production) {
                Sentry.captureException({error,body:req.body})
            }
            }
            if (error.status === 403) {
              this.router.navigate(['/administrador/operacion-denegada'])
            }
            if (error.status === 500) {

            
              alert('Ha ocurrido un error mientras se intentaba realizar la operación. Por favor vuelva a intentar nuevamente la operación. En caso de persistir comuníquese con el administrador del sistema.')
              if (environment.production) {
                Sentry.captureException({error,body:req.body})
            }

            }
          }}
        ))


  }
}
