import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { SocioDetalle } from 'src/app/store/socio/models';
import { Store } from '@ngxs/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
declare var $:any;

@Component({
  selector: 'app-socio-informacion-gral',
  templateUrl: './socio-informacion-gral.component.html',
  styleUrls: ['./socio-informacion-gral.component.css']
})
export class SocioInformacionGralComponent implements OnInit,OnDestroy {

  @Output() operacionEditar:EventEmitter<string>= new EventEmitter()
  public socio:SocioDetalle;  
  
  destroy$: Subject<boolean> = new Subject<boolean>();
  
  constructor(private store:Store) { }

  ngOnInit() {

    this.store.select(state => state.socio.socio_detalle).pipe(takeUntil(this.destroy$)).subscribe(
      data => {
                  this.socio=data;                                           
      }  
    );
  }

  editar(){
    
    let seccion:string;

    switch ($('.nav-link.active')[0].text) {
      case "Domicilio conexión" :
        seccion ='domicilioConexion'
        break;
        case "Domicilio legal" :
        seccion ='domicilioLegal'
        break;
        case "Varios" :
        seccion ='varios'
        break;
        case "Lote" :
        seccion ='lote'
        break;
        case "Datos técnicos" :
        seccion ='datosTecnicos'
        break;
        case "Débito Automático" :
        seccion ='debitoAutomatico'
        break;    
    }

    
    this.operacionEditar.emit(seccion)
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }


}
