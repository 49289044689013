import { State, StateContext, Action } from "@ngxs/store";
import { GetPeriodos, GetPrestadoresDebitoAutomatico, GetTiposDni, GetTiposIva, GetCategorias, GetEstados, GetFinSupension, GetTarifas, GetFinTarifas, GetZonas, GetSituacionesEspecial, GetCalles, GetProvincias, GetTiposMedianeras, GetTiposConexion, GetTiposMaterial, GetCondicionRegistro, GetBancos, SaveCalles, UpdateCalles, DeleteCalles, GetCajas, SaveZonas, EditZonas, DeleteZonas, SaveEstado, EditEstado, DeleteEstado } from './actions';
import { GeneralService } from 'src/app/general/general.service';
import { Periodo, ItemsLista, ItemsListaConFin, ItemsListaCaja, ItemsListaZona, ItemsListaConexion } from './models';
import { Injectable } from '@angular/core';
import { mergeMap } from 'rxjs/operators';

export class GeneralStateModel {
    periodos: Periodo[];
    tiposDni: ItemsLista[];
    tiposIva: ItemsLista[];
    categorias: ItemsLista[];
    estados: ItemsListaConexion[];
    inicializadoEstados:boolean;
    finSuspension: ItemsLista[];
    tarifas: ItemsListaConFin[];
    finTarifas: ItemsLista[];
    zonas: ItemsListaZona[];
    inicializadoZonas:boolean;
    situacionesEspecial: ItemsListaConFin[];
    calles: ItemsLista[];
    provincias: ItemsLista[];
    tiposMedianeras: ItemsLista[];
    tiposConexion: ItemsLista[];
    tiposMaterial: ItemsLista[];
    prestadoresDebitoAutomatico: ItemsLista[];
    condicionesRegistro: ItemsLista[];
    bancos: ItemsLista[];
    cajas: ItemsListaCaja[];
}


@State<GeneralStateModel>({
    name: 'general',
    defaults: {
        periodos: [],
        tiposDni: [],
        tiposIva: [],
        categorias: [],
        estados: [],
        inicializadoEstados:false,
        finSuspension: [],
        tarifas: [],
        finTarifas: [],
        zonas: [],
        inicializadoZonas:false,
        situacionesEspecial: [],
        calles: [],
        provincias: [],
        tiposMedianeras: [],
        tiposConexion: [],
        tiposMaterial: [],
        prestadoresDebitoAutomatico: [],
        condicionesRegistro: [],
        bancos: [],
        cajas: []
    }
})


@Injectable()
export class GeneralState {

    constructor(private gs: GeneralService) { }

    @Action(GetPeriodos)
    getPeriodos(ctx: StateContext<GeneralStateModel>) {
        const state = ctx.getState()

        if (state.periodos.length == 0) {
            this.gs.getPeriodos().subscribe(
                data => {
                    ctx.patchState({
                        periodos: data
                    });
                }
            )
        }

    }


    @Action(GetTiposDni)
    getTiposDni(ctx: StateContext<GeneralStateModel>) {
        const state = ctx.getState()
        // llamamos a la api si los tiposDni no poseen datos
        // de esta manera evitamos ir a buscar los datos cada vez que los necesitemos

        if (state.tiposDni.length == 0) {
            this.gs.getTiposDni().subscribe(
                data => {
                    ctx.patchState({
                        tiposDni: data
                    }
                    )

                }
            )
        }

    }


    @Action(GetTiposIva)
    getTiposIva(ctx: StateContext<GeneralStateModel>) {
        const state = ctx.getState()

        if (state.tiposIva.length == 0) {
            this.gs.getTiposIva().subscribe(
                data => {
                    ctx.patchState({
                        tiposIva: data
                    }
                    )
                }
            )
        }

    }


    @Action(GetCategorias)
    getCategorias(ctx: StateContext<GeneralStateModel>) {
        const state = ctx.getState()

        if (state.categorias.length == 0) {
            this.gs.getCategorias().subscribe(
                data => {
                    ctx.patchState({
                        categorias: data
                    }
                    )
                }
            )
        }

    }


    @Action(GetEstados)
    getEstados(ctx: StateContext<GeneralStateModel>, action: GetEstados) {
        const state = ctx.getState()
        let seguir = false

        if(!action.update && !state.inicializadoEstados){seguir=true}
        if(action.update && state.inicializadoEstados){seguir=true}

        if(seguir){

          this.gs.getEstados().subscribe(
            data => {
                ctx.patchState({
                    estados: data,
                    inicializadoEstados:true
                }
                )
            }
        )
        }
    }


    @Action(SaveEstado)
    saveEstado(ctx: StateContext<GeneralStateModel>, action:SaveEstado) {
      return this.gs.saveEstado(action.estado,action.facturar)
      .pipe(
       mergeMap(()=> ctx.dispatch(new GetEstados(true)))
     )

    }

    @Action(EditEstado)
    editEstado(ctx: StateContext<GeneralStateModel>, action:EditEstado) {

      return this.gs.editEstado(action.idEstado,action.estado,action.facturar)
      .pipe(
       mergeMap(()=> ctx.dispatch(new GetEstados(true)))
     )

    }

    @Action(DeleteEstado)
    deleteEstado(ctx: StateContext<GeneralStateModel>, action:DeleteEstado) {

      return this.gs.deleteEstado(action.idEstado,action.estado, action.facturar)
      .pipe(
       mergeMap(()=> ctx.dispatch(new GetEstados(true)))
     )

    }




    @Action(GetFinSupension)
    getFinSupension(ctx: StateContext<GeneralStateModel>) {
        const state = ctx.getState()

        if (state.finSuspension.length == 0) {
            this.gs.getFinSupension().subscribe(
                data => {
                    ctx.patchState({
                        finSuspension: data
                    }
                    )
                }
            )
        }

    }


    @Action(GetTarifas)
    GetTarifas(ctx: StateContext<GeneralStateModel>) {
        const state = ctx.getState()

        if (state.tarifas.length == 0) {
            this.gs.getTarifas().subscribe(
                data => {
                    ctx.patchState({
                        tarifas: data
                    }
                    )
                }
            )
        }

    }


    @Action(GetFinTarifas)
    getFinTarifas(ctx: StateContext<GeneralStateModel>) {
        const state = ctx.getState()

        if (state.finTarifas.length == 0) {
            this.gs.getFinTarifas().subscribe(
                data => {
                    ctx.patchState({
                        finTarifas: data
                    }
                    )
                }
            )
        }

    }


    @Action(GetZonas)
    getZonas(ctx: StateContext<GeneralStateModel>, action:GetZonas) {
        const state = ctx.getState()
        let seguir = false

        if(!action.update && !state.inicializadoZonas){seguir=true}
        if(action.update && state.inicializadoZonas){seguir=true}

        if(seguir){this.gs.getZonas().subscribe(
          data => {
              ctx.patchState({
                  zonas: data,
                  inicializadoZonas:true
              }
              )
          }
      )}


    }

    @Action(SaveZonas)
    saveZonas(ctx: StateContext<GeneralStateModel>, action:SaveZonas) {
      return this.gs.saveZonas(action.zona,action.imprimir)
      .pipe(
       mergeMap(()=> ctx.dispatch(new GetZonas(true)))
     )

    }

    @Action(EditZonas)
    editZonas(ctx: StateContext<GeneralStateModel>, action:EditZonas) {

      return this.gs.editZonas(action.idZona,action.zona,action.imprimir)
      .pipe(
       mergeMap(()=> ctx.dispatch(new GetZonas(true)))
     )

    }

    @Action(DeleteZonas)
    deleteZonas(ctx: StateContext<GeneralStateModel>, action:DeleteZonas) {

      return this.gs.deleteZonas(action.idZona,action.zona, action.imprimir)
      .pipe(
       mergeMap(()=> ctx.dispatch(new GetZonas(true)))
     )

    }




    @Action(GetSituacionesEspecial)
    getSituacionesEspecial(ctx: StateContext<GeneralStateModel>) {
        const state = ctx.getState()

        if (state.situacionesEspecial.length == 0) {
            this.gs.getSituacionesEspecial().subscribe(
                data => {
                    ctx.patchState({
                        situacionesEspecial: data
                    }
                    )
                }
            )
        }

    }



    @Action(GetCalles)
    getCalles({ patchState }: StateContext<GeneralStateModel>) {
        this.gs.getCalles().subscribe(
            data => {
                patchState({
                    calles: data
                });
            }
        )
    }


    @Action(GetProvincias)
    getProvincias(ctx: StateContext<GeneralStateModel>) {
        const state = ctx.getState()

        if (state.provincias.length == 0) {
            this.gs.getProvincias().subscribe(
                data => {
                    ctx.patchState({
                        provincias: data
                    }
                    )
                }
            )
        }

    }


    @Action(GetTiposMedianeras)
    getTiposMedianeras(ctx: StateContext<GeneralStateModel>) {
        const state = ctx.getState()
        if (state.tiposMedianeras.length == 0) {
            this.gs.getTiposMedianeras().subscribe(
                data => {
                    ctx.patchState({
                        tiposMedianeras: data
                    }
                    )
                }
            )
        }

    }


    @Action(GetTiposConexion)
    getTiposConexion(ctx: StateContext<GeneralStateModel>) {
        const state = ctx.getState()

        if (state.tiposConexion.length == 0) {
            this.gs.getTiposConexion().subscribe(
                data => {
                    ctx.patchState({
                        tiposConexion: data
                    }
                    )
                }
            )
        }

    }


    @Action(GetTiposMaterial)
    getTiposMaterial(ctx: StateContext<GeneralStateModel>) {
        const state = ctx.getState()

        if (state.tiposMaterial.length == 0) {
            this.gs.getTiposMaterial().subscribe(
                data => {
                    ctx.patchState({
                        tiposMaterial: data
                    }
                    )
                }
            )
        }

    }

    @Action(GetPrestadoresDebitoAutomatico)
    getPrestadoresDebitoAutomatico(ctx: StateContext<GeneralStateModel>) {
        const state = ctx.getState()

        if (state.prestadoresDebitoAutomatico.length == 0) {
            this.gs.getPrestadoresDebitoAutomatico().subscribe(
                data => {
                    ctx.patchState({
                        prestadoresDebitoAutomatico: data
                    }
                    )
                }
            )
        }

    }


    @Action(GetCondicionRegistro)
    getCondicionRegistro(ctx: StateContext<GeneralStateModel>) {
        const state = ctx.getState()

        if (state.condicionesRegistro.length == 0) {
            this.gs.getCondicionRegistro().subscribe(
                data => {
                    ctx.patchState({
                        condicionesRegistro: data
                    }
                    )
                }
            )
        }

    }


    @Action(GetBancos)
    getBancos(ctx: StateContext<GeneralStateModel>) {
        const state = ctx.getState()

        if (state.bancos.length == 0) {
            this.gs.getBancos().subscribe(
                data => {
                    ctx.patchState({
                        bancos: data
                    }
                    )
                }
            )
        }

    }
    @Action(GetCajas)
    getCajas(ctx: StateContext<GeneralStateModel>) {
        const state = ctx.getState()

        if (state.cajas.length == 0) {
            this.gs.getCajas().subscribe(
                data => {
                    ctx.patchState({
                        cajas: data
                    }
                    )
                }
            )
        }

    }



    @Action(SaveCalles)
    saveCalles({ patchState }: StateContext<GeneralStateModel>, action: SaveCalles) {
        return this.gs.guardarCalles(action.idCalle, action.nombre);
    }

    @Action(UpdateCalles)
    updateCalles({ patchState }: StateContext<GeneralStateModel>, action: UpdateCalles) {
        return this.gs.actualizarCalles(action.idCalle, action.nombre);
    }

    @Action(DeleteCalles)
    deleteCalles({ patchState }: StateContext<GeneralStateModel>, action: DeleteCalles) {
        return this.gs.eliminarCalles(action.idCalle)
    }







}
