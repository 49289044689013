
 <div *ngIf="!reciboSemestral == false ; else sinRecibosParaMostrar" class="card shadow listadas">

    <ul class="list-group list-group-flush">
      <li class="list-group-item text-secondary" style="background-color: #ced6e0">
        <div class="row">
          <div class="col-10"><b> Pago Semestral -
              <span *ngIf="reciboSemestral.semestre==1"> Primer Semestre</span>
              <span *ngIf="reciboSemestral.semestre==2"> Segundo Semestre</span>
          </b> </div>
          <div class="col-2">
            <span *ngIf="reciboSemestral.estado_pago==1" class="badge badge-danger">Impaga</span>
            <span *ngIf="reciboSemestral.estado_pago==3" class="badge badge-success">Pagada</span>
          </div>
        </div>



    </li>
    <li class="list-group-item text-secondary"  style="font-size: small;">
      <fieldset>
        <legend>Detalle Recibo</legend>
        <div class="row">
          <div class="col-6">Recibo:  {{reciboSemestral.numero_recibo}}</div>
          <div class="col-6">Vencimiento : {{reciboSemestral.fecha_vencimiento | date: 'dd-MM-yyyy'}}</div>
      </div>
      <ng-container *ngFor="let concepto of reciboSemestral.conceptos">
        
          <div class="row">
            <div class="col-md-9">{{concepto.concepto}}:</div>
            <div class="col-md-3 text-right"><b> ${{concepto.valor |formatDecimal}} </b></div>
          </div>
        
    </ng-container>
    <div class="row">
      <div class="col-md-9">Importe total recibo:</div>
      <div class="col-md-3 text-right"><b> ${{reciboSemestral.importe_total |formatDecimal}} </b></div>
    </div>
      </fieldset>
    </li>
      

    <ng-container *ngIf="mostrarDescuentoPorCreditoAFavor">
        <li class="list-group-item text-secondary">
          <div class="row">
            <div class="col-md-9">Descuento por crédito a favor:</div>
            <div class="col-md-3 text-right"><b> $-{{montoADescontarPorCreditoAFavor |formatDecimal}} </b></div>
          </div>
        </li>
    </ng-container>

    <ng-container *ngIf="mostrarDescuentoFacturasSemestresPreviamenteAbonadas">
        <li class="list-group-item text-secondary">
          <div class="row">
            <div class="col-md-9">Descuento por factura del semestre previamente abonada:</div>
            <div class="col-md-3 text-right"><b> $-{{montoADescontarPorFacturasSemestresPreviamenteAbonadas |formatDecimal}} </b></div>
          </div>
        </li>
    </ng-container>

    <ng-container *ngIf="mostrarDescuentoPorDebitoAutomatico">
      <li class="list-group-item text-secondary">
        <div class="row">
          <div class="col-md-9">Descuento por adhesión débito automático del 10%:</div>
          <div class="col-md-3 text-right"><b> $-{{montoADescontarPorDebitoAutomatico |formatDecimal}} </b></div>
        </div>
      </li>
  </ng-container>

  <ng-container *ngIf="mostrarDescuentoPorAjustePorRedondeo">
    <li class="list-group-item text-secondary">
      <div class="row">
        <div class="col-md-9">Descuento por ajuste por redondeo:</div>
        <div class="col-md-3 text-right"><b> $-{{montoADescontarPorAjustePorRedondeo |formatDecimal}} </b></div>
      </div>
    </li>
</ng-container>


    <li class="list-group-item" *ngIf="reciboSemestral.estado_pago==1">
      <div class="row">
        <div class="col-md-9"><strong>Total a pagar:</strong></div>
        <div class="col-md-3 text-right"><b> ${{totalReciboAPagar |formatDecimal}} </b></div>
      </div>
    </li>

      <li class="list-group-item">
          <div class="row">

            @if (reciboSemestral.estado_pago==1) {
              <div class="col-md-3"  >
              @if (!mostrarDescuentoPorAjustePorRedondeo) {
                <button type="button" class="btn btn-outline-info btn-sm h-100" title="aplicar descuento sobre el total a pagar" (click)="onAplicarDescuentoPorRedondeo()">Aplicar dto. por redondeo</button>
              }@else {
                <button type="button" class="btn btn-outline-danger btn-sm h-100" title="quitar descuento aplicado sobre el total a pagar" (click)="onQuitarDescuentoPorRedondeo()">Quitar dto. por redondeo</button>
              }
            </div>  
            }
              
              <div class="col-md-3"  *ngIf="montoCreditoRegistrado>0 && reciboSemestral.estado_pago==1 && !mostrarDescuentoPorCreditoAFavor"><button type="button" class="btn btn-outline-info btn-sm h-100" title="aplicar descuento sobre el total del recibo semestral" (click)="onAplicarDescuentoPorCreditoAFavor()">Aplicar dto. por crédito a favor</button></div>
              <div class="col-md-3"  *ngIf="montoCreditoRegistrado>0 && reciboSemestral.estado_pago==1 && mostrarDescuentoPorCreditoAFavor" ><button type="button" class="btn btn-outline-danger btn-sm h-100" title="quitar descuento aplicado sobre recibo semestral" (click)="onQuitarDescuentoPorCreditoAFavor()">Quitar dto. por crédito a favor</button></div>
              <div class="col-md-3"  *ngIf="montoFacturasSemestresPreviamenteAbonadas>0 && reciboSemestral.estado_pago==1 && !mostrarDescuentoFacturasSemestresPreviamenteAbonadas"><button type="button" class="btn btn-outline-info btn-sm h-100" title="aplicar descuento sobre el total del recibo semestral" (click)="onAplicarDescuentoPorFacturasDelSemestreAbonadas()">Aplicar dto. por facturas abonadas</button></div>
              <div class="col-md-3"  *ngIf="montoFacturasSemestresPreviamenteAbonadas>0 && reciboSemestral.estado_pago==1 && mostrarDescuentoFacturasSemestresPreviamenteAbonadas" ><button type="button" class="btn btn-outline-danger btn-sm h-100" title="quitar descuento aplicado sobre recibo semestral" (click)="onQuitarDescuentoPorFacturasDelSemestreAbonadas()">Quitar dto. por facturas abonadas</button></div>
              
              @if (estaSocioAdheridoDebitoAutomatico() && reciboSemestral.estado_pago==1) {
                <div class="col-md-3"  >
                @if (!mostrarDescuentoPorDebitoAutomatico) {
                  <button type="button" class="btn btn-outline-info btn-sm h-100" title="aplicar descuento sobre el total del recibo semestral" (click)="onAplicarDescuentoPorDebitoAutomatico()">Aplicar dto. por débito automático</button>
                }@else {
                  <button type="button" class="btn btn-outline-danger btn-sm h-100" title="quitar descuento aplicado sobre el total del recibo semestral" (click)="onQuitarDescuentoPorDebitoAutomatico()">Quitar dto. por débito automático</button>
                }
              </div>  
              }
              
              
             
              

            </div>

      </li>
      <li class="list-group-item">
        <div class="row d-flex justify-content-center">
          <div class="col-md-4"><button type="button" class="btn btn-outline-info btn-sm" title="Imprimir Recibo Semestral" (click)="imprimirReciboSemestral()" ><i class="fas fa-print"></i> &nbsp;Imprimir Recibo</button></div>
          <div class="col-md-4" *ngIf="reciboSemestral.estado_pago==1" ><button  (click)="pagar()" style="float: right;" type="button" class="btn btn-success btn-sm btn-block"  [disabled]="pagoEnProceso || deshabilitarPago " >Pagar</button></div>
          <div class="col-md-6" *ngIf="reciboSemestral.estado_pago==3"><button  type="button" class="btn btn-outline-info btn-sm" (click)="imprimirComprobantePago()" ><i class="fas fa-print"></i> &nbsp;Imprimir Comprobante Pago</button></div>
        </div>
      </li>
    </ul>

</div>


<ng-template #sinRecibosParaMostrar>

    <div class="row justify-content-md-center">

        <div class="alert alert-info" role="alert">
          No existen recibos disponibles para pago ...
        </div>

    </div>
    <div class="row justify-content-md-center mt-4">
      <div class="col-md-3">
        <button *ngIf="reciboInformacionSemestral" type="button" class="btn btn-outline-info btn-sm" (click)="generarReciboCliente()">Generar Recibo {{reciboInformacionSemestral.semestre}}</button>
      </div>
    </div>

</ng-template>

<app-operacion-si-no [titulo]="'Pago Semestral'" [contenidoMensaje]="'Desea realizar la operación? recuerde que al pagar el recibo, las facturas del semestre serán marcadas como pagadas.'"
     (retornoOperacion)="retornoOperacionSiNo($event)"></app-operacion-si-no>



     <app-autorizacion (retornoOperacion)="retornoAutorizacion($event)"></app-autorizacion>
     <app-operacion-loading></app-operacion-loading>

