import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ReciboSimple, ReciboSimpleData } from '../store/recibo/models';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json'
  })
};


@Injectable({
  providedIn: 'root'
})
export class ReciboSimpleService {

  urlRest = environment;
  constructor(private http: HttpClient) { }

   // Recibo simple
   saveReciboSimple(recibo:ReciboSimple):Observable<any>{
    return this.http.post<any>(`${this.urlRest.urlapiNode}/recibos/simple`,{recibo},httpOptions)
  }


  getReciboSimpleList():Observable<ReciboSimpleData[]>{
    return this.http.get<ReciboSimpleData[]>(`${this.urlRest.urlapiNode}/recibos/simple`)
  }
  

  getReciboSimple(recibo:number):Observable<any>{
    return this.http.get(`${this.urlRest.urlapiNode}/recibos/simple/${recibo}`)
  }

  deleteReciboSimple(recibo:number,observacion:string,usuarioAutorizante:number):Observable<any>{
    let params = new HttpParams()
    .set('recibo',recibo.toString())
    .set('usuarioAutorizante',usuarioAutorizante.toString())
    .set('observacion',observacion)
    return this.http.delete(`${this.urlRest.urlapiNode}/recibos/simple`,{params})
  }

 

  imprimirReciboSimple(recibo:number):Observable<any>{
    return this.http.get(`${this.urlRest.urlapiNode}/recibos/simple/impresion/${recibo}`,{ responseType: 'blob'})
  }
}
