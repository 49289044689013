    <div class="card">
      <div class="card-header card-header-info">
          Convenios de Pago
      </div>
      <div class="card-body">

          <div class="row">
              <div class="col-md-12">

                    <ag-grid-angular
                    #agGridConvenios
                    style="width: 100%; height: 200px;"
                    class="ag-theme-alpine"
                    [rowData]="rowData"
                    [columnDefs]="columnDefs"
                    [colResizeDefault]="'fit'"
                    [rowSelection]= "'single'"

                    [rowHeight]="25"
                    ></ag-grid-angular>
              </div>
      </div>
      <div class="row mt-3">
        <div class="col-md-12">
          <button  type="button" title="generar nuevo convenio" class="btn btn-sm btn-outline-primary"  (click)="nuevoConvenio()" *appRole="permisoModoRequeridoABMConvenios"><i class="fas fa-plus"></i> Nuevo convenio</button>
          <button type="button" title="editar convenio" class="btn btn-sm btn-outline-primary ml-2" (click)="editarConvenio()" *appRole="permisoModoRequeridoABMConvenios"><i class="far fa-edit"></i> Modificar convenio</button>
          <button type="button" title="eliminar convenio" class="btn btn-sm btn-outline-danger ml-2" (click)="onEliminarConvenio()" *appRole="permisoModoRequeridoABMConvenios"><i class="far fa-trash-alt"></i> Eliminar convenio</button>
        </div>
      </div>
  </div>
  </div>


