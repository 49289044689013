<div class="card">
    <div class="card-header" style="background-color:#17a2b8; color: white">Créditos - Imputar Créditos Otorgados</div>
    <div class="card-body">
        <div class="row">
            <div class="col-md-12">
                    <div class="jumbotron jumbotron-fluid">
                            <div class="container">
                                <p>A continuación podrá impactar, reflejar, en la próxima facturación el crédito otorgado a los
                                    socios/usuarios. Tenga bien tener en cuenta los siguientes puntos: </p>

                                    <ul>
                                        <li>Los períodos en el cual se impactará el crédito serán: {{periodo1}}-{{periodo2}} los cuales aún no han sido facturados.</li>
                                        <li>Se tendrá en cuenta el monto total de crédito registrado del socio/usuario para realizar
                                            el descuento.</li>
                                        <li>El monto a descontar será inferior o igual al monto indicado en el cuadro tarifario del respectivo socio/usuario.</li>
                                        <li>Recuerde que luego de impactar los créditos deberá realizar el proceso de facturación.</li>
                                    </ul>
                                    <button type="button" class="btn btn-outline-primary" (click)="obtenerListadoAImputar()">Obtener listado de créditos a imputar</button>
                            </div>
                        </div>
            </div>

        </div>
        <br>
        <div class="row" *ngIf="rowData.length>0">
            <div class="col-md-6"><span><b>Total de imputaciones a realizar: {{rowData.length}}</b></span></div>
<div class="col-md-6 ">
  <div class="float-right">
    <button type="button" class="btn btn-outline-success" (click)="onBtExport()" title="Exportar a Excel"><i class="fas fa-file-excel"></i></button></div>

</div>
        </div>
        <br>
        <div class="row">
            <div class="col-md-12">
                         <ag-grid-angular
                          #agGridCreditosImputar
                          style="width: 100%; height: 350px;"
                          class="ag-theme-alpine"
                          [rowData]="rowData"
                          [columnDefs]="columnDefs"
                          [colResizeDefault]="'fit'"
                          [rowSelection]= "'single'"
                          ></ag-grid-angular>
            </div>
        </div>
        <br>
        <div class="row" >
            <div class="col-12 mt-5" *ngIf="rowData.length>0">
                <ng-container *ngIf="!estaProcesando; else procesando">
                  <button type="button" class="btn btn-success right"  (click)="imputar()">Imputar Créditos</button>
              </ng-container>
              <ng-template #procesando>
                  <button class="btn btn-info right" type="button" >
                      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      <span class="ml-2">Procesando... La operación puede demorar algunos segundos ...</span>
                    </button>
              </ng-template>
              </div>
        </div>
    </div>
</div>



<app-operacion-si-no [titulo]="'Imputación de Créditos Otorgados'"  [contenidoMensaje]="'Desea realizar la imputación de los créditos listados?'"
(retornoOperacion)="retornoOperacion($event)"></app-operacion-si-no>

