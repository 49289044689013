import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ReciboSemestralPago, ReciboSemestralDetalle, ReciboSemestralListado, InformacionReciboSemestralActual, ReciboSimple, FacturasSemestrePagas } from '../store/recibo/models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class ReciboService {

  urlRest = environment;

  constructor(private http: HttpClient) { }




  getInformacionReciboSemestralActivo():Observable<InformacionReciboSemestralActual>{
    return this.http.get<InformacionReciboSemestralActual>(`${this.urlRest.urlapiNode}/recibos/semestral/activo`)
  }

  getListadoReciboSemestralCliente(cliente:number):Observable<ReciboSemestralListado[]>{
    return this.http.get<ReciboSemestralListado[]>(`${this.urlRest.urlapiNode}/recibos/semestral/cliente/listado/${cliente}`)
    }

  getReciboSemestralCliente(cliente:number):Observable<ReciboSemestralPago>{
   return this.http.get<ReciboSemestralPago>(`${this.urlRest.urlapiNode}/recibos/semestral/cliente/${cliente}`)
   }



   getCodSociosRecibosSemestrales(recibo:number):Observable<any>{
    return this.http.get(`${this.urlRest.urlapiNode}/recibos/semestral/${recibo}`).pipe(
      map(response =>  response[0])
    )
   }


   imprimirReciboSemestral(recibo:number):Observable<any>{
    return this.http.post(`${this.urlRest.urlapiNode}/recibos/semestral/imprimir`,{recibo},{ responseType: 'blob'})
  }

  

  pagarReciboSemestral(recibo:number,cliente:number,totalRecibo:number,
    descuentos:any[],
    caja:number,fechaCobranza:string,bancoCobranza:string,
    numeroTransferenciaCobranza:string,
    usuarioAutorizante):Observable<any>{
    return this.http.post(`${this.urlRest.urlapiNode}/cobranza/recibos/pago`,{recibo,cliente,totalRecibo,descuentos,caja,fechaCobranza,bancoCobranza,numeroTransferenciaCobranza,usuarioAutorizante},httpOptions)

  }

  getReciboSemestralComprobantePago(recibo:number,cliente:number):Observable<any>{
    return this.http.get(`${this.urlRest.urlapiNode}/recibos/semestral/comprobante/${recibo}/${cliente}`,{ responseType: 'blob'})
    }

    getDetalleReciboSemestralComprobantePago(numeroRecibo:number):Observable<ReciboSemestralDetalle[]>{
      return this.http.get<ReciboSemestralDetalle[]>(`${this.urlRest.urlapiNode}/recibos/semestral/pagado/${numeroRecibo}`)
      }

      getInformacionFacturasPagasReciboSemestreActivo(cliente:number):Observable<FacturasSemestrePagas[]>{
        return this.http.get<FacturasSemestrePagas[]>(`${this.urlRest.urlapiNode}/recibos/semestral/activo/facturas/pagas/${cliente}`)
        }

      anularCobroRecibo(recibo:number,comprobanteCaja:number,cliente:number,usuario:number):Observable<any>{
        return this.http.put(`${this.urlRest.urlapiNode}/recibos/semestral/pago/anular`,{'recibo':recibo,'comprobanteCaja':comprobanteCaja,'cliente':cliente,'usuario':usuario},httpOptions)
      }

      generarArchivoRendicionRedLinkRefresh():Observable<any>{
        return this.http.get(`${this.urlRest.urlapiNode}/recibos/semestral/presentacion-link/generar/refresh`,{ responseType: 'blob'})
       }

       generarArchivoRendicionRedLinkControl():Observable<any>{
        return this.http.get(`${this.urlRest.urlapiNode}/recibos/semestral/presentacion-link/generar/control`,{ responseType: 'blob'})
       }


       impactarEnFacturasPagoReciboSemestral():Observable<any>{
        return this.http.put(`${this.urlRest.urlapiNode}/recibos/semestral/pago/impactar-facturas`,httpOptions)
      }



      generarReciboSemestral(reciboSemestral:any):Observable<any>{
        return this.http.post(`${this.urlRest.urlapiNode}/recibos/semestral/generar`,{reciboSemestral},httpOptions)
      }

      generarReciboSemestralCliente(recibo:number,cliente:number):Observable<any>{
        return this.http.post(`${this.urlRest.urlapiNode}/recibos/semestral/generar/cliente`,{recibo,cliente},httpOptions)
      }



      generarConceptosTarifasReciboSemestral(
        semestre:number,
        fechaEmision:string,fechaVencimiento:string,aplicaDescuento:boolean,porcentajeDescuento:number
        ):Observable<any>{
        return this.http.post(`${this.urlRest.urlapiNode}/recibos/semestral/generar/conceptos-tarifas`,{semestre,fechaEmision,fechaVencimiento,aplicaDescuento,porcentajeDescuento})
      }


      generarArchivoRendicionRecibosSemestralesPagoMisCuentas():Observable<any>{
        return this.http.get(`${this.urlRest.urlapiNode}/recibos/semestral/presentacion-link/generar/refresh`,{ responseType: 'blob'})
       }


    }
