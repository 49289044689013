import { Component, OnInit, ViewChild, ElementRef, inject } from '@angular/core';
import { Router } from '@angular/router';
import { SrcImg } from 'src/app/shared/src-img';
import { Store } from '@ngxs/store';
import { Login } from 'src/app/store/auth/actions';
import { BehaviorSubject, of, throwError } from 'rxjs';
import {  GetReciboSemestralHabilitado } from 'src/app/store/sistema-configuracion/actions';
import { catchError, tap } from 'rxjs/operators';
import packageInfo from 'package.json'
import { environment } from 'src/environments/environment';
import { NotificacionesStateService } from 'src/app/store/notificaciones/notificaciones-state.service';




@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  @ViewChild('user', { static: true }) user: ElementRef;

  public usuario:string =  !environment.production?"mmunoz":null;
  public password:string = !environment.production?"mmun099":null;
  public errorLogin= false;
  public sesionExpirada : boolean;

  srcimg: SrcImg = new SrcImg();

  private loadingSubject = new BehaviorSubject<boolean>(false);
public loading$ = this.loadingSubject.asObservable();

versionNumero= packageInfo.version

  private _notificaciones = inject(NotificacionesStateService);

  constructor(private router: Router,private store:Store) { }

  ngOnInit( ) {

  }

  onSubmit() {
    this.loadingSubject.next(true);
    this.store.dispatch(new Login({username:this.usuario,password:this.password})).pipe(

      tap(_=> {this.loadingSubject.next(false);
      this.iniciarConfiguracionSistema();
      this.router.navigate(['/'])}),

      catchError(err => {
        if (err === 'Unauthorized'){
          this.loadingSubject.next(false);
          this.errorLogin = true;
          this.setUnauthorizedForm();
        }
        return throwError(err);
      })
      ).subscribe();


   }

   setUnauthorizedForm(){
   // this.user.nativeElement.focus();
}

iniciarConfiguracionSistema(){
  this.store.dispatch(new GetReciboSemestralHabilitado())
  this._notificaciones.loadNotificaciones();
}

}
