<form [formGroup]="convenioForm" (ngSubmit)="editarConvenio($event)" class="needs-validation" novalidate>
  <div class="card">
    <div class="card-header card-header-info">Editar Convenio</div>
    <div class="card-body">
        <div class="mb-1 row">
          <label for="inputFecha" class="col-sm-3 col-form-label col-form-label-sm">Fecha convenio</label>
          <div class="col-sm-4">
            <input type="text" class="form-control form-control-sm"
             id="inputFecha" formControlName="fecha">
          </div>
        </div>
        <div class="mb-1 row">
          <label for="inputImporte" class="col-sm-3 col-form-label col-form-label-sm">Importe</label>
          <div class="col-sm-4">
            <input type="text" class="form-control form-control-sm"
             id="inputImporte" formControlName="importe">
          </div>
        </div>
        <div class="mb-1 row">
          <label for="inputCuotas" class="col-sm-3 col-form-label col-form-label-sm">Cuotas</label>
          <div class="col-sm-4">
            <input type="text" class="form-control form-control-sm"
             id="inputCuotas" formControlName="cuotas">
          </div>
        </div>
        <div class="mb-1 row">
          <label for="inputValorCuotas" class="col-sm-3 col-form-label col-form-label-sm">Valor cuotas</label>
          <div class="col-sm-4">
            <input type="text" class="form-control form-control-sm"
             id="inputValorCuotas" formControlName="valorCuota">
          </div>
        </div>

        <div class="mb-1 row">
          <label for="inputFacturadas" class="col-sm-3 col-form-label col-form-label-sm">Facturadas</label>
          <div class="col-sm-4">
            <input type="text" class="form-control form-control-sm"
            id="inputFacturadas"
            [class.is-invalid]="valorFacturadasIsInvalid"
            maxlength="5" formControlName="facturadas" appNumberEnteroOnly>
          </div>
          <div *ngIf="valorFacturadasIsInvalid" class="col-sm-5">
            <div *ngIf="getFacturadasField.hasError('required'); else menorQue" class="invalid-feedback"> valor requerido!</div>
            <ng-template #menorQue>
              <div class="invalid-feedback"> supera el total de cuotas</div>
            </ng-template>
        </div>
        </div>

    </div>

    <div class="card-footer ">
      <div class="float-right">
        <button (click)="cancelar()" class="btn  btn-sm btn-outline-danger">Cancelar</button> &nbsp;
        <button class="btn btn-success btn-sm" type="submit" [disabled]="convenioForm.invalid">Guardar</button>
      </div>

    </div>



  </div>

  </form>


