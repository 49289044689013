import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup,  Validators } from '@angular/forms';
import { OperacionesABM } from 'src/app/shared/enums/enums';
import { Time } from 'src/app/utils/time';
import { ItemsLista, ItemsListaConFin } from 'src/app/store/general/models';
import { SocioDetalle } from 'src/app/store/socio/models';
import { ValidacionFormularioSocio } from '../validacion-formulario-socio';

@Component({
  selector: 'app-datos-conexion-info',
  templateUrl: './datos-conexion-info.component.html',
  styleUrls: ['./datos-conexion-info.component.css']
})
export class DatosConexionInfoComponent implements OnInit {

  @Input() modoOperacion: OperacionesABM;
  @Input() socio: SocioDetalle;
  @Input() estados: ItemsLista[]
  @Input() finSuspension: ItemsLista[]
  @Input() tarifas: ItemsListaConFin[];
  @Input() zonas: ItemsLista[]
  @Input() situacionesEspecial: ItemsListaConFin[]


  socioForm: UntypedFormGroup

  constructor( private fb: UntypedFormBuilder) { }


  ngOnInit(): void {
    this.socioForm = this.initSocioFormModel();
    this.setearSocioForm()
  }

  initSocioFormModel(){
    return this.fb.group({
      estadoConexion: [this.socio.id_estado, Validators.required],
      finEstadoConexion: [
        { value: this.socio.finsuspension, disabled: true },
      ],
      tarifa: [this.socio.id_tarifa, Validators.required],
      zona: [this.socio.id_zona, Validators.required],
      situacionEspecial: [this.socio.id_situacion, Validators.required],
      fechaInicioSituacionEspecial: [
        { value: Time.formatDate(this.socio.fecha_inicio_situacion_especial,"YYYY-MM-DD"), disabled: true },
        Validators.required,
      ],
      fechaFinSituacionEspecial:[Time.formatDate(this.socio.fecha_fin_situacion_especial,"YYYY-MM-DD")]
    })
  }

  setearSocioForm(){
    this.situacionEspecialField.valueChanges.subscribe(value => {
      // si es un acuerdo por ordenanza 2312 habilitamos la fecha desde
      // deberiamos obtener dicho valor de la base de datos


      let requierefin = this.situacionesEspecial.filter(x => x.id === +value)[0].requierefin

      if(requierefin){
        this.fechaInicioSituacionEspecialField.enable()
      }else{
        this.fechaInicioSituacionEspecialField.reset()
        this.fechaInicioSituacionEspecialField.disable()
      }

    })

    this.fechaInicioSituacionEspecialField.valueChanges.subscribe(value => {

      // calculamos la fecha de fin de acuerdo a la fecha de inicio
      this.setFechaFinSituacionEspecial(value)
    })

    this.zonaField.valueChanges.subscribe(value=>{

      if (this.modoOperacion == OperacionesABM.editar) {
            if(this.socio.debitoAutomatico){
              if (+value !== 2) {
                alert("Recuerde que el socio tiene habilitado el débito automático. Al modificar la zona no se realizará dicho débito.")
              }
            }
      }

    })

    if (this.modoOperacion == OperacionesABM.editar) {

      if(this.socio.requiereFinSituacionEspecial){
        this.situacionEspecialField.disable()
        this.setFechaFinSituacionEspecial(this.fechaInicioSituacionEspecialField.value)
        this.fechaInicioSituacionEspecialField.disable()
      }


    }
  }


  get estadoConexionField(){
    return this.socioForm.controls.estadoConexion
  }

  get isInvalidEstadoConexionField(){
    return this.estadoConexionField.touched && this.estadoConexionField.invalid
  }

  get tarifaField(){
    return this.socioForm.controls.tarifa
  }

  get isInvalidTarifaField(){
    return this.tarifaField.touched && this.tarifaField.invalid
  }

  get zonaField(){
    return this.socioForm.controls.zona
  }

  get isInvalidZonaField(){
    return this.zonaField.touched && this.zonaField.invalid
  }

  get situacionEspecialField(){
    return this.socioForm.controls.situacionEspecial
  }

  get isInvalidSituacionEspecialField(){
    return this.situacionEspecialField.touched && this.situacionEspecialField.invalid
  }

  get fechaInicioSituacionEspecialField(){
    return this.socioForm.controls.fechaInicioSituacionEspecial
  }


  get fechaFinSituacionEspecialField(){
    return this.socioForm.controls.fechaFinSituacionEspecial
  }

  get isInvalidFechaInicioSituacionEspecialField(){
    return this.fechaInicioSituacionEspecialField.touched && this.fechaInicioSituacionEspecialField.invalid
  }

  get isValidForm(){
    return this.socioForm.valid
  }

  get situacionEspecial(){
    return this.situacionesEspecial.filter(x => x.id === +this.situacionEspecialField.value)[0]
  }

  setFechaFinSituacionEspecial(fechaInicioSituacionEspecial){
    let infoSituacion = this.situacionEspecial
    const fechaFinSituacionEspecial=   Time.add(fechaInicioSituacionEspecial,infoSituacion.duracion,infoSituacion.unidad_de_tiempo)
    this.fechaFinSituacionEspecialField.setValue(fechaFinSituacionEspecial)


  }



  showInvalidControl(){
    ValidacionFormularioSocio.setErrorsInForm(this.socioForm);
  }

  get formValue(){
    return this.socioForm.getRawValue()
  }

}
