import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Subscription, fromEvent, Subject } from 'rxjs';
import { filter, takeUntil, tap, mergeMap, distinctUntilChanged, map } from 'rxjs/operators';
import { FacturaService } from 'src/app/factura/factura.service';
import { OperacionSiNoComponent } from 'src/app/shared/operacion-si-no/operacion-si-no.component';
import { InformacionCajaComponent } from 'src/app/factura/informacion-caja/informacion-caja.component';
import { Store } from '@ngxs/store';
import { PagarFacturas, GetFacturasPagas, GetFacturasImpagasActualizadas } from 'src/app/store/factura/actions';
import { OperacionExitosaComponent } from 'src/app/shared/operacion-exitosa/operacion-exitosa.component';
import { OperacionLoadingComponent } from 'src/app/shared/operacion-loading/operacion-loading.component';
import { HttpResponse } from '@angular/common/http';
import { Imprimir } from 'src/app/shared/imprimir';
import { GetObservaciones } from 'src/app/store/observacion/actions';
import { GetCuentaCorriente } from 'src/app/store/cuentacorriente/actions';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-cobranza-simple',
  templateUrl: './cobranza-simple.component.html',
  styleUrls: ['./cobranza-simple.component.css']
})
export class CobranzaSimpleComponent implements OnInit {

  @ViewChild(InformacionCajaComponent, { static: true })
  informacionCajaComponent: InformacionCajaComponent;

  @ViewChild(OperacionSiNoComponent, { static: true })
  operacionSiNoComponent: OperacionSiNoComponent;

  @ViewChild('inputFactura',{static:true}) inputFactura:ElementRef

  @ViewChild('pagarBtn',{static:true}) pagarBtn: ElementRef

  @ViewChild(OperacionExitosaComponent, { static: true })
  operacionExitosaComponent: OperacionExitosaComponent;

  @ViewChild(OperacionLoadingComponent, { static: true })
  operacionLoadingComponent: OperacionLoadingComponent;

  buscarFacturaSubscription:Subscription
  factura:any

  noExisteFacturaSearchInput:boolean=false

  public numeroDeComprobante: string;

  public imprimir = new Imprimir();



  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private store:Store,private fs:FacturaService) {
  }

  ngOnInit(): void {

    this.store.select(state=>state.factura.numeroComprobantePagoGenerado).pipe(takeUntil(this.destroy$)).subscribe(
      numeroDeComprobante => {this.numeroDeComprobante = numeroDeComprobante}
    )
  }

  buscarFactura(nroFactura){
    this.noExisteFacturaSearchInput = false
    this.fs.getFactura(nroFactura).subscribe(
      facturaReturn=>{
        this.factura = facturaReturn

        if(!facturaReturn){
          this.noExisteFacturaSearchInput = true
        }else{
          if(facturaReturn.Estado===1){
            this.setFocus(this.pagarBtn)
          }

        }

      }
    )

  }



  pagar(){
    this.operacionSiNoComponent.open();
  }

  retornoOperacionSiNo(retorno){
    if(retorno){

      let opcionesCaja=this.informacionCajaComponent.getOpcionesPago()
      let facturaPago = {'Factura':this.factura.Factura,'Total':this.factura.Total,'Neto':this.factura.Neto,'IVA':this.factura.IVA,'Recargo':this.factura.Recargo,'IVARec':this.factura.IVARec}
      const self = this
      this.store.dispatch(new PagarFacturas([facturaPago],this.factura.id_socio,this.factura.Total,
        this.factura.Total,0,opcionesCaja.caja,opcionesCaja.fechaCobranza,opcionesCaja.banco,opcionesCaja.numeroTransferencia)).subscribe(
         () => {

          Swal.fire(
            '','El pago ha sido realizado con éxito!',
            'success'
          ).then(()=>this.retornoOperacionExitosaPagoFactura())

          }
       )
    }
  }

  retornoOperacionExitosaPagoFactura(){

    this.operacionLoadingComponent.loadingTitle="Generando comprobante de pago";
    this.operacionLoadingComponent.open();

    this.fs.getComprobantePagoFacturaMultiple(this.numeroDeComprobante,this.factura.id_socio)
    .pipe(
      tap((contenido: HttpResponse<Blob>)=>{
        this.imprimir.imprimir(contenido);
        this.operacionLoadingComponent.close();
       }),
       mergeMap(()=> this.store.dispatch(new GetFacturasPagas(this.factura.id_socio,true))),
      mergeMap(()=>this.store.dispatch(new GetFacturasImpagasActualizadas(this.factura.id_socio,true))),
      mergeMap(()=> this.store.dispatch(new GetObservaciones(this.factura.id_socio,true))),
      mergeMap(()=>this.store.dispatch(new GetCuentaCorriente(this.factura.id_socio,true)))
    )
    .subscribe(
      ()=> {this.factura = null; this.inputFactura.nativeElement.value = ""}
    )
  }


  ngAfterViewInit(){
    this.buscarFacturaSubscription =  fromEvent(this.inputFactura.nativeElement, 'keyup').pipe(
      filter( (e:any)=>e.keyCode===13),
      map((event: any) => {
        return event.target.value;
      }),
      filter( (res)=>res.length>0),
      distinctUntilChanged()
    ).subscribe(res => this.buscarFactura(res));

      this.setFocus(this.inputFactura)

  }

  setFocus(elem:ElementRef){
    setTimeout(()=>{
      elem.nativeElement.focus();

    },0);
  }

  ngOnDestroy() {
    this.buscarFacturaSubscription.unsubscribe()

    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}
