import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SocioComponent } from './socio/socio.component';
import { Routes, RouterModule } from '@angular/router';
import { BusquedaSocioComponent } from './busqueda-socio/busqueda-socio.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FacturaModule } from '../factura/factura.module';
import { CobranzaModule } from '../cobranza/cobranza.module';
import { SocioInformacionGralComponent } from './socio-informacion-gral/socio-informacion-gral.component';
import { AltaSocioComponent } from './abm-socio/alta-socio/alta-socio.component';
import { SharedModule } from '../shared/shared.module';
import { FormularioSocioComponent } from './abm-socio/formulario-socio/formulario-socio.component';
import { ActualizarSocioComponent } from './abm-socio/actualizar-socio/actualizar-socio.component';
import { MenuAbmSocioComponent } from './abm-socio/menu-abm-socio/menu-abm-socio.component';
import { GeneralCallesResolveService, GeneralBarriosResolveService, GeneralBancosResolveService, GeneralSituacionEspecialResolveService } from '../general/general-resolve.service';
import { GeneralModule } from '../general/general.module';
import { ListadoSocioComponent } from './listado-socio/listado-socio.component';
import { AgGridModule } from 'ag-grid-angular';
import { ReciboSemestralModule } from '../recibo-semestral/recibo-semestral.module';
import { ObservacionesComponent } from './observaciones/observaciones.component';
import { CuentaCorrienteComponent } from './cuenta-corriente/cuenta-corriente.component';
import { CreditoModule } from '../credito/credito.module';
import { CisternaComponent } from './cisterna-planos/cisterna/cisterna.component';
import { PlanosComponent } from './cisterna-planos/planos/planos.component';
import { AutorizacionModule } from '../autorizacion/autorizacion.module';
import { ControlCisternaPlanosComponent } from './cisterna-planos/control-cisterna-planos/control-cisterna-planos.component';
import { ActualizarEmailComponent } from './abm-socio/actualizar-email/actualizar-email.component';
import { PlazosFormComponent } from './cisterna-planos/shared/plazos-form/plazos-form.component';
import { VisualizarButtonComponent } from './busqueda-socio/visualizar-button/visualizar-button.component';
import { AccionesModule } from '../acciones/acciones.module';
import { ConvenioModule } from '../convenio/convenio.module';
import { BusquedaAvanzadaComponent } from './busqueda-socio/busqueda-avanzada/busqueda-avanzada.component';
import { SocioInfoComponent } from './abm-socio/formulario-socio/socio-info/socio-info.component';
import { DatosConexionInfoComponent } from './abm-socio/formulario-socio/datos-conexion-info/datos-conexion-info.component';
import { DomicilioConexionInfoComponent } from './abm-socio/formulario-socio/domicilio-conexion-info/domicilio-conexion-info.component';
import { DomicilioLegalInfoComponent } from './abm-socio/formulario-socio/domicilio-legal-info/domicilio-legal-info.component';
import { VariosInfoComponent } from './abm-socio/formulario-socio/varios-info/varios-info.component';
import { LoteInfoComponent } from './abm-socio/formulario-socio/lote-info/lote-info.component';
import { DatosTecnicosInfoComponent } from './abm-socio/formulario-socio/datos-tecnicos-info/datos-tecnicos-info.component';
import { DebitoInfoComponent } from './abm-socio/formulario-socio/debito-info/debito-info.component';
import { SocioControlSituacionEspecialComponent } from './socio-control-situacion-especial/socio-control-situacion-especial.component';
import { FormularioControlSituacionEspecialComponent } from './socio-control-situacion-especial/formulario-control-situacion-especial/formulario-control-situacion-especial.component';




const routes: Routes = [

  {
    path: '',
    children: [
      {
        path: 'gestion',
        component: MenuAbmSocioComponent
        },
        {
          path: 'edicion',
          component: ActualizarSocioComponent,
          resolve:{
            calles:GeneralCallesResolveService ,
            barrios:GeneralBarriosResolveService ,
            bancos: GeneralBancosResolveService ,
            situacionesEspeciales: GeneralSituacionEspecialResolveService
              }
          },
          {
            path: 'alta',
            component: AltaSocioComponent,
            resolve:{
              calles:GeneralCallesResolveService ,
              barrios:GeneralBarriosResolveService ,
              bancos: GeneralBancosResolveService ,
              situacionesEspeciales: GeneralSituacionEspecialResolveService
                  }
            },
            {
              path: 'listado',
              component: ListadoSocioComponent,      
              },{
               path: 'control-cisterna-planos',
              component: ControlCisternaPlanosComponent
              },
              {
                path: 'control-situacion-especial',
               component: SocioControlSituacionEspecialComponent
               },
      
        {
          path: '',
          component: SocioComponent
        }
    ]
    }

  
  ];

@NgModule({
  declarations: [SocioComponent, BusquedaSocioComponent, SocioInformacionGralComponent, AltaSocioComponent, FormularioSocioComponent, ActualizarSocioComponent, MenuAbmSocioComponent,ListadoSocioComponent, ObservacionesComponent, CuentaCorrienteComponent, CisternaComponent, PlanosComponent, ControlCisternaPlanosComponent, ActualizarEmailComponent, PlazosFormComponent, VisualizarButtonComponent, BusquedaAvanzadaComponent, SocioInfoComponent, DatosConexionInfoComponent, DomicilioConexionInfoComponent, DomicilioLegalInfoComponent, VariosInfoComponent, LoteInfoComponent, DatosTecnicosInfoComponent, DebitoInfoComponent,SocioControlSituacionEspecialComponent, FormularioControlSituacionEspecialComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    FacturaModule,
    CobranzaModule,
    ConvenioModule,
    ReciboSemestralModule,
    CreditoModule,
    AccionesModule,
    SharedModule,
    AutorizacionModule,
    ReactiveFormsModule,
    AgGridModule,
     GeneralModule
  ],
  exports:[BusquedaSocioComponent,BusquedaAvanzadaComponent]
})
export class SocioGestionModule { }
