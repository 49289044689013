
import { State, Action, StateContext, Selector } from "@ngxs/store";
import { Login, Logout } from "./actions";
import { tap, catchError} from "rxjs/operators";
import { UserSession } from 'src/app/login/auth/user';
import { AuthService } from 'src/app/login/auth/auth.service';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';



export class AuthStateModel {
    userSession: UserSession;
}

@State<AuthStateModel>({
    name: 'auth',
    defaults: {
        userSession: null,

    }
})


@Injectable()
export class AuthState {
    constructor(private as: AuthService) { }

    @Selector()
    static token(state: AuthStateModel) { return state?.userSession?.token }

    @Selector()
    static usuarioNombre(state: AuthStateModel) { return state.userSession.nombre }

    @Selector()
    static usuario(state: AuthStateModel) { return state.userSession.usuario }

   
    @Selector()
    static usuarioLogin(state: AuthStateModel) { return state.userSession.userlogin }

    @Selector()
    static usuarioPermisos(state: AuthStateModel) { return state.userSession.permisos.split(',').map(x=>+x) }

    @Action(Login)
    login({ patchState }: StateContext<AuthStateModel>, { user }: Login) {
       return this.as.login(user).pipe(
            tap((result) => {
                patchState({ userSession: result }) }
                ),
            catchError(() => {return throwError('Unauthorized')})
            )
    }

    @Action(Logout)
    logout({ setState, getState }: StateContext<AuthStateModel>) {
        setState(null);
    }


}
