import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreditoSocioComponent } from './credito-socio/credito-socio.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { AutorizacionModule } from '../autorizacion/autorizacion.module';
import { CreditoImpactarComponent } from './credito-administrador/credito-impactar/credito-impactar.component';
import { FacturaModule } from '../factura/factura.module';
import { AgGridModule } from 'ag-grid-angular';



@NgModule({
  declarations: [CreditoSocioComponent, CreditoImpactarComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    AgGridModule,
   AutorizacionModule,
   FacturaModule,
   AgGridModule,
  ],
  exports:[CreditoSocioComponent]
})
export class CreditoModule { }
