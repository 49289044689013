import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {Utils} from  '../utils'

@Component({
  selector: 'app-resumen-totales',
  templateUrl: './resumen-totales.component.html',
  styleUrls: ['./resumen-totales.component.css']
})
export class ResumenTotalesComponent implements OnInit {

  totalFacturas=0
  totalAdeudado=0
  totalRecargo = 0
  destroy$: Subject<boolean> = new Subject<boolean>();
  utils:Utils = new Utils()

  constructor(private store:Store) { }

  ngOnInit(): void {
    this.store.select(state => state.factura.facturas_impagas).pipe(takeUntil(this.destroy$)).subscribe(
      (data:any[])=> {    
        this.totalFacturas = data.length
         const {montoTotal,montoRecargo} = this.utils.calcularResumenListado(data)
          this.totalAdeudado=montoTotal
          this.totalRecargo=montoRecargo
      }

    )
  }

 

   ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}
