
export class GetAdeudaFacturas{
  static readonly type = '[Factura] GetAdeudaFacturas'
  constructor(public cliente:number){}
}

export class GetFacturasImpagasActualizadas{
    static readonly type = '[Factura] GetFacturasImpagasActualizadas'
    constructor(public socio:number, public update:boolean){}
}

export class GetFacturasPagas{
    static readonly type = '[Factura] GetFacturasPagas'
    constructor(public socio:number, public update:boolean){}
}


export class SetSearchFacturasImpagasActualizadas{
    static readonly type = '[Factura] SetSearchFacturasImpagasActualizadas'
    constructor(){}
}


export class PagarFacturas{
    static readonly type = '[Factura] PagarFacturas'
    constructor( public facturas:any[], public cliente:number,public totalAPagarEnFacturas:number, public totalACobrar:number,
         public descuentoTotalEnPorcentaje:number,
         public caja:number,
         public fechaCobranza:string,
         public bancoCobranza:string,
         public numeroTransferenciaCobranza:string
         ){}
}

export class ResetFacturas{
  static readonly type = '[Observaciones] ResetFacturas'
  constructor( ){}
}




