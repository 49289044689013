import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'operations'
})
export class OperationsPipe implements PipeTransform {

  transform(value1: any, value2, operation:string): number {

  let valueOperation=0
    switch (operation) {
      case "+":
        valueOperation = (+value1||0) + (+value2||0)
        break;
        case "*":
          valueOperation = (+value1||0) * (+value2||0)
          break;

      default:
        break;
    }

    return +valueOperation.toFixed(2)
  }

}
