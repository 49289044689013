import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { SocioDetalle } from 'src/app/store/socio/models';
import { Store } from '@ngxs/store';
import {  Subject, Observable } from 'rxjs';
import { SetSocioParaEdicion, GetDocumentacionCisterna, GetDocumentacionCisternaPlazos, GetDocumentacionPlanos, GetDocumentacionPlanosPlazos } from 'src/app/store/socio/actions';
import { Router } from '@angular/router';
import { takeUntil, tap, mergeMap, filter } from 'rxjs/operators';
import { SistemaConfiguracionState } from 'src/app/store/sistema-configuracion/states';
import { GetAdeudaFacturas } from 'src/app/store/factura/actions';
import {  TieneConvenioNoVolcadoCliente, TieneDeudaConvenioCliente } from 'src/app/store/convenio/actions';
import { VisorImpresionComponent } from 'src/app/shared/visor-impresion/visor-impresion.component';
import { SocioGestionService } from '../socio-gestion.service';

import { OperacionInformacionComponent } from 'src/app/shared/operacion-informacion/operacion-informacion.component';
import { HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-socio',
  templateUrl: './socio.component.html',
  styleUrls: ['./socio.component.css']
})
export class SocioComponent implements OnInit,OnDestroy {

  @ViewChild('visorImpresion', { static: true }) visorImpresion:VisorImpresionComponent;

  @ViewChild('informacionLibreDeuda', { static: true })
  operacionInformacionComponent: OperacionInformacionComponent;

  mensajeLibreDeuda:string


  public socio:SocioDetalle;
  public tieneFacturasAdeudadas: boolean
  searchFacturasImpagas:boolean;

  public opcionSeleccionada:number = 3 // 0-facturas adeudadas
  destroy$: Subject<boolean> = new Subject<boolean>();

  habilitarRecibosSemestrales:boolean =false

  tieneConveniosNoVolcados$:Observable<boolean>

  tieneConveniosAdeudados:boolean
  

  socioIsLoading$ = this.store.select(state => state.socio.loading_busqueda)

  constructor(private store:Store,private router: Router,private sgs:SocioGestionService) {

   }

  ngOnInit() {

    this.habilitarRecibosSemestrales = this.store.selectSnapshot(SistemaConfiguracionState.habilitarRecibosSemestrales);

    this.store.select(state => state.factura.search_facturas_impagas).pipe(takeUntil(this.destroy$)).subscribe(
      data=>{
        this.searchFacturasImpagas = data
      }
    )

    this.store.select(state => state.convenio.tieneDeudaConvenios).pipe(takeUntil(this.destroy$)).subscribe(
      data=>{
        this.tieneConveniosAdeudados = data
      }
    )

    this.tieneConveniosNoVolcados$ = this.store.select(state => state.convenio.tieneConveniosNoVolcados)


     this.store.select(state => state.socio.socio_detalle)
     .pipe(
       takeUntil(this.destroy$),
       tap(data => { this.socio=null; return data }),
        filter(data=>!!data),
      tap(data=>{this.socio=data; this.opcionSeleccionada=3;}),
       mergeMap(()=> this.store.dispatch(new GetAdeudaFacturas(this.socio.id_socio))),
       mergeMap(()=> this.store.dispatch(new GetDocumentacionCisterna(this.socio.id_socio) )),
       mergeMap(()=> this.store.dispatch(new GetDocumentacionCisternaPlazos(this.socio.id_socio) )),
       mergeMap(()=> this.store.dispatch(new GetDocumentacionPlanos(this.socio.id_socio) )),
       mergeMap(()=> this.store.dispatch(new GetDocumentacionPlanosPlazos(this.socio.id_socio) )),
       mergeMap(()=> this.store.dispatch(new TieneDeudaConvenioCliente(this.socio.id_socio) )),
       mergeMap(()=> this.store.dispatch(new TieneConvenioNoVolcadoCliente(this.socio.id_socio) ))

     )
     .subscribe(
      );

this.store.select(state => state.factura.tiene_facturas_impagas).pipe(takeUntil(this.destroy$)).subscribe(
      data => this.tieneFacturasAdeudadas = data
    )





  }


  editar(seccion){

    this.store.dispatch(new SetSocioParaEdicion(this.socio))
    //debemos rutear a socio detalle
    this.router.navigate(['/socio/edicion',{ seccion:seccion}]);
 }

 editarInformacionGeneral(seccion){
   this.editar(seccion);
 }

 imprimirLibreDeuda(){
  this.opcionSeleccionada=12

  if(this.tieneFacturasAdeudadas){
    this.mensajeLibreDeuda = 'El socio registra deuda con la cooperativa, NO PUEDE emitirse el "Certificado de Libre Deuda".'
    this.operacionInformacionComponent.open()
    return
  }

  if(this.tieneConveniosAdeudados){
    this.mensajeLibreDeuda = 'El socio registra deuda de convenio con la cooperativa, NO PUEDE emitirse el "Certificado de Libre Deuda".'
    this.operacionInformacionComponent.open()
    return
  }



  this.sgs.getLibreDeuda(this.socio.id_socio).subscribe(
    (contenido: HttpResponse<Blob>)=>{
      this.visorImpresion.imprimir(contenido)
    }
    );

}


 ngOnDestroy() {
  this.destroy$.next(true);
  this.destroy$.unsubscribe();
}



}
