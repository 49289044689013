<div class="row  justify-content-md-center" *ngIf="!documentacionCisterna">
    <div class="col-8">
      <div class="alert alert-danger" role="alert">
        <div>
          <b>La documentación de Cisterna aún no ha sido presentada</b>. Se podrá agregar un plazo para la entrega de la
          mísma o hacer click en "Documentación Presentada" en caso de querer notificar la presentación de la
          documentación correspondiente.
        </div>
        <br>
        <div class="center">
          <button class="btn btn-info btn-sm" (click)="notificarPresentacion()">Documentación Presentada</button>
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-4">

    <div class="col-6">
      <div class="row">
        <div class="col-12" *ngIf="!documentacionCisterna">
            <app-plazos-form [tipoDocumentacion]="'Cisterna'" (nuevoPlazoRetorno)="agregarNuevoPlazo($event)"></app-plazos-form>
        </div>
        <div class="col-12" *ngIf="documentacionCisterna">
          <fieldset class="p-2" style="border: solid 1px #ddd">
            <legend style="font-size: 18px">Documentación Cisterna</legend>
            <div>
              <p class="ml-3">Documentación presentada: <span class="badge badge-success">Si</span> </p>
              <p class="ml-3">Fecha presentación: {{documentacionCisterna.fecha_presentacion}}</p>
              <p class="ml-3">Observación: {{documentacionCisterna.observacion}}</p>
            </div>
            <br>
            <a href="javascript:;" title="Anular presentación" (click)="eliminarPresentacion()"
                class="btn btn-sm btn-outline-danger ml-3" role="button" aria-pressed="true"><i
                  class="fas fa-trash-alt"></i></a>
          </fieldset>
        </div>


      </div>

    </div>
    <div class="col-6">
      <fieldset class="p-2" style="border: solid 1px #ddd">
        <legend style="font-size: 18px">Listado e Impresión de plazos para presentación</legend>

        <label *ngIf="!documentacionCisterna"><span class="badge badge-info my-2">5</span> Seleccione los plazos para observar en detalle e imprimir la
          declaración</label>
        <ag-grid-angular #agGridPlazosCisterna style="width: 100%; height: 250px;" class="ag-theme-alpine"
          [rowData]="plazosPresentacion" [columnDefs]="columnDefs" [colResizeDefault]="'fit'" [rowSelection]="'single'"
          (selectionChanged)="onSelectionChanged($event)" (gridReady)="onGridReady($event)"></ag-grid-angular>

        <br>

        <fieldset class="m-2 p-2" style="border: solid 1px #ddd">
          <legend class="ml-2" style="font-size: 18px">Detalle del plazo</legend>
          <div class="col-12" *ngIf="selectedRow">
            <p>Fecha de inicio: {{selectedRow.fecha_desde}}. Fecha fin presentacion: {{selectedRow.fecha_hasta}}. </p>
            <p>Plazo en días: {{selectedRow.dias_plazo}}. </p>
            <p>Observación: {{selectedRow.observacion}}. </p>
            <p>Email: {{emailCliente}}. <a  *ngIf="!documentacionCisterna && !selectedRow?.plazo_extendido" href="javascript:;" title="modificar email" class="ml-4" (click)="editarEmail()" ><i class="fas fa-envelope-square"></i> editar</a></p>
            <p>Notificacion por mail realizadas: {{selectedRow.notificacion_por_mail}}. </p>
          </div>
          <div class="row" *ngIf="selectedRow && !documentacionCisterna && !selectedRow?.plazo_extendido">
            <div class="col-6">
              <a href="javascript:;" title="Eliminar plazo" (click)="eliminarPlazo()"
                class="btn btn-sm btn-outline-danger ml-3" role="button" aria-pressed="true"><i
                  class="fas fa-trash-alt"></i></a>
            </div>

            <div class="col-6 text-right">
              <button class="btn btn-outline-info btn-sm" (click)="generarDeclaracion()" title="Imprimir declaracion cisterna"><i class="fas fa-print"></i> Imprimir
                declaración</button>
            </div>
          </div>

        </fieldset>

      </fieldset>
    </div>
  </div>

  <app-operacion-si-no-observacion [titulo]="tituloOperacionSiNo"
    [contenidoMensaje]="contenidoMensajeOperacionSiNo1" [observacionRequerida]="observacionRequerida"
    [observacionPorDefecto]="contenidoMensajeOperacionSiNo2"
    [id]="'operacion_si_no_observacion_cisterna'" (retornoOperacion)="retornoOperacionSiNo($event)">
  </app-operacion-si-no-observacion>

    <app-operacion-informacion [titulo]="'Documentación Cisterna'"
    [contenidoMensaje]="'Existen plazos que aún no han vencido por lo tanto no se generará un nuevo plazo de vencimiento.'"
    #informacionDocumentacionCisterna [id]="'informacionDocumentacionCisterna'"></app-operacion-informacion>

  <app-actualizar-email (retornoOperacion)="retornoOperacionActualizarEmail($event)" [id]="'email_edit_modal_cisterna'"></app-actualizar-email>
