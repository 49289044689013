import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { ListadoFacturasComponent } from 'src/app/factura/listado-facturas/listado-facturas.component';
import { Store } from '@ngxs/store';
import { SetFacturasNuevoConvenio } from 'src/app/store/convenio/actions';

@Component({
  selector: 'app-listado-facturas-disponibles',
  templateUrl: './listado-facturas-disponibles.component.html',
  styleUrls: ['./listado-facturas-disponibles.component.css']
})
export class ListadoFacturasDisponiblesComponent implements OnInit {

  @Output() cancelarEvent = new EventEmitter();

  @ViewChild(ListadoFacturasComponent, { static: true })
  listadoFacturasComponent: ListadoFacturasComponent;
  
  constructor(private store:Store) { }

  ngOnInit(): void {
  }
  
  agregarAConvenio(){  
  this.store.dispatch(new SetFacturasNuevoConvenio(this.listadoFacturasComponent.obtenerFacturasSeleccionadas()))
  }

  cancelar(){
    this.cancelarEvent.emit()
  }

}
