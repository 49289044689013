import { Component, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngxs/store';
import { SocioState } from '../store/socio/states';
import { GetInformacionParaNuevoConvenio, GetConveniosCliente, DeleteConvenio } from '../store/convenio/actions';
import { GetUltimoPeriodoFacturado } from '../store/periodo/actions';
import { Convenio } from '../store/convenio/convenio.model';
import { OperacionSiNoObservacionComponent } from '../shared/operacion-si-no-observacion/operacion-si-no-observacion.component';
import Swal from 'sweetalert2'
import { AutorizacionComponent } from '../autorizacion/autorizacion.component';
import { GetObservaciones } from '../store/observacion/actions';
import { mergeMap } from 'rxjs/operators';
import { GetFacturasImpagasActualizadas, GetFacturasPagas } from '../store/factura/actions';
import { GetCuentaCorriente } from '../store/cuentacorriente/actions';

@Component({
  selector: 'app-convenio',
  templateUrl: './convenio.component.html',
  styleUrls: ['./convenio.component.css']
})
export class ConvenioComponent implements OnInit {

  @ViewChild(OperacionSiNoObservacionComponent, { static: true })
  operacionSiNoObservacionComponent: OperacionSiNoObservacionComponent;

  @ViewChild(AutorizacionComponent, { static: true })
  autorizacionComponent: AutorizacionComponent;

  habilitarNuevoConvenio:boolean= false
  cliente:number
  convenioEditar:Convenio = null
  convenioEliminar:Convenio = null
  observacionEdicionEliminacion:string

  estaHabilitadaEdicion:boolean=false

  tituloOperacionSiNo:string
  mensajeOperacionSiNo:string

  operacionARealizar:number // 1-editar convenio, 2-eliminar convenio


  constructor(private store:Store) { }

  ngOnInit(): void {
     this.cliente = this.store.selectSnapshot(SocioState.cliente);
     this.store.dispatch(new GetConveniosCliente(this.cliente,false))
  }

  nuevoConvenio(){
   this.store.dispatch(new GetInformacionParaNuevoConvenio(this.cliente));
   this.store.dispatch(new GetUltimoPeriodoFacturado)
   this.habilitarNuevoConvenio = true
  }

  editarConvenio(event){
    this.operacionARealizar=1
    this.tituloOperacionSiNo = "Modificar convenio"
    this.mensajeOperacionSiNo = "Desea modificar el convenio? Si efectúa cambios en el convenio se registrará en la base de datos de seguridad."
    this.operacionSiNoObservacionComponent.open()
    this.convenioEditar = event
  }

  onEliminarConvenio(event){
    this.convenioEliminar = event
    if(+this.convenioEliminar.cuotas===+this.convenioEliminar.facturadas){
      Swal.fire(
        '',
        'El convenio no puede ser eliminado ya que el mismo ha sido cancelado!',
        'info'
      )


    }else{
      this.operacionARealizar=2
      this.tituloOperacionSiNo = "Eliminar convenio"
      this.mensajeOperacionSiNo = "Desea eliminar el convenio? Si dá de baja el convenio se registrará en la base de datos de seguridad. Tenga en cuenta lo siguiente: Las facturas asociadas al convenio serán marcadas como adeudadas. Los convenios previos no finalizados que han sido cancelado por dicho convenio serán restaurados. "
      this.operacionSiNoObservacionComponent.open()
    }



  }

  finzalizarEdicion(){
    this.estaHabilitadaEdicion=false
  }

  cancelarNuevoConvenio(){
    this.habilitarNuevoConvenio = false
  }

  retornoOperacionSiNo(retorno){
    this.observacionEdicionEliminacion = retorno.observacion

    if (retorno.opcion_seleccion) {
      if(this.operacionARealizar===1){
        this.estaHabilitadaEdicion=true
      }else{
        this.eliminarConvenio()
      }

    }
  }

  eliminarConvenio(){
    this.solicitarAutorizacion()
  }

  solicitarAutorizacion(){
    this.autorizacionComponent.open()
  }

  retornoAutorizacion(retorno){
    if(retorno.habilita){
      const cliente = this.store.selectSnapshot(SocioState.cliente);

      this.store.dispatch(new DeleteConvenio(cliente,this.convenioEliminar.idConvenio,retorno.usuarioAutorizante,this.observacionEdicionEliminacion))
        .pipe(
          mergeMap(()=>   this.store.dispatch(new GetObservaciones(this.cliente,true))),
          mergeMap(() =>  this.store.dispatch(new GetFacturasImpagasActualizadas(this.cliente, true))),
          mergeMap(() =>  this.store.dispatch(new GetFacturasPagas(this.cliente, true))),
          mergeMap(() =>  this.store.dispatch(new GetCuentaCorriente(this.cliente, true)))
        )
        .subscribe(
          ()=>{
            Swal.fire(
              'El convenio ha sido eliminado con éxito!',
              'Recuerde que deberá eliminar los recibos que han sido generados durante la creación del convenio!',
              'success'
            )
          }
        )

    }
  }

}
