import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-resumen-pago',
  templateUrl: './resumen-pago.component.html',
  styleUrls: ['./resumen-pago.component.css']
})
export class ResumenPagoComponent implements OnInit,OnDestroy {

  @Input() modoImputacion:boolean=false
  @Input() totalACobrar:number = 0
  @Input() descuentoSobreRecargo:number = 0
  @Input() saldoCredito:number = 0

  @Output() pagarEvent = new EventEmitter()

  facturas:Subscription

  constructor(private store:Store) { }

  ngOnInit(): void {
    this.facturas= this.store.select(state => state.factura.facturas_impagas).subscribe(
      ()=> {
        this.resetValues()
      }
    )
  }

  resetValues(){
    this.totalACobrar = 0
    this.descuentoSobreRecargo=0
  }

  pagar(){

    this.pagarEvent.emit()
  }

  ngOnDestroy() {
    this.facturas.unsubscribe()
  }


}
