import { State, Action, StateContext } from '@ngxs/store';
import { GetConvenioPagoList, GetConvenioPagoDetail, NewConvenioPago, CobranzaConvenioPago } from './actions';
import { Injectable } from '@angular/core';
import { ConvenioPago } from './models';
import { tap,mergeMap } from 'rxjs/operators';
import { ConvenioPorServicioService } from 'src/app/convenio-de-pago-por-servicio/convenio-por-servicio.service';

export class ConvenioPagoStateModel {
  convenioPagoList:ConvenioPago[];
  convenioDetail:any;
  numeroComprobantePagoGenerado:string;
}


@State<ConvenioPagoStateModel>({
    name: 'convenioPago',
    defaults: {
      convenioPagoList:[],
      convenioDetail:null,
      numeroComprobantePagoGenerado:null
    }
})

@Injectable()
export class ConvenioPagoState {

    constructor(private cps: ConvenioPorServicioService) { }

    @Action(GetConvenioPagoList)
    getConvenioPagoList(ctx: StateContext<ConvenioPagoStateModel>) {

          this.cps.getConvenioPagoList().subscribe(
            (conveniosR:any)=>{
              ctx.patchState({
                convenioPagoList: conveniosR.data.convenios
                });
            }
          )

    }

    @Action(GetConvenioPagoDetail)
    getConvenioPagoDetail(ctx: StateContext<ConvenioPagoStateModel>, action:GetConvenioPagoDetail) {
          this.cps.getConvenioPagoDetail(action.convenio).subscribe(
            (convenioR:any)=>{
              ctx.patchState({
                convenioDetail: convenioR.data.convenio
                });
            }
          )
    }

    @Action(NewConvenioPago)
    newConvenioPago(ctx: StateContext<ConvenioPagoStateModel>, action:NewConvenioPago) {
      return this.cps.nuevoConvenioPago(action.socio,action.concepto,action.importe,action.cuotas,action.valorCuota,action.fecha).pipe(
        mergeMap(()=>ctx.dispatch(new GetConvenioPagoList())
        ))

    }

    @Action(CobranzaConvenioPago)
    cobranzaConvenioPago( ctx : StateContext<ConvenioPagoStateModel>, action:CobranzaConvenioPago) {
      return this.cps.cobranzaConvenioPago(action.recibo,action.convenio).pipe(
        tap(
          (cobranzaR:any) => {

            ctx.patchState({
                  numeroComprobantePagoGenerado:cobranzaR.data.comprobantePagoReciboConvenio
                });

          }
          ),
        mergeMap(()=>ctx.dispatch(new GetConvenioPagoDetail(action.convenio))
        ))

    }


    


}

