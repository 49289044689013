import { Component, OnInit, EventEmitter, Output } from '@angular/core';
declare var $:any;

@Component({
  selector: 'app-calle-modal',
  templateUrl: './calle-modal.component.html',
  styleUrls: ['./calle-modal.component.css']
})
export class CalleModalComponent implements OnInit {

  @Output() retornoCalleModal = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  open(){
    $('#calleModal').modal('show')
  }

  finalizar(){
    $('#calleModal').modal('hide')
    this.retornoCalleModal.emit(true);
  }

}
