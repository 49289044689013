<div class="modal" tabindex="-1" role="dialog" [attr.id]="id" data-backdrop="false">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Operación exitosa</h5>
      </div>
      <div class="modal-body">
        <p>{{contenidoMensaje}}</p>
      </div>
      <div class="modal-footer">
        <!-- <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button> -->
        <button type="button" class="btn btn-success" (click)="aceptar()">Aceptar</button>
      </div>
    </div>
  </div>
</div>
