
      <div class="card">
      <div class="card-header card-header-info">
            <i class="fas fa-dollar-sign"></i> Pago de facturas
      </div>

      <div class="card-body">
            <div class="row">
                  <div class="col-md-7">
                        <app-listado-facturas [rowSelectionOpcion]="'multiple'" [sorteableOpcion]="true" [visualizacionExpandida]="true" [mostrarDetalleSeleccion]="true"
                        (seleccionRetorno)="onSeleccionFacturas($event)"></app-listado-facturas>
                  </div>
                  <div class="col-sm-5">
                        <div class="row"><div class="col-md-12">
                              <app-resumen-pago
                              [totalACobrar]="totalACobrar"
                              [descuentoSobreRecargo]="descuentoSobreRecargo"
                              (pagarEvent)="pagar($event)"
                              ></app-resumen-pago>
                        </div></div>
                        <div class="row"><div class="col-md-12">
                              <app-resumen-selecccion
                        [seleccionadasAPagar]="seleccionadasAPagar"
                        [totalAPagarEnFacturas]="totalAPagarEnFacturas"
                        [recargoAPagar]="recargoAPagar"
                        (aplicarDescuentoEvent)="onDescuentoSobreRecargo($event)"
                         ></app-resumen-selecccion>
                        </div></div>
                        <div class="row"><div class="col-md-12">
                              <app-informacion-caja></app-informacion-caja>
                        </div></div>

                      </div>


            </div>


      </div>

      </div>


      <app-operacion-si-no [titulo]="'Pago Múltiple de Facturas'" [contenidoMensaje]="'Desea realizar la operación? recuerde que las facturas seleccionadas serán marcadas como pagadas.'"
     (retornoOperacion)="retornoOperacionSiNo($event)"></app-operacion-si-no>




     <app-operacion-loading></app-operacion-loading>
