<div class="modal" tabindex="-1" role="dialog" [attr.id]="id" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Autorización</h5>       
      </div>
      
      <form [formGroup]="autorizacionForm" (ngSubmit)="onAuthorize($event)" autocomplete="off" novalidate>
      <div class="modal-body">                    
              <p>{{mensajeAutorizacion}}</p>                             
              
              <div class="form-group">
                <label>Usuario</label>
                <input type="text" class="form-control form-control-sm" rows="3" formControlName="usuario" [ngClass]="{ 'is-invalid': isInvalidUsuarioField }">
                <div *ngIf="isInvalidUsuarioField" class="invalid-feedback">Campo obligatorio</div>
              </div>
              <div class="form-group">
                <label>Contraseña</label>
                <input type="password" class="form-control form-control-sm" rows="3" formControlName="password" [ngClass]="{ 'is-invalid': isInvalidPasswordField }">
                <div *ngIf="isInvalidPasswordField" class="invalid-feedback">Campo obligatorio</div>
              </div>

              <div *ngIf="mostrarErrorNoAutorizado" class="alert alert-danger" role="alert">
                  Usuario no habilitado para autorizar la operación!
              </div>

            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-outline-danger btn-sm" (click)="cancelar()">Cancelar</button>
              <button [disabled]="!autorizacionForm.valid" type="submit" class="btn btn-success btn-sm">Aceptar</button>
            </div>

          </form>
          
        
    </div>
  </div>
</div>
