<div class="card shadow" style="border-color:#1595a9; padding: 0px;">
  <div class="card-header card-header-info bg-info text-white"><b>Total a {{modoImputacion?'Imputar':'Pagar'}}</b><b
      *ngIf="descuentoSobreRecargo>0" style="color: greenyellow;">&nbsp;con descuento aplicado</b></div>
  <div class="card-body" style="padding: 0px;">
    <table class="table table-condensed" style="margin: 0px">
      <tr>
        <td class="text-muted text-center" style="border-right: 1px solid #ddd;">
          <h4>${{totalACobrar| formatDecimal}}</h4>
        </td>
        <td width="60%" style="text-align: center;">
          <button type="button" class="btn btn-success btn-sm" *ngIf="!modoImputacion else modoImputacionButton"
             (click)="pagar()" [disabled]="totalACobrar==0" title="pagar">Pagar</button>
          <ng-template #modoImputacionButton>
            <button type="button" class="btn btn-info"
            [disabled]="!(modoImputacion && +totalACobrar>0 && +totalACobrar <= +saldoCredito)"
            (click)="pagar()">Imputar</button>
          </ng-template>

        </td>
      </tr>
      <tr *ngIf="modoImputacion">
        <td ><span>Saldo crédito: ${{saldoCredito}}</span></td>
        <td><h6 *ngIf="+totalACobrar > +saldoCredito"class="text-danger">Ha superado el saldo!</h6></td>
      </tr>
    </table>
  </div>
</div>
