import { Component, OnInit } from '@angular/core';
import { FiltroFecha } from 'src/app/shared/modelos/filtro-fecha.model';
import { Observable, Subject } from 'rxjs';
import { Store } from '@ngxs/store';
import { ControlCobranza } from 'src/app/store/cobranza/models';
import { takeUntil } from 'rxjs/operators';

import  moment from 'moment';
import { StartBusquedaComprobante, GetComprobanteCobranzaPorFecha, GetControlCobranza } from 'src/app/store/cobranza/actions';

@Component({
  selector: 'app-control-cobranza',
  templateUrl: './control-cobranza.component.html',
  styleUrls: ['./control-cobranza.component.css']
})
export class ControlCobranzaComponent implements OnInit {

  private gridApiCobranzas;

  columnDefs = [
    { headerName: 'N° de comprobante', field: 'id_compcaja', sortable: true, filter: true,width: 150 },
    { headerName: 'Caja', field: 'id_caja', sortable: true, filter: true,width: 80 },
    { headerName: 'Factura', field: 'id_factura', sortable: true, filter: true,width: 100 },
    { headerName: 'Recibo', field: 'id_recibo', sortable: true, filter: true,width: 100 },
    { headerName: 'Cliente', field: 'cliente', sortable: true, filter: true,width: 150 },
    { headerName: 'Importe', field: 'importe', sortable: true, filter: true,width: 100 },
    { headerName: 'Fecha Caja', field: 'fecha_caja', sortable: true, filter: true,width: 150 },
    { headerName: 'Usuario', field: 'usuario', sortable: true, filter: true,width: 120 },
    { headerName: 'Fecha Registro', field: 'fecha_registro', sortable: true, filter: true,width: 150 }
  ]

  rowData: ControlCobranza[] = [];

  filas:number;
  importeTotal:number;

  public filtroFecha:FiltroFecha = new FiltroFecha();

  public loadingBusquedaComprobante$:Observable<boolean>;

  destroy$: Subject<boolean> = new Subject<boolean>();



  constructor(private store:Store) { }

  ngOnInit(): void {

    this.store.select(state => state.cobranza.controlCobranza).pipe(takeUntil(this.destroy$)).subscribe(
      data => {
                  this.rowData = data; this.filas=data.length; this.obtenerImporteAcumulado(data)
      }
    );

    this.loadingBusquedaComprobante$ = this.store.select(state => state.cobranza.loading_busqueda)

    this.filtroFecha.fecha_desde= moment().format("YYYY-MM-DD");
    this.filtroFecha.fecha_hasta= moment().format("YYYY-MM-DD");

  }

  aplicarFiltroFecha(){
    this.store.dispatch(new StartBusquedaComprobante())
    this.store.dispatch(new GetControlCobranza(this.filtroFecha))
  }

  onGridReadyCobranzas(params) {
    this.gridApiCobranzas = params.api;
  }


  onBtExportCobranzas() {
    this.gridApiCobranzas.exportDataAsCsv({ fileName: 'control_cobranzas_'+ '-' + moment().format("YYYY-MM-DD"), columnSeparator: ';' })
  }

  onFilterChanged(){

    this.filas = this.gridApiCobranzas.getDisplayedRowCount();
    this.importeTotal = 0

    let acumuladoImporte = 0
    for (var i = 0; i < this.filas; i++) {
      var rowNode = this.gridApiCobranzas.getDisplayedRowAtIndex(i);
      acumuladoImporte += +rowNode.data.importe
    }

    this.importeTotal = +acumuladoImporte.toFixed(2)
  }

  obtenerImporteAcumulado(data){

    this.importeTotal = 0

    let acumuladoImporte = 0

    acumuladoImporte = data.map(elem=>elem.importe).reduce((acc,importe)=>acc+importe,0)

    this.importeTotal = +acumuladoImporte.toFixed(2)

  }

  clearFilter(){
    this.gridApiCobranzas.setFilterModel(null);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}
