<div class="row">

    <div class="col-sm-3">
        <a  routerLink="/socio/alta">
            <div class="card card-success text-center">
                <div class="card-block card-title text-secondary">
                  <h1 class="m-b-2 pt-4"><i class="fas fa-user-plus display-3"></i></h1>
                  <h6 class="">Nuevo</h6>
                </div>
              </div>
        </a>
        
      </div>

      <div  class="col-sm-3" *appRole="permisoModoRequeridoVerListadoGeneralDeSocios">
          <a  routerLink="/socio/listado">
              <div class="card card-success text-center">
                  <div class="card-block card-title text-secondary">
                    <h1 class="m-b-2 pt-4"><i class="fas fa-list-ol display-3"></i></h1>
                    <h6 class="">Listado</h6>
                  </div>
                </div>
          </a>
          
        </div>


        <div class="col-sm-3">
          <a  routerLink="/socio/control-cisterna-planos">
              <div class="card card-success text-center">
                  <div class="card-block card-title text-secondary">
                    <h1 class="m-b-2 pt-4"><i class="fas fa-file-signature display-3"></i></h1>
                    <h6 class="">Control Cisterna/Planos</h6>
                  </div>
                </div>
          </a>
          
        </div>

</div>