import { State, StateContext, Action } from "@ngxs/store";
import { ComprobanteCobranza, ControlCobranza } from './models';
import { CobranzaService } from 'src/app/cobranza/cobranza.service';
import { GetComprobanteCobranzaPorNumeroComprobante, GetComprobanteCobranzaPorSocio, GetComprobanteCobranzaPorFecha, StartBusquedaComprobante, GetControlCobranza } from './actions';
import { Injectable } from '@angular/core';

export class CobranzaStateModel {
        controlCobranza:ControlCobranza[];
     comprobantesCobranza:ComprobanteCobranza[];
     loading_busqueda:boolean;

}


@State<CobranzaStateModel>({
    name: 'cobranza',
    defaults: {
        controlCobranza:[],
        comprobantesCobranza:[],
        loading_busqueda:false
    }
})


@Injectable()
export class CobranzaState {

    constructor(private cs: CobranzaService) { }

    @Action(GetControlCobranza)
    getControlCobranza({ patchState }: StateContext<CobranzaStateModel>,action: GetControlCobranza  ) {
           this.cs.getListadoControlCobranza(action.fecha).subscribe(
                data=> {
                    patchState({
                        controlCobranza: data,
                        loading_busqueda:false
                    });
                }
            )
    }

    @Action(GetComprobanteCobranzaPorNumeroComprobante)
    getComprobanteCobranzaPorId({ patchState }: StateContext<CobranzaStateModel>,action: GetComprobanteCobranzaPorNumeroComprobante  ) {
           this.cs.getComprobanteCobranzaPorNumeroComprobante(action.payload).subscribe(
                data=> {
                    patchState({
                        comprobantesCobranza: data,
                        loading_busqueda:false
                    });
                }
            )
    }

    @Action(GetComprobanteCobranzaPorSocio)
    getComprobanteCobranzaPorSocio({ patchState }: StateContext<CobranzaStateModel>,action: GetComprobanteCobranzaPorSocio  ) {
           this.cs.getComprobanteCobranzaPorSocio(action.payload).subscribe(
                data=> {
                    patchState({
                        comprobantesCobranza: data,
                        loading_busqueda:false
                    });
                }
            )
    }

    @Action(GetComprobanteCobranzaPorFecha)
    getComprobanteCobranzaPorFecha({ patchState }: StateContext<CobranzaStateModel>,action: GetComprobanteCobranzaPorFecha  ) {
           this.cs.getComprobanteCobranzaPorFecha(action.fecha).subscribe(
                data=> {
                    patchState({
                        comprobantesCobranza: data,
                        loading_busqueda:false
                    });
                }
            )
    }

    @Action(StartBusquedaComprobante)
    startBusquedaComprobante({ patchState }: StateContext<CobranzaStateModel>) {

        patchState({
            loading_busqueda: true
        });
    }
}
