import { Component, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { Store } from '@ngxs/store';
import { PresentacionDocumentacion } from 'src/app/store/socio/models';
import { OperacionSiNoObservacionComponent } from 'src/app/shared/operacion-si-no-observacion/operacion-si-no-observacion.component';
import { SetDocumentacionCisterna, NewDocumentacionCisternaPlazo, GetSocioDetalle, DeleteDocumentacionPlazoCisterna, DeleteDocumentacionCisterna } from 'src/app/store/socio/actions';
import { SocioState } from 'src/app/store/socio/states';
import { OperacionInformacionComponent } from 'src/app/shared/operacion-informacion/operacion-informacion.component';
import { OperacionSiNoComponent } from 'src/app/shared/operacion-si-no/operacion-si-no.component';
import { AutorizacionComponent } from 'src/app/autorizacion/autorizacion.component';
import { AuthState } from 'src/app/store/auth/state';
import { SocioGestionService } from '../../socio-gestion.service';
import { HttpResponse } from '@angular/common/http';
import { ExportarArchivo } from 'src/app/shared/exportar';
import { ActualizarEmailComponent } from '../../abm-socio/actualizar-email/actualizar-email.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-cisterna',
  templateUrl: './cisterna.component.html',
  styleUrls: ['./cisterna.component.css']
})
export class CisternaComponent implements OnInit {

  @ViewChild(OperacionSiNoObservacionComponent, { static: true })
  operacionSiNoObservacionComponent: OperacionSiNoObservacionComponent;
  @ViewChild('informacionDocumentacionCisterna', { static: true })
  operacionInformacionComponent: OperacionInformacionComponent;

  @ViewChild('siNoDocumentacionCisterna', { static: false })
  operacionSiNoComponent: OperacionSiNoComponent;

  @ViewChild(AutorizacionComponent, { static: true })
  autorizacionComponent: AutorizacionComponent;

  @ViewChild(ActualizarEmailComponent, { static: true })
  actualizarEmailComponent: ActualizarEmailComponent;


  documentacionCisterna$: Subscription
  plazosDocumentacionCisterna$: Subscription


  documentacionCisterna: PresentacionDocumentacion
  plazosPresentacion: any;

  columnDefs: any = [
    { headerName: 'Inicio', field: 'fecha_desde', width: 150 },
    { headerName: 'Fin', field: 'fecha_hasta', width: 150 },
    {
      headerName: 'Días para vencimiento', field: 'dias_para_vencimiento', flex: 1,
      cellStyle: params => params.value <= 7 ? { color: 'red' } : null
    },
    { field: 'observacion', hide: true },
    { field: 'dias_plazo', hide: true },
    { field: 'notificacion_por_mail', hide: true },
    { field: 'plazo_extendido', hide: true },
    { field: 'id', hide: true }

  ];

  private gridApi;
  selectedRow: any

  mensaje: string

  tipoOperacion: number //1-nuevo plazo, 2-eliminar, 3-anular presentacion

  public file = new ExportarArchivo();

  emailCliente: string

  tituloOperacionSiNo:string
  contenidoMensajeOperacionSiNo1:string
  contenidoMensajeOperacionSiNo2:string
  observacionRequerida:boolean

  constructor(private store: Store, private sgs: SocioGestionService) {
  }

  ngOnInit(): void {

    this.documentacionCisterna$ = this.store.select(state => state.socio.socio_documentacion_cisterna).subscribe(
      data => this.documentacionCisterna = data
    )
    this.plazosDocumentacionCisterna$ = this.store.select(state => state.socio.socio_plazos_documentacion_cisterna).subscribe(
      data => { this.plazosPresentacion = data; this.selectedRow = null }
    )

  }



  notificarPresentacion() {
    this.tituloOperacionSiNo = "Presentación documentación Planos"
    this.contenidoMensajeOperacionSiNo1 = 'Desea indicar que la presentación ha sido realizada?'
    this.contenidoMensajeOperacionSiNo2 = 'En caso de necesitarlo puede agregar una observación.'
    this.observacionRequerida = false
    this.tipoOperacion=4
    this.operacionSiNoObservacionComponent.open()
  }

  retornoOperacionSiNo(retorno) {
    if (retorno.opcion_seleccion) {

      if ([2,3].includes(this.tipoOperacion)){
        this.anulacionPresentacionCisterna(retorno.observacion)
      }else{

      const cliente = this.store.selectSnapshot(SocioState.cliente);
      this.store.dispatch(new SetDocumentacionCisterna(cliente, retorno.observacion)).subscribe(
        _ =>
        Swal.fire(
          '','La operación ha sido realizada con éxito!',
          'success'
        )
      )

    }
    }
  }




  agregarNuevoPlazo(e) {
    if (this.verificaNuevoPlazo()) {
      const cliente = this.store.selectSnapshot(SocioState.cliente);
      this.store.dispatch(new NewDocumentacionCisternaPlazo(cliente, e.observacion, e.fecha_desde, e.fecha_hasta, e.dias_plazo)).subscribe(
        _ => {
          Swal.fire(
            '','El plazo ha sido agregado con éxito!',
            'success'
          )
          }
      )
    } else {
      this.operacionInformacionComponent.open()
    }
  }

  verificaNuevoPlazo() {
    return this.plazosPresentacion.filter(elem => elem.dias_para_vencimiento > 0).length == 0
  }

  eliminarPlazo() {
    this.tipoOperacion = 2
    this.tituloOperacionSiNo = "Documentación Planos"
    this.contenidoMensajeOperacionSiNo1 = 'Desea eliminar el plazo para la entrega de la documentación? Si dá de baja el plazo, esto se registrará en la base de datos de seguridad.'
    this.contenidoMensajeOperacionSiNo2 = 'Tenga en cuenta que ¡¡¡ LOS CAMBIOS NO SE PUEDEN DESHACER !!! Está seguro de querer continuar ?'
    this.observacionRequerida = true
    this.operacionSiNoObservacionComponent.open()
  }

  eliminarPresentacion(){
    this.tipoOperacion = 3
    this.tituloOperacionSiNo = "Documentación Planos"
    this.contenidoMensajeOperacionSiNo1 = 'Desea indicar que la documentación no ha sido presentada? esto se registrará en la base de datos de seguridad.'
    this.contenidoMensajeOperacionSiNo2 = ''
    this.observacionRequerida = true
    this.operacionSiNoObservacionComponent.open()
  }

  anulacionPresentacionCisterna(observacion) {

      const cliente = this.store.selectSnapshot(SocioState.cliente);
      const usuario = this.store.selectSnapshot(AuthState.usuario);

      if (this.tipoOperacion==2){
      this.store.dispatch(new DeleteDocumentacionPlazoCisterna(cliente, this.selectedRow.id, usuario, 0, 'Cisterna. '+observacion)).subscribe(
        () => {
          Swal.fire(
            '','El plazo ha sido anulado con éxito!',
            'success'
          )
          this.selectedRow = null
        }
      )
      }else{
        this.store.dispatch(new DeleteDocumentacionCisterna(cliente, this.documentacionCisterna.documentacion, usuario, 0, 'Cisterna. '+observacion)).subscribe(
          () => {
            Swal.fire(
              '','El presentacion ha sido anulada con éxito!',
              'success'
            )

          }
        )
      }



  }

  generarDeclaracion() {
    const cliente = this.store.selectSnapshot(SocioState.cliente);
    const codSubcodCliente = `${this.store.selectSnapshot(SocioState.codigoCliente)}${this.store.selectSnapshot(SocioState.subCodigoCliente)}`;
    this.sgs.createDeclaracionCisternaDocumentacionPlazo(cliente, this.selectedRow.id).subscribe((contenido: HttpResponse<Blob>) => {
      this.file.exportarArchivo(contenido, `declaracion_jurada_cisterna_${codSubcodCliente}`, 'docx')
    })
  }

  onGridReady(params) {
    this.gridApi = params.api;
  }
  onSelectionChanged(e) {
    this.emailCliente = this.store.selectSnapshot(SocioState.email);
    this.selectedRow = this.gridApi.getSelectedRows()[0];
  }



  editarEmail() {
    const cliente = this.store.selectSnapshot(SocioState.cliente);
    this.actualizarEmailComponent.setCliente({ cliente, email: this.emailCliente })
    this.actualizarEmailComponent.open()
  }

  retornoOperacionActualizarEmail(retorno) {
    if (retorno) {
      const codigoCliente = this.store.selectSnapshot(SocioState.codigoCliente)
      const subCodigoCliente = this.store.selectSnapshot(SocioState.subCodigoCliente)
      this.store.dispatch(new GetSocioDetalle(codigoCliente, subCodigoCliente))
    }
  }



  ngOnDestroy() {
    this.plazosDocumentacionCisterna$.unsubscribe();
    this.documentacionCisterna$.unsubscribe();
  }

}
