import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterBarComponent } from './footer-bar/footer-bar.component';
import { TopBarComponent } from './top-bar/top-bar.component';
import { LayoutComponent } from './layout.component';
import { RouterModule } from '@angular/router';
import { ContentComponent } from './content/content.component';
import { LeftBarComponent } from './left-bar/left-bar.component';
import { SocioGestionModule } from '../socio-gestion/socio-gestion.module';
import { SharedModule } from '../shared/shared.module';
import { NotificacionBadgeComponent } from '../notificaciones/notificacion-badge/notificacion-badge.component';

@NgModule({
  declarations: [FooterBarComponent,  TopBarComponent,LeftBarComponent, ContentComponent, LayoutComponent],
  imports: [
    CommonModule,RouterModule,SocioGestionModule,SharedModule,NotificacionBadgeComponent
  ],
  exports:[FooterBarComponent, ContentComponent, TopBarComponent]

})
export class LayoutModule { }
