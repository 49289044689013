import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { FiltroFecha } from '../shared/modelos/filtro-fecha.model';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json'
  })
};


@Injectable({
  providedIn: 'root'
})

export class CobranzaService {


  urlRest = environment;

  constructor(private http: HttpClient) { }

  getListadoControlCobranza(fecha:FiltroFecha): Observable<any>{
    return this.http.get(`${this.urlRest.urlapiNode}/cobranza/control/${fecha.fecha_desde}/${fecha.fecha_hasta}`)

  }

  getComprobanteCobranzaPorNumeroComprobante(numero_comprobante): Observable<any>{
    return this.http.get(`${this.urlRest.urlapiNode}/cobranza/comprobante/${numero_comprobante.numero_comprobante}`)
  }


  getComprobanteCobranzaPorSocio(socio): Observable<any>{
    return this.http.get(`${this.urlRest.urlapiNode}/cobranza/comprobante/cliente/${socio.cod_socio}/${socio.sub_cod_socio}`)
  }

  getComprobanteCobranzaPorFecha(fecha:FiltroFecha): Observable<any>{
    return this.http.get(`${this.urlRest.urlapiNode}/cobranza/comprobante/fecha/${fecha.fecha_desde}/${fecha.fecha_hasta}`)
  }


  getFacturasComprobantes(numero_comprobante): Observable<any>{
    return this.http.get(`${this.urlRest.urlapiNode}/cobranza/comprobante/facturas/${numero_comprobante}`)
  }


  anularCobranzaMultiple(numero_comprobante:string,usuario:number,usuarioAutorizante:string):Observable<any>{
    return this.http.post(`${this.urlRest.urlapiNode}/cobranza/pago-multiple/anular`,{numero_comprobante,usuario,usuarioAutorizante},httpOptions)
  }


  anularCobranzaSimple(facturas:any[],observacion:string,cliente:number,usuarioAutorizante:string):Observable<any>{
    return this.http.post(`${this.urlRest.urlapiNode}/cobranza/pago/anular`,{facturas,observacion,cliente,usuarioAutorizante},httpOptions)
  }


  getResumenDiarioPDF(fechaDesdeResumen:string,fechaHastaResumen:string,periodoResumen:string,tipoResumen:string,origenResumen:string): Observable<any>{
  return this.http.post(`${this.urlRest.urlapiNode}/cobranza/reportes/resumen-diario/pdf`,{fechaDesdeResumen,fechaHastaResumen,periodoResumen,tipoResumen,origenResumen},{
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      }),
       responseType: 'blob'
  })

   }

   getResumenDiarioExcel(fechaDesdeResumen:string,fechaHastaResumen:string,periodoResumen:string,tipoResumen:string,origenResumen:string): Observable<any>{
    return this.http.post(`${this.urlRest.urlapiNode}/cobranza/reportes/resumen-diario/excel`,{fechaDesdeResumen,fechaHastaResumen,periodoResumen,tipoResumen,origenResumen},{
        headers: new HttpHeaders({
          'Content-Type':  'application/json'
        }),
         responseType: 'blob'
    })

  }

  getResumenDiarioRecibosPDF(fechaDesdeResumen:string,fechaHastaResumen:string,periodoResumen:string): Observable<any>{
    return this.http.post(`${this.urlRest.urlapiNode}/cobranza/reportes/resumen-diario-recibos/pdf`,{fechaDesdeResumen,fechaHastaResumen,periodoResumen},
    {
         responseType: 'blob'
    })
   }

  getControlRecaudacionPDF(tipoListado:number,caja:number,origenResumen:number,fechaListado:string,ordenarPor:number):Observable<any>{
    return this.http.post(`${this.urlRest.urlapiNode}/cobranza/reportes/control-recaudacion/pdf`,{tipoListado,caja,origenResumen,fechaListado,ordenarPor},
      {responseType: 'blob'})
  }

}
