import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Usuario } from '../store/usuario/model';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

  constructor(private http:HttpClient) { }

  getUsuarios():Observable<Usuario[]> {
    return this.http.get<Usuario[]>(`${environment.urlapiNode}/usuarios`);
   }

   saveUsuario(
    nombre:string,
    apellido:string,
    username:string,
    estaActivo:boolean,
    rol:number,
    permisos:number[],
    password:string
   ):Observable<any> {
    return this.http.post(`${environment.urlapiNode}/auth/register`,{nombre,apellido,username,estaActivo,rol,permisos,password});
   }

   updateUsuario(
    id_usuario:number,
    nombre:string,
    apellido:string,
    username:string,
    estaActivo:boolean,
    rol:number,
    permisos:number[]
   ):Observable<any> {
    return this.http.put(`${environment.urlapiNode}/auth/register`,{id_usuario,nombre,apellido,username,estaActivo,rol,permisos});
   }

   updatePassword(idUsuario: number, newPassword:string):Observable<any> {
    return this.http.put(`${environment.urlapiNode}/auth/password`,{idUsuario,newPassword});
   }

}
