import { State, StateContext, Action } from "@ngxs/store";
import { ReciboSemestralPago, ReciboSemestralDetalle, ReciboSemestralListado, InformacionReciboSemestralActual, FacturasSemestrePagas } from './models';
import { ReciboService } from 'src/app/recibo-semestral/recibo-semestral.service';
import { GetReciboSemestralParaPago,  GetDetalleReciboSemestralPagado, AnularCobroRecibo, GetListadoReciboSemestral, GetInformacionReciboSemestreActivo, GenerarReciboSemestralCliente, ResetReciboSemestral, GetInformacionFacturasPagasReciboSemestreActivo  } from './actions';
import { Injectable } from '@angular/core';
import { mergeMap } from 'rxjs/operators';
import { of, from } from "rxjs";



export class ReciboStateModel {
     recibo_semestral_listado:ReciboSemestralListado[];
     recibo_semestral_pago:ReciboSemestralPago;
     recibo_semestral_detalle:ReciboSemestralDetalle[];
     recibo_semestral_facturas_pagas:FacturasSemestrePagas[]
     informacion_recibo_semetral_activo:InformacionReciboSemestralActual;
     inicializado_recibo_semestral_pago:boolean;
     inicializado_recibo_semestral_listado:boolean;
     inicializado_semestral_facturas_pagas:boolean;

}


@State<ReciboStateModel>({
    name: 'recibo',
    defaults: {
        recibo_semestral_listado:[],
        recibo_semestral_pago:null,
        recibo_semestral_detalle:[],
        recibo_semestral_facturas_pagas:[],
        informacion_recibo_semetral_activo:null,
        inicializado_recibo_semestral_pago:false,
        inicializado_recibo_semestral_listado:false,
        inicializado_semestral_facturas_pagas:false
    }
})

@Injectable()
export class ReciboState {

    constructor(private rs: ReciboService) { }

    @Action(GetInformacionReciboSemestreActivo)
    getInformacionReciboSemestreActivo(ctx: StateContext<ReciboStateModel>) {
      const state =ctx.getState()
      if(!state.informacion_recibo_semetral_activo){
        this.rs.getInformacionReciboSemestralActivo().subscribe(
          data=> {
              ctx.patchState({
                  informacion_recibo_semetral_activo:data
              });
          }
      )
      }

    }


    @Action(GetListadoReciboSemestral)
    getListadoReciboSemestral(ctx: StateContext<ReciboStateModel>, action:GetListadoReciboSemestral) {
      const state = ctx.getState()
      let seguir = false

      if(!action.update && !state.inicializado_recibo_semestral_listado){seguir=true}
      if(action.update && state.inicializado_recibo_semestral_listado){seguir=true}
      if (seguir) {
        this.rs.getListadoReciboSemestralCliente(action.cliente).subscribe(
          data=> {
              ctx.patchState({
                  recibo_semestral_listado: data,
                  inicializado_recibo_semestral_listado:true
              });
          }
      )
      }

    }

    @Action(GetReciboSemestralParaPago)
    getReciboSemestral(ctx: StateContext<ReciboStateModel>, action:GetReciboSemestralParaPago) {
      const state = ctx.getState()
      let seguir = false

      if(!action.update && !state.inicializado_recibo_semestral_pago){seguir=true}
      if(action.update && state.inicializado_recibo_semestral_pago){seguir=true}
      if (seguir) {
        this.rs.getReciboSemestralCliente(action.cliente).subscribe(
          data=> {
              ctx.patchState({
                  recibo_semestral_pago: data,
                  inicializado_recibo_semestral_pago:true
              });
          }
      )
      }

    }

    @Action(GetDetalleReciboSemestralPagado)
    GetDetalleReciboSemestralPagado({ patchState }: StateContext<ReciboStateModel>, action:GetDetalleReciboSemestralPagado) {
           this.rs.getDetalleReciboSemestralComprobantePago(action.cliente).subscribe(
                data=> {
                    patchState({
                        recibo_semestral_detalle: data
                    });
                }
            )
    }

    @Action(GetInformacionFacturasPagasReciboSemestreActivo)
    getInformacionFacturasPagasReciboSemestreActivo(ctx: StateContext<ReciboStateModel>, action:GetInformacionFacturasPagasReciboSemestreActivo) {
      const state = ctx.getState()
      let seguir = false

      if(!action.update && !state.inicializado_semestral_facturas_pagas){seguir=true}
      if(action.update && state.inicializado_semestral_facturas_pagas){seguir=true}
      if (seguir) {
        this.rs.getInformacionFacturasPagasReciboSemestreActivo(action.cliente).subscribe(
          data=> {
            ctx.patchState({
                  recibo_semestral_facturas_pagas: data,
                  inicializado_semestral_facturas_pagas:true
              });
          }
      )
      }
          
    }

    


    @Action(AnularCobroRecibo)
    anularCobroRecibo({ patchState }: StateContext<ReciboStateModel>, action:AnularCobroRecibo) {
          return this.rs.anularCobroRecibo(action.recibo,action.comprobanteCaja,action.cliente,action.usuario)
    }



    @Action(GenerarReciboSemestralCliente)
    generarReciboSemestralCliente(ctx: StateContext<ReciboStateModel>, action:GenerarReciboSemestralCliente) {
       return   this.rs.generarReciboSemestralCliente(action.recibo,action.cliente).pipe(
            mergeMap(()=> ctx.dispatch(new GetListadoReciboSemestral(action.cliente,true))),
            mergeMap(()=> ctx.dispatch(new GetReciboSemestralParaPago(action.cliente,true)))
           )
    }



    @Action(ResetReciboSemestral)
    resetReciboSemestral({ patchState }: StateContext<ResetReciboSemestral>) {

        patchState({
          recibo_semestral_pago:null,
          recibo_semestral_listado:[],
          inicializado_recibo_semestral_pago:false,
          inicializado_recibo_semestral_listado:false,
          recibo_semestral_facturas_pagas:[],
          inicializado_semestral_facturas_pagas:false
        })

        return from([]);
    }






}
