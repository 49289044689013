<div class="card">
  <div class="card-header card-header-info">
    <i class="fas fa-money-check-alt"></i> Cuenta corriente
  </div>
  <div class="card-body">
    <div class="row justify-content-md-center">
      <div class="col-10">
          <ag-grid-angular
          #agGridCuentaCorriente
          style="width: 100%; height: 350px;"
          class="ag-theme-alpine"
          [rowData]="rowData"
          [columnDefs]="columnDefs"
          [colResizeDefault]="'fit'"
          [rowSelection]= "'single'"
          [rowHeight]="25"
          ></ag-grid-angular>
      </div>
    </div>
  </div>
</div>


