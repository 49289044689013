
<a class="nav-link" routerLink="/notificaciones" title="Ingresar al buzon de notificaciones" style="position: absolute;" >  
    <i class="fa-regular fa-envelope fa-xl" style="color: #f6f5f4;"></i> 

    @if (isLoading()) {
        <span class="spinner-border spinner-border-sm text-warning" style="position: relative; inset:-15px 0px 0px 10px;" role="status" aria-hidden="true"></span>
    }

    @if (totalNotificaciones()>0 && !isLoading()) {
        <span class="badge badge-pill badge-danger" style="position: relative; inset:-15px 0px 0px 10px;">{{totalNotificaciones()}}</span>
    }
    
</a> 
