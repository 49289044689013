import { Component, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngxs/store';
import { GetTiposDni, GetTiposIva, GetCategorias, GetEstados, GetFinSupension, GetTarifas, GetZonas,  GetProvincias, GetTiposMedianeras, GetTiposConexion, GetTiposMaterial, GetPrestadoresDebitoAutomatico, GetCondicionRegistro, GetBancos } from 'src/app/store/general/actions';
import { Router, ActivatedRoute } from '@angular/router';
import { ClearSocioParaEdicion, SocioUsuarioEdicion,  StartEnProcesoSocioAltaModificacion } from 'src/app/store/socio/actions';
import { UntypedFormGroup } from '@angular/forms';
import { Socio } from '../../socio';
import { SocioState } from "src/app/store/socio/states";
import { OperacionExitosaComponent } from 'src/app/shared/operacion-exitosa/operacion-exitosa.component';
import { OperacionesABM } from 'src/app/shared/enums/enums';
import Swal from 'sweetalert2';




@Component({
  selector: 'app-actualizar-socio',
  templateUrl: './actualizar-socio.component.html',
  styleUrls: ['./actualizar-socio.component.css']
})
export class ActualizarSocioComponent implements OnInit {

  @ViewChild(OperacionExitosaComponent, { static: true })
  operacionExitosaComponent: OperacionExitosaComponent;

  seccionIr:string;

  socio: Socio = new Socio();

  modoOperacion: OperacionesABM= OperacionesABM.editar



  constructor(private store: Store,private router: Router, private route: ActivatedRoute) {
  }

  ngOnInit() {

    this.seccionIr= this.route.snapshot.paramMap.get('seccion')

this.store.dispatch(new GetTiposDni());
this.store.dispatch(new GetTiposIva());
this.store.dispatch(new GetCategorias());
this.store.dispatch(new GetEstados());
this.store.dispatch(new GetFinSupension());
this.store.dispatch(new GetTarifas());
this.store.dispatch(new GetZonas());
this.store.dispatch(new GetProvincias());
this.store.dispatch(new GetTiposMedianeras());
this.store.dispatch(new GetTiposConexion());
this.store.dispatch(new GetTiposMaterial());
this.store.dispatch(new GetPrestadoresDebitoAutomatico());
this.store.dispatch(new GetCondicionRegistro());



  }

  cancelarOperacion(){
    this.store.dispatch(new ClearSocioParaEdicion());
    this.router.navigate(['/socio']);
  }

  actualizar(event:UntypedFormGroup){

    this.store.dispatch(new StartEnProcesoSocioAltaModificacion())

    const idCliente = this.store.selectSnapshot(SocioState.cliente);

  this.store.dispatch(new SocioUsuarioEdicion(this.socio.generarSocioUsuarioAltaEdicion(event),idCliente)).subscribe(
    x => {

      Swal.fire(
        '','El Socio/Usuario ha sido actualizado de manera exitosa!',
        'success'
      ).then(()=>this.router.navigate(['/socio']))

    }
  );

}




}
