import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
declare var $:any;


@Component({
  selector: 'app-operacion-si-no',
  templateUrl: './operacion-si-no.component.html',
  styleUrls: ['./operacion-si-no.component.css']
})
export class OperacionSiNoComponent implements OnInit {

  @ViewChild('buttonAceptar',{static:true}) buttonAceptar: ElementRef

  @Input() titulo:string;
  @Input() contenidoMensaje:string;
  @Input() id:string="operacion_si_no";


  @Output() retornoOperacion = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  open(){
    $(`#${this.id}`).modal('show')

    this.buttonAceptar.nativeElement.focus()
  }

  aceptar(){
    $(`#${this.id}`).modal('hide')
    this.retornoOperacion.emit(true);
  }

  cancelar(){
    $(`#${this.id}`).modal('hide')
    this.retornoOperacion.emit(false);
  }



}
