import { Component, OnInit, Input, OnDestroy, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { Store } from '@ngxs/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import  moment from 'moment';
import { GetFacturasImpagasActualizadas, GetFacturasPagas } from 'src/app/store/factura/actions';
import { SocioState } from 'src/app/store/socio/states';



@Component({
  selector: 'app-listado-facturas',
  templateUrl: './listado-facturas.component.html',
  styleUrls: ['./listado-facturas.component.css']
})
export class ListadoFacturasComponent implements OnInit,OnDestroy {


  @Output() seleccionRetorno = new EventEmitter();
  @Output() impresionEvent = new EventEmitter();

  @Input() height:string = "400px"
  @Input() filterOpcion:boolean = false
  @Input() sorteableOpcion:boolean = false
  @Input() rowSelectionOpcion:string = 'single'
  @Input() visualizacionExpandida:boolean = false
  @Input() mostrarDetalleSeleccion:boolean = true
  @Input() modoImpresion: boolean=false;
  @Input() habilitarFacturasPagas: boolean=false;



  facturas_impagas: any[];
  facturas_pagas: any[];
  lista_facturas: any[];
  columnDefs=[]
  verFacturasPagas=false

  gridApi:any;
  gridColumnApi: any;

  //opciones de seleccion
  seleccionarTodo:boolean=false
  totalSeleccionadas:number = 0

  destroy$: Subject<boolean> = new Subject<boolean>();



  constructor(private store:Store) {

  }

  ngOnInit(): void {

    this.columnDefs = [
      {headerName: 'Periodo', field: 'Periodo',sortable: this.sorteableOpcion, filter: this.filterOpcion, width:140,headerCheckboxSelection: true,
      checkboxSelection: true },
      {headerName: 'Factura', field: 'Factura',sortable: this.sorteableOpcion, filter: this.filterOpcion, width:100  },
      {headerName: 'Estado', field: 'Estado',sortable: this.sorteableOpcion, filter: this.filterOpcion, width:100 ,
      cellStyle: function(params) {
        if (params.value=='Pagada') {
            //mark police cells as red
            return {color: 'green'};
        } else {
            return null;
        }
    } },
      {headerName: 'Total', field: 'Total',sortable: this.sorteableOpcion, filter: this.filterOpcion, width:100 },
      {headerName: 'Neto', field: 'Neto',sortable: this.sorteableOpcion, filter: this.filterOpcion, width:100 ,hide:!this.visualizacionExpandida},
      {headerName: 'Iva', field: 'IVA',sortable: this.sorteableOpcion, filter: this.filterOpcion, width:100 ,hide:!this.visualizacionExpandida},
      {headerName: 'Recargo', field: 'Recargo',sortable: this.sorteableOpcion, filter: this.filterOpcion, width:100 ,hide:!this.visualizacionExpandida,valueFormatter:this.formatNumber },
      {headerName: 'Iva rec', field: 'IVARec',sortable: this.sorteableOpcion, filter: this.filterOpcion, width:100 ,hide:!this.visualizacionExpandida,valueFormatter:this.formatNumber },
      {headerName: 'Cons. M3', field: 'ConsM3',sortable: this.sorteableOpcion, filter: this.filterOpcion, width:100 ,hide:!this.visualizacionExpandida,valueFormatter:this.formatNumber },
      {headerName: 'Lectura', field: 'Lectura',sortable: this.sorteableOpcion, filter: this.filterOpcion, width:100 ,hide:!this.visualizacionExpandida },
      {headerName: 'Lect ant', field: 'LectAnt',sortable: this.sorteableOpcion, filter: this.filterOpcion, width:100 ,hide:!this.visualizacionExpandida },
      {headerName: 'Fecha Pago', field: 'FechaPago',sortable: this.sorteableOpcion, filter: this.filterOpcion, width:150 ,hide:this.verFacturasPagas,
      valueFormatter: function(params){
          if (params.value!==''){
            return  moment(params.value).format("DD/MM/YYYY")
          }else{
            return null
          }

      } },
      {headerName: 'Cobrado', field: 'Cobrado',sortable: this.sorteableOpcion, filter: this.filterOpcion, width:100 ,hide:!this.visualizacionExpandida ,valueFormatter:this.formatNumber },
      {headerName: 'Descuento', field: 'Descuento',sortable: this.sorteableOpcion, filter: this.filterOpcion, width:100 ,hide:!this.visualizacionExpandida ,valueFormatter:this.formatNumber },
      {headerName: 'IVADesc', field: 'IVADesc',sortable: this.sorteableOpcion, filter: this.filterOpcion, width:100 ,hide:!this.visualizacionExpandida ,valueFormatter:this.formatNumber }

    ];

    const cliente = this.store.selectSnapshot(SocioState.cliente);

    this.store.dispatch(new GetFacturasImpagasActualizadas(cliente,false))
    this.store.dispatch(new GetFacturasPagas(cliente,false))

    this.store.select(state => state.factura.facturas_impagas).pipe(takeUntil(this.destroy$)).subscribe(
      data=> {
        this.facturas_impagas = data
        this.lista_facturas= data
        this.verFacturasPagas = false
      }
    )

    this.store.select(state => state.factura.facturas_pagas).pipe(takeUntil(this.destroy$)).subscribe(
      data=> {
        this.facturas_pagas = data
      }
    )
  }


  formatNumber(params){
    return params.value?.toFixed(2) || ""
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  onSelectionChanged(event){
    this.totalSeleccionadas = this.gridApi.getSelectedRows().length
    this.seleccionRetorno.emit(this.gridApi.getSelectedRows())
  }

  public obtenerFacturasSeleccionadas(){
    return this.gridApi.getSelectedRows()
  }

  mostrarSiNoFacturasPagas(event){
    if(event.target.checked){
      this.lista_facturas = this.facturas_pagas

    }else{
      this.lista_facturas = this.facturas_impagas
    }
    this.seleccionRetorno.emit([])
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}
