import { State, StateContext, Action } from "@ngxs/store";
import {  GetBarrios, SaveBarrio, EditBarrio, DeleteBarrio } from './actions';

import { Injectable } from '@angular/core';
import { Barrio } from './models';
import { BarrioService } from 'src/app/barrio/barrio.service';
import { mergeMap } from 'rxjs/operators';

export class BarrioStateModel {
    barrios: Barrio[];
}


@State<BarrioStateModel>({
    name: 'barrio',
    defaults: {
        barrios: []
    }
})


@Injectable()
export class BarrioState {

    constructor(private bs: BarrioService) { }


    @Action(GetBarrios)
    // llamamos a la api en cada solicitud del servicio ya que los barrios pueden actualizarse en cada llamada
    getBarrios({ patchState }: StateContext<BarrioStateModel>) {
        this.bs.getBarrios().subscribe(
            data => {
                patchState({
                    barrios: data
                });
            }
        )
    }

    @Action(SaveBarrio)
    saveBarrio(ctx: StateContext<BarrioStateModel>, action:SaveBarrio) {

      return this.bs.nuevoBarrio(action.barrio)
      .pipe(
       mergeMap(()=> ctx.dispatch(new GetBarrios()))
     )

    }

    @Action(EditBarrio)
    editBarrio(ctx: StateContext<BarrioStateModel>, action:EditBarrio) {

      return this.bs.editarBarrio(action.idBarrio,action.barrio)
      .pipe(
       mergeMap(()=> ctx.dispatch(new GetBarrios()))
     )

    }

    @Action(DeleteBarrio)
    deleteBarrio(ctx: StateContext<BarrioStateModel>, action:DeleteBarrio) {

      return this.bs.deleteBarrio(action.idBarrio,action.barrio)
      .pipe(
       mergeMap(()=> ctx.dispatch(new GetBarrios()))
     )

    }







}
