<div class="card">
  <div class="card-header card-header-info"><i class="fa fa-registered "></i> Recibos</div>
  <div class="card-body">
    <div class="row">
      <div class="col-sm-6">
        <div class="row">
          <div class="col-md-12">
            <app-recibo-semestral-listado 
      (imprimirComprobantePagoEvent)="imprimirComprobantePago($event)"
      (imprimirReciboEvent)="imprimirRecibo($event)"
      ></app-recibo-semestral-listado>
          </div>
          <div class="col-md-12 mt-3">
            <div class="alert alert-warning" role="alert">
              Si el socio posee <b>facturas del semestre sin abonar a las cuales se les ha imputado crédito a favor</b>, y desea aplicar dicho crédito como descuento del pago semestral, recomendamos ir a la opcion de <b>créditos</b> y allí generar un nuevo crédito a favor por el monto imputado; de esta manera el sistema permitirá aplicar el <b>descuento por crédito a favor</b> (se habilita la opción de descuento).
               </div>
          </div>
        </div>
       
      </div>
        <div class="col-sm-6">
           
      <app-recibo-semestral-cobranza 
      (imprimirComprobantePagoCobranzaEvent)="imprimirComprobantePagoCobranza($event)"
      (imprimirReciboEvent)="imprimirRecibo($event)"
      ></app-recibo-semestral-cobranza>

          </div>
      </div>
     </div>
</div>

<app-recibo-semestral-impresiones></app-recibo-semestral-impresiones>


