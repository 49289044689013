import {  Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup,  Validators } from '@angular/forms';
import { OperacionesABM } from 'src/app/shared/enums/enums';
import { ItemsLista } from 'src/app/store/general/models';
import { SocioDetalle } from 'src/app/store/socio/models';
import { Time } from 'src/app/utils/time';
import { ValidacionFormularioSocio } from '../validacion-formulario-socio';

@Component({
  selector: 'app-datos-tecnicos-info',
  templateUrl: './datos-tecnicos-info.component.html',
  styleUrls: ['./datos-tecnicos-info.component.css'],

})
export class DatosTecnicosInfoComponent implements OnInit {


  @Input() modoOperacion: OperacionesABM;
  @Input() socio: SocioDetalle;
  @Input() tiposMedianeras: ItemsLista[];
  @Input() tiposConexion: ItemsLista[];
  @Input() tiposMaterial: ItemsLista[];

  socioForm: UntypedFormGroup
  constructor( private fb: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.socioForm = this.initSocioFormModel();
    this.setearSocioForm();
  }

  initSocioFormModel(){
    return  this.fb.group({
      numeroMedidor: [this.socio.medidor],
      marcaMedidor: [
        { value: this.socio.marcaMedidor, disabled: true },
        Validators.required,
      ],
      estadoMedidor: [
        { value: this.socio.lecturaAnt, disabled: true },
        Validators.required,
      ],
      ultimaLecturaMedidor: [
        {
          value:Time.formatDate(this.socio.fLecturaAnt,"YYYY-MM-DD"),
          disabled: true,
        },
        Validators.required,
      ],

      distanciaAMedianera: [this.socio.ubicServicio],
      medianera: [this.socio.medianera],
      tipoConexion: [this.socio.tipoConexion, Validators.required],

      distanciaLineaMunicipalRedTroncal: [this.socio.distanciaARed],
      profundidadRedTroncal: [this.socio.profundidadRed],
      tipoMaterialRedTroncal: [this.socio.id_material],
      diametroRedTroncal: [this.socio.diametroRed],
    })
  }

  setearSocioForm(){
    if (this.modoOperacion == OperacionesABM.alta) {
      this.socioForm
      .get("distanciaAMedianera")
      .setValidators(Validators.required);
    this.socioForm.get("medianera").setValidators(Validators.required);
    this.socioForm.get("tipoConexion").setValidators(Validators.required);
    this.socioForm.get("distanciaLineaMunicipalRedTroncal").setValidators(Validators.required);
    this.socioForm.get("profundidadRedTroncal").setValidators(Validators.required);
    this.socioForm.get("tipoMaterialRedTroncal").setValidators(Validators.required);
    this.socioForm.get("diametroRedTroncal").setValidators(Validators.required);
    }

    if (this.modoOperacion == OperacionesABM.editar) {
      if (this.socio.medidor?.length > 0 && !(this.socio.medidor === "")) {
        this.socioForm.get("marcaMedidor").enable();
        this.socioForm.get("estadoMedidor").enable();
        this.socioForm.get("ultimaLecturaMedidor").enable();
      }
    }
  }

  get marcaMedidorField(){
    return this.socioForm.controls.marcaMedidor
  }

  get isInvalidMarcaMedidorField(){
    return this.marcaMedidorField.touched && this.marcaMedidorField.invalid
  }

  get estadoMedidorField(){
    return this.socioForm.controls.estadoMedidor
  }

  get isInvalidEstadoMedidorField(){
    return this.estadoMedidorField.touched && this.estadoMedidorField.invalid
  }

  get ultimaLecturaMedidorField(){
    return this.socioForm.controls.ultimaLecturaMedidor
  }

  get isInvalidUltimaLecturaMedidorField(){
    return this.ultimaLecturaMedidorField.touched && this.ultimaLecturaMedidorField.invalid
  }

  get distanciaAMedianeraField(){
    return this.socioForm.controls.distanciaAMedianera
  }

  get isInvalidDistanciaAMedianeraField(){
    return this.distanciaAMedianeraField.touched && this.distanciaAMedianeraField.invalid
  }

  get medianeraField(){
    return this.socioForm.controls.medianera
  }

  get isInvalidMedianeraField(){
    return this.medianeraField.touched && this.medianeraField.invalid
  }

  get tipoConexionField(){
    return this.socioForm.controls.tipoConexion
  }

  get isInvalidTipoConexionField(){
    return this.tipoConexionField.touched && this.tipoConexionField.invalid
  }


  get distanciaLineaMunicipalRedTroncalField(){
    return this.socioForm.controls.distanciaLineaMunicipalRedTroncal
  }

  get isInvalidDistanciaLineaMunicipalRedTroncalField(){
    return this.distanciaLineaMunicipalRedTroncalField.touched && this.distanciaLineaMunicipalRedTroncalField.invalid
  }

  get profundidadRedTroncalField(){
    return this.socioForm.controls.profundidadRedTroncal
  }

  get isInvalidProfundidadRedTroncalField(){
    return this.profundidadRedTroncalField.touched && this.profundidadRedTroncalField.invalid
  }

  get tipoMaterialRedTroncalField(){
    return this.socioForm.controls.tipoMaterialRedTroncal
  }

  get isInvalidTipoMaterialRedTroncalField(){
    return this.tipoMaterialRedTroncalField.touched && this.tipoMaterialRedTroncalField.invalid
  }

  get diametroRedTroncalField(){
    return this.socioForm.controls.diametroRedTroncal
  }

  get isInvalidDiametroRedTroncalField(){
    return this.diametroRedTroncalField.touched && this.diametroRedTroncalField.invalid
  }


  verificarNumeroMedidor(event) {
    if (event.target.value.length > 0 && !(event.target.value === '')) {
      this.socioForm.get('marcaMedidor').enable();
      this.socioForm.get('estadoMedidor').enable();
      this.socioForm.get('ultimaLecturaMedidor').enable();
    } else {
      this.socioForm.get('marcaMedidor').disable();
      this.socioForm.get('estadoMedidor').disable();
      this.socioForm.get('ultimaLecturaMedidor').disable();
      this.socioForm.get('marcaMedidor').setValue('');
      this.socioForm.get('estadoMedidor').setValue('');
      this.socioForm.get('ultimaLecturaMedidor').setValue('');
    }
  }

  get isValidForm(){
    return this.socioForm.valid
  }

  showInvalidControl(){
    ValidacionFormularioSocio.setErrorsInForm(this.socioForm);
  }

  get formValue(){
    return this.socioForm.getRawValue()
  }

}
