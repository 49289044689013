<div class="card">
    <div class="card-header card-header-info">
        Facturas para convenio
    </div>
    <div class="card-body">
        <div class="row">
            <div class="col-md-12">
                <app-listado-facturas [height]="'300px'" [rowSelectionOpcion]="'multiple'"></app-listado-facturas>
            </div>
        </div>
    </div>
    <div class="card-footer">
        <div class="float-right">
            <button class="btn btn-sm btn-outline-danger" (click)="cancelar()">Cancelar</button>
            &nbsp;
            <button class="btn btn-sm btn-outline-info" (click)="agregarAConvenio()">Agregar a nuevo convenio</button>
        </div>
    </div>
</div>
