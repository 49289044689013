import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { NewCredito, DeleteCreditoOtorgado, UpdateCreditoOtorgadoImputarFacturasAdeudadas, GetCreditoImputado, GetCreditoMontoTotal, GetCreditosOtorgados } from 'src/app/store/credito/actions';
import { AuthState } from 'src/app/store/auth/state';
import { SocioState } from 'src/app/store/socio/states';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap } from 'rxjs/operators';
import { OperacionSiNoComponent } from 'src/app/shared/operacion-si-no/operacion-si-no.component';
import { CreditoService } from '../credito.service';
import { HttpResponse } from '@angular/common/http';
import { Imprimir } from 'src/app/shared/imprimir';
import { AutorizacionComponent } from 'src/app/autorizacion/autorizacion.component';
import  moment from 'moment';
import { GetFacturasImpagasActualizadas, GetFacturasPagas } from 'src/app/store/factura/actions';

import { Utils } from 'src/app/factura/utils';
import { GetCuentaCorriente  } from 'src/app/store/cuentacorriente/actions';
import { ModoChequeoRol, PermisosModoRequerido } from 'src/app/shared/role.directive';
import { TipoPermisos } from 'src/app/store/roles-permisos/model';
import Swal from 'sweetalert2';

declare var $: any;

@Component({
  selector: 'app-credito-socio',
  templateUrl: './credito-socio.component.html',
  styleUrls: ['./credito-socio.component.css']
})
export class CreditoSocioComponent implements OnInit,OnDestroy {

  @ViewChild(OperacionSiNoComponent, { static: true })
  operacionSiNoComponent: OperacionSiNoComponent;

  @ViewChild(AutorizacionComponent, { static: true })
  autorizacionComponent: AutorizacionComponent;

  @ViewChild('agGridCredito', { static: true }) agGridCredito: any;

  rowData: any;

  columnDefs:any = [
    {headerName: 'Total', field: 'total', width:100 },
    {headerName: 'Periodo', field: 'periodo', width:100 },
    {headerName: 'Fecha Imputacion', field:'fecha_imputacion', flex:1}
  ];

  permisoModoRequeridoEliminarCreditosImputados:PermisosModoRequerido={permisosRequerido:[TipoPermisos.EliminarCreditosImputados],
    modoRequerido:ModoChequeoRol.RequiereTodosLosPermisos}

  creditoForm: UntypedFormGroup;
  imputacionEliminarForm: UntypedFormGroup;
  submitted = false;

  modo: number = 0; // 0- seleccion 1-edicion 2-alta

  montoTotal:number
  creditos:any[]
  facturasSeleccionadas:any[]=[];
  imputaciones:any[]
  imputarEnFacturas:boolean=false

  utils:Utils = new Utils()
 seleccionadasAPagar = 0
totalAPagarEnFacturas = 0
recargoAPagar= 0
totalACobrar = 0



  private creditoEliminar:number;

  destroy$: Subject<boolean> = new Subject<boolean>();

  public imprimir = new Imprimir();

  mensajeEliminacion:string


  periodoImputacion:string;

  cliente:number

  constructor(private formBuilder: UntypedFormBuilder,private store:Store,private cs:CreditoService ) {

   }

  ngOnInit() {
     this.cliente = this.store.selectSnapshot(SocioState.cliente);
     this.store.dispatch(new GetCreditoMontoTotal(this.cliente,false))
     this.store.dispatch(new GetCreditosOtorgados(this.cliente,false))
     this.store.dispatch(new GetCreditoImputado(this.cliente,false))



    this.initForm();
    this.store.select(state => state.credito.credito_monto_total).pipe(takeUntil(this.destroy$)).subscribe(
      data=>{
        this.montoTotal = data?.monto
      }
    )

      this.store.select(state => state.credito.creditos_otorgados).pipe(takeUntil(this.destroy$)).subscribe(
      data=>{
        this.creditos = data
      }
    )

     this.store.select(state => state.credito.creditos_imputaciones).pipe(takeUntil(this.destroy$)).subscribe(
      data=>{
        this.imputaciones = data; this.rowData = data
      }
    )


  }

  initForm() {

    this.creditoForm = this.formBuilder.group({
      montoTotal: [{ value: '', disabled: true }, Validators.required],
      observacion: [{ value: '', disabled: true }]

    });

    this.imputacionEliminarForm = this.formBuilder.group({
      desdePeriodoImputar: [{ value: '', disabled: false }, Validators.required]
    });
  }

  nuevo() {
    this.creditoForm.get('montoTotal').setValue('')
    this.creditoForm.get('montoTotal').enable()

    this.creditoForm.get('observacion').setValue('')
    this.creditoForm.get('observacion').enable()

    this.modo = 2;
  }

  guardar(){
    this.imputarEnFacturas=false
    this.autorizacionComponent.open()
  }

  retornoAutorizacion(retorno){
    if(retorno.habilita){
        if(!this.imputarEnFacturas){
          this.guardarHabilitado(retorno.usuarioAutorizante)
        }else{
          this.imputarEnFacturasHabilitado(retorno.usuarioAutorizante)
        }
  }
}


  guardarHabilitado(usuarioAutorizante){
    let total = this.creditoForm.get('montoTotal').value
    let observacion = this.creditoForm.get('observacion').value

    const usuario = this.store.selectSnapshot(AuthState.usuario);



    this.store.dispatch(new NewCredito({'total':total,'observacion':observacion,'cliente':this.cliente,'usuario':usuario,'usuarioAutorizante':usuarioAutorizante})).subscribe(
      ()=>{

        Swal.fire(
          '','Se ha cargado el crédito de manera exitosa!',
          'success'
        ).then(()=>this.refrescarCredito())

      }
    )

  }

  cancelar(){
    this.refrescarCredito()

  }


  refrescarCredito() {
    this.creditoForm.get('montoTotal').disable()
    this.creditoForm.get('montoTotal').setValue('')

    this.creditoForm.get('observacion').disable()
    this.creditoForm.get('observacion').setValue('')

    this.modo = 0;
  }


  eliminarCredito(credito) {
    this.mensajeEliminacion = 'Desea eliminar el crédito otorgado?'
    this.creditoEliminar = credito;
    this.operacionSiNoComponent.open();
  }



  retornoOperacionSiNo(retorno) {
    if (retorno) {

      const usuario = this.store.selectSnapshot(AuthState.usuario);
        const cliente = this.store.selectSnapshot(SocioState.cliente);
        // eliminacion de los creditos otorgados
        this.store.dispatch(new DeleteCreditoOtorgado({'credito':this.creditoEliminar,'cliente':cliente,'usuario':usuario})).subscribe(
          ()=>{

            Swal.fire(
              '','El crédito ha sido eliminado de manera exitosa. El crédito total otorgado ha sido actualizado!',
              'success'
            )
          }
        )

    }

  }

  imputarCreditoSaldoEnFacturasAdeudadas(){
    this.imputarEnFacturas=true

    const self = this;
    setTimeout(function(){
        self.scrollTo('imputarFacturas')
    }, 100)
  }




  imprimirCreditoOtorgado(credito){
    this.cs.getComprobanteCreditoOtorgado(credito)
    .subscribe(
      (contenido: HttpResponse<Blob>)=>{
        this.imprimir.imprimir(contenido);
      }
    )
    }

    imprimirCreditoSaldo(){
      const cliente = this.store.selectSnapshot(SocioState.cliente);
      this.cs.getComprobanteCreditoSaldo(cliente)
      .subscribe(
        (contenido: HttpResponse<Blob>)=>{
          this.imprimir.imprimir(contenido);
        }
      )
      }

      imputarSaldoEnFacturas(){
        this.imputarEnFacturas=true
        this.autorizacionComponent.open()
      }


    imputarEnFacturasHabilitado(usuarioAutorizante){

      let fechaActual= moment().format("YYYY-MM-DD");

      let pagos = this.facturasSeleccionadas.map(factura=>{return {'importeTotal':factura.Total, 'importeCobrado':factura.Total,
      'fechaPago':fechaActual,'idFactura':factura.Factura,'periodo':this.getPeriodo(factura.Periodo),
      'recargo':factura.Recargo,'ivaRecargo':factura.IVARec,'descuento':0,'ivaDescuento':0}})

      const usuario = this.store.selectSnapshot(AuthState.usuario);
      const cliente = this.store.selectSnapshot(SocioState.cliente);

      this.store.dispatch(new UpdateCreditoOtorgadoImputarFacturasAdeudadas(pagos,cliente,usuario,usuarioAutorizante)).pipe(
        mergeMap(()=>this.store.dispatch(new GetFacturasImpagasActualizadas(cliente,true))),
        mergeMap(()=>this.store.dispatch(new GetFacturasPagas(cliente,true))),
        mergeMap(()=>this.store.dispatch(new GetCuentaCorriente(cliente,true))),
      ).subscribe(
        ()=>{
          this.imputarEnFacturas = false

          Swal.fire(
            '','Las facturas seleccionadas han sido imputadas de manera exitosa. El crédito total otorgado ha sido actualizado!',
            'success'
          )
        }
      )

    }

    getPeriodo(periodo){
     return periodo.substring(5,periodo.length) + periodo.substring(0,2)
    }

    scrollTo(cuadro){
      $([document.documentElement, document.body]).animate({
        scrollTop: $("#"+cuadro).offset().top - 100
    }, 2000);

    }



    eliminarImputacion(){
      const usuario = this.store.selectSnapshot(AuthState.usuario);
    const cliente = this.store.selectSnapshot(SocioState.cliente);

    this.cs.deleteImputacion(cliente,this.agGridCredito.api.getSelectedRows(),this.imputacionEliminarForm.get('desdePeriodoImputar').value).pipe(
      mergeMap(()=> this.store.dispatch(new GetCreditoImputado(cliente,true))),
        mergeMap(()=>this.store.dispatch(new GetCreditoMontoTotal(cliente,true))),
        mergeMap(()=>this.store.dispatch(new GetFacturasImpagasActualizadas(cliente,true))),
        mergeMap(()=>this.store.dispatch(new GetFacturasPagas(cliente,true))),
        //mergeMap(()=>this.store.dispatch(new SetCuentaCorriente(cliente)))
    ).subscribe(
      ()=>{

        Swal.fire(
          '','La operación ha sido realizada con éxito!',
          'success'
        )
      }
    )

    }

    onSeleccionFacturas(event){

      if(!event){
        this.facturasSeleccionadas =[]
      }else{
        this.facturasSeleccionadas =  event
      }

      const {montoTotal,montoRecargo} = this.utils.calcularResumenListado(this.facturasSeleccionadas)
      this.seleccionadasAPagar = this.facturasSeleccionadas.length
      this.totalAPagarEnFacturas=montoTotal
      this.recargoAPagar=montoRecargo
      this.totalACobrar=montoTotal
    }



  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}
