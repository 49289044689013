import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConvenioComponent } from './convenio.component';
import { NuevoConvenioPagoComponent } from './nuevo-convenio/nuevo-convenio-pago/nuevo-convenio-pago.component';
import { ListaConvenioPagoComponent } from './lista-convenio-pago/lista-convenio-pago.component';
import { FacturaModule } from '../factura/factura.module';
import { ListadoFacturasDisponiblesComponent } from './listado-facturas-disponibles/listado-facturas-disponibles.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { NuevoConvenioComponent } from './nuevo-convenio/nuevo-convenio.component';
import { DatosConvenioComponent } from './nuevo-convenio/datos-convenio/datos-convenio.component';
import { AutorizacionModule } from '../autorizacion/autorizacion.module';
import { AgGridModule } from 'ag-grid-angular';
import { EditarConvenioComponent } from './editar-convenio/editar-convenio.component';



@NgModule({
  declarations: [ConvenioComponent, NuevoConvenioPagoComponent, ListaConvenioPagoComponent, ListadoFacturasDisponiblesComponent, NuevoConvenioComponent, DatosConvenioComponent, EditarConvenioComponent],
  imports: [
    CommonModule,
    FacturaModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
    AutorizacionModule,
    AgGridModule
  ],
  exports:[ConvenioComponent]
})
export class ConvenioModule { }
