import { Component, OnInit, ViewChild } from '@angular/core';
import { ImpresionListadoComponent } from '../impresion-listado/impresion-listado.component';
import { FacturaDetalle } from 'src/app/store/factura/models';
import { OperacionLoadingComponent } from 'src/app/shared/operacion-loading/operacion-loading.component';
import { Store } from '@ngxs/store';
import { SocioState } from 'src/app/store/socio/states';
import { FacturaService } from '../factura.service';
import { HttpResponse } from '@angular/common/http';
import { Imprimir } from 'src/app/shared/imprimir';
import { OperacionSiNoObservacionComponent } from 'src/app/shared/operacion-si-no-observacion/operacion-si-no-observacion.component';
import { AutorizacionComponent } from 'src/app/autorizacion/autorizacion.component';
import { GetFacturasImpagasActualizadas, GetFacturasPagas } from 'src/app/store/factura/actions';
import { CobranzaService } from 'src/app/cobranza/cobranza.service';
import { GetObservaciones } from 'src/app/store/observacion/actions';
import { mergeMap } from 'rxjs/operators';
import { VisorImpresionComponent } from 'src/app/shared/visor-impresion/visor-impresion.component';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-facturas',
  templateUrl: './facturas.component.html',
  styleUrls: ['./facturas.component.css']
})
export class FacturasComponent implements OnInit {
  @ViewChild(ImpresionListadoComponent, { static: true })
  impresionListadoComponent: ImpresionListadoComponent;

  @ViewChild(OperacionLoadingComponent, { static: true })
  operacionLoadingComponent: OperacionLoadingComponent;

  @ViewChild(OperacionSiNoObservacionComponent, { static: true })
  operacionSiNoObservacionComponent: OperacionSiNoObservacionComponent;

  @ViewChild(AutorizacionComponent, { static: true })
  autorizacionComponent: AutorizacionComponent;

  @ViewChild('visorImpresion', { static: true }) visorImpresion:VisorImpresionComponent;

  imprimirUtils:Imprimir = new Imprimir()

  facturasSeleccionadas:FacturaDetalle[] = []

  tituloAnulacion:string
  mensajeAnulacion:string
  observacionPeriodosSeleccionados:string
  observacionRetornoAnulacion:string

  operacionTipo:number = 1  // 1-Anulacion Facturas,  2- Anulacion Cobranza



  constructor(private store:Store,private fs:FacturaService,private cs:CobranzaService) {

  }

  ngOnInit(): void {
  }


  imprimirListadoFacturas(){
    this.impresionListadoComponent.open()
  }

  onSeleccionFacturas(event){
    if(!event){
      this.facturasSeleccionadas =[]
    }else{
      this.facturasSeleccionadas =  event
    }

  }

  onImprimirFacturas(){

if (this.facturasSeleccionadas.length>0){
  this.operacionLoadingComponent.open();
  const cliente = this.store.selectSnapshot(SocioState.cliente);
  this.fs.imprimirFactura(cliente,this.facturasSeleccionadas.map(factura=>factura.Factura))
  .subscribe(
    (contenido: HttpResponse<Blob>)=>{
            this.operacionLoadingComponent.close();
            // this.visorImpresion.imprimir(contenido)
           this.imprimirUtils.imprimir(contenido);
    },
    (
      error=>   this.operacionLoadingComponent.close()
    ));
}

  }

  onAnularFacturas(){
    let periodosSeleccionados = this.facturasSeleccionadas.map(factura=>factura.Periodo)

    if (periodosSeleccionados.length>0 && this.incluyeFacturasImpagas()){
      this.operacionTipo = 1
      this.tituloAnulacion = 'Anulación de Facturas'
      this.mensajeAnulacion = 'Desea realizar la anulación de las facturas? Recuerde que al realizar la operación las facturas seleccionadas pasarán de estado Impago a Anulado y no formarán parte de deuda alguna.'
      this.observacionPeriodosSeleccionados = `Anulación de los periodos: ${periodosSeleccionados.join(' - ')}.`
      this.operacionSiNoObservacionComponent.open()
    }

  }

  onAnularPagos(){
    let periodosSeleccionados = this.facturasSeleccionadas.map(factura=>factura.Periodo)
    if (periodosSeleccionados.length>0 && this.incluyeFacturasPagas()){
      this.operacionTipo = 2
      this.tituloAnulacion = 'Anulación de Pagos'
      this.mensajeAnulacion = 'Desea realizar la anulación de los pagos? Recuerde que al realizar la operación las facturas seleccionadas pasarán de estado Pagado a Impago.'
      this.observacionPeriodosSeleccionados = `Anulación de pagos: ${periodosSeleccionados.join(' - ')}.`
      this.operacionSiNoObservacionComponent.open()
    }
  }

  retornoOperacionSiNo(retorno){
    if (retorno.opcion_seleccion) {
      this.observacionRetornoAnulacion =   retorno.observacion
      this.autorizacionComponent.open()
    }
  }

  retornoAutorizacion(retorno){
    if(retorno.habilita){
      const cliente = this.store.selectSnapshot(SocioState.cliente)
      this.operacionLoadingComponent.open();

      switch (this.operacionTipo) {
        case 1:
          let facturasAnular = this.facturasSeleccionadas.map(factura=>factura.Factura)
          this.anularFacturas(facturasAnular,cliente,retorno.usuarioAutorizante)
          break;
          case 2:
         let facturasPago = this.facturasSeleccionadas.map(factura=> { return {"Factura":factura.Factura,"Cobrado": +(+factura.Cobrado).toFixed(2),"FechaPago":factura.FechaPago} })
          this.anularPagos(facturasPago,cliente,retorno.usuarioAutorizante)
          break;
      }


    }

  }

  anularFacturas(facturas,cliente,usuarioAutorizante){

    this.fs.facturasAnular(facturas,this.observacionRetornoAnulacion,cliente,usuarioAutorizante).pipe(
      mergeMap(()=>this.store.dispatch(new GetFacturasImpagasActualizadas(cliente,true))),
      mergeMap(()=> this.store.dispatch(new GetObservaciones(cliente,true))),
    ).subscribe(
      ()=> this.operacionOk('La/s factura/s han sido anuladas con exito!') ,
      ()=>this.operacionError()
    )
  }

  anularPagos(facturas,cliente,usuarioAutorizante){

    this.cs.anularCobranzaSimple(facturas,this.observacionRetornoAnulacion,cliente,usuarioAutorizante).pipe(
      mergeMap(()=> this.store.dispatch(new GetFacturasPagas(cliente,true))),
      mergeMap(()=>this.store.dispatch(new GetFacturasImpagasActualizadas(cliente,true))),
      mergeMap(()=> this.store.dispatch(new GetObservaciones(cliente,true))),
    ).subscribe(
      ()=>{this.operacionOk('El pago de la/s factura/s han sido anulados con exito!') },
      ()=>this.operacionError()
    )
  }

  operacionOk(mensaje){
    Swal.fire(
      '',mensaje,
      'success'
    )
    this.operacionLoadingComponent.close();
  }

  operacionError(){
    Swal.fire(
      '','Ha ocurrido un error mientras se realizaba la operación!',
      'error'
    )
    this.operacionLoadingComponent.close();
  }

  incluyeFacturasImpagas(){
    const facturaImpaga=this.facturasSeleccionadas.find(factura=>factura.Estado==='Impaga')
    return !!facturaImpaga
  }

  incluyeFacturasPagas(){
    const facturaImpaga=this.facturasSeleccionadas.find(factura=>factura.Estado==='Pagada')
    return !!facturaImpaga
  }


}
