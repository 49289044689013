<ng-container [formGroup]="socioForm">


<div id="varios"></div>

<div class="card shadow mt-4">
  <div class="card-body">
    <p style="border-left: solid 5px #17a2b8; padding-left: 5px"><b>Varios</b></p>
    <hr>
    <div class="form-group row">
      <label for="inputProfesion" class="col-sm-2 col-form-label col-form-label-sm  text-right">Profesión</label>
      <div class="col">
        <input type="text" maxlength="80" class="form-control form-control-sm" formControlName="profesion"
          id="inputProfesion" placeholder="">
      </div>
    </div>
    <fieldset class="p-2" style="border: solid 1px #ddd">
      <legend style="font-size: 16px">Conyuge</legend>
      <div class="form-group row">
        <label for="inputNombreConyuge" class="col-sm-2 col-form-label col-form-label-sm  text-right">Nombre</label>
        <div class="col">
          <input type="text" maxlength="80" class="form-control form-control-sm"
            formControlName="nombreConyuge" id="inputNombreConyuge" (change)="verificarNombreConyuge($event)"
            placeholder="">
        </div>
      </div>
      <div class="form-group row">
        <label for="inputTipoDocumentoConyuge" class="col-sm-2 col-form-label col-form-label-sm  text-right">Documento</label>
        <div class="col-sm-3">
          <select class="form-control form-control-sm" id="inputTipoDocumentoConyuge"
            formControlName="tipoDocumentoConyuge"
            [ngClass]="{ 'is-invalid':isInvalidTipoDocumentoConyugeField }">
            <option *ngFor="let tipoDni of tiposDni" [value]="tipoDni.id">{{tipoDni.detalle}}</option>
          </select>
          <div *ngIf="isInvalidTipoDocumentoConyugeField" class="invalid-feedback">
            Campo obligatorio
          </div>
        </div>
        <div class="col">
          <input type="text" maxlength="9" class="form-control form-control-sm"
            formControlName="numeroDocumentoConyuge" placeholder=""
            [ngClass]="{ 'is-invalid':isInvalidNumeroDocumentoConyugeField }" appNumberEnteroOnly>
          <div *ngIf="isInvalidNumeroDocumentoConyugeField" class="invalid-feedback">
            Campo obligatorio
          </div>
        </div>
      </div>
    </fieldset>
    <br>
    <fieldset class="p-2" style="border: solid 1px #ddd">
      <legend style="font-size: 16px">Convenio Coop. de Luz</legend>
      <div class="form-group row">
        <label for="inputnumeroSocioEnCooperativa" class="col-3 col-form-label col-form-label-sm  text-right">numero. Socio en
          Coop. de Luz</label>
        <div class="col">
          <input type="text" maxlength="12" class="form-control form-control-sm"
            formControlName="numeroSocioEnCooperativa" id="inputnumeroSocioEnCooperativa"
            (change)="verificarNumeroSocioConvenio($event)" placeholder="">
        </div>
      </div>
      <div class="form-group row">
        <label for="inputFechaDeAltaConvenio" class="col-3 col-form-label col-form-label-sm  text-right">Fecha de alta del
          convenio</label>
        <div class="col">
          <input type="date" class="form-control form-control-sm" formControlName="fechaDeAltaConvenio"
            id="inputFechaDeAltaConvenio" placeholder=""
            [ngClass]="{ 'is-invalid':isInvalidFechaDeAltaConvenioField }">
          <div *ngIf="isInvalidFechaDeAltaConvenioField" class="invalid-feedback">
            Campo obligatorio
          </div>
        </div>
      </div>
    </fieldset>
  </div>
</div>
</ng-container>
