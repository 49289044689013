import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Notificaciones } from '../store/notificaciones/notificaciones';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class NotificacionesService {

 private _http = inject(HttpClient);
  
  urlRest = environment;

  
  getNotificaciones():Observable<Notificaciones[]>{
    return this._http.get<Notificaciones[]>(`${this.urlRest.urlapiNode}/notificaciones`);
  }


}
