import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { mergeMap } from 'rxjs/operators';
import { UsuarioService } from "src/app/usuario/usuario.service";
import { GetUsuarios, SaveUsuario, SetUsuarioEdicion, UpdatePassword, UpdateUsuario } from "./actions";
import { Usuario } from "./model";


export class UsuarioStateModel{
    usuarios: Usuario[];
    inicializado_usuarios:boolean;
    usuarioEdicion:Usuario;
}

@State<UsuarioStateModel>({
    name:'usuario',
    defaults:{
        usuarios:[],
        inicializado_usuarios:false,
        usuarioEdicion:null
    }
})

@Injectable()
export class UsuarioState{
    constructor(private us:UsuarioService){}

    @Selector()
    static usuarioParaEdicion(state: UsuarioStateModel) { return state.usuarioEdicion}

    @Action(GetUsuarios)
    getUsuarios(ctx: StateContext<UsuarioStateModel>, action:GetUsuarios){

        const state = ctx.getState()
        let seguir = false

        if(!action.update && !state.inicializado_usuarios){seguir=true}
        if(action.update && state.inicializado_usuarios){seguir=true}

        if(seguir){
            return this.us.getUsuarios().subscribe(
                data=>{
                    ctx.patchState({
                        usuarios: data,
                        inicializado_usuarios:true
                    });
                }
            )
        }
    }


    @Action(SaveUsuario)
    saveUsuario(ctx: StateContext<UsuarioStateModel>, action:SaveUsuario) {
      return this.us.saveUsuario(action.nombre,action.apellido,action.username,action.estaActivo,action.rol,action.permisos,action.password)
      .pipe(
       mergeMap(()=> ctx.dispatch(new GetUsuarios(true)))
     )
    }

    @Action(UpdateUsuario)
    updateUsuario(ctx: StateContext<UsuarioStateModel>, action:UpdateUsuario) {
      return this.us.updateUsuario(action.id_usuario,action.nombre,action.apellido,action.username,action.estaActivo,action.rol,action.permisos)
      .pipe(
       mergeMap(()=> ctx.dispatch(new GetUsuarios(true)))
     )
    }

    @Action(SetUsuarioEdicion)
    SetUsuarioEdicion({ patchState }: StateContext<UsuarioStateModel>, {usuario}:SetUsuarioEdicion) {
        patchState({
          usuarioEdicion: usuario
        });

    }

    @Action(UpdatePassword)
    updatePassword(ctx: StateContext<UsuarioStateModel>, action:UpdatePassword) {
      return this.us.updatePassword(action.idUsuario,action.newPassword)

    }



}


