import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { Store } from '@ngxs/store';
import { SocioGestionService } from '../socio-gestion.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-listado-socio',
  templateUrl: './listado-socio.component.html',
  styleUrls: ['./listado-socio.component.css']
})
export class ListadoSocioComponent implements OnInit, OnDestroy  {


  @ViewChild('agGridSocios', { static: true }) agGridSocios: any;

  destroy$: Subject<boolean> = new Subject<boolean>();

  rowData: any;

  columnDefs = [
    {headerName: 'IDSocio', field: 'id_socio',sortable: true, filter: true, width:80 },
    {headerName: 'Código', field: 'codSocio',sortable: true, filter: true },
    {headerName: 'Sub Código', field:         'subCodSocio',sortable: true, filter: true },
    {headerName: 'Condicion Registro', field: 'condicionRegistro',sortable: true, filter: true },
    {headerName: 'Nombre', field: 'nombre',sortable: true, filter: true },
    {headerName: 'Tipo Documento', field: 'tipoDocumento',sortable: true, filter: true },
    {headerName: 'Documento Nro', field:         'documento',sortable: true, filter: true },
    {headerName: 'Zona', field:                 'zona',sortable: true, filter: true, resizable: true},
    {headerName: 'Distrito', field:  'distrito',                                      sortable: true, filter: true },
    {headerName: 'Barrio', field:  'barrio',                                      sortable: true, filter: true , resizable: true},
    {headerName: 'IVA', field: 'iva',        sortable: true, filter: true },
    {headerName: 'Tarifa', field:   'tarifa',              sortable: true, filter: true },
    {headerName: 'Estado', field:'estado',         sortable: true, filter: true },
    {headerName: 'Categoria', field: 'categoria',sortable: true, filter: true },
    {headerName: 'Fin Suspension', field: 'finsuspension',sortable: true, filter: true },
    {headerName: 'Situacion Especial', field: 'situacion',sortable: true, filter: true },
    {headerName: 'Factura Electronica', field:'facturaElectronica',        sortable: true, filter: true },
    {headerName: 'Debito Automatico', field: 'debitoAutomatico',sortable: true, filter: true },
    {headerName: 'Banco', field:  'banco',               sortable: true, filter: true , resizable: true},
    {headerName: 'Email', field:  'email',               sortable: true, filter: true , resizable: true}
  ];


filas:number;
  constructor(private sgs: SocioGestionService) { }

  ngOnInit() {

    this.sgs.getSociosListado().pipe(takeUntil(this.destroy$)).subscribe(
      listadoSocios=> {this.rowData = listadoSocios; this.filas =  listadoSocios.length}
    )
  }


  onBtExport(){
   this.agGridSocios.api.exportDataAsCsv({ fileName: 'socios',columnSeparator:';' })
  }

  clearFilter(){
    this.agGridSocios.api.setFilterModel(null);
  }

  onFilterChanged(){
    this.filas = this.agGridSocios.api.getDisplayedRowCount();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}
