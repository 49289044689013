<div class="card" style="margin-bottom: 15px">
  <div class="card-header filtro" style="background-color:#17a2b8; color: white">
    Cobranza - Factura
  </div>
  <div class="card-body" style="padding: 0.5rem !important;">

    <div class="row">
      <div class="col-md-7 ">
        <div class="row">
          <div class="col-md-2"></div>
          <div class="col-md-7">

            <fieldset class="p-1 mb-1" style="border: solid 1px #ddd">
              <legend style="font-size: 14px">Factura</legend>
              <div class="form-group row">
                <label for="inputFactura" class="col-sm-3 col-form-label text-right">Factura</label>
                <div class="col-sm-7">
                  <input type="text" class="form-control" id="inputFactura" autocomplete="off" maxlength="10"
                    placeholder="Ingrese el nº de factura" #inputFactura tabindex="1" appNumberEnteroOnly>
                </div>
                <div *ngIf="noExisteFacturaSearchInput"> <label class="text-info  ml-5"> <small>La factura no se encuentra registrada!</small> </label></div>
              </div>



            </fieldset>
          </div>
        </div>

        <div class="row">

          <div class="col-md-12">

            <fieldset class="p-1 mb-1" style="border: solid 1px #ddd">
              <legend style="font-size: 14px">Datos Factura  <span *ngIf="factura?.Estado===3" class="badge badge-pill badge-success" style="font-size: 13px">Pagada</span></legend>
              <div class="form-group row">
                <label for="inputSocio" class="col-sm-2 col-form-label col-form-label-sm text-right">Socio</label>
                <div class="col-sm-2">
                  <input type="text" class="form-control form-control-sm" id="inputSocio" [value]="factura?.socio"
                    readonly>
                </div>
                <div class="col-sm-7">
                  <input type="text" class="form-control form-control-sm" id="inputSocioNombre"
                    [value]="factura?.nombre" readonly>
                </div>
                <!-- <div class="col-sm-2 mt-1">
                  <button type="button" class="btn btn-sm btn-outline-info mb-2 w-100 btn-option" [disabled]="!factura" >Ir a socio</button>
                </div> -->
              </div>
              <div class="form-group row">
                <label for="inputPeriodo" class="col-sm-2 col-form-label col-form-label-sm text-right">Periodo</label>
                <div class="col-sm-2 mt-1">
                  <input type="text" class="form-control form-control-sm" id="inputPeriodo" [value]="factura?.Periodo"
                    readonly>
                </div>
                <label for="inputVencimento1" class="col-sm-2 col-form-label col-form-label-sm text-right">1º
                  Vencimiento</label>
                <div class="col-sm-2">
                  <input type="text" class="form-control form-control-sm" id="inputVencimento1"
                    [value]="factura?.FechaPrimerVenc" readonly>
                </div>
                <label for="inputVencimento2" class="col-sm-2 col-form-label col-form-label-sm text-right">2º
                  Vencimiento</label>
                <div class="col-sm-2">
                  <input type="text" class="form-control form-control-sm" id="inputVencimento2"
                    [value]="factura?.FechaSegundoVenc" readonly>
                </div>
              </div>
              <div class="form-group row">
                <label for="inputFacturado"
                  class="col-sm-2 col-form-label col-form-label-sm text-right">Facturado</label>
                <div class="col-sm-2">
                  <input type="text" class="form-control form-control-sm" id="inputFacturado"
                    [value]="factura?.ImporteTotal||0 | formatCurrency" readonly>
                </div>
                <label for="inputRecargo" class="col-sm-2 col-form-label col-form-label-sm text-right">Recargo</label>
                <div class="col-sm-2">
                  <input type="text" class="form-control form-control-sm" id="inputRecargo"
                    [value]="factura?.RecargoTotal||0 | formatCurrency" readonly>
                </div>
                <label for="inputTotal"
                  class="col-sm-1 col-form-label col-form-label-sm text-right">Total</label>
                <div class="col-sm-3">
                  <input type="text" class="form-control form-control-sm" id="inputTotal"
                    [value]="factura?.Total||0 | formatCurrency" readonly>
                </div>
              </div>



            </fieldset>

          </div>
        </div>

      </div>
      <div class="col-md-5">
        <div class="row">
          <div class="col-md-12">
            <div class="card shadow" style="border-color:#1595a9; padding: 0px;">
              <div class="card-header card-header-info bg-info text-white"><b>Total a Pagar</b></div>
              <div class="card-body" style="padding: 0px;">
                <table class="table table-condensed" style="margin: 0px">
                  <tr>
                    <td class="text-muted text-center" style="border-right: 1px solid #ddd;">
                      <h4>{{factura?.Total||0 | formatCurrency}}</h4>
                    </td>
                    <td width="60%" style="text-align: center;">
                      <button type="button" class="btn btn-success btn-sm"
                         (click)="pagar()" [disabled]=" (factura?.Total||0) ==0 || factura?.Estado===3" title="pagar" tabindex="2" #pagarBtn>Pagar</button>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>

        </div>
        <app-informacion-caja [tabindex]=2></app-informacion-caja>
      </div>
    </div>

  </div>


  <app-operacion-si-no [titulo]="'Cobranza de Factura'" [contenidoMensaje]="'Desea realizar la operación de pago?'"
  (retornoOperacion)="retornoOperacionSiNo($event)"></app-operacion-si-no>

    <app-operacion-loading></app-operacion-loading>
