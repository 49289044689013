<div class="card">
  <div class="card-header card-header-info">
    <i class="fas fa-hand-holding-usd"></i> Créditos
  </div>
  <div class="card-body">

    <div class="row justify-content-between">
      <div class="col-md-6">
        <div class="card shadow mb-2">
          <div class="card-header text-muted">

            <div class="btn-group mr-2" role="group" aria-label="First group">
              <button type="button" class="btn btn-sm btn-outline-info" (click)="nuevo()"><i class="fas fa-plus-square"></i>
                Nuevo crédito</button>

            </div>


          </div>
          <form [formGroup]="creditoForm" (ngSubmit)="guardar()"  (keydown.enter)="$event.preventDefault()" novalidate>
            <div class="card-body">

              <div class="form-group row">
                <label for="inputTotal" class="col-sm-3 col-form-label col-form-label-sm text-right">Monto Total $</label>
                <div class="col-5">
                  <input type="text" formControlName="montoTotal" class=" form-control form-control-sm" id="inputTotal" maxlength="10"
                    [ngClass]="{ 'is-invalid': (submitted && (creditoForm.controls['montoTotal'].errors)  ) }"
                    appNumberDecimalOnly>
                  <small id="totalHelp" class="form-text text-muted">Utilizar <b>.</b> como separador decimal</small>
                  <div *ngIf="submitted && creditoForm.controls['montoTotal'].errors" class="invalid-feedback">
                    <div *ngIf="creditoForm.controls['montoTotal'].errors.required">Debe ingresar el monto del crédito
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <label for="inputObservacion" class="col-sm-3 col-form-label col-form-label-sm text-right">Observación</label>
                <div class="col">
                  <input type="text" formControlName="observacion" class=" form-control form-control-sm" id="inputObservacion"
                    maxlength="100">
                </div>
              </div>

              <br>
              <div class="row justify-content-md-center">
                <div class="alert alert-info" role="alert">
                  El crédito no impactará en la cobranza diaria
                </div>
              </div>

            </div>
            <div class="card-footer" *ngIf="modo !== 0">
              <button (click)="cancelar()" class="btn  btn-sm btn-outline-danger">Cancelar</button> &nbsp;
              <button [disabled]="!creditoForm.valid" type="submit" class="btn btn-success btn-sm">Guardar</button>
            </div>
          </form>
        </div>

      </div>

      <div class="col-md-5">
        <div class="card shadow listadas">

          <ul class="list-group list-group-flush">
            <li class="list-group-item text-white bg-info" style="background-color: #ced6e0"><b>Total crédito
                registrado</b></li>
            <li class="list-group-item text-secondary text-center">
              <h2 class="mt-2 mb-2">${{montoTotal}}</h2>
            </li>
          </ul>
          <div class="card-footer" style="background-color: #f7f7f7;">
            <div class="row"><span>* crédito disponible para ser imputado en la/s próxima/s factura/s</span></div>
            <div class="row mt-3">
              <div class="col-4"><button *ngIf="montoTotal>0" type="button" class="btn btn-outline-info mr-4"
                  (click)="imprimirCreditoSaldo()" title="Imprimir comprobante del saldo registrado"><i
                    class="fas fa-print"></i></button></div>
              <div class="col-8 text-right"><button *ngIf="montoTotal>0" type="button" class="btn  btn-sm btn-outline-info mr-4"
                  (click)="imputarCreditoSaldoEnFacturasAdeudadas()"
                  title="Imputar crédito registrado en facturas adeudas"><i class="fas fa-dollar-sign"></i>&nbsp;Imputar
                  en facturas impagas</button></div>
            </div>
          </div>
        </div>

      </div>
    </div>

    <br>

    <div class="row">



      <div class="col-md-7">
        <div class="card shadow mb-2">
          <div class="card-header card-header-info text-muted">
            <h5 class="card-title">Créditos otorgados</h5>
          </div>

          <div class="card-body" style=" overflow:auto;
                        max-height:300px;">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th scope="col" class="text-center">Opciones</th>
                  <th scope="col">Fecha alta crédito</th>
                  <th scope="col">Total</th>
                  <th scope="col">Otorgado por</th>
                  <th scope="col">Autorizado por</th>
                  <th scope="col">Fue imputado</th>
                  <th scope="col">Observación</th>

                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let credito of creditos" [ngStyle]="{'color':credito.imputado? '#ff7601' : '' }">
                  <td style="width:10%; vertical-align: middle">
                    <div class="btn-group" role="group">
                      <a href="javascript:;" (click)="imprimirCreditoOtorgado(credito.id)" title="Imprimir comprobante"
                        class="btn btn-outline-info" role="button" aria-pressed="true"><i class="fas fa-print"></i></a>
                      <a *ngIf="montoTotal >= credito.total && !credito.imputado && !imputarEnFacturas"
                        href="javascript:;" (click)="eliminarCredito(credito.id)" title="Eliminar crédito"
                        class="btn btn-outline-danger mr-1" role="button" aria-pressed="true"><i
                          class="fas fa-trash-alt"></i></a>

                    </div>
                  </td>
                  <td style="vertical-align: middle">{{credito.fechaAlta}}</td>
                  <td style="vertical-align: middle">{{credito.total}}</td>
                  <td style="vertical-align: middle">{{credito.usuario_alta}}</td>
                  <td style="vertical-align: middle">{{credito.usuario_autorizante}}</td>
                  <td style="vertical-align: middle">{{credito.imputado?'Si':'No'}}</td>
                  <td>{{credito.observacion}}</td>
                </tr>
              </tbody>
            </table>



            <br>
            <div *ngIf="creditos.length==0" class="row justify-content-md-center">
              <div class="alert alert-info" role="alert">
                No se registran créditos otorgados
              </div>
            </div>

          </div>


        </div>
      </div>
      <div class="col-md-5">
        <div class="card shadow mb-2">
          <div class="card-header card-header-info text-muted">
            <h5 class="card-title">Imputaciones realizadas</h5>
          </div>

          <div class="card-body">

            <ag-grid-angular #agGridCredito style="width: 100%; height: 250px;" class="ag-theme-alpine"
              [rowData]="rowData" [columnDefs]="columnDefs" [colResizeDefault]="'fit'" [rowSelection]="'multiple'">
            </ag-grid-angular>
            <br>
            <div *ngIf="imputaciones.length==0" class="row justify-content-md-center">
              <div class="alert alert-info" role="alert">
                No se registran imputaciones sobre la facturación
              </div>
            </div>

            <br>
            <ng-container *appRole="permisoModoRequeridoEliminarCreditosImputados" >
              <form [formGroup]="imputacionEliminarForm" (keydown.enter)="$event.preventDefault()" novalidate>

                <div class="form-group row">
                  <label for="inputPeriodo" class="col-sm-10 col-form-label col-form-label-sm">Desde que periodo se re-imputará el
                    crédito?</label>
                  <div class="col-4">
                    <input type="text" formControlName="desdePeriodoImputar" class=" form-control form-control-sm" id="inputPeriodo"
                      maxlength="6">
                    <small id="totalHelp" class="form-text text-muted">* Obligatorio</small>
                  </div>
                </div>


                <button [disabled]="!imputacionEliminarForm.valid" type="submit" class="btn btn-danger btn-sm"
                  (click)="eliminarImputacion()">Eliminar Imputación</button>
              </form>
            </ng-container>



          </div>


        </div>
      </div>

    </div>

  </div>
</div>

<app-operacion-si-no [titulo]="'Créditos'" [contenidoMensaje]="mensajeEliminacion"
  (retornoOperacion)="retornoOperacionSiNo($event)"></app-operacion-si-no>

<app-autorizacion (retornoOperacion)="retornoAutorizacion($event)"></app-autorizacion>


<div class="card shadow mb-2" id="imputarFacturas" *ngIf="imputarEnFacturas">
  <div class="card-header text-muted">
    <div class="row">
      <div class="col-8">
        <h5 class="card-title">Imputar Crédito sobre Facturas Adeudadas <small class="text-info">- No impacta en
            cobranza diaria</small></h5>
      </div>
      <div class="col-4 text-right">
        <button type="button" class="btn btn-outline-danger" (click)="imputarEnFacturas=false">Cancelar</button>
      </div>
    </div>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-md-7">
        <app-listado-facturas [rowSelectionOpcion]="'multiple'" [sorteableOpcion]="true" [visualizacionExpandida]="true" [mostrarDetalleSeleccion]="true"
        (seleccionRetorno)="onSeleccionFacturas($event)"></app-listado-facturas>
      </div>
      <div class="col-md-5">
        <div class="row">
          <div class="col-md-12">
            <app-resumen-pago
            [totalACobrar]="totalACobrar"
            [modoImputacion]="true"
            [saldoCredito]="montoTotal"
            (pagarEvent)="imputarSaldoEnFacturas($event)"
            ></app-resumen-pago>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <app-resumen-selecccion
                        [seleccionadasAPagar]="seleccionadasAPagar"
                        [totalAPagarEnFacturas]="totalAPagarEnFacturas"
                        [recargoAPagar]="recargoAPagar"
                        [modoImputacion]="true"
                         ></app-resumen-selecccion>
          </div>

        </div>

          </div>
    </div>
  </div>
</div>
