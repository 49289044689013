import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import {meses} from '../../../../shared/enums/enums'
import { Store } from '@ngxs/store';
import { FacturaService } from 'src/app/factura/factura.service';
import { GetPeriodos, GetZonas } from 'src/app/store/general/actions';
import { GetUltimoPeriodoFacturado,GetAniosPeriodoFacturado } from 'src/app/store/periodo/actions';
import { filter } from 'rxjs/operators';
import { VisorImpresionComponent } from 'src/app/shared/visor-impresion/visor-impresion.component';
import { HttpResponse } from '@angular/common/http';
import { GetBarrios } from 'src/app/store/barrio/actions';

@Component({
  selector: 'app-deuda-por-periodo',
  templateUrl: './deuda-por-periodo.component.html',
  styleUrls: ['./deuda-por-periodo.component.css']
})
export class DeudaPorPeriodoComponent implements OnInit,OnDestroy  {

  @ViewChild('visorImpresion', { static: true }) visorImpresion:VisorImpresionComponent;

  resumenForm: UntypedFormGroup;

  loading: boolean = false;

  aniosFacturado$:Observable<any[]>

  subcriptionPeriodo: Subscription;
  subcriptionUltimoPeriodo: Subscription;

  meses = meses;

  barrios$: Observable<any[]>
  zonas$: Observable<any[]>

  periodoSeleccionado:string


  constructor(private fb: UntypedFormBuilder, private store:Store, private fs:FacturaService) {
    this.resumenForm = this.fb.group({
      mesPeriodo:['',Validators.required],
      anioPeriodo:['',Validators.required],
      tipoListadoPorBarrioZona:['1',Validators.required],
      barrio:[{value:'',disabled:true},Validators.required],
      zona:[{value:'',disabled:true},Validators.required]
    })

   }

  ngOnInit(): void {
    this.store.dispatch(new GetPeriodos())
    this.store.dispatch(new GetBarrios())
    this.store.dispatch(new GetZonas())
    this.store.dispatch(new GetUltimoPeriodoFacturado())
    this.store.dispatch(new GetAniosPeriodoFacturado())

    this.aniosFacturado$ = this.store.select(state=>state.periodo.anios_periodo_facturado)
    this.barrios$ = this.store.select(state => state.barrio.barrios)
    this.zonas$ = this.store.select(state => state.general.zonas)

    this.subcriptionUltimoPeriodo =this.store.select(state => state.periodo.ultimo_periodo_facturado)
    .pipe(
      filter(data => !!data)
      )
    .subscribe(
      ultimoPeriodoReturn => {
        this.mesPeriodoField.setValue(ultimoPeriodoReturn.substring(4,6))
        this.anioPeriodoField.setValue(ultimoPeriodoReturn.substring(0,4))
      }
    )

    this.initForm()

  }

  initForm(){


    this.tipoListadoPorBarrioZonaField.valueChanges.subscribe(value=>{
      if (+value==2){
        this.barrioField.enable()
        this.zonaField.disable()
        this.zonaField.setValue('')
      }else if(+value==4){
        this.zonaField.enable()
        this.barrioField.disable()
        this.barrioField.setValue('')
      }else{
        this.barrioField.disable()
        this.barrioField.setValue('')
        this.zonaField.disable()
        this.zonaField.setValue('')
      }

    })
  }



  get mesPeriodoField(){
    return this.resumenForm.controls.mesPeriodo
  }

  get anioPeriodoField(){
   return  this.resumenForm.controls.anioPeriodo
  }


  get tipoListadoPorBarrioZonaField(){
    return  this.resumenForm.controls.tipoListadoPorBarrioZona
  }

  get barrioField(){
    return this.resumenForm.controls.barrio
  }

  get zonaField(){
    return this.resumenForm.controls.zona
  }

  get valorMesPeriodoIsInvalid(){
    return this.mesPeriodoField.touched && this.mesPeriodoField.invalid
  }

  get valorAnioPeriodoIsInvalid(){
    return this.anioPeriodoField.touched && this.anioPeriodoField.invalid
  }

  get valorBarrioIsInvalid(){
    return this.barrioField.touched && this.barrioField.invalid
  }

  get valorZonaIsInvalid(){
    return this.zonaField.touched && this.zonaField.invalid
  }


  generarResumen(event:Event){
    event.preventDefault()

    if(this.resumenForm.valid){
      this.periodoSeleccionado = this.anioPeriodoField.value+this.mesPeriodoField.value

      if(+this.tipoListadoPorBarrioZonaField.value==1){
        this.getResumenZonaBarrio(1,-1)
      }

      if(+this.tipoListadoPorBarrioZonaField.value==2){
        this.getResumenZonaBarrio(1,this.barrioField.value)
      }

      if(+this.tipoListadoPorBarrioZonaField.value==3){
        this.getResumenZonaBarrio(2,-1)
      }

      if(+this.tipoListadoPorBarrioZonaField.value==4){
        this.getResumenZonaBarrio(2,this.zonaField.value)
      }

    }
  }

  getResumenZonaBarrio(division:number,filtroDivision:number){

    this.inhabilitarForm()
      this.fs.getListadoEstadoDeudaBarrioZona(
           this.periodoSeleccionado,division,filtroDivision
           )
        .subscribe(
          (contenido: HttpResponse<Blob>)=>{
            this.visorImpresion.imprimir(contenido)
            this.habilitarForm()
          },
          ()=>this.habilitarForm());
    }


  inhabilitarForm(){
    this.loading=true;
    this.mesPeriodoField.disable()
    this.anioPeriodoField.disable()

  }

  habilitarForm(){
    this.loading = false;
    this.mesPeriodoField.enable()
    this.anioPeriodoField.enable()

  }


  ngOnDestroy(){
    this.subcriptionUltimoPeriodo.unsubscribe()
  }

}
