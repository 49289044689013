import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NumberOnlyDirective, NumberEnteroOnlyDirective, NumberDecimalPositiveOnlyDirective } from './number-only.directive';
import { OperacionExitosaComponent } from './operacion-exitosa/operacion-exitosa.component';
import { OperacionSiNoComponent } from './operacion-si-no/operacion-si-no.component';
import { FormatNumberPipe, FormatDecimalPipe, FormatCurrencyPipe } from './format-number.pipe';
import { OperacionLoadingComponent } from './operacion-loading/operacion-loading.component';
import { OperacionInformacionComponent } from './operacion-informacion/operacion-informacion.component';
import { ReactiveFormsModule } from '@angular/forms';
import { OperacionSiNoObservacionComponent } from './operacion-si-no-observacion/operacion-si-no-observacion.component';
import { AgButtonRenderComponent } from './ag-button-render/ag-button-render.component';
import { OperationsPipe } from './operations.pipe';
import { VisorImpresionComponent } from './visor-impresion/visor-impresion.component';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { ListViewComponent } from './list-view/list-view.component';
import { AgGridModule } from 'ag-grid-angular';
import { TabDirective } from './tabKey/tab.directive';
import { RoleDirective } from './role.directive';
import { RoleAlgunPermisoDirective } from './role.directive';





@NgModule({
  declarations: [ NumberOnlyDirective,NumberEnteroOnlyDirective,NumberDecimalPositiveOnlyDirective, OperacionExitosaComponent, OperacionSiNoComponent,FormatNumberPipe,FormatCurrencyPipe,FormatDecimalPipe, OperacionLoadingComponent, OperacionInformacionComponent, OperacionSiNoObservacionComponent, AgButtonRenderComponent, OperationsPipe,VisorImpresionComponent, ListViewComponent, TabDirective, RoleDirective, RoleAlgunPermisoDirective],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    PdfJsViewerModule, 
  
    AgGridModule,
  ],
  exports:[NumberOnlyDirective,NumberEnteroOnlyDirective,NumberDecimalPositiveOnlyDirective,OperacionExitosaComponent,OperacionSiNoComponent,FormatNumberPipe,FormatDecimalPipe,FormatCurrencyPipe,OperacionLoadingComponent,OperacionInformacionComponent,OperacionSiNoObservacionComponent,OperationsPipe,VisorImpresionComponent,ListViewComponent,TabDirective,RoleDirective,RoleAlgunPermisoDirective]
})
export class SharedModule { }
