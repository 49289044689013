import { State, Action, StateContext } from '@ngxs/store';
import { CreditoService } from 'src/app/credito/credito.service';
import { NewCredito, GetCreditoMontoTotal, GetCreditosOtorgados, GetCreditoImputado, DeleteCreditoOtorgado, UpdateCreditoOtorgadoImputarFacturasAdeudadas, ResetCredito } from './actions';
import { tap, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { merge } from 'jquery';
import { of, from } from 'rxjs';

export class CreditoStateModel {
    credito_monto_total:any;
    creditos_otorgados:any[];
    creditos_imputaciones:any[];
    inicializado_credito_monto_total:boolean;
    inicializado_creditos_otorgados:boolean;
    inicializado_creditos_imputaciones:boolean;
}


@State<CreditoStateModel>({
    name: 'credito',
    defaults: {
        credito_monto_total:null,
        creditos_otorgados:[],
        creditos_imputaciones:[],
        inicializado_credito_monto_total:false,
        inicializado_creditos_otorgados:false,
        inicializado_creditos_imputaciones:false,
    }
})

@Injectable()
export class CreditoState {

    constructor(private cs: CreditoService) { }

    @Action(NewCredito)
    newCredito(ctx: StateContext<CreditoStateModel>, action:NewCredito) {

        const state = ctx.getState();
       return this.cs.nuevoCredito(action.credito).pipe(
            mergeMap( ()=>ctx.dispatch(new GetCreditoMontoTotal(action.credito.cliente,true))),
            mergeMap( () => ctx.dispatch(new GetCreditosOtorgados(action.credito.cliente,true)))
             )
    }


    @Action(GetCreditoMontoTotal)
    getCreditoMontoTotal(ctx: StateContext<CreditoStateModel>, action:GetCreditoMontoTotal) {
      const state = ctx.getState()
      let seguir = false

      if(!action.update && !state.inicializado_credito_monto_total){seguir=true}
      if(action.update && state.inicializado_credito_monto_total){seguir=true}

      if(seguir){
        this.cs.getMontoTotalCredito(action.cliente).subscribe(
          data=> {
              ctx.patchState({
                  credito_monto_total: data,
                  inicializado_credito_monto_total:true
              });


          }
      )
      }


    }

    @Action(GetCreditosOtorgados)
    getCreditosOtorgados(ctx: StateContext<CreditoStateModel>, action:GetCreditosOtorgados) {

      const state = ctx.getState()
      let seguir = false

      if(!action.update && !state.inicializado_creditos_otorgados){seguir=true}
      if(action.update && state.inicializado_creditos_otorgados){seguir=true}

      if(seguir){
        this.cs.getCreditosOtorgados(action.cliente).subscribe(
          data=> {
              ctx.patchState({
                  creditos_otorgados: data,
                  inicializado_creditos_otorgados:true
              });


          }
      )
      }


    }

    @Action(GetCreditoImputado)
    getCreditoImputado(ctx: StateContext<CreditoStateModel>, action:GetCreditoImputado) {

      const state = ctx.getState()
      let seguir = false

      if(!action.update && !state.inicializado_creditos_imputaciones){seguir=true}
      if(action.update && state.inicializado_creditos_imputaciones){seguir=true}

      if(seguir){
        this.cs.getImputacionesCredito(action.cliente).subscribe(
          data=> {
              ctx.patchState({
                  creditos_imputaciones: data,
                  inicializado_creditos_imputaciones:true
              });


          }
      )
      }

    }

    @Action(DeleteCreditoOtorgado)
    deleteCredito(ctx: StateContext<CreditoStateModel>, action:DeleteCreditoOtorgado) {
        const state = ctx.getState();
        return  this.cs.deleteCredito(action.credito)
        .pipe(
          mergeMap( ()=>ctx.dispatch(new GetCreditoMontoTotal(action.credito.cliente,true))),
          mergeMap( () => ctx.dispatch(new GetCreditosOtorgados(action.credito.cliente,true)))
           )

    }


    @Action(UpdateCreditoOtorgadoImputarFacturasAdeudadas)
    updateCreditoOtorgadoImputarFacturasAdeudadas(ctx: StateContext<CreditoStateModel>, action:UpdateCreditoOtorgadoImputarFacturasAdeudadas) {
        const state = ctx.getState();
        return  this.cs.creditoImputarSobreFacturasAdeudadas(action.pagos,action.cliente,action.usuario,action.usuarioAutorizante)
        .pipe(
          mergeMap( ()=>ctx.dispatch(new GetCreditoMontoTotal(action.cliente,true))),
          mergeMap( () => ctx.dispatch(new GetCreditosOtorgados(action.cliente,true))),
          mergeMap( () => ctx.dispatch(new GetCreditoImputado(action.cliente,true)))
           )
    }



    @Action(ResetCredito)
    resetCredito({ patchState }: StateContext<CreditoStateModel>) {

        patchState({
          credito_monto_total:null,
        creditos_otorgados:[],
        creditos_imputaciones:[],
          inicializado_credito_monto_total:false,
        inicializado_creditos_otorgados:false,
        inicializado_creditos_imputaciones:false,
        })

        return from([]);
    }



}

