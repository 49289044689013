import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { FacturaService } from '../factura.service';
import { VisorImpresionComponent } from 'src/app/shared/visor-impresion/visor-impresion.component';
import { Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { GetPeriodos, GetZonas } from 'src/app/store/general/actions';
import { UntypedFormGroup, UntypedFormBuilder, Validators, AbstractControl } from '@angular/forms';
import { Periodo } from 'src/app/store/general/models';
import { Imprimir } from 'src/app/shared/imprimir';
import { filter, first } from 'rxjs/operators';
import { GetBarrios } from 'src/app/store/barrio/actions';

@Component({
  selector: 'app-imprimir-facturas',
  templateUrl: './imprimir-facturas.component.html',
  styleUrls: ['./imprimir-facturas.component.css']
})
export class ImprimirFacturasComponent implements OnInit,OnDestroy {

  @ViewChild('visorImpresion', { static: true }) visorImpresion:VisorImpresionComponent;

  barrios$: Observable<any[]>
  zonas$: Observable<any[]>



  barrioAImprimir: number

  impresionForm: UntypedFormGroup

  estaProcesando:boolean =false

  imprimirUtils:Imprimir = new Imprimir()

  periodos:Periodo[]
  periodosSub: Subscription;

  constructor(private fs: FacturaService, private store:Store, private fb:UntypedFormBuilder) { }



  ngOnInit(): void {
    this.store.dispatch(new GetBarrios())
    this.store.dispatch(new GetZonas())
    this.store.dispatch(new GetPeriodos())

    this.barrios$ = this.store.select(state=>state.barrio.barrios)
    this.zonas$ = this.store.select(state => state.general.zonas)

    this.periodosSub= this.store.select(state => state.general.periodos).pipe(
    filter( data=> data.length>0)
    ).subscribe(
      periodosR=> {
      this.periodos = periodosR
      let self=this
      setTimeout(() =>{
        self.periodoDesdeField.setValue(periodosR[1].id_periodo)
        self.periodoHastaField.setValue(periodosR[0].id_periodo)
      },1000)
    }
    );



    this.initForm()
  }

  initForm(){
    this.impresionForm = this.fb.group({
      tipoImpresion:['1', Validators.required], //1-Periodos completos, 2-facturas de una zona, 3- facturas de un barrio
      sociosAdheridosDebitoAutomatico:[false],
      barrio:[{value:'',disabled:true}, Validators.required],
      incluirZonasABarrio:[{value:false,disabled:true}],
      zona:[{value:'',disabled:true}, Validators.required],
      ordenarPor:['1'],
      periodoDesde:['',Validators.required],
      incluirPeriodoHasta:[true],
      periodoHasta:[{disabled:false}, Validators.required],
      incluirFacturasPagas:[false],
      sociosAdheridosAFacturaDigital:[false],
      imprimirSinInteresPorVencimiento:[false],
    },
    { validator: this.EsMenorQue })


    this.tipoImpresionField.valueChanges.subscribe(
      value=>{
        if(value=='1'){
          this.barrioField.disable()
          this.zonaField.disable()
          this.incluirZonasABarrioField.disable()
        }
        if(value=='2'){
          this.barrioField.disable()
          this.zonaField.enable()
          this.incluirZonasABarrioField.disable()
        }
        if(value=='3'){
          this.barrioField.enable()
          this.zonaField.disable()
          this.incluirZonasABarrioField.enable()
        }

      }
    )

    this.incluirPeriodoHastaField.valueChanges.subscribe(
      value=>{
      value?this.periodoHastaField.enable():this.periodoHastaField.disable();
      }
    )

  }

  barrioSeleccionado(event){
    this.barrioAImprimir = event.target.value;
  }

  get tipoImpresionField(){
    return this.impresionForm.controls.tipoImpresion
  }

  get sociosAdheridosDebitoAutomaticoField(){
    return this.impresionForm.controls.sociosAdheridosDebitoAutomatico
  }


  get barrioField(){
    return this.impresionForm.controls.barrio
  }

  get incluirZonasABarrioField(){
    return this.impresionForm.controls.incluirZonasABarrio
  }

  get ordenarPorField(){
    return this.impresionForm.controls.ordenarPor
  }

  get zonaField(){
    return this.impresionForm.controls.zona
  }

  get periodoDesdeField(){
    return this.impresionForm.controls.periodoDesde
  }


  get incluirPeriodoHastaField(){
    return this.impresionForm.controls.incluirPeriodoHasta
  }

  get periodoHastaField(){
    return this.impresionForm.controls.periodoHasta
  }

  get incluirFacturasPagasField(){
    return this.impresionForm.controls.incluirFacturasPagas
  }

  get getValorBarrioIsInvalid(){
     return this.barrioField.errors && this.barrioField.touched
  }

  get getValorPeriodoDesdeIsInvalid(){
    return this.periodoDesdeField.errors && this.periodoDesdeField.touched
 }

 get getValorPeriodoHastaIsInvalid(){
  return this.periodoHastaField.errors && this.periodoHastaField.touched
 }

 get sociosAdheridosAFacturaDigitalField(){
  return this.impresionForm.controls.sociosAdheridosAFacturaDigital
}

get imprimirSinInteresPorVencimientoField(){
  return this.impresionForm.controls.imprimirSinInteresPorVencimiento
}



 EsMenorQue(AC: AbstractControl) {

  let valorA = AC.get("periodoHasta").value; // to get value in input tag
  let valorB = AC.get("periodoDesde").value; // to get value in input tag
  if (+valorA < +valorB) {
    AC.get("periodoHasta").setErrors({ EsMenorQue: true });
  } else {
    return null;
  }
}


  onImprimirFacturas(event:Event){
    event.preventDefault();

    if(this.impresionForm.valid){

      this.estaProcesando=true
     
    this.fs.imprimirFacturasGeneral
    (this.tipoImpresionField.value,
      this.sociosAdheridosDebitoAutomaticoField.value,
      this.barrioField.value,
      this.incluirZonasABarrioField.value,
      this.zonaField.value,
      this.ordenarPorField.value,
      this.periodoDesdeField.value,
      this.incluirPeriodoHastaField.value,
      this.periodoHastaField.value,
      this.incluirFacturasPagasField.value,
      this.sociosAdheridosAFacturaDigitalField.value,
      this.imprimirSinInteresPorVencimientoField.value
      ).pipe(first()).subscribe(
        (contenido: any)=>{
      const a = new Blob([contenido], { type: 'application/pdf' });
       let url = window.URL.createObjectURL(a);
      window.open(url, '_blank', 'fullscreen=yes')
          this.estaProcesando=false;
      },
      ()=>{
        this.estaProcesando=false
      }
      )

    }

  }

  onUltimoPeriodoFacturado(){
    this.periodoDesdeField.setValue(this.periodos[1].id_periodo)
    this.periodoHastaField.setValue(this.periodos[0].id_periodo)
  }

  ngOnDestroy(){
    this.periodosSub.unsubscribe()
  }

}
