import { State, StateContext, Action, Selector, Store } from "@ngxs/store";

import { CuentaCorriente } from './model';
import { Injectable } from '@angular/core';
import { SocioGestionService } from 'src/app/socio-gestion/socio-gestion.service';
import { GetCuentaCorriente, ResetCuentaCorriente } from './actions';
import { of, from } from "rxjs";


export class CuentaCorrienteStateModel {
  cuenta_corriente: CuentaCorriente[];
  inicializado:boolean;
}



@State<CuentaCorrienteStateModel>({
  name: 'cuenta_corriente',
  defaults: {
    cuenta_corriente:[],
    inicializado:false
  }
})



@Injectable()
export class CuentaCorrienteState {

    constructor(private sgs: SocioGestionService) { }


    @Action(GetCuentaCorriente)
    getCuentaCorriente(ctx: StateContext<CuentaCorrienteStateModel>, action: GetCuentaCorriente) {

      const state = ctx.getState()
      let seguir = false

      if(!action.update && !state.inicializado){seguir=true}
      if(action.update && state.inicializado){seguir=true}

      if (seguir) {
        this.sgs.getClienteCuentaCorriente(action.cliente).subscribe(
          data => {
              ctx.patchState({
                  cuenta_corriente: data,
                  inicializado:true
              });
          }
      )
      }
    }



    @Action(ResetCuentaCorriente)
    resetCuentaCorriente({ patchState }: StateContext<CuentaCorrienteStateModel>) {

        patchState({
          cuenta_corriente:[],
          inicializado:false
        })

        return from([]);
    }


}
