import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Cesp } from '../store/afip/models';

import * as _ from 'lodash';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json'
  })
};


@Injectable({
  providedIn: 'root'
})
export class AfipService {
  urlRest = environment;
  constructor(private http: HttpClient) { }

  getCespList():Observable<any>{
    return this.http.get(`${this.urlRest.urlapiNode}/afip/cesp`)
   }

  addCesp(cesp:string,fechaProceso:string,fechaValidoDesde:string,fechaValidoHasta:string):Observable<any>{
    return this.http.post(`${this.urlRest.urlapiNode}/afip/cesp`,{cesp,fechaProceso,fechaValidoDesde,fechaValidoHasta},httpOptions)
   }

   updateCesp(idCesp:number, cesp:string,fechaProceso:string,fechaValidoDesde:string,fechaValidoHasta:string):Observable<any>{
    return this.http.put(`${this.urlRest.urlapiNode}/afip/cesp`,{idCesp,cesp,fechaProceso,fechaValidoDesde,fechaValidoHasta},httpOptions)
   }

   generarPresentacionCobranza(periodo,separador):Observable<any>{
    return this.http.post(`${this.urlRest.urlapiNode}/afip/presentacion/cobranzas`,{periodo,separador},{ responseType: 'blob'})
   }

   generarPresentacionFacturacion(periodo,separador):Observable<any>{
    return this.http.post(`${this.urlRest.urlapiNode}/afip/presentacion/facturacion`,{periodo,separador},{ responseType: 'blob'})
   }

   generarPresentacionPagosAnticipados(periodo,separador):Observable<any>{
    return this.http.post(`${this.urlRest.urlapiNode}/afip/presentacion/pagos-anticipados`,{periodo,separador},{ responseType: 'blob'})
   }
}
