<div class="menu-ppal">
    <nav class="navbar navbar-expand-lg navbar-dark bg-dark fixed-top shadow"
      style="background-color: #19647E !important; height: 60px !important;">
      <a class="navbar-brand" class="navbar-brand mr-1" style="color: #049ed5;" href="/login">
        <img width="30" src="./././assets/images/gota.png" alt="">
        CAPP
      </a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNavDropdown">
        <ul class="navbar-nav mr-auto general">
          <!--   SECCION ARCHIVO -->
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink"
              data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Archivo
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
              <li><a class="dropdown-item" routerLink="/barrios">Barrios</a></li>
              <li><a class="dropdown-item" routerLink="/cajas">Cajas</a></li>
              <li><a class="dropdown-item"  routerLink="/general/calles">Calles</a></li>
              <li><a class="dropdown-item"  routerLink="/conexiones/estados">Estados de conexión</a></li>
              <li><a class="dropdown-item" routerLink="/zonas">Zonas</a></li>

            </ul>
          </li>
          <!--   SECCION FACTURACION -->
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink"
              data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Facturación
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
              <li><a class="dropdown-item" routerLink="/facturas/imprimir">Imprimir facturas</a></li>
              <div class="dropdown-divider"></div>
              <li *appRole="permisoModoRequeridoProcesoDeFacturacion"><a class="dropdown-item" routerLink="/facturas/proceso-de-facturacion">Proceso de facturación</a></li>
              <li *appRole="permisoModoRequeridoProcesoDeFacturacionMensual"><a class="dropdown-item" routerLink="/facturas/proceso-de-facturacion-mensual">Proceso de facturación mensual</a></li>
              <li *appRole="permisoModoRequeridoGeneracionReciboSemestral"><a class="dropdown-item" routerLink="/recibo-semestral/generar">Generación recibo semestral</a></li>
              <div class="dropdown-divider"></div>
              <li><a class="dropdown-item" routerLink="/facturas/resumen-facturacion">Resúmen de facturación</a></li>
              <div class="dropdown-divider"></div>
              <li><a class="dropdown-item" routerLink="/servicio-medido/carga-estados">Carga de estados</a></li>
              <div class="dropdown-divider"></div>
              <li *appRole="permisoModoRequeridoGeneracionNotasDeCreditos"><a class="dropdown-item" routerLink="/notas-de-credito/procesar">Proceso de generacion de notas de créditos</a></li>
              <li><a class="dropdown-item" routerLink="/notas-de-credito/imprimir">Imprimir notas de créditos</a></li>
            </ul>
          </li>
          <!--   SECCION COBRANZAS -->
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink"
              data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Cobranzas
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink" style="min-width: 219px;">
              <li><a class="dropdown-item"routerLink="/cobranzas/factura">Cajas</a></li>
              <li class="dropdown-submenu"><a class="dropdown-item dropdown-toggle" href="#">Recibos</a>
                <ul class="dropdown-menu">
                  <li><a class="dropdown-item" routerLink="/recibos/nuevo">Nuevos Recibos</a></li>
                  <li><a class="dropdown-item" routerLink="/recibos/eliminar">Eliminar Recibos</a></li>
                  <li><a class="dropdown-item" routerLink="/recibos/listado-recibos">Listar Recibos</a></li>
                </ul>
              </li>

              <li class="dropdown-submenu"><a class="dropdown-item dropdown-toggle" href="#">Control Cobranzas</a>
                <ul class="dropdown-menu">
                  <li><a class="dropdown-item" routerLink="/caja/comprobante">Comprobante de pago</a></li>
                  <li><a class="dropdown-item" routerLink="/cobranzas/control">Listado de Cobranzas</a></li>
                </ul>
              </li>
              <div class="dropdown-divider"></div>
              <li class="dropdown-submenu"><a class="dropdown-item dropdown-toggle" href="#">Reportes Diarios </a>
                <ul class="dropdown-menu">
                  <li><a class="dropdown-item" routerLink="/cobranzas/control-recaudacion">Control de recaudación</a></li>
                  <li><a class="dropdown-item" routerLink="/cobranzas/resumen-diario">Resúmen diario de cobranzas</a></li>
                </ul>
              </li>

              <li class="dropdown-submenu"><a class="dropdown-item dropdown-toggle" [appRoleAlgunPermiso]="permisoModoRequeridoImpresionDeuda" href="#">Resúmen de deuda/pago
                </a>
                <ul class="dropdown-menu">
                  <li><a class="dropdown-item" routerLink="/facturas/resumen-deuda-por-periodo" *appRole="permisoModoRequeridoImpresionListadoDeDeudaPagoPorPeriodo">Listado por período</a></li>
                  <li><a class="dropdown-item" routerLink="/socio-reportes/listado-deudores" *appRole="permisoModoRequeridoImpresionListadoDeudaUsuarios">Listado deudores generales/convenio</a></li>
                </ul>
              </li>
              <div class="dropdown-divider"></div>
              <li class="dropdown-submenu"><a class="dropdown-item dropdown-toggle" href="#">Pago Fácil</a>
                <ul class="dropdown-menu">
                  <li><a class="dropdown-item" routerLink="/pago-facil/procesar">Procesar archivo de rendición</a></li>
                </ul>
              </li>
            </ul>
          </li>
          <!--   SECCION ADMINISTRACION -->
          <li class="nav-item dropdown administracion" *appRole="permisoModoRequeridoOpcionesAdministracion">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink"
              data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Administracion
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
              <li class="dropdown-submenu"><a class="dropdown-item dropdown-toggle" href="#">AFIP</a>
                <ul class="dropdown-menu">
                  <li><a class="dropdown-item" routerLink="/afip/cesp">CESP</a></li>
                  <li><a class="dropdown-item" routerLink="/afip/presentaciones">Presentaciones para AFIP</a></li>
                </ul>
              </li>

              <li class="dropdown-submenu"><a class="dropdown-item dropdown-toggle" href="#">Debito automático
                  abierto/cerrado</a>
                <ul class="dropdown-menu">
                  <li><a class="dropdown-item" routerLink="/bpn/debito/generar-listado" >Generar Listado para Presentación</a></li>
                  <li><a class="dropdown-item" routerLink="/bpn/debito/procesar-rendicion">Procesar Archivo de Rendición</a></li>
                </ul>
              </li>

              <li class="dropdown-submenu"><a class="dropdown-item dropdown-toggle" href="#">Factura digital</a>
                <ul class="dropdown-menu">
                  <li><a class="dropdown-item" routerLink="/factura-digital/listado-clientes">Listado socios para campaña via email</a></li>
                </ul>
              </li>

              <li class="dropdown-submenu"><a class="dropdown-item dropdown-toggle" href="#">Pago Fácil</a>
                <ul class="dropdown-menu">
                  <li><a class="dropdown-item" routerLink="/pago-facil/procesar">Procesar archivo de rendición</a></li>
                </ul>
              </li>

              <li class="dropdown-submenu"><a class="dropdown-item dropdown-toggle" href="#">Pago Mis Cuentas</a>
                <ul class="dropdown-menu">
                  <li><a class="dropdown-item" routerLink="/pago-mis-cuentas/rendicion-generar" >Generar Listado para Presentación de Facturas</a></li>
                  <li><a class="dropdown-item" routerLink="/pago-mis-cuentas/rendicion-generar-recibos-semestrales">Generar Listado para Presentación de Recibos Semestrales</a>
                  </li>
                  <li><a class="dropdown-item" routerLink="/pago-mis-cuentas/procesar-rendicion">Procesar Archivo de Rendición</a></li>
                </ul>
              </li>

              <li class="dropdown-submenu"><a class="dropdown-item dropdown-toggle" href="#">Recibo semestral</a>
                <ul class="dropdown-menu">
                  <li><a class="dropdown-item" routerLink="/recibo-semestral/rendicion-generar">Generar archivos de rendición RED LINK</a></li>
                  <li><a class="dropdown-item" routerLink="/recibo-semestral/anulacion" >Anulación</a></li>
                </ul>
              </li>

              <li class="dropdown-submenu"><a class="dropdown-item dropdown-toggle" href="#">Red Link</a>
                <ul class="dropdown-menu">
                  <li><a class="dropdown-item" routerLink="/red-link/rendicion/generar">Generar Listado para Presentación de Facturas</a></li>
                  <li><a class="dropdown-item" routerLink="/red-link/rendicion/procesar">Procesar archivos de rendición</a></li>
                </ul>
              </li>

              <li><a class="dropdown-item" routerLink="/bpn/rendicion-cajas">Rendición Cajas BPN</a></li>

              <li class="dropdown-submenu"><a class="dropdown-item dropdown-toggle" href="#">Tarifas</a>
                <ul class="dropdown-menu">
                  <li><a class="dropdown-item" routerLink="/tarifas/cuadro-tarifario-reglas">Reglas del cuadro tarifario</a></li>
                  <li><a class="dropdown-item" routerLink="/tarifas/cuadro-tarifario">Cuadro tarifario</a></li>
                  <li><a class="dropdown-item" routerLink="/tarifas/cuadro-tarifario-socio-control">Control Cuadro tarifario - Socio</a></li>
                </ul>
              </li>

              <li><a class="dropdown-item" routerLink="/utilidades">Utilidades</a></li>


            </ul>
          </li>

          <!--   SECCION UTILIDADES -->
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink"
              data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Utilidades
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
              <li><a class="dropdown-item" routerLink="/configuracion-sistema" >Configurar sistema</a></li>
            </ul>
          </li>

        

          <!--   SECCION SOCIOS -->
          <li class="nav-item dropdown" *appRole="permisoModoRequeridoOpcionesABMSituacionEspecialConVencimiento">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink"
              data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Socios
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
              <li><a class="dropdown-item" routerLink="/socio/control-situacion-especial">Control situación especial</a></li>
            </ul>
          </li>



          <!--   SECCION CONVENIOS POR SERVICIO -->
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink"
              data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Convenios
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
              <li><a class="dropdown-item" routerLink="/convenio-por-servicio/listado-convenios">Convenio de pago por obra de servicio</a></li>
            </ul>
          </li>

            <!--   SECCION USUARIOS -->
            <li class="nav-item dropdown" *appRole="permisoModoRequeridoOpcionesABMUsuarios">
              <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Usuarios
              </a>
              <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                <li><a class="dropdown-item" routerLink="/roles-permisos/permisos">Permisos</a></li>
                <li><a class="dropdown-item" routerLink="/roles-permisos/roles">Roles</a></li>
                <li><a class="dropdown-item" routerLink="/usuarios">Usuarios</a></li>
              </ul>
            </li>

            <li class="nav-item dropdown ml-5" >
              <app-notificacion-badge />
            </li>

        </ul>

        
        


        <app-busqueda-socio class="mt-1" style="display: inline-flex;"></app-busqueda-socio>


      </div>




    </nav>
  </div>
