import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class GeneralService {
  urlRest = environment;
  constructor(private http: HttpClient) { }

  getPeriodos(): Observable<any>{
    return this.http.get(`${this.urlRest.urlapiNode}/periodos`)

  }

  getProximosPeriodosDeFacturacion(): Observable<any>{
    return this.http.get(`${this.urlRest.urlapiNode}/periodos/a-facturar`)
  }

  getTiposDni(): Observable<any>{
    return this.http.get(`${this.urlRest.urlapiNode}/general/tipos-dni`)
  }



  getTiposIva(): Observable<any>{
    return this.http.get(`${this.urlRest.urlapiNode}/general/tipos-iva`)

  }



  getCategorias(): Observable<any>{
    return this.http.get(`${this.urlRest.urlapiNode}/general/categorias`)
  }

  getEstados(): Observable<any>{
    return this.http.get(`${this.urlRest.urlapiNode}/general/estados`)
  }

  getFinSupension(): Observable<any>{
    return this.http.get(`${this.urlRest.urlapiNode}/general/fin-suspension`)
  }

  getTarifas(): Observable<any>{
    return this.http.get(`${this.urlRest.urlapiNode}/general/tarifas`)
  }

  getFinTarifas(): Observable<any>{
    return this.http.get(`${this.urlRest.urlapiNode}/general/fin-tarifas`)
  }

  getZonas(): Observable<any>{
    return this.http.get(`${this.urlRest.urlapiNode}/general/zonas`)
  }

  saveEstado(estado:string,facturar:boolean): Observable<any>{
    return this.http.post(`${this.urlRest.urlapiNode}/general/estados`,{estado,facturar})
  }

  editEstado(idEstado:number, estado:string,facturar:boolean): Observable<any>{
    return this.http.put(`${this.urlRest.urlapiNode}/general/estados`,{idEstado,estado,facturar})
  }

  deleteEstado(idEstado:number,estado:string,facturar:boolean):Observable<any>{
    let params = new HttpParams()
    .set('idEstado',idEstado.toString())
    .set('estado',estado.toString())
    .set('facturar',facturar)

    return this.http.delete(`${this.urlRest.urlapiNode}/general/estados`,{params})
  }


  saveZonas(zona:string,imprimir:boolean): Observable<any>{
    return this.http.post(`${this.urlRest.urlapiNode}/general/zonas`,{zona,imprimir})
  }

  editZonas(idZona:number, zona:string,imprimir:boolean): Observable<any>{
    return this.http.put(`${this.urlRest.urlapiNode}/general/zonas`,{idZona,zona,imprimir})
  }

  deleteZonas(idZona:number,zona:string,imprimir:boolean):Observable<any>{
    let params = new HttpParams()
    .set('idZona',idZona.toString())
    .set('zona',zona.toString())
    .set('imprimir',imprimir)

    return this.http.delete(`${this.urlRest.urlapiNode}/general/zonas`,{params})
  }

  getSituacionesEspecial(): Observable<any>{
    return this.http.get(`${this.urlRest.urlapiNode}/general/situaciones-especial`)
  }


  getCalles(): Observable<any>{
    return this.http.get(`${this.urlRest.urlapiNode}/general/calles`)
  }

  getProvincias(): Observable<any>{
    return this.http.get(`${this.urlRest.urlapiNode}/general/provincias`)
  }

  getTiposMedianeras(): Observable<any>{
    return this.http.get(`${this.urlRest.urlapiNode}/general/tipos-medianeras`)
  }

  getTiposConexion(): Observable<any>{
    return this.http.get(`${this.urlRest.urlapiNode}/general/tipos-conexion`)
  }

  getTiposMaterial(): Observable<any>{
    return this.http.get(`${this.urlRest.urlapiNode}/general/tipos-material`)
  }

  getPrestadoresDebitoAutomatico(): Observable<any>{
    return this.http.get(`${this.urlRest.urlapiNode}/general/prestadores-debito-automatico`)

  }

  getCondicionRegistro(): Observable<any>{
    return this.http.get(`${this.urlRest.urlapiNode}/general/condiciones-registro`)

  }


  getBancos(): Observable<any>{
    return this.http.get(`${this.urlRest.urlapiNode}/general/bancos`)

  }
  getCajas(): Observable<any>{
    return this.http.get(`${this.urlRest.urlapiNode}/general/cajas`)
  }

  callesChequearExistencia(idcalle:number):Observable<any>{
    return this.http.get(`${this.urlRest.urlapiNode}/calles/existe/${idcalle}`)
   }



  guardarCalles(idcalle:number, nombre:string):Observable<any>{
    return this.http.post(`${this.urlRest.urlapiNode}/calles`,{idcalle,nombre},httpOptions)
    }

    actualizarCalles(idcalle:number, nombre:string):Observable<any>{
      return this.http.put(`${this.urlRest.urlapiNode}/calles`,{idcalle,nombre},httpOptions)
      }

      eliminarCalles(idcalle:number):Observable<any>{
        return this.http.delete(`${this.urlRest.urlapiNode}/calles/${idcalle}`)
        }

}
