<ng-container [formGroup]="socioForm">


<div id="domicilioConexion"></div>
<div class="card shadow mt-4">
  <div class="card-body">
    <p style="border-left: solid 5px #17a2b8; padding-left: 5px"><b> Domicilio de conexión</b></p>
    <hr>

    <div class="form-group row">
      <label for="inputBarrioConexion" class="col-sm-1 col-form-label col-form-label-sm  text-right">Barrio</label>
      <div class="col-sm-6">

        <input type="text" class="form-control form-control-sm" list="barriosConexionInput"
          (change)="onSelectionBarrioChange($event,'id_barrioConexion')" formControlName="barrioConexion"
          [ngClass]="{ 'is-invalid': isInvalidBarrioConexionField }" />
        <datalist id="barriosConexionInput">
          <option *ngFor="let barrio of barrios" [value]="barrio.barrio">{{barrio.barrio}}</option>
        </datalist>

        <div *ngIf="isInvalidBarrioConexionField" class="invalid-feedback">
          <div *ngIf="socioForm.controls['barrioConexion'].errors.required">Campo obligatorio</div>
          <div *ngIf="socioForm.controls['barrioConexion'].errors.esBarrioPlottierValido">El barrio
            seleccionado no se encuentra registrado</div>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label for="inputCodCalleConexion" class="col-sm-1 col-form-label col-form-label-sm  text-right">Calle</label>
      <div class="col-sm-6">
        <input type="text" class="form-control form-control-sm" list="callesInput"
          (change)="onSelectionCalleConexion($event)" formControlName="calleConexion"
          [ngClass]="{ 'is-invalid': isInvalidCalleConexionField }" autocomplete="off" />
        <datalist id="callesInput">
          <option *ngFor="let calle of calles" [value]="calle.detalle">{{calle.detalle}}</option>
        </datalist>
        <div *ngIf="isInvalidCalleConexionField" class="invalid-feedback">
          <div *ngIf="socioForm.controls['calleConexion'].errors.required">Campo obligatorio</div>
          <div *ngIf="socioForm.controls['calleConexion'].errors.esCallePlottierValida">La calle seleccionada
            no se encuentra registrada</div>
        </div>
      </div>
      <div class="col-sm-1">
        <button type="button" class="btn btn-sm btn-outline-info" (click)="nuevaCalle()" title="Nueva calle"><i
            class="fas fa-plus-square"></i></button>
      </div>
      <label for="inputnumeroCalleConexion" class="col-sm-2 col-form-label col-form-label-sm  text-right">número</label>
      <div class="col-sm-2">
        <input type="text" maxlength="15" class="form-control form-control-sm"
          formControlName="numeroCalleConexion" placeholder="" autocomplete="off" appNumberEnteroOnly>
      </div>
    </div>
    <div class="form-group row">
      <label for="inputPisoDepartamentoConexion" class="col-sm-2 col-form-label col-form-label-sm ">Piso, Depto, etc.</label>
      <div class="col-sm-4">
        <input type="text" maxlength="80" class="form-control form-control-sm"
          formControlName="pisoDepartamentoConexion" id="inputPisoDepartamentoConexion" placeholder="">
      </div>
      <label for="inputDistritoElectoral" class="col-sm-2 col-form-label col-form-label-sm  text-right">Distrito Elect.</label>
      <div class="col-sm-2">
        <select class="form-control form-control-sm" formControlName="distritoElectoral" id="inputDistritoElectoral" [ngClass]="{ 'is-invalid': isInvalidDistritoElectoralField }">
          <option value=""></option>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>         
        </select>
        <!-- <input type="text" maxlength="2" class="form-control form-control-sm"
          formControlName="distritoElectoral" id="inputDistritoElectoral" placeholder=""
          [ngClass]="{ 'is-invalid': isInvalidDistritoElectoralField }" appNumberEnteroOnly> -->
        <div *ngIf="isInvalidDistritoElectoralField" class="invalid-feedback">
          Campo obligatorio
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label for="inputObservacionConexion" class="col-sm-2 col-form-label col-form-label-sm ">Observación</label>
      <div class="col">
        <input type="text" maxlength="200" class="form-control form-control-sm"
          formControlName="observacionConexion" id="inputObservacionConexion" placeholder="">
        <small id="emailHelp" class="form-text text-muted">Información adicional sobre el domicilio de
          conexión. Dicha información será mostrada en la impresión de facturas.</small>
      </div>
    </div>
  </div>
</div>
</ng-container>
