import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';


interface AutorizacionItem {
  puedeAutorizar: boolean;
}


@Injectable({
  providedIn: 'root'
})
export class AutorizacionService {

  

  constructor(private http: HttpClient) { }


habilitaOperacion(username:string,password:string):Observable<any>{
    return this.http.post<AutorizacionItem>(`${environment.urlapiNode}/auth/habilita-autorizacion`,{username,password})
   }

}
