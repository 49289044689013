import  dayjs from "dayjs";

// controla que la fecha sea del formato dd/mm/aaaa
const fechaRex = (charStr) => !!/^(?:(?:31(\/)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/)(?:0?[1,3-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/.test(charStr);

 export class Time {

  constructor(){}

  static esFormatoFechaLocal(fecha: string): boolean {
    return fechaRex(fecha)
  }


  static formatDate(fecha:string,formato:string){

    if (!dayjs(fecha).isValid()) return null

    return  dayjs(fecha).format(formato);
  }


  static fromLocalToStandar(dateFrom:string){
    let dateParser =  dateFrom.split('/')
    return  dayjs( dateParser[2]+'-'+dateParser[1]+'-'+dateParser[0]).format('YYYY-MM-DD')
   }

  static currentDateToStandar(){
    return  dayjs().format('YYYY-MM-DD')
  }

  /**
   *
   * @param date
   * @param value
   * @param unit
   * @returns la suma de n unidades de tiempo a la fecha dada
   */
  static add(date:Date,value:number,unit:'d'|'M'):string{
    if (!dayjs(date).isValid()) return ""

    return  dayjs(date).add(value,unit).format('YYYY-MM-DD')
  }


}
