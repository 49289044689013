import { Component, OnInit, ViewChild } from '@angular/core';
import { FiltroFecha, FiltroSocio, FiltroNumeroComprobante } from 'src/app/shared/modelos/filtro-fecha.model';
import  moment from 'moment';
import { ComprobanteCobranza } from 'src/app/store/cobranza/models';
import { Store } from '@ngxs/store';
import { GetComprobanteCobranzaPorFecha, GetComprobanteCobranzaPorSocio, GetComprobanteCobranzaPorNumeroComprobante, StartBusquedaComprobante } from 'src/app/store/cobranza/actions';
import { FacturaService } from 'src/app/factura/factura.service';
import { HttpResponse } from '@angular/common/http';
import { Imprimir } from 'src/app/shared/imprimir';
import { OperacionLoadingComponent } from 'src/app/shared/operacion-loading/operacion-loading.component';
import { ExportarArchivo } from 'src/app/shared/exportar';
import { CobranzaService } from '../cobranza.service';
import { Observable, Subject } from 'rxjs';
import { OperacionSiNoComponent } from 'src/app/shared/operacion-si-no/operacion-si-no.component';
import { AutorizacionComponent } from 'src/app/autorizacion/autorizacion.component';
import { takeUntil } from 'rxjs/operators';
import { AuthState } from 'src/app/store/auth/state';
import { ButtonRendererComponent } from './render/button-renderer.component';
import Swal from 'sweetalert2';
import { Time } from 'src/app/utils/time';

declare var $:any;

const formatDate = (params)=>{
  if (params.value!==''){
    return  Time.formatDate(params.value,"DD/MM/YYYY")
  }else{
    return null
  }
}


@Component({
  selector: 'app-listado-comprobantes',
  templateUrl: './listado-comprobantes.component.html',
  styleUrls: ['./listado-comprobantes.component.css']
})

export class ListadoComprobantesComponent implements OnInit {

  @ViewChild(OperacionLoadingComponent, { static: true })
  operacionLoadingComponent: OperacionLoadingComponent;

  @ViewChild(OperacionSiNoComponent, { static: true })
  operacionSiNoComponent: OperacionSiNoComponent;

  @ViewChild(AutorizacionComponent, { static: true })
  autorizacionComponent: AutorizacionComponent;

  columnDefs = [
    { headerName: 'N° de comprobante', field: 'id_compcaja', sortable: true, filter: true },
    { headerName: 'Cod Socio', field: 'codsocio', sortable: true, filter: true },
    { headerName: 'Sub Cod Socio', field: 'subcodsocio', sortable: true, filter: true },
    { headerName: 'Fecha', field: 'fecha', sortable: true, filter: true,width: 150, valueFormatter: formatDate },
    { headerName: 'Total', field: 'cobrado', sortable: true, filter: true,width: 100 },
    {
      headerName: 'Visualizar',
      cellRenderer: 'buttonRenderer',
      width: 85,
      cellRendererParams: {
        onClick: this.visualizarComprobante.bind(this),
        label: 'ver',
        classBtn:'primary'
      }
    },
    {
      headerName: 'Descargar',
      cellRenderer: 'buttonRenderer',
      width: 85,
      cellRendererParams: {
        onClick: this.descargarComprobante.bind(this),
        label: 'descargar',
        classBtn:'warning'
      }
    },
    {
      headerName: 'Imprimir',
      cellRenderer: 'buttonRenderer',
      width: 75,
      cellRendererParams: {
        onClick: this.imprimirComprobante.bind(this),
        label: 'imprimir',
        classBtn:'success'
      }
    },
    {
      headerName: 'Eliminar',
      cellRenderer: 'buttonRenderer',
      width: 75,
      cellRendererParams: {
        onClick: this.anularComprobante.bind(this),
        label: 'eliminar',
        classBtn:'danger'
      }
    }

  ];

  rowData: any[] = [];
  public filtrarPorFecha:boolean=true;
  public filtrarPorSocio:boolean=false;
  public filtrarPorNumeroComprobante:boolean=false;

  public filtroFecha:FiltroFecha = new FiltroFecha();
  public filtroSocio:FiltroSocio = new FiltroSocio();
  public filtroNumeroComprobante:FiltroNumeroComprobante = new FiltroNumeroComprobante();

  public listados: ComprobanteCobranza[];

  public comprobanteSeleccionado:ComprobanteCobranza;

  public pdf = new ExportarArchivo();
  public imprimir = new Imprimir();

  public loadingBusquedaComprobante$:Observable<boolean>;

  total:number=0;



  destroy$: Subject<boolean> = new Subject<boolean>();

  comprobanteAnular:any
  frameworkComponents: any;

  constructor(private store:Store,private fs:FacturaService, private cs:CobranzaService) {

    this.frameworkComponents = {
      buttonRenderer: ButtonRendererComponent,
    }
  }

  ngOnInit() {

    this.store.select(state => state.cobranza.comprobantesCobranza).pipe(takeUntil(this.destroy$)).subscribe(
      data => {

                  this.rowData = data
                  this.total = data.length;
      }
    );

    this.loadingBusquedaComprobante$ = this.store.select(state => state.cobranza.loading_busqueda)

    this.filtroFecha.fecha_desde= moment().format("YYYY-MM-DD");
    this.filtroFecha.fecha_hasta= moment().format("YYYY-MM-DD");


  }


  onSelectionChange(event){

    this.filtrarPorFecha = (event.target.value == "fecha");
    this.filtrarPorSocio = (event.target.value == "socio");
    this.filtrarPorNumeroComprobante = (event.target.value == "numeroComprobante");

  }

  aplicarFiltroFecha(){
    this.store.dispatch(new StartBusquedaComprobante())
    this.store.dispatch(new GetComprobanteCobranzaPorFecha(this.filtroFecha))
  }

  aplicarFiltroSocio(){
    this.store.dispatch(new StartBusquedaComprobante())
    this.store.dispatch(new GetComprobanteCobranzaPorSocio(this.filtroSocio))
  }

  aplicarFiltroNumeroComprobante(){
    this.store.dispatch(new StartBusquedaComprobante())
    this.store.dispatch(new GetComprobanteCobranzaPorNumeroComprobante(this.filtroNumeroComprobante))
  }

  imprimirComprobante(e){
  let  comprobante = e.rowData
    this.operacionLoadingComponent.loadingTitle="Generando comprobante de pago";
        this.operacionLoadingComponent.open();

    this.fs.getComprobantePagoFacturaMultiple(comprobante.id_compcaja,comprobante.id_socio)
    .subscribe(
      (contenido: HttpResponse<Blob>)=>{

          this.imprimir.imprimir(contenido);
          this.operacionLoadingComponent.close();
      });
  }

  descargarComprobante(e){
    let  comprobante = e.rowData
    this.operacionLoadingComponent.loadingTitle="Generando comprobante de pago";
        this.operacionLoadingComponent.open();

    this.fs.getComprobantePagoFacturaMultiple(comprobante.id_compcaja,comprobante.id_socio)
    .subscribe(
      (contenido: HttpResponse<Blob>)=>{

        this.pdf.exportarArchivo(contenido,'Comprobante de pago '+ comprobante.id_compcaja,'pdf');
          this.operacionLoadingComponent.close();
      });
  }

  visualizarComprobante(e){
    let  comprobante = e.rowData
    this.cs.getFacturasComprobantes(comprobante.id_compcaja).subscribe(
      data=>{
        $('#detalle_comprobante').modal('show');
        comprobante.facturas= data;
        this.comprobanteSeleccionado = comprobante;
      }
    )



  }

  cerrarVisualizador(){
    $('#detalle_comprobante').modal('hide');
  }


  obtenerTotal(factura){
    let total = 0;
    total = (factura.total_facturado * 1) + (factura.total_recargo *1)
    return total.toFixed(2);
  }

  anularComprobante(e){
    let  comprobante = e.rowData
    this.comprobanteAnular =comprobante
    this.operacionSiNoComponent.open();
  }

  retornoOperacionSiNo(retorno) {
    if (retorno) {

      this.autorizacionComponent.open()
    }
  }

  retornoAutorizacion(retorno){
    if(retorno.habilita){

      const usuario = this.store.selectSnapshot(AuthState.usuario);

     this.cs.anularCobranzaMultiple(this.comprobanteAnular.id_compcaja,usuario,retorno.usuarioAutorizante).subscribe(
      ()=>{
        this.rowData=[]
        Swal.fire(
          '','La cobranza ha sido anulada con éxito!',
          'success'
        )
      }
     )


    }

  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}
