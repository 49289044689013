import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import  moment from 'moment'
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { OperacionSiNoComponent } from 'src/app/shared/operacion-si-no/operacion-si-no.component';

@Component({
  selector: 'app-plazos-form',
  templateUrl: './plazos-form.component.html',
  styleUrls: ['./plazos-form.component.css']
})
export class PlazosFormComponent implements OnInit {
  
  @ViewChild('siNoPlazos', { static: false })
  operacionSiNoComponent: OperacionSiNoComponent;

  @Input() tipoDocumentacion:string
  @Output() nuevoPlazoRetorno= new EventEmitter();

  plazosForm:UntypedFormGroup
  mensaje:string
  constructor(private formBuilder:UntypedFormBuilder) { }

  ngOnInit(): void {
    this.initForm()
  }

  initForm() {

    this.plazosForm = this.formBuilder.group({
      fechaInicioDelPlazo: [ moment().format("YYYY-MM-DD"),Validators.required],
      plazoEnDias: [180] ,
      fechaFinDelPlazo: [ moment().add(180,'days').format('DD-MM-YYYY')] ,
      observacion: ''      
    });
  }

  actualizarFechaFin(event){     
    let fechaInicio = this.plazosForm.get('fechaInicioDelPlazo').value
    let plazoDias = this.plazosForm.get('plazoEnDias').value
    this.plazosForm.get("fechaFinDelPlazo").setValue( moment(fechaInicio).add(plazoDias,'days').format('DD-MM-YYYY'))
  }

  onSubmit(){   
    this.mensaje='Desea generar un nuevo plazo para la entrega de la documentación? Recuerde que podrá imprimir la declaración, y realizar notificaciones vía mail sobre la misma, una vez generada.'
    this.operacionSiNoComponent.open()
  
  }

  retornoOperacionConsultaPlazos(e){
    if (e){
      this.nuevoPlazoRetorno.emit(
        {
        fecha_desde: this.plazosForm.get("fechaInicioDelPlazo").value , 
        observacion:this.plazosForm.get("observacion").value,
        fecha_hasta: moment(this.plazosForm.get("fechaInicioDelPlazo").value).add(this.plazosForm.get("plazoEnDias").value,'days').format("YYYY-MM-DD") ,
        dias_plazo:this.plazosForm.get("plazoEnDias").value
      }
      )
     this.plazosForm.reset({fechaInicioDelPlazo:moment().format("YYYY-MM-DD"),plazoEnDias:180,fechaFinDelPlazo:moment().add(180,'days').format('DD-MM-YYYY'),observacion:''}) 
    }
  }
  

}
