import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Acciones } from '../store/acciones/model';
import { environment } from 'src/environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})


export class AccionesService {



  constructor(private http:HttpClient) { }

  getAccionesCliente(cliente:number):Observable<Acciones[]>{
   return this.http.get<Acciones[]>(`${environment.urlapiNode}/acciones/${cliente}`)
  }

  addAccionesCliente(cliente:number,fechaSubscripcion:string,cuotas:number,valorCuota:number,importeTotal:number,cuotasAbonadas:number):Observable<any>{
    return this.http.post(`${environment.urlapiNode}/acciones`,{cliente,fechaSubscripcion,cuotas,valorCuota,importeTotal,cuotasAbonadas},httpOptions)
   }

   updateAccionesCliente(accion:number,fechaSubscripcion:string,cuotas:number,valorCuota:number,importeTotal:number,cuotasAbonadas:number,observacion:string):Observable<any>{
    return this.http.put(`${environment.urlapiNode}/acciones`,{accion,fechaSubscripcion,cuotas,valorCuota,importeTotal,cuotasAbonadas,observacion},httpOptions)
   }

   deleteAccionesCliente(accion:number,usuario_autorizante:number,observacion:string):Observable<any>{
     let params = new HttpParams()
     .set('accion',accion.toString())
     .set('usuario_autorizante',usuario_autorizante.toString())
     .set('observacion',observacion)
    return this.http.delete(`${environment.urlapiNode}/acciones`,{params})
   }

}
