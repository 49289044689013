import { Component, OnInit, Input, OnChanges, Output, EventEmitter, OnDestroy } from '@angular/core';

import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import {  Subscription } from 'rxjs';
import { ModoChequeoRol, PermisosModoRequerido } from 'src/app/shared/role.directive';
import { TipoPermisos } from 'src/app/store/roles-permisos/model';
declare  var $:any;

@Component({
  selector: 'app-resumen-selecccion',
  templateUrl: './resumen-selecccion.component.html',
  styleUrls: ['./resumen-selecccion.component.css']
})
export class ResumenSelecccionComponent implements OnInit,OnChanges,OnDestroy {

  @Input() modoImputacion:Boolean = false
  @Input() seleccionadasAPagar:number = 0
  @Input() totalAPagarEnFacturas:number = 0
  @Input() recargoAPagar:number = 0



  @Output() aplicarDescuentoEvent= new EventEmitter()


  permisoModoRequeridoHabilitadoDescuentoSobreIntereses:PermisosModoRequerido={permisosRequerido:[TipoPermisos.HabilitaDescuentoSobreIntereses],
    modoRequerido:ModoChequeoRol.RequiereTodosLosPermisos}




  descuentoRecargoForm: UntypedFormGroup;
  descuentoSobreRecargo=0
  descuentoTotal=0
  descuentoEfectivo=0

  facturas:Subscription

  constructor(private formBuilder: UntypedFormBuilder,private store:Store) { }

  ngOnInit(): void {
   this.facturas= this.store.select(state => state.factura.facturas_impagas).subscribe(
      ()=> {
        this.resetValues()
      }
    )

    this.initDescuentoRecargoForm();
  }


  ngOnChanges() {

      this.descuentoSobreRecargo=0
  }

  resetValues(){
    this.seleccionadasAPagar=0
    this.totalAPagarEnFacturas=0
    this.recargoAPagar= 0
    this.descuentoSobreRecargo= 0
  }

  initDescuentoRecargoForm(){
    this.descuentoRecargoForm = this.formBuilder.group({
      recargo: ['', Validators.required],
    });
}

aplicarDescuento(){

  this.descuentoSobreRecargo  = +this.descuentoRecargoForm.get('recargo').value
  this.descuentoTotal = +(this.recargoAPagar - ( this.recargoAPagar * this.descuentoSobreRecargo / 100)).toFixed(2) ;
  this.descuentoEfectivo = +this.recargoAPagar - this.descuentoTotal
  this.aplicarDescuentoEvent.emit(this.descuentoSobreRecargo)
  $('#descuentoModal').modal('hide')

}


 quitarDescuento(){
  this.descuentoSobreRecargo=0;
  this.aplicarDescuentoEvent.emit(0)
 }

 ngOnDestroy() {
   this.facturas.unsubscribe()
 }

}
