import { Component, OnInit, Output, EventEmitter, Input  } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { Store } from '@ngxs/store';
import { SetSocioUsuarioEmail } from 'src/app/store/socio/actions';
import Swal from 'sweetalert2';

declare var $:any;

@Component({
  selector: 'app-actualizar-email',
  templateUrl: './actualizar-email.component.html',
  styleUrls: ['./actualizar-email.component.css']
})
export class ActualizarEmailComponent implements OnInit {

  @Input() id:string='email_edit_modal'
  @Output() retornoOperacion = new EventEmitter();

  emailForm: UntypedFormGroup
  cliente:any



  constructor(private formBuilder: UntypedFormBuilder,private store:Store) {
    this.initForm()
   }

  ngOnInit(): void {

  }


  initForm(){
    this.emailForm = this.formBuilder.group({
      email:['', [Validators.required,this.esEmail]]
    })
  }

  setCliente(cliente){
    this.cliente = cliente
    this.emailForm.get('email').setValue(cliente.email)
  }

  open(){
    $(`#${this.id}`).modal('show')
  }

  aceptar(){
    this.store.dispatch(new SetSocioUsuarioEmail(this.cliente.cliente,this.emailForm.get('email').value)).subscribe(
      ()=> {

        Swal.fire(
          '','La operación ha sido realizada con éxito!',
          'success'
        )
        $(`#${this.id}`).modal('hide');
        this.emailForm.reset();
        this.retornoOperacion.emit(true);   }
    )

  }

  cancelar(){
    $(`#${this.id}`).modal('hide')
    this.emailForm.reset()
    this.retornoOperacion.emit(false);
  }

  esEmail(c: UntypedFormControl) {
    if (!c.value == false) {
      let exp = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
      let re = new RegExp(exp);
      const comparacion = re.test(c.value);
      return comparacion ? null : {
        esEmail: {
          valid: false
        }
      }
    } else {
      return null;
    }

  }


}
