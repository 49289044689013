import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { mergeMap } from 'rxjs/operators';
import { ResetAccionesCliente } from '../store/acciones/actions';
import { ResetConvenios } from '../store/convenio/actions';
import { ResetCredito } from '../store/credito/actions';
import { ResetCuentaCorriente } from '../store/cuentacorriente/actions';
import { ResetFacturas } from '../store/factura/actions';
import { ResetObservaciones } from '../store/observacion/actions';
import { ResetReciboSemestral } from '../store/recibo/actions';
import { GetSocioDetalle, StartBusquedaSocioDetalle } from '../store/socio/actions';

@Injectable({
  providedIn: 'root'
})
export class SocioNavigationService {

  constructor( private store:Store,private router: Router) { }

  goToSocio(codigo:string,subcodigo:string){

    this.store.dispatch([new StartBusquedaSocioDetalle(),
      new ResetCuentaCorriente(),new ResetReciboSemestral()
      ,new ResetObservaciones(), new ResetCredito()
      , new ResetAccionesCliente(), new ResetFacturas(), new ResetConvenios()]).
      pipe(
        mergeMap(()=>this.store.dispatch(new GetSocioDetalle(codigo,subcodigo))))
      .subscribe(
        )

        this.router.navigate(['/socio']);

  }
}
