export class GetPermisos{
    static readonly type = '[RolesPermisos] GetPermisos'
    constructor(public update:boolean=false){}
}


export class SavePermiso{
    static readonly type = '[RolesPermisos] SavePermiso'
    constructor(public permiso:string){}
  }
  
  export class EditPermiso{
    static readonly type = '[RolesPermisos] EditPermiso'
    constructor(public idPermiso:number,public permiso:string){}
  }
  
  
  export class DeletePermiso{
    static readonly type = '[RolesPermisos] DeletePermiso'
    constructor(public idPermiso:number,public permiso:string){}
  }
  

  export class GetRoles{
    static readonly type = '[RolesPermisos] GetRoles'
    constructor(public update:boolean=false){}
}


export class SaveRol{
  static readonly type = '[RolesPermisos] SaveRol'
  constructor(public rol:string,public permisos:number[]){}
}

export class EditRol{
  static readonly type = '[RolesPermisos] EditRol'
  constructor(public idRol:number,public rol:string,public permisos:number[]){}
}


export class DeleteRol{
  static readonly type = '[RolesPermisos] DeleteRol'
  constructor(public idRol:number){}
}