import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as _ from 'lodash';
import {environment} from '../../environments/environment';
import { Observable } from 'rxjs';
import { FacturaDetalle } from '../store/factura/models';
import { first } from 'rxjs/operators';



const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class FacturaService {

  urlRest = environment;

  constructor(private http: HttpClient) { }

  getFactura(factura:number):Observable<any>{
    return this.http.get<any>(`${this.urlRest.urlapiNode}/facturas/${factura}`)
   }

  getAdeudaFacturas(socio:number):Observable<any>{
    return this.http.get<any>(`${this.urlRest.urlapiNode}/facturas/adeuda/${socio}`,httpOptions)
   }

  getFacturasSociosImpagasActualizadas(socio:any):Observable<FacturaDetalle[]>{
    return this.http.get<FacturaDetalle[]>(`${this.urlRest.urlapiNode}/facturas/impagas/${socio}`)
   }

   getFacturasPagas(socio:any):Observable<FacturaDetalle[]>{
    return this.http.get<FacturaDetalle[]>(`${this.urlRest.urlapiNode}/facturas/pagas/${socio}`)
   }


   facturasPagar(facturas:FacturaDetalle[],
    cliente:number,
    totalAPagarEnFacturas:number,
    totalACobrar:number,
    descuentoTotalEnPorcentaje:number,
    caja:number,
    fechaCobranza:string,
    bancoCobranza:string,
    numeroTransferenciaCobranza:string

    ):Observable<any>{
    return this.http.post(`${this.urlRest.urlapiNode}/cobranza/facturas/pago`,{facturas,cliente,totalAPagarEnFacturas,totalACobrar,descuentoTotalEnPorcentaje,caja,
      fechaCobranza,
      bancoCobranza,
      numeroTransferenciaCobranza
      },httpOptions)
   }

   getComprobantePagoFacturaMultiple(numero_comprobante,id_usuario): Observable<any>{
    return this.http.get(`${this.urlRest.urlapiNode}/cobranza/comprobante/${numero_comprobante}/${id_usuario}`,{ responseType: 'blob'})
  }

  getListadoFacturas(cliente:number,tipoListado:string,limitarPeriodo:boolean,periodoDesde:string,periodoHasta:string): Observable<any>{
    return this.http.post(`${this.urlRest.urlapiNode}/facturas/listado/adeudadas/pagas`,{cliente,tipoListado,limitarPeriodo,periodoDesde,periodoHasta},{ responseType: 'blob'})
  }


  imprimirFactura(cliente:number,facturas:string[]):Observable<any>{
    return this.http.post(`${this.urlRest.urlapiNode}/facturas/imprimir`,{cliente,facturas},{ responseType: 'blob'})
  }

  imprimirFacturasGeneral(tipoImpresion:string,sociosAdheridosDebitoAutomatico:boolean,barrio:string,incluirZonasABarrio:boolean,zona:string,ordenarPor:string,periodoDesde:string,incluirPeriodoHasta:boolean,periodoHasta:string,incluirFacturasPagas:boolean
    ,sociosAdheridosAFacturaDigital:boolean,imprimirSinInteresPorVencimiento:boolean):Observable<any>{
    return this.http.post(`${this.urlRest.urlapiNode}/facturas/imprimir-new`,{tipoImpresion,sociosAdheridosDebitoAutomatico,barrio,incluirZonasABarrio,zona,ordenarPor,periodoDesde,incluirPeriodoHasta,periodoHasta,incluirFacturasPagas,sociosAdheridosAFacturaDigital,imprimirSinInteresPorVencimiento},{responseType: 'blob'})
  }

  facturasAnular(facturas:string[],observacion:string,cliente:number,usuarioAutorizante:string):Observable<any>{
    return this.http.put(`${this.urlRest.urlapiNode}/facturas/anular`,{facturas,observacion,cliente,usuarioAutorizante},httpOptions)
   }

   impactarEnFacturasPagoAnticipadoPorFueraDeSistema():Observable<string>{
    return this.http.put<string>(`${this.urlRest.urlapiNode}/facturas/pago/anticipado/impactar`,httpOptions)
  }

  impactarAcuerdoOM2312(periodo1:string,periodo2:string):Observable<string>{
    return this.http.post<string>(`${this.urlRest.urlapiNode}/facturacion/concepto/acuerdo2312`,JSON.stringify({periodo1,periodo2}),httpOptions)
  }

  impactarCobroMedidor(periodo1:string,periodo2:string):Observable<string>{
    return this.http.post<string>(`${this.urlRest.urlapiNode}/facturacion/concepto/medidor`,JSON.stringify({periodo1,periodo2}),httpOptions)
  }

  generarFacturacion(periodos:any[],habilitadoFacturarServicioMedidoField:boolean):Observable<any>{
    return this.http.post(`${this.urlRest.urlapiNode}/facturacion`,{periodos,incluirServicioMedido:habilitadoFacturarServicioMedidoField})
  }

 /*  generarFacturacionMensual(periodo:any):Observable<any>{
    return this.http.post(`${this.urlRest.urlapiNode}/facturacion/mensual`,{periodo},{
      reportProgress: true,
      observe: 'events'
    })
  } */

  generarFacturacionMensual(periodo:any):Observable<any>{
    return this.http.post(`${this.urlRest.urlapiNode}/facturacion/mensual`,{periodo})
  }


  // reportes para el resumen de facturacion

  getResumenGeneralPorConceptos(periodo:string,division:number,filtroDivision:number):Observable<any>{
    return this.http.get(`${this.urlRest.urlapiNode}/reportes/resumen/facturacion/conceptos/pdf/${periodo}/${division}/${filtroDivision}`,{responseType: 'blob'})
  }

  getResumenGeneralPorTarifa(periodo:string):Observable<any>{
    return this.http.get(`${this.urlRest.urlapiNode}/reportes/resumen/facturacion/tarifa/pdf/${periodo}`,{responseType: 'blob'})
  }

  getResumenGeneralPorBarrio(periodo:string):Observable<any>{
    return this.http.get(`${this.urlRest.urlapiNode}/reportes/resumen/facturacion/barrio/pdf/${periodo}`,{responseType: 'blob'})
  }

  getResumenGeneralSubdiario(periodo:string,division:number,filtroDivision:number):Observable<any>{
    return this.http.get(`${this.urlRest.urlapiNode}/reportes/resumen/facturacion/subdiario/pdf/${periodo}/${division}/${filtroDivision}`,{responseType: 'blob'})
  }

  getResumenGeneralFacturacionBarrioZona(periodo:string,division:number,filtroDivision:number):Observable<any>{
    return this.http.get(`${this.urlRest.urlapiNode}/reportes/resumen/facturacion/tarifa/barrio/zona/pdf/${periodo}/${division}/${filtroDivision}`,{responseType: 'blob'})
  }


  getListadoEstadoDeudaBarrioZona(periodo:string,division:number,filtroDivision:number):Observable<any>{
    return this.http.get(`${this.urlRest.urlapiNode}/reportes/resumen/estado-factura/periodo/barrio/zona/pdf/${periodo}/${division}/${filtroDivision}`,{responseType: 'blob'})
  }




}
