import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Store } from '@ngxs/store';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { GetCalles, SaveCalles, UpdateCalles, DeleteCalles } from 'src/app/store/general/actions';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { GeneralService } from '../general.service';
import { OperacionSiNoComponent } from 'src/app/shared/operacion-si-no/operacion-si-no.component';
import Swal from 'sweetalert2';
declare var $: any;

@Component({
  selector: 'app-calle',
  templateUrl: './calle.component.html',
  styleUrls: ['./calle.component.css']
})
export class CalleComponent implements OnInit, OnDestroy {

  @ViewChild('agGridCalles', { static: true }) agGridCalles: any;

  @ViewChild(OperacionSiNoComponent, { static: true })
  operacionSiNoComponent: OperacionSiNoComponent;

  destroy$: Subject<boolean> = new Subject<boolean>();

  public filtroText = "";



  columnDefs = [
    { headerName: 'Código', field: 'id', sortable: true, filter: true },
    { headerName: 'Calle', field: 'detalle', sortable: true, filter: true }
  ];

  rowData: any;

  calleForm: UntypedFormGroup;
  submitted = false;

  modo: number = 0; // 0- seleccion 1-edicion 2-alta

  errorCalleRegistrada = false;

  constructor(private gs: GeneralService, private store: Store, private formBuilder: UntypedFormBuilder) {
  }

  ngOnInit() {

    this.store.select(state => state.general.calles).pipe(takeUntil(this.destroy$)).subscribe(
      data => { this.rowData = data; })
    this.store.dispatch(new GetCalles());

    this.initForm();
  }

  initForm() {

    this.calleForm = this.formBuilder.group({
      codigo: [{ value: '', disabled: true }, Validators.required],
      calle: [{ value: '', disabled: true }, Validators.required]
    });
  }

  onFilterTextBoxChanged() {
    this.agGridCalles.api.setQuickFilter(this.filtroText);
  }

  onSelectionChanged() {
    var selectedRows = this.agGridCalles.api.getSelectedRows();

    this.calleForm.get('codigo').setValue(selectedRows[0].id)
    this.calleForm.get('calle').setValue(selectedRows[0].detalle)
    this.calleForm.get('codigo').disable()
    this.calleForm.get('calle').disable()
    this.modo = 0;

  }
  nuevo() {
    this.calleForm.get('codigo').setValue('')
    this.calleForm.get('calle').setValue('')
    this.calleForm.get('codigo').enable()
    this.calleForm.get('calle').enable()
    this.modo = 2;
  }

  editar() {
    this.calleForm.get('codigo').disable()
    this.calleForm.get('calle').enable()
    this.modo = 1;
  }

  eliminar() {

    if (this.modo == 0 && +this.calleForm.get('codigo').value > 0) {
      this.operacionSiNoComponent.open();
    }

  }


  onSubmit() {
    this.submitted = true;
    this.errorCalleRegistrada = false;

    if (this.calleForm.valid) {
      if (this.modo == 2) {
        this.guardar()

      } else {
        this.actualizar()
      }

    }
  }

  guardar() {
    this.gs.callesChequearExistencia(this.calleForm.get('codigo').value).subscribe(
      result => {

        if (!result.existe) {
          this.store.dispatch(new SaveCalles(this.calleForm.get('codigo').value, this.calleForm.get('calle').value)).subscribe(
            _ => {
              Swal.fire(
                '','La calle ha sido dado de alta de manera exitosa!',
                'success'
              )
              this.refrescarCalles()
            }
          )

        } else {
          this.errorCalleExistente();
        }
      }
    )
  }

  actualizar() {
    this.store.dispatch(new UpdateCalles(this.calleForm.get('codigo').value, this.calleForm.get('calle').value)).subscribe(
      _ => {
        Swal.fire(
          '','La calle ha sido actualizada de manera exitosa!',
          'success'
        )
        this.refrescarCalles()
      }
    )
  }


  retornoOperacionSiNo(retorno) {

    if (retorno) {
      this.store.dispatch(new DeleteCalles(this.calleForm.get('codigo').value)).subscribe(
        _ => {


          Swal.fire(
            '','La calle ha sido eliminada de manera exitosa!',
            'success'
          )
          this.calleForm.get('codigo').setValue('')
          this.calleForm.get('calle').setValue('')
          this.refrescarCalles()

        }
      )

    }

  }

  refrescarCalles() {
    this.calleForm.get('codigo').disable()
    this.calleForm.get('calle').disable()

    this.store.dispatch(new GetCalles());
    this.modo = 0;
  }


  errorCalleExistente() {
    const elemt = '[formControlName=codigo]'
    $(elemt)[0].focus();
    this.errorCalleRegistrada = true;
    Swal.fire(
      '','El código de la calle ingresado ya se encuentra registrado!',
      'error'
    )
  }

  cancelar() {
    this.modo = 0;
    this.calleForm.get('codigo').setValue('')
    this.calleForm.get('calle').setValue('')
    this.calleForm.get('codigo').disable()
    this.calleForm.get('calle').disable()
  }



  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}
