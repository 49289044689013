export class Utils {

    constructor(){}

    calcularResumenListado(facturas){
    
        let  montoTotal = 0
        let  montoRecargo = 0
         
           facturas.forEach(factura=> {
             montoTotal += +factura.Total;  
             montoRecargo += (+factura.Recargo) + (+factura.IVARec); 
           });
         
       
           montoTotal = +montoTotal.toFixed(2)
           montoRecargo = +montoRecargo.toFixed(2)
     
           return {montoTotal,montoRecargo}
       
       }
}
