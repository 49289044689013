
    <div class="card  mt-3">
      <div class="card-header card-header-info">
        <b>Seleccionadas para {{modoImputacion?'imputar':'pagar'}}</b>
      </div>
      <div class="card-body" style="padding: 0px;">
      <table class="table table-responsive text-center" style="margin: 0px">
          <tr>
            <td>Seleccionadas</td>
            <td>Total recargo incluido</td>
            <td>Recargo</td>
          </tr>
          <tr>
            <td class="text-muted">
              <h5>{{seleccionadasAPagar }}</h5>
            </td>
            <td class="text-muted">
              <h5>${{totalAPagarEnFacturas | formatDecimal}}</h5>
            </td>
            <td class="text-muted">
              <h5>${{recargoAPagar | formatDecimal}}</h5>
            </td>
          </tr>
        </table>

        <table class="table table-responsive text-center" *ngIf="descuentoSobreRecargo>0">
          <tr>
            <td class="no-border-top text-muted">Recargo con {{descuentoSobreRecargo}} % de descuento<br></td>
            <td class="text-center text-muted"><b>${{descuentoTotal | formatDecimal}}</b></td>
            <td class="no-border-top text-muted">Descuento aplicado</td>
            <td class="text-center text-muted"><b>${{descuentoEfectivo | formatDecimal}}</b></td>
          </tr>
        </table>

      </div>
      <div class="card-footer" style="padding: 0px; text-align: center;">
        <ng-container *appRole="permisoModoRequeridoHabilitadoDescuentoSobreIntereses" >
          <button  [disabled]="recargoAPagar==0" *ngIf="descuentoSobreRecargo==0 && !modoImputacion"  type="button" class="btn btn-outline-info btn-sm" data-toggle="modal" data-target="#descuentoModal"><i class="fas fa-dollar-sign"></i> &nbsp;Aplicar descuento</button>
        </ng-container>
        
        <button *ngIf="descuentoSobreRecargo>0" type="button" class="btn btn-outline-danger btn-sm" (click)="quitarDescuento()"><i class="fas fa-dollar-sign"></i> &nbsp;Quitar descuento</button>
    </div>
    </div>


  <div class="modal fade" id="descuentoModal" tabindex="-1" role="dialog" aria-labelledby="descuentoModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="descuentoModalLabel">Descuento sobre recargo</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form [formGroup]="descuentoRecargoForm" (ngSubmit)="aplicarDescuento()" >
        <div class="modal-body">

                <div class="form-group">
                    <label for="recargoField">Ingrese el descuento a aplicar sobre el recargo</label>
                    <input type="text" class="form-control" id="recargoField" aria-describedby="descuentoHelp" placeholder="%" autocomplete="off" formControlName="recargo" [ngClass]="{ 'is-invalid': descuentoRecargoForm.controls['recargo'].errors }" appNumberDecimalOnly autofocus>
                    <small class="form-text text-muted" id="descuentoHelp">descuento en %</small>
                    <div *ngIf="descuentoRecargoForm.controls['recargo'].errors" class="invalid-feedback">
                      <div *ngIf="descuentoRecargoForm.controls['recargo'].errors.required">Debe ingresar el descuento que desea aplicar</div>
                  </div>
                  </div>


        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
          <button [disabled]="!descuentoRecargoForm.valid" type="submit" class="btn btn-primary" >Aceptar</button>
        </div>
      </form>
      </div>
    </div>
  </div>
