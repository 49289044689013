import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CobranzaMultipleComponent } from './cobranza-multiple/cobranza-multiple.component';
import { FacturaModule } from '../factura/factura.module';
import { SharedModule } from '../shared/shared.module';
import { ListadoComprobantesComponent } from './listado-comprobantes/listado-comprobantes.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AutorizacionModule } from '../autorizacion/autorizacion.module';
import { AgGridModule } from 'ag-grid-angular';
import { ButtonRendererComponent } from './listado-comprobantes/render/button-renderer.component';
import { ControlCobranzaComponent } from './control-cobranza/control-cobranza.component';
import { RouterModule } from '@angular/router';
import { ResumenDiarioComponent } from './reportes/resumen-diario/resumen-diario.component';
import { CobranzaSimpleComponent } from './cobranza-simple/cobranza-simple.component';
import { ControlRecaudacionComponent } from './reportes/control-recaudacion/control-recaudacion.component';

const routes=[{
  path:'control',
  component:ControlCobranzaComponent
},
{path:'control-recaudacion',
  component:ControlRecaudacionComponent}
  ,
{path:'resumen-diario',
  component:ResumenDiarioComponent}
  ,
{path:'factura',
  component:CobranzaSimpleComponent}
]

@NgModule({
  declarations: [CobranzaMultipleComponent, ListadoComprobantesComponent,ButtonRendererComponent, ControlCobranzaComponent, ResumenDiarioComponent, CobranzaSimpleComponent, ControlRecaudacionComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FacturaModule,
    SharedModule,
    FormsModule,
    AutorizacionModule,
    AgGridModule,
   ReactiveFormsModule
  ],
  exports:[CobranzaMultipleComponent]
})
export class CobranzaModule { }
