import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { GeneralService } from './general.service';
import { Observable } from 'rxjs';
import { ItemsLista, ItemsListaConFin } from '../store/general/models';
import { BarrioService } from '../barrio/barrio.service';
import { Barrio } from '../store/barrio/models';

@Injectable({
  providedIn: 'root'
})
export class GeneralCallesResolveService {

  constructor(private gs: GeneralService) {}

  resolve(): Observable<ItemsLista[]> {
    return this.gs.getCalles();
  }
}

@Injectable({
  providedIn: 'root'
})
export class GeneralBarriosResolveService {

  constructor(private bs: BarrioService) {}

  resolve(): Observable<Barrio[]> {
    return this.bs.getBarrios();
  }
}


@Injectable({
  providedIn: 'root'
})
export class GeneralBancosResolveService {

  constructor(private gs: GeneralService) {}

  resolve(): Observable<ItemsLista[]> {
    return this.gs.getBancos();
  }
}

@Injectable({
  providedIn: 'root'
})
export class GeneralSituacionEspecialResolveService {

  constructor(private gs: GeneralService) {}

  resolve(): Observable<ItemsListaConFin[]> {
    return this.gs.getSituacionesEspecial();
  }
}


