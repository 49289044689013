import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Barrio } from '../store/barrio/models';

@Injectable({
  providedIn: 'root'
})
export class BarrioService {

  constructor(private http:HttpClient) { }

  getBarrios(): Observable<Barrio[]>{
    return this.http.get<Barrio[]>(`${environment.urlapiNode}/barrios`)
  }

  nuevoBarrio(barrio:string):Observable<any>{
    return this.http.post(`${environment.urlapiNode}/barrios`,{barrio})
  }


  editarBarrio(idBarrio:number,barrio:string):Observable<any>{
    return this.http.put(`${environment.urlapiNode}/barrios`,{idBarrio,barrio})
  }

  deleteBarrio(idBarrio:number,barrio:string):Observable<any>{
    let params = new HttpParams()
      .set("idBarrio", idBarrio.toString())
      .set("barrio", barrio)

    return this.http.delete(`${environment.urlapiNode}/barrios`,{params})
  }


}
