export class GetObservaciones{
  static readonly type = '[Observaciones] GetObservaciones'
  constructor( public cliente:number, public update:boolean){}
}


export class ResetObservaciones{
  static readonly type = '[Observaciones] ResetObservaciones'
  constructor( ){}
}

