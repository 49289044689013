<ng-container [formGroup]="socioForm">
  <div id="debitoAutomatico"></div>
  <div class="card shadow mt-4">
    <div class="card-body">
      <p style="border-left: solid 5px #17a2b8; padding-left: 5px"><b>Débito automático</b></p>
      <hr>
      <fieldset class="p-2" style="border: solid 1px #ddd">
        <legend style="font-size: 16px">Información bancaria</legend>
        <div class="form-group row">
          <label for="inputDebitoAutomatico" class="col-sm-4 col-form-label col-form-label-sm  text-right">Adhesión al débito
            automático</label>
          <div class="col-1">
            <input type="checkbox"  maxlength="16" class="form-control form-control-sm" id="inputDebitoAutomatico"
              formControlName="debitoAutomatico" (change)="onSelectionAdhesionDebitoAutomaticoChange($event)"
              placeholder="">
          </div>
        </div>
        <div class="form-group row">
          <label for="bancosInput" class="col-sm-3 col-form-label col-form-label-sm  text-right">Banco</label>

          <div class="col">
            <input type="text" class="form-control form-control-sm" list="bancosInput"
              formControlName="bancoDebitoAutomatico" (change)="onSelectionBancoDebitoAutomaticoChange($event)"
              [ngClass]="{ 'is-invalid': isInvalidBancoDebitoAutomaticoField }" />
            <datalist id="bancosInput">
              <option *ngFor="let banco of bancos" [value]="banco.detalle">{{banco.detalle}}</option>
            </datalist>
            <div *ngIf="isInvalidBancoDebitoAutomaticoField" class="invalid-feedback">
              <div *ngIf="socioForm.controls['bancoDebitoAutomatico'].errors.required">Campo obligatorio</div>
              <div *ngIf="socioForm.controls['bancoDebitoAutomatico'].errors.esBancoValido">El banco seleccinado
                no se encuentra registrado</div>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label for="inputCbuCuentaDebitoAutomatico"
            class="col-sm-3 col-form-label col-form-label-sm  text-right">CBU/Cuenta</label>
          <div class="col-2">
            <input type="text" class="form-control form-control-sm"
              formControlName="codigoCuentaDebitoAutomatico" id="inputCbuCuentaDebitoAutomatico">
          </div>
          <span>_</span>
          <div class="col">
            <input type="text" class="form-control form-control-sm" formControlName="cbuCuentaDebitoAutomatico"
              maxlength="19" placeholder="" [ngClass]="{ 'is-invalid': isInvalidCbuCuentaDebitoAutomaticoField}"
              appNumberEnteroOnly>
            <div *ngIf="isInvalidCbuCuentaDebitoAutomaticoField" class="invalid-feedback">
              <div *ngIf="socioForm.controls['cbuCuentaDebitoAutomatico'].errors.required">Campo obligatorio
              </div>
              <div *ngIf="socioForm.controls['cbuCuentaDebitoAutomatico'].errors.esNumeroXdigitos">CBU debe
                estar compuesto por 22 dígitos en total</div>
            </div>
          </div>
        </div>


      <div class="row mt-5">
        <div class="col-md-12">
          <div class="justify-content-md-center">
            <div class="alert alert-info" role="alert">
              <p><i class="fas fa-exclamation-circle"></i> &nbsp;<small>Tenga en cuenta que se aplicará el débito automático siempre y cuando el socio se encuentre en la zona "DEBITO AUTOMATICO EN BANCO"! Modifique la zona en caso de ser necesario...</small></p>
            </div>
          </div>
        </div>

      </div>
      </fieldset>
      <br>
      <fieldset class="p-2" style="border: solid 1px #ddd">
        <legend style="font-size: 16px">Tarjeta Naranja</legend>
        <div class="form-group row">
          <label for="inputNumeroTarjetaNaranja" class="col-sm-3 col-form-label col-form-label-sm  text-right">Número de
            tarjeta</label>
          <div class="col">
            <input type="text"  maxlength="16" class="form-control form-control-sm" formControlName="numeroTarjetaNaranja"
              id="inputNumeroTarjetaNaranja" placeholder="" appNumberEnteroOnly>
          </div>
        </div>
        <div class="form-group row">
          <label for="inputFechaAltaTarjetaNaranja" class="col-sm-3 col-form-label col-form-label-sm  text-right">Fecha de
            alta</label>
          <div class="col">
            <input type="date" class="form-control form-control-sm" formControlName="fechaAltaTarjetaNaranja"
              id="inputFechaAltaTarjetaNaranja" placeholder="">
          </div>
        </div>
      </fieldset>
    </div>
  </div>

</ng-container>
