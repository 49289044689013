import { State, StateContext, Action } from "@ngxs/store";


import { Injectable } from '@angular/core';
import { SocioGestionService } from 'src/app/socio-gestion/socio-gestion.service';
import { GetObservaciones, ResetObservaciones } from './actions';
import { from } from "rxjs";



export class ObservacionStateModel {
  socio_observaciones: string;
  inicializado:boolean;
}



@State<ObservacionStateModel>({
  name: 'observacion',
  defaults: {
    socio_observaciones:null,
    inicializado:false
  }
})



@Injectable()
export class ObservacionState {

    constructor(private sgs: SocioGestionService) { }

    @Action(GetObservaciones)
    getObservaciones(ctx: StateContext<ObservacionStateModel>, action: GetObservaciones) {

      const state = ctx.getState()
      let seguir = false

      if(!action.update && !state.inicializado){seguir=true}
      if(action.update && state.inicializado){seguir=true}

      if(seguir){

        this.sgs.getClienteObservaciones(action.cliente).subscribe(
          data => {
                ctx.patchState({
                  socio_observaciones: data,
                  inicializado:true
              });
          }
      )
      }



    }




    @Action(ResetObservaciones)
    resetObservaciones({ patchState }: StateContext<ResetObservaciones>) {

        patchState({
          socio_observaciones:null,
          inicializado:false
        })

        return from([]);
    }


}
