import { Time } from '../utils/time';
import { SocioDetalle } from '../store/socio/models';

export class Socio {

    constructor(){}

    generarSocioUsuarioAltaEdicion(socioEntrada:any){


    const cliente = {
        'condicionRegistro': socioEntrada?.condicionRegistro,
        'codSocio': socioEntrada?.codigo,
        'subCodSocio': socioEntrada?.subcodigo,
        'nombre': socioEntrada.apellidoNombre,
        'tipoDocumento': socioEntrada.tipoDocumento,
        'documento': socioEntrada.documento,
        'cuit': (socioEntrada.tipoCuilCuit.length>0? `${socioEntrada.tipoCuilCuit}-`:'') +socioEntrada.numeroCuilCuit+ (socioEntrada.digitoVerificadorCuilCuit.length>0? `-${socioEntrada.digitoVerificadorCuilCuit}`:''),
        'iva': socioEntrada.iva,
        'categoria': socioEntrada.categoria,
        'telefonoFijo': socioEntrada.telefonoFijo,
        'telefonoCelular': socioEntrada.celular,
        'email': socioEntrada.email,
        'facturaElectronica': socioEntrada.facturaElectronica,
        'fechaAlta': Time.formatDate(socioEntrada.fechaDeAlta,"YYYY-MM-DD"),
        'estado': socioEntrada.estadoConexion,
        'finsuspension': socioEntrada.finEstadoConexion,
        'tarifa': socioEntrada.tarifa,
        'zona': socioEntrada.zona,
        'situacion': socioEntrada.situacionEspecial,
        'fecha_inicio_situacion_especial': Time.formatDate(socioEntrada.fechaInicioSituacionEspecial,"YYYY-MM-DD"),
        'fecha_fin_situacion_especial': Time.formatDate(socioEntrada.fechaFinSituacionEspecial,"YYYY-MM-DD"),
        'barrio': socioEntrada.id_barrioConexion,
        'codCalle': socioEntrada.codCalleConexion,
        'calle': socioEntrada.calleConexion,
        'numero': socioEntrada.numeroCalleConexion,
        'adicDomic': socioEntrada.pisoDepartamentoConexion,
        'distrito': socioEntrada.distritoElectoral,
        'observacionConexion': socioEntrada.observacionConexion,
        'provinciaLegal': socioEntrada.provinciaDomicilioLegal,
        'localidadLegal': socioEntrada.localidadDomicilioLegal,
        'codPostalLegal': socioEntrada.codigoPostalDomicilioLegal,
        'barrioLegal': socioEntrada.id_barrioDomicilioLegal,
        'calleLegal': socioEntrada.calleDomicilioLegal,
        'nroLegal': socioEntrada.numeroCalleDomicilioLegal,
        'adicDomicLegal': socioEntrada.pisoDepartamentoDomicilioLegal,
        'profesion': socioEntrada.profesion,
        'conyuge_nombre': socioEntrada.nombreConyuge,
        'conyuge_tipodoc': socioEntrada.tipoDocumentoConyuge,
        'conyuge_documento': socioEntrada.numeroDocumentoConyuge,
        'id_coopluz': socioEntrada.numeroSocioEnCooperativa,
        'fechaConvCoopLuz': Time.formatDate(socioEntrada.fechaDeAltaConvenio,"YYYY-MM-DD"),
        'nomenclatura': socioEntrada.nomenclaturaCatastral,
        'manzana': socioEntrada.manzanaCatastral,
        'lote': socioEntrada.loteCatastral,
        'ruta': socioEntrada.ruta,
        'orden': socioEntrada.orden,
        'medidor': socioEntrada.numeroMedidor,
        'marcaMedidor': socioEntrada.marcaMedidor,
        'lecturaAnt': socioEntrada.estadoMedidor,
        'fLecturaAnt': Time.formatDate(socioEntrada.ultimaLecturaMedidor,"YYYY-MM-DD"),
        'ubicServicio': socioEntrada.distanciaAMedianera,
        'medianera': socioEntrada.medianera,
        'tipoConexion': socioEntrada.tipoConexion,
        'distanciaARed': socioEntrada.distanciaLineaMunicipalRedTroncal,
        'profundidadRed': socioEntrada.profundidadRedTroncal,
        'tipoMaterial': socioEntrada.tipoMaterialRedTroncal,
        'diametroRed': socioEntrada.diametroRedTroncal,
        'debitoAutomatico': socioEntrada.debitoAutomatico,
        'banco': socioEntrada?.bancoDebitoAutomatico,
        'CBU': socioEntrada.codigoCuentaDebitoAutomatico + socioEntrada.cbuCuentaDebitoAutomatico || null,
        'nroTarjeta_TN': socioEntrada.numeroTarjetaNaranja,
        'fechaAlta_TN': Time.formatDate(socioEntrada.fechaAltaTarjetaNaranja,"YYYY-MM-DD")
    }

    return cliente;

    }


     nuevoSocioUsuario():SocioDetalle{
        const cliente = {
            id_socio:null,
            id_condicionRegistro:'',
            condicionRegistro:'',
            codSocio: '',
            subCodSocio: '',
            nombre: '',
            tipoDocumento: '',
            tipoDocumentoDetalle: '',
            documento: '',
            cuit: '',
            id_iva: '',
            iva: '',
            id_categoria:'',
            categoria: '',
            telefonoFijo: '',
            telefonoCelular: '',
            email: '',
            facturaElectronica: false,
            fechaAlta: '',
            id_estado:'',
            estado: '',
            finsuspension: '',
            id_tarifa: '',
            tarifa: '',
            requiereFin:false,
            id_zona: '',
            zona: '',
            id_situacion: '',
            situacion: '',
            requiereFinSituacionEspecial:false,
            fecha_inicio_situacion_especial:'',
            fecha_fin_situacion_especial:'',
            accion_a_realizar_situacion_especial:'',
            id_barrio: '',
            barrio: '',
            codCalle:'',
            calle: '',
            numero: '',
            adicDomic: '',
            distrito: '',
            observacionConexion:'',
            id_provinciaLegal: '',
            provinciaLegal: '',
            localidadLegal: '',
            codPostalLegal: '',
            id_barrioLegal: '',
            codCalleLegal: '',
            barrioLegal: '',
            calleLegal: '',
            nroLegal: '',
            adicDomicLegal: '',
            profesion: '',
            conyuge_nombre: '',
            conyuge_tipodoc: '',
            conyuge_tipodocDetalle: '',
            conyuge_documento: '',
            id_coopluz: '',
            fechaConvCoopLuz: '',
            nomenclatura: '',
            manzana: '',
            lote: '',
            ruta: '',
            orden: '',
            medidor: '',
            marcaMedidor: '',
            lecturaAnt: '',
            fLecturaAnt: '',
            ubicServicio: '',
            medianera: '',
            detalle_medianera:'',
            tipoConexion: '',
            detalle_conexion:'',
            distanciaARed: '',
            profundidadRed: '',
            id_material: '',
            tipoMaterial: '',
            diametroRed: '',
            debitoAutomatico: false,
            debAutPrestador:'',
            banco: '',
            CBU: '',
            nroTarjeta_TN: '',
            fechaAlta_TN: '',
            enprocjudicial:false,
            encodene:false,
            enveraz:false

        }

        return cliente;
    }



}
