import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Convenio } from '../store/convenio/convenio.model';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class ConvenioService {

  urlRest = environment;
  constructor(private http: HttpClient) { }

  getConveniosCliente(cliente:number):Observable<Convenio[]>{
    return this.http.get<Convenio[]>(`${environment.urlapiNode}/convenio/${cliente}`)
   }

   getTieneDeudaConveniosCliente(cliente:number):Observable<any>{
    return this.http.get(`${environment.urlapiNode}/convenio/adeuda/${cliente}`)
   }

   getTieneConveniosNoVolcadosCliente(cliente:number):Observable<any>{
    return this.http.get(`${environment.urlapiNode}/convenio/no-volcados/${cliente}`)
   }

  getValorMinimoCuota(cliente:number): Observable<any>{
    return this.http.get(`${this.urlRest.urlapiNode}/convenio/cuota/valor-minimo/${cliente}`)
  }

  getTotalAdeudadoPorConvenio(cliente:number): Observable<any>{
    return this.http.get(`${this.urlRest.urlapiNode}/convenio/deuda/${cliente}`)
  }

  getInformacionParaNuevoConvenio(cliente:number): Observable<any>{
    return this.http.get(`${this.urlRest.urlapiNode}/convenio/nuevo/informacion/${cliente}`)
  }

  nuevoConvenio(cliente:number, fechaConvenio, fechaCaja, caja:number, montoTotal:number, cuotas:number, montoValorCuotas:number,
    cuotasAbonadas:number, pseudoconvenio,convenioJudicial,tipoConvenio:number,descuentoSobreIntereses:number,
    montoCostoFinanciero:number,montoGastosAdministrativos:number,montoReconexion:number,facturas:any[]): Observable<any>{
    return this.http.post(`${this.urlRest.urlapiNode}/convenio`,{cliente, fechaConvenio, fechaCaja, caja, montoTotal, cuotas, montoValorCuotas, cuotasAbonadas, pseudoconvenio,convenioJudicial,tipoConvenio,descuentoSobreIntereses,montoCostoFinanciero,montoGastosAdministrativos,montoReconexion,facturas},httpOptions)
  }

  updateConvenio(cliente:number,convenio:number, cuotas:number, facturadas:number,observacion:string): Observable<any>{
    return this.http.put(`${this.urlRest.urlapiNode}/convenio`,{cliente,convenio, cuotas, facturadas,observacion},httpOptions)
  }


  deleteConvenio(convenio:number, usuarioAutorizante:number, observacion:string): Observable<any>{
    let params = new HttpParams()
    .set('convenio',convenio.toString())
    .set('usuarioAutorizante',usuarioAutorizante.toString())
    .set('observacion',observacion)
    return this.http.delete(`${this.urlRest.urlapiNode}/convenio`,{params})
  }


  imprimirConvenio(socio:number,domicilioReal:string,barrio:string,ciudadReal:string,importeDeuda:number,cantidadCuotas:number,valorCuota:number,fechaConvenio:string): Observable<any>{
    return this.http.post(`${this.urlRest.urlapiNode}/convenio/imprimir`,{socio,domicilioReal,barrio,ciudadReal,importeDeuda,cantidadCuotas,valorCuota,fechaConvenio},{responseType: 'blob'})
  }

  imprimirPagare(socio:number,domicilioReal:string,ciudadReal:string,importeDeuda:number): Observable<any>{
    return this.http.post(`${this.urlRest.urlapiNode}/convenio/imprimir/pagare`,{socio,domicilioReal,ciudadReal,importeDeuda},{responseType: 'blob'})
  }



}
