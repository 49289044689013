<div class="modal" tabindex="-1" role="dialog" [attr.id]="id" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{titulo}}</h5>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-2"><i class="fa fa-question-circle fa-3x"  style="color:cadetblue" aria-hidden="true"></i></div>
            <div class="col-md-8"> <p style="text-align: justify;" [innerHTML]="contenidoMensaje"></p></div>
        </div>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-danger" (click)="cancelar()" tabindex="1">No</button>
        <button type="button" class="btn btn-success" (click)="aceptar()"  #buttonAceptar>Si</button>
      </div>
    </div>
  </div>
</div>
