import { Component, OnInit } from '@angular/core';
import { ReciboService } from '../recibo-semestral.service';
import { ExportarArchivo } from 'src/app/shared/exportar';
import  moment from 'moment';
import { forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-recibo-semestral-generar-rendicion',
  templateUrl: './recibo-semestral-generar-rendicion.component.html',
  styleUrls: ['./recibo-semestral-generar-rendicion.component.css']
})
export class ReciboSemestralGenerarRendicionComponent implements OnInit {


  public txt = new ExportarArchivo();

  estaProcesando = false


  constructor(private rs:ReciboService) {
  }

  ngOnInit() {

  }


  generarArchivosDeRendicion(){
    this.estaProcesando =true

    const self = this;
    setTimeout(function(){
        self.generarArchivo()
    }, 2000)
  }

  generarArchivo(){

    let diaRefresh= moment().format("DD");

    let mes= moment().format("MM");
    let mesRefresh
    switch (mes) {
      case "10":
        mesRefresh= "A"
        break;
        case "11":
          mesRefresh= "B"
          break;
          case "12":
            mesRefresh= "C"
            break;
      default:
          mesRefresh= moment().format("M");
        break;
    }

    const generacionArchivo = forkJoin(this.rs.generarArchivoRendicionRedLinkRefresh(),this.rs.generarArchivoRendicionRedLinkControl());
    generacionArchivo.pipe(
     finalize(()=>
     { this.estaProcesando = false;
       Swal.fire(
        '','Los archivos han sido generados con éxito!',
        'success' )
       }
        )
    ).subscribe(archivos=>{ this.txt.exportarArchivo(archivos[0],'PDGI1'+mesRefresh+diaRefresh,'txt'),this.txt.exportarArchivo(archivos[1],'CDGI1'+mesRefresh+diaRefresh,'txt')})

}

}
