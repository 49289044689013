import { State, Action, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { PeriodoService } from 'src/app/periodo/periodo.service';
import { GetUltimoPeriodoFacturado, GetAniosPeriodoFacturado, GetPeriodosPorFacturacion, GetPeriodosFacturacionMensual } from './actions';
import { PeriodoPorFacturacion } from 'src/app/periodo/periodo.model';
import { Periodo } from './model';


export class PeriodoStateModel {
    ultimo_periodo_facturado:string;
    anios_periodo_facturado:string[];
    periodosPorFacturacion:PeriodoPorFacturacion[];
    periodosFacturacionMensual: Periodo[];
}


@State<PeriodoStateModel>({
    name: 'periodo',
    defaults: {
        ultimo_periodo_facturado:null   ,
        anios_periodo_facturado:[],
        periodosPorFacturacion:[],
        periodosFacturacionMensual:[]
    }
})

@Injectable()
export class PeriodoState {

    constructor(private ps: PeriodoService) { }



    @Action(GetUltimoPeriodoFacturado)
    getUltimoPeriodoFacturado({ patchState }: StateContext<PeriodoStateModel>) {
        this.ps.getUltimoPeriodo().subscribe(
            data=> {
                    patchState({
                        ultimo_periodo_facturado: data?.periodo||null
                    });

            }
        )
    }


    @Action(GetPeriodosPorFacturacion)
    getPeriodosPorFacturacion({ patchState }: StateContext<PeriodoStateModel>) {
      
        this.ps.getPeriodosPorFacturacion().subscribe(
            data=> {
                    patchState({
                      periodosPorFacturacion: data
                    });

            }
        )
    }





    @Action(GetAniosPeriodoFacturado)
    getAniosPeriodoFacturado({ patchState }: StateContext<PeriodoStateModel>) {
        this.ps.getAniosPeriodoFacturado().subscribe(
            data=> {
                    patchState({
                      anios_periodo_facturado: data
                    });

            }
        )
    }


    @Action(GetPeriodosFacturacionMensual)
    getPeriodosFacturacionMensual({ patchState }: StateContext<PeriodoStateModel>) {
        this.ps.getPeriodosFacturacionMensual().subscribe(
            data=> {
                    patchState({
                      periodosFacturacionMensual: data
                    });

            }
        )
    }







}
