import { Component, OnInit, ViewChild } from '@angular/core';
import { CobranzaService } from '../../cobranza.service';
import { HttpResponse } from '@angular/common/http';
import { Imprimir } from 'src/app/shared/imprimir';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { AuthState } from 'src/app/store/auth/state';
import { ExportarArchivo } from 'src/app/shared/exportar';
import { VisorImpresionComponent } from 'src/app/shared/visor-impresion/visor-impresion.component';

@Component({
  selector: 'app-resumen-diario',
  templateUrl: './resumen-diario.component.html',
  styleUrls: ['./resumen-diario.component.css']
})
export class ResumenDiarioComponent implements OnInit {

  @ViewChild('visorImpresion', { static: true }) visorImpresion:VisorImpresionComponent;


  imprimir: Imprimir = new Imprimir()

  formResumenDiario:UntypedFormGroup

  usuariologin:string

  loading:boolean =false
  loadingRecibo:boolean =false

  txt = new ExportarArchivo();

  constructor(private cs:CobranzaService, private fb:UntypedFormBuilder,private store:Store) {
    this.formResumenDiario = this.fb.group({
      periodoResumen: [ '1', Validators.required], //1-del dia, 2- entre dias
      fechaDesdeResumen: [ new Date().toISOString().substring(0, 10), Validators.required],
      fechaHastaResumen: [ {value:new Date().toISOString().substring(0, 10), disabled:true}  , Validators.required],
      tipoResumen: [ '1', Validators.required],   //1-cajas por separado, 2-todas las cajas
      origenResumen: [ '1', Validators.required], //1-todas las cobranzas, 2-lo cobrado por
      incluirExcel: [ false]
    })
  }

  ngOnInit(): void {
    this.usuariologin = this.store.selectSnapshot(AuthState.usuarioLogin);

    this.getPeriodoResumenField.valueChanges.subscribe(
      valor=> {
        if(valor==2){
          this.getFechaHastaResumenField.enable()}
        else{
            this.getFechaHastaResumenField.disable()
         }
      }
    )

  }



  getResumenDiario(event: Event) {
    event.preventDefault();
    if (this.formResumenDiario.valid) {
      this.loading = true;
       this.cs.getResumenDiarioPDF(
         this.getFechaDesdeResumenField.value, this.getFechaHastaResumenField.value,
         this.getPeriodoResumenField.value, this.getTipoResumenField.value, this.getOrigenResumenField.value
         )
      .subscribe(
        (contenido: HttpResponse<Blob>)=>{

          this.visorImpresion.imprimir(contenido)
            this.loading = false;

        },
        ()=>this.loading=false);

        if(this.getExcelField.value){
          this.generarExcel()
        }


    }
  }

  getDetallesRecibos(event: Event) {
    event.preventDefault();
    if (this.formResumenDiario.valid) {
      this.loadingRecibo = true;
       this.cs.getResumenDiarioRecibosPDF(
         this.getFechaDesdeResumenField.value, this.getFechaHastaResumenField.value,
         this.getPeriodoResumenField.value
         )
      .subscribe(
        (contenido: HttpResponse<Blob>)=>{

          this.visorImpresion.imprimir(contenido)

            this.loadingRecibo = false;

        },
        ()=>this.loadingRecibo=false);

    }
  }

  generarExcel(){
    this.cs.getResumenDiarioExcel(
      this.getFechaDesdeResumenField.value, this.getFechaHastaResumenField.value,
      this.getPeriodoResumenField.value, this.getTipoResumenField.value, this.getOrigenResumenField.value
      )
   .subscribe(
     (archivo: HttpResponse<Blob>)=>{
       this.txt.exportarArchivo(archivo,'resumen-diario-cobranzas','xlsx');
     });
  }




  get getPeriodoResumenField(){
    return this.formResumenDiario.get("periodoResumen");
  }
  get getFechaDesdeResumenField(){
    return this.formResumenDiario.get("fechaDesdeResumen");
  }

  get getFechaHastaResumenField(){
    return this.formResumenDiario.get("fechaHastaResumen");
  }


  get getExcelField() {
    return this.formResumenDiario.get("incluirExcel");
  }

  get getOrigenResumenField() {
    return this.formResumenDiario.get("origenResumen");
  }

get valorOrigenResumenIsInvalid(){
  return (this.getOrigenResumenField.errors && this.getOrigenResumenField.touched)
}

  get getTipoResumenField() {
    return this.formResumenDiario.get("tipoResumen");
  }

get valorTipoResumenIsInvalid(){
  return (this.getTipoResumenField.errors && this.getTipoResumenField.touched)
}


get valorFechaDesdeIsInvalid(){
  return (this.getFechaDesdeResumenField.errors && this.getFechaDesdeResumenField.touched)
}

get valorFechaHastaIsInvalid(){
  return (this.getFechaHastaResumenField.errors && this.getFechaHastaResumenField.touched)
}

}
