

    <div class="card card-accent-info" *ngIf="socio">
      <div class="card-body" *ngIf="socio">
        <div class="row">
          <div class="col-md-12">
            <h4><b>{{socio.nombre}}</b>
              <ng-container> - <small class="text-muted"> {{socio.codSocio}} / {{socio.subCodSocio}}</small>
              </ng-container>
            </h4>
          </div>
        </div>

        <div class="row mt-1">
          <div class="col-md-4">
            <div class="card shadow-sm mb-2">
              <div class="card-header card-header-info text-muted">
                Datos del {{socio.condicionRegistro}}  - <small>ID{{socio.id_socio}}</small>
                <a href="javascript:;" title="editar información" class=" float-right" (click)="editar('datosSocio')"><i
                    class="fas fa-user-edit"></i></a>
              </div>
              <div class="card-body" style="overflow: auto; padding: 0px;">
                <table class="table table-striped table-sm" style="margin: 0px">
                  <tbody>
                    <tr>
                      <td class="text-right"><b>Documento: </b></td>
                      <td>{{socio.tipoDocumentoDetalle}} {{socio.documento}}</td>
                    </tr>
                    <tr>
                      <td class="text-right"><b>Cuil/Cuit:</b> </td>
                      <td>{{socio.cuit}}</td>
                    </tr>
                    <tr>
                      <td class="text-right"><b>IVA: </b></td>
                      <td>{{socio.iva}}</td>
                    </tr>
                    <tr>
                      <td class="text-right"><b>Categoría: </b></td>
                      <td>{{socio.categoria}}</td>
                    </tr>
                    <tr>
                      <td class="text-right"><b>Tel. fijo: </b></td>
                      <td>{{socio.telefonoFijo}}</td>
                    </tr>
                    <tr>
                      <td class="text-right"><b>Tel. celular: </b></td>
                      <td>{{socio.telefonoCelular}}</td>
                    </tr>
                    <tr>
                      <td class="text-right"><b>Email: </b></td>
                      <td>{{socio.email}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="card shadow-sm mb-2">
              <div class="card-header card-header-info text-muted">
                Datos de conexión
                <a href="javascript:;" title="editar información" class=" float-right"
                  (click)="editar('datosConexion')"><i class="fas fa-user-edit"></i></a>
              </div>
              <div class="card-body" style="padding: 0px;">
                <table class="table table-striped table-sm" style="margin: 0px">
                  <tbody>
                    <tr>
                      <td class="text-right" width="30%"><b>Estado Conex.: </b></td>
                      <td colspan="2">{{socio.estado}}</td>
                    </tr>
                    <tr>
                      <td class="text-right"><b>Finaliza en: </b></td>
                      <td colspan="2">{{socio.finsuspension}}</td>
                    </tr>
                    <tr>
                      <td class="text-right"><b>Tarifa: </b></td>
                      <td colspan="2">{{socio.tarifa}}</td>
                    </tr>
                    <tr>
                      <td class="text-right"><b>Zona fact.: </b></td>
                      <td colspan="2">{{socio.zona}}</td>
                    </tr>
                    <tr>
                      <td class="text-right"><b>Situación esp. </b></td>
                      <td colspan="2">{{socio.situacion}}</td>
                    </tr>
                    <tr *ngIf="socio.fecha_inicio_situacion_especial">
                      <td class="text-right"><b>Fecha Inicio. </b></td>
                      <td >{{socio.fecha_inicio_situacion_especial | date:'dd/MM/yyyy'}}<b> - Fecha Fin. </b>{{socio.fecha_fin_situacion_especial | date:'dd/MM/yyyy'}}</td>
                      <td ><b>Acción:</b> {{socio.accion_a_realizar_situacion_especial}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="card shadow mb-2">

              <ng-container *ngIf="searchFacturasImpagas">
                <div class="card-body bg-warning">
                  <h5 class="card-title text-center text-white">Verificando estado de deuda <i
                      class='fa fa-refresh fa-spin ml-4'></i> </h5>
                </div>
              </ng-container>

              <ng-container *ngIf="!searchFacturasImpagas">
                <div class="card-body bg-danger" *ngIf="tieneFacturasAdeudadas; else sinFacturasAdeudadas">
                  <h5 class="card-title text-center text-white">Se registran facturas adeudas</h5>
                </div>
                <ng-template #sinFacturasAdeudadas>
                  <div class="card-body bg-info">
                    <h5 class="card-title text-center text-white">No se registran facturas adeudas</h5>
                  </div>
                </ng-template>

              </ng-container>

              <br>
              <table class="table table-bordered table-responsive">
                <tr>
                  <td>Factura Digital
                    <span class="badge badge-success" *ngIf="socio.facturaElectronica else adherirFacturaElectronica">
                      Adherido </span>
                    <ng-template #adherirFacturaElectronica>
                      <a href="javascript:;" (click)="editar('datosSocio')" class="badge badge-secondary"
                        title="Adherir">No adherido</a>
                    </ng-template>
                  </td>

                  <td>Débito Automático

                    <span class="badge badge-success" *ngIf="(socio.debitoAutomatico && +socio.id_zona==2)  else adherirDebito"> Adherido
                    </span>
                    <ng-template #adherirDebito>
                      <a href="javascript:;" (click)="editar('debitoAutomatico')" class="badge badge-secondary"
                        title="Adherir">No adherido</a>
                    </ng-template>
                  </td>
                  <td *ngIf="tieneConveniosNoVolcados$|async">
                    <h5><span class="badge rounded-pill convenioNoVolcado text-dark" title="El socio tiene convenios no volcados">NV</span></h5>
                   </td>
                  <td *ngIf="socio.encodene || socio.enveraz">
                   <h5><span class="badge rounded-pill bg-warning text-dark" title="El socio se encuentra registrado en CODENE o VERAZ">CV</span></h5>
                  </td>
                  <td *ngIf="socio.enprocjudicial">
                    <h5><span class="badge rounded-pill bg-danger" title="El socio se encuentra en proceso judicial">PJ</span></h5>
                  </td>
                </tr>

              </table>
            </div>

          </div>
        </div>
        <!--FIN DE ROW-->
        <div class="row mt-2">
          <div class="col-12">
            <div class="card-group text-center">


              <div class="card card-menu" [ngClass]="{'opcion-seleccionada':opcionSeleccionada === 0}">
                <a href="javascript:;" (click)="opcionSeleccionada=0">
                  <div class="card-body card-body-menu">
                    <h5><i class="fas fa-file-invoice-dollar" color="cadetblue"></i></h5>
                    <p class="card-text">Facturas</p>
                  </div>
                </a>
              </div>




              <div class="card card-menu" [ngClass]="{'opcion-seleccionada':opcionSeleccionada === 2}">
                <a href="javascript:;" (click)="opcionSeleccionada=2">
                  <div class="card-body card-body-menu">
                    <h5><i class="fas fa-dollar-sign" color="cadetblue"></i></h5>
                    <p class="card-text">Pago de facturas</p>
                  </div>
                </a>
              </div>


              <div class="card card-menu" [ngClass]="{'opcion-seleccionada':opcionSeleccionada === 3}">
                <a href="javascript:;" (click)="opcionSeleccionada=3">
                  <div class="card-body card-body-menu">
                    <h5><i class="fa fa-info-circle" color="cadetblue"></i></h5>
                    <p class="card-text">Informacion gral</p>
                  </div>
                </a>
              </div>


              <div class="card card-menu" [ngClass]="{'opcion-seleccionada':opcionSeleccionada === 4}">
                <a href="javascript:;" (click)="opcionSeleccionada=4">
                  <div class="card-body card-body-menu">
                    <h5><i class="fa fa-registered" color="cadetblue"></i></h5>
                    <p class="card-text">Semestres</p>
                  </div>
                </a>
              </div>


              <div class="card card-menu" [ngClass]="{'opcion-seleccionada':opcionSeleccionada === 7}">
                <a href="javascript:;" (click)="opcionSeleccionada=7">
                  <div class="card-body card-body-menu">
                    <h5><i class="fa fa-comments" color="cadetblue"></i></h5>
                    <p class="card-text">Observaciones</p>
                  </div>
                </a>
              </div>


              <div class="card card-menu" [ngClass]="{'opcion-seleccionada':opcionSeleccionada === 8}">
                <a href="javascript:;" (click)="opcionSeleccionada=8">
                  <div class="card-body card-body-menu">
                    <h5><i class="fa fa-money-check-alt" color="cadetblue"></i></h5>
                    <p class="card-text">Ctas Corrientes</p>
                  </div>
                </a>
              </div>

            <div class="card card-menu" [ngClass]="{'opcion-seleccionada':opcionSeleccionada === 11}">
                <a href="javascript:;" (click)="opcionSeleccionada=11">
                  <div class="card-body card-body-menu">
                    <h5><i class="fa fa-handshake" color="cadetblue"></i></h5>
                    <p class="card-text">Convenios</p>
                  </div>
                </a>
              </div>


              <div class="card card-menu" [ngClass]="{'opcion-seleccionada':opcionSeleccionada === 9}">
                <a href="javascript:;" (click)="opcionSeleccionada=9">
                  <div class="card-body card-body-menu">
                    <h5><i class="fa fa-hand-holding-usd" color="cadetblue"></i></h5>
                    <p class="card-text">Creditos</p>
                  </div>
                </a>
              </div>

              <div class="card card-menu" [ngClass]="{'opcion-seleccionada':opcionSeleccionada === 10}">
                <a href="javascript:;" (click)="opcionSeleccionada=10">
                  <div class="card-body card-body-menu">
                    <h5><i class="far fa-file" color="cadetblue"></i></h5>
                    <p class="card-text">Acciones</p>
                  </div>
                </a>
              </div>
              <div class="card card-menu" [ngClass]="{'opcion-seleccionada':opcionSeleccionada === 12}">
                <a href="javascript:;" (click)="imprimirLibreDeuda()">
                  <div class="card-body card-body-menu">
                    <h5><i class="far fa-file-alt" color="cadetblue"></i></h5>
                    <p class="card-text">Libre deuda</p>
                  </div>
                </a>
              </div>



            </div>
          </div>
        </div>
        <!--Fila del menu de opciones-->

        <!--FIN DE ROW 2-->



        <div class="row mt-4">
          <div class="col">
            <app-facturas *ngIf="opcionSeleccionada == 0"></app-facturas>
            <app-cobranza-multiple *ngIf="opcionSeleccionada == 2"></app-cobranza-multiple>
            <app-socio-informacion-gral *ngIf="opcionSeleccionada == 3"
              (operacionEditar)="editarInformacionGeneral($event)"></app-socio-informacion-gral>
            <app-recibo-semestral *ngIf="opcionSeleccionada == 4 && habilitarRecibosSemestrales"></app-recibo-semestral>
            <app-observaciones *ngIf="opcionSeleccionada == 7"></app-observaciones>
            <app-cuenta-corriente *ngIf="opcionSeleccionada == 8"></app-cuenta-corriente>
            <app-credito-socio *ngIf="opcionSeleccionada == 9"></app-credito-socio>
            <app-acciones *ngIf="opcionSeleccionada == 10"> </app-acciones>
         <app-convenio *ngIf="opcionSeleccionada == 11"></app-convenio>
          </div>
        </div>
      </div>
    </div>

   
    @if(socioIsLoading$|async ){
      <div class="row justify-content-md-center">
        <div class="alert alert-info" role="alert">
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Buscando ...
        </div>
      </div>
    }@else if (!socio) { 
      <div class="card">
        <div class="card-header" style="background-color:#17a2b8; color: white">Socio Gestión</div>
        <div class="card-body">
  
          <div class="row justify-content-md-center">
            <div class="alert alert-info" role="alert">
              El socio no se encuentra registrado ...
            </div>
          </div>
  
        </div>
      </div>
      }


    

  <br>

  <app-visor-impresion #visorImpresion [id]="'socioVisor'"></app-visor-impresion>

  <app-operacion-informacion [titulo]="'Socio con deuda'"
    [contenidoMensaje]="mensajeLibreDeuda"
    #informacionLibreDeuda [id]="'informacionLibreDeuda'"></app-operacion-informacion>
