<form [formGroup]="altaAccionForm" novalidate (ngSubmit)="save($event)" class="needs-validation">
<div class="card">
  <div class="card-header card-header-info">{{modoEdicion?'Editar':'Nueva'}} Subscripción de acciones</div>
  <div class="card-body">

      <div class="mb-1 row">
        <label for="inputFechaSubscripcion" class="col-sm-3 col-form-label col-form-label-sm">Fecha de suscripción</label>
        <div class="col-sm-5">
          <input type="date" class="form-control form-control-sm"
          [class.is-invalid]="valorFechaIsInvalid"
           id="inputFechaSubscripcion" formControlName="fechaSubscripcion">
        </div>
        <div *ngIf="valorFechaIsInvalid" class="col-sm-4">
          <div *ngIf="getFechaSubscripcionField.hasError('required')" class="invalid-feedback"> valor requerido!</div>
        </div>
      </div>
      <div class="mb-1 row">
        <label for="inputCuotas" class="col-sm-3 col-form-label col-form-label-sm">Cuotas</label>
        <div class="col-sm-5">
          <input type="text" class="form-control form-control-sm"
          [class.is-invalid]="cuotasIsInvalid"
          id="inputCuotas"
          maxlength="5" formControlName="cuotas"  appNumberEnteroOnly>
        </div>
        <div *ngIf="cuotasIsInvalid" class="col-sm-4">
          <div *ngIf="getCuotasField.hasError('required'); else mayorAcero" class="invalid-feedback"> valor requerido!</div>
          <ng-template #mayorAcero>
            <div class="invalid-feedback"> debe ser mayor a 0!</div>
          </ng-template>
        </div>
      </div>

      <div class="mb-1 row">
        <label for="inputValorCuotas" class="col-sm-3 col-form-label col-form-label-sm">Valor Cuotas</label>
        <div class="col-sm-5">
          <input type="text" class="form-control form-control-sm"
          [class.is-invalid]="valorCuotaIsInvalid"
          id="inputValorCuotas"
          maxlength="9" formControlName="valorCuota" appNumberDecimalOnly>
          <small id="valorCuotaHelp" class="form-text text-muted text-informacion">Utilizar <b>.</b> como separador decimal</small>
        </div>
        <div *ngIf="valorCuotaIsInvalid" class="col-sm-4">

            <div *ngIf="getValorCuotaField.hasError('required'); else mayorAceroVC" class="invalid-feedback"> valor requerido!</div>
            <ng-template #mayorAceroVC>
              <div class="invalid-feedback"> debe ser mayor a 0!</div>
            </ng-template>

        </div>

      </div>
      <div class="mb-1 row">
        <label for="inputImporteTotal" class="col-sm-3 col-form-label col-form-label-sm">Importe total</label>
        <div class="col-sm-5">
          <input type="text" class="form-control form-control-sm" id="inputImporteTotal"
          formControlName="importeTotal"
          >
        </div>
      </div>
      <div class="mb-1 row">
        <label for="inputCuotasAbonadas" class="col-sm-3 col-form-label col-form-label-sm">Cuotas Abonadas</label>
        <div class="col-sm-5">
          <input type="text" class="form-control form-control-sm"
          id="inputCuotasAbonadas"
          [class.is-invalid]="valorCuotasAbonadasIsInvalid"
          maxlength="5" formControlName="cuotasAbonadas" appNumberEnteroOnly>
        </div>
        <div *ngIf="valorCuotasAbonadasIsInvalid" class="col-sm-4">
          <div *ngIf="getCuotasAbonadasField.hasError('required'); else menorQue" class="invalid-feedback"> valor requerido!</div>
          <ng-template #menorQue>
            <div class="invalid-feedback"> supera el total de cuotas</div>
          </ng-template>
      </div>
      </div>

  </div>

  <div class="card-footer ">
    <div class="float-right">
      <button (click)="cancelar()" class="btn  btn-sm btn-outline-danger">Cancelar</button> &nbsp;
      <button class="btn btn-success btn-sm" type="submit" [disabled]="altaAccionForm.invalid">Guardar</button>
    </div>

  </div>



</div>

</form>


