import { Component, OnInit, Input, ViewChild, Output, EventEmitter, signal } from '@angular/core';
import { Store } from '@ngxs/store';
import { SocioDetalle } from 'src/app/store/socio/models';
import { Subject, Observable } from 'rxjs';
import { takeUntil, filter, tap, mergeMap } from 'rxjs/operators';
import  dayjs from 'dayjs'
import  "dayjs/locale/es"
import { OperacionSiNoComponent } from 'src/app/shared/operacion-si-no/operacion-si-no.component';
import { OperacionInformacionComponent } from 'src/app/shared/operacion-informacion/operacion-informacion.component';

import { SetCanonCobrado, SetCostoIntimacionCobrado } from 'src/app/store/convenio/actions';

import { ReciboSimpleService } from 'src/app/recibo-simple/recibo-simple.service';
import { ConvenioService } from '../../convenio.service';
import { HttpResponse } from '@angular/common/http';


@Component({
  selector: 'app-datos-convenio',
  templateUrl: './datos-convenio.component.html',
  styleUrls: ['./datos-convenio.component.css']
})
export class DatosConvenioComponent implements OnInit {

  @ViewChild('visorImpresion', { static: true }) visorImpresion;

  @ViewChild('siNoDatosConvenio', { static: false })
  operacionSiNoComponent: OperacionSiNoComponent;

  @ViewChild('informacionDatosConvenio', { static: true })
  operacionInformacionComponent: OperacionInformacionComponent;


  socio: SocioDetalle = null;
  destroy$: Subject<boolean> = new Subject<boolean>();

  @Input() fechaConvenio: string = ""
  @Input() importeDeuda: number = 0
  @Input() cantidadCuotas: number = 0
  @Input() valorCuota: number = 0
  @Input() desde: string = ''
  @Input() hasta: string = ''
  @Input() importeCanonMunicipal: number = 0
  @Input() importeIntimacion: number = 0

  @Output() salirEvent = new EventEmitter();

  fechaDesdeConvenio: string
  fechaHastaConvenio: string

  domicilioReal: string
  ciudadReal: string = 'Plottier'
  domicilioLegal: string
  ciudadLegal: string = 'Plottier'
  barrio: string

  
  mensajeRecibo: string = 'Ha sido cobrado el importe correspondiente al '
  mensajeReciboCanonMunicipal: string = 'Canon Municipal '
  mensajeReciboCostoIntimacion: string = 'Costo de Intimación '
  mensajeOperacionConsulta: string
  mensajePagare: string = 'Tenga en cuenta que la impresión de un pagaré es PERMANENTE, el número generado para el mismo no puede ser eliminado o reutilizado. <br><br> Desea continuar con la impresión del pagaré ?'
  mensaje: string
  operacionConsulta: number  // 1-Canon Municipal 2-Costo Intimacion 3-Pagaré
  ocultarImpresionReciboCostoIntimacion$: Observable<boolean>
  ocultarImpresionReciboCanonMunicipal$: Observable<boolean>
  


  isLoadingImpresionConvenio=signal(false)
  isLoadingImpresionPagare=signal(false)
  

  constructor(private store: Store, private rs:ReciboSimpleService,private cs:ConvenioService) { }

  ngOnInit(): void {
    this.store.select(state => state.socio.socio_detalle).pipe(takeUntil(this.destroy$)).subscribe(
      socio => {
      this.socio = socio;
        this.domicilioLegal = socio.calle + ' ' + (socio.numero || 'S/N');
        this.domicilioReal = socio.calle + ' ' + (socio.numero || 'S/N');
        this.barrio = socio.barrio
      }
    )

    this.store.select(state => state.periodo.ultimo_periodo_facturado).pipe(takeUntil(this.destroy$)).subscribe(
      periodo => this.setFechaDesdeHasta(periodo)
    )


    // verifico si los recibos estan cobrados o no
    this.ocultarImpresionReciboCanonMunicipal$= this.store.select(state => state.convenio.canonCobrado)
    this.ocultarImpresionReciboCostoIntimacion$= this.store.select(state => state.convenio.costoIntimacionCobrado)



  }

  setFechaDesdeHasta(periodo: string) {
    let anio = periodo.substring(0, 4)
    let mes = periodo.substring(4, 6)

    let fechaDesde = dayjs(`${anio}-${mes}-01`, "YYYY-MM-DD")
    this.fechaDesdeConvenio = fechaDesde.add(1, 'month').locale('es').format('MMMM [del] YYYY')
    this.fechaHastaConvenio = fechaDesde.add(this.cantidadCuotas, 'month').locale('es').format('MMMM [del] YYYY')
  }

  imprimirConvenio() {

    this.isLoadingImpresionConvenio.set(true)

      this.cs.imprimirConvenio(this.socio.id_socio,this.domicilioReal, this.barrio, this.ciudadReal,this.importeDeuda,
        this.cantidadCuotas, this.valorCuota,this.fechaConvenio)
        .pipe(tap( _ => this.isLoadingImpresionConvenio.set(false)   ))
        .subscribe((contenido: HttpResponse<Blob>)=>{
            this.visorImpresion.imprimir(contenido)    
          }  
       )

  }

  imprimirPagare() {
    this.mensaje = this.mensajePagare
    this.operacionConsulta = 3
    this.operacionSiNoComponent.open()
  }

  imprimirReciboCanonMunicipal() {
    this.mensaje = this.mensajeRecibo + this.mensajeReciboCanonMunicipal + '?'
    this.mensajeOperacionConsulta = this.mensajeReciboCanonMunicipal
    this.operacionConsulta = 1
    this.operacionSiNoComponent.open()
  }

  imprimirReciboCostoIntimacion() {
    this.mensaje = this.mensajeRecibo + this.mensajeReciboCostoIntimacion + '?'
    this.mensajeOperacionConsulta = this.mensajeReciboCostoIntimacion
    this.operacionConsulta = 2
    this.operacionSiNoComponent.open()
  }



  retornoOperacionConsultaRecibo(respuesta) {
    if (!respuesta) {
      if ([1, 2].includes(this.operacionConsulta)) { this.operacionInformacionComponent.open() }
    } else {
      
      switch (this.operacionConsulta) {
        case 1:
          this.guardarMostrarReciboCanon()
          break;
        case 2:
          this.guardarMostrarReciboIntimacion()
          break;
        case 3:
          this.mostrarPagare()
          break;
      }
    }


  }

  guardarMostrarReciboCanon() {

    //indico que el canon municipal fue cobrado
    this.store.dispatch(new SetCanonCobrado(true))
    //imprimo el recibo

    const recibo = {
      idSocio: this.socio.id_socio,
      nombreNoSocio: '',
      descripcion: 'Canon Municipal correspondiente al convenio de pago.-',
      importe: this.importeCanonMunicipal,
      fecha: this.fechaConvenio,
      neto: 0,
      noGravado: 0,
      IVA: 0,
      impactarEnCobranza:false
    }

    this.imprimirRecibo(recibo)

  }
  guardarMostrarReciboIntimacion() {

    this.store.dispatch(new SetCostoIntimacionCobrado(true))

    
    const recibo = {
      idSocio: this.socio.id_socio,
      nombreNoSocio: '',
      descripcion: 'Costo de la(s) intimación(es) mediante carta documento',
      importe: this.importeIntimacion,
      fecha: this.fechaConvenio,
      neto: 0,
      noGravado: 0,
      IVA: 0,
      impactarEnCobranza:true
    }

    this.imprimirRecibo(recibo)



  }


  imprimirRecibo(recibo){
    this.rs.saveReciboSimple(recibo).pipe(
      filter(reciboReturn => !!reciboReturn),    
      mergeMap((reciboReturn)=> this.rs.imprimirReciboSimple(reciboReturn.reciboId) )
    ).subscribe(
      (contenido: HttpResponse<Blob>)=>{
        this.visorImpresion.imprimir(contenido)    
      } 
    )
  }
  mostrarPagare() {    

    this.isLoadingImpresionPagare.set(true)

    this.cs.imprimirPagare(this.socio.id_socio,this.domicilioReal, this.ciudadReal,this.importeDeuda)
      .pipe(tap( _ => this.isLoadingImpresionPagare.set(false)   ))
      .subscribe((contenido: HttpResponse<Blob>)=>{
          this.visorImpresion.imprimir(contenido)    
        }  
     )

  }

  retornoOperacionInformacion(respuesta) {

  }

  salir(){
    this.salirEvent.emit()
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}

