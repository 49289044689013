
export class GetUltimoPeriodoFacturado{
    static readonly type = '[Periodo] GetUltimoPeriodoFacturado'
    constructor(){}
}

export class GetAniosPeriodoFacturado{
  static readonly type = '[Periodo] GetAniosPeriodoFacturado'
  constructor(){}
}

export class GetPeriodosPorFacturacion{
  static readonly type = '[Periodo] GetPeriodosPorFacturacion'
  constructor(){}
}

export class GetPeriodosFacturacionMensual{
  static readonly type = '[Periodo] GetPeriodosFacturacionMensual'
  constructor(){}
}

