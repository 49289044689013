<div class="card" style="margin-bottom: 15px">
  <div class="card-header filtro" style="background-color:#17a2b8; color: white">
   Planilla para control de recaudación
  </div>
  <div class="card-body">


      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-7">
            <form [formGroup]="formControlRecaudacion" (ngSubmit)="getControlRecaudacion($event)" novalidate >
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-7">
                      <fieldset class="p-2" style="border: solid 1px #ddd">
                        <legend style="font-size: 12px">Tipo de listado</legend>

                        <div class="form-check">
                          <input class="form-check-input" type="radio"  id="tipoListado1" value="1" formControlName="tipoListado">
                          <label class="form-check-label col-form-label-sm mr-4" for="tipoListado1">
                            Listado completo de todas las cajas
                          </label>
                        </div>

                        <div class="form-check" >
                          <input class="form-check-input" type="radio"  id="tipoListado2" value="2" formControlName="tipoListado">
                          <label class="form-check-label col-form-label-sm mr-4" for="tipoListado2">
                           Listado completo discriminado por caja
                          </label>
                        </div>
                        <div class="form-check" >
                          <input class="form-check-input" type="radio"  id="tipoListado3" value="3" formControlName="tipoListado">
                          <label class="form-check-label col-form-label-sm mr-4" for="tipoListado3">
                           Listado de una caja
                          </label>
                        </div>

                        <div class="form-group">
                          <label class="col-form-label col-form-label-sm"  for="cajaControl">Caja</label>
                          <select  class="form-control form-control-sm" id="cajaControl" formControlName="caja"
                          [ngClass]="{ 'is-invalid': valorCajaIsInvalid  }">
                            <ng-container *ngFor="let caja of cajas$|async">
                              <option [value]="caja.id">{{caja.id}} - {{caja.detalle}}</option>
                            </ng-container>
                        </select>
                        <div *ngIf="valorCajaIsInvalid" class="invalid-feedback">
                          <small>Valor requerido</small>
                        </div>
                        </div>

                      </fieldset>
                    </div>
                    <div class="col-md-5">
                      <div class="row">
                        <div class="col-md-12">
                          <fieldset class="p-2" style="border: solid 1px #ddd">
                            <legend style="font-size: 12px">Emitir listado del día</legend>
                            <div class="col-sm-10"><input type="date" class="col form-control form-control-sm"
                              id="idFechaControl" formControlName="fechaListado"
                              [ngClass]="{ 'is-invalid': valorFechaListadoIsInvalid  }"></div>
                              <div *ngIf="valorFechaListadoIsInvalid" class="invalid-feedback">
                                <small>Valor requerido</small>
                              </div>
                          </fieldset>
                        </div>

                      </div>
                      <div class="row">

                        <div class="col-md-12">
                          <fieldset class="p-2" style="border: solid 1px #ddd">
                            <legend style="font-size: 12px">Ordenar por ...</legend>
                            <div class="form-check" >
                              <input class="form-check-input" type="radio"  id="ordenarPor2" value="1" formControlName="ordenarPor">
                              <label class="form-check-label col-form-label-sm mr-4" for="ordenarPor2">
                               Socio
                              </label>
                            </div>
                            <div class="form-check" >
                              <input class="form-check-input" type="radio"  id="ordenarPor3" value="2" formControlName="ordenarPor">
                              <label class="form-check-label col-form-label-sm mr-4" for="ordenarPor3">
                               Factura
                              </label>
                            </div>
                          </fieldset>
                        </div>

                      </div>

                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-7">
                      <fieldset class="p-2 mt-3" style="border: solid 1px #ddd">
                        <legend style="font-size: 12px">Emitir listado de control de ...</legend>
                        <div class="form-check">
                          <input class="form-check-input" type="radio"  id="Radios3" value="1" formControlName="origenResumen">
                          <label class="form-check-label col-form-label-sm mr-4" for="Radios3">
                             Todas las cobranzas
                          </label>
                        </div>

                        <div class="form-check">
                          <input class="form-check-input" type="radio"  id="Radios4"  value="2" formControlName="origenResumen">
                          <label class="form-check-label col-form-label-sm mr-4" for="Radios4">
                              Lo cobrado por <b>{{usuariologin}}</b>
                          </label>
                        </div>
                      </fieldset>
                      </div>
                      <div class="col-md-5">
                        <fieldset class="p-2 mt-3" style="border: solid 1px #ddd; text-align: center;">
                          <button type="submit"  class="btn btn-sm btn-outline-primary mt-3 mb-3" [disabled]="loading || !formControlRecaudacion.valid">
                            <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                             generar resumen</button>
                        </fieldset>
                      </div>
                    </div>

                 </div>
                </div>
            </form>
          </div>
        </div>



  </div>
</div>


