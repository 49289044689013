import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  AbstractControl,
} from "@angular/forms";
import { Convenio } from "src/app/store/convenio/convenio.model";
import { Store } from "@ngxs/store";
import { SocioState } from "src/app/store/socio/states";
import { UpdateConvenio } from "src/app/store/convenio/actions";
import { mergeMap } from "rxjs/operators";
import { GetObservaciones } from "src/app/store/observacion/actions";
import Swal from "sweetalert2";

@Component({
  selector: "app-editar-convenio",
  templateUrl: "./editar-convenio.component.html",
  styleUrls: ["./editar-convenio.component.css"],
})
export class EditarConvenioComponent implements OnInit {
  @Output() edicionConvenioReturn = new EventEmitter();
  @Input() convenioAEditar: Convenio;

  @Input() observacionEdicion: string = null;

  convenioForm: UntypedFormGroup;



  constructor(
    private store: Store,
    private fb: UntypedFormBuilder
  ) {
    this.buildForm();
  }

  ngOnInit(): void {
    this.setearDatos();
  }

  buildForm() {
    this.convenioForm = this.fb.group(
      {
        fecha: [{ value: "", disabled: true }],
        importe: [{ value: "", disabled: true }],
        cuotas: [{ value: "", disabled: true }],
        valorCuota: [{ value: "", disabled: true }],
        facturadas: [{ value: "" }, Validators.required],
      },
      { validator: this.EsMenorQue }
    );
  }

  setearDatos() {
    this.convenioForm.controls.fecha.setValue(this.convenioAEditar.fecha);
    this.convenioForm.controls.importe.setValue(this.convenioAEditar.importe);
    this.convenioForm.controls.cuotas.setValue(this.convenioAEditar.cuotas);
    this.convenioForm.controls.valorCuota.setValue(
      this.convenioAEditar.valorcuota
    );
    this.convenioForm.controls.facturadas.setValue(
      this.convenioAEditar.facturadas
    );
  }

  get getFacturadasField() {
    return this.convenioForm.controls.facturadas;
  }

  get valorFacturadasIsInvalid() {
    return this.getFacturadasField.errors && this.getFacturadasField.touched;
  }

  editarConvenio(event: Event) {
    event.preventDefault();


    if (this.convenioForm.valid) {
      const cliente = this.store.selectSnapshot(SocioState.cliente);
      this.store
        .dispatch(
          new UpdateConvenio(
            cliente,
            this.convenioAEditar.idConvenio,
            this.convenioForm.controls.cuotas.value,
            +this.convenioForm.controls.facturadas.value,
            this.observacionEdicion
          )
        )
        .pipe(
          mergeMap(() =>
            this.store.dispatch(new GetObservaciones(cliente, true))
          )
        )
        .subscribe((_) => {
          Swal.fire(
            '','El convenio ha sido actualizado con éxito!',
            'success'
          ).then(()=>this.cancelar())
        });
    }
  }

  cancelar() {
    this.convenioForm.reset();
    this.edicionConvenioReturn.emit();
  }

  EsMenorQue(AC: AbstractControl) {
    let valorA = AC.get("cuotas").value || 0; // to get value in input tag
    let valorB = AC.get("facturadas").value || 0; // to get value in input tag
    if (+valorA < +valorB) {
      AC.get("facturadas").setErrors({ EsMenorQue: true });
    } else {
      return null;
    }
  }
}
