import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { PeriodoPorFacturacion } from './periodo.model';
import { Periodo } from '../store/periodo/model';


const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class PeriodoService {

  urlRest = environment;

  constructor(private http: HttpClient) { }


  getUltimoPeriodo(): Observable<any>{
    return this.http.get(`${this.urlRest.urlapiNode}/periodos/ultimo-facturado`)
    .pipe(
      filter(periodo => !!periodo)
    )
  }

  getAniosPeriodoFacturado(): Observable<any>{
    return this.http.get(`${this.urlRest.urlapiNode}/periodos/anios-facturado`)
  }

  getPeriodosPorFacturacion(): Observable<PeriodoPorFacturacion[]>{
    return this.http.get<PeriodoPorFacturacion[]>(`${this.urlRest.urlapiNode}/periodos/por-facturacion`)
  }

  getPeriodosFacturacionMensual(): Observable<Periodo[]>{
    return this.http.get<Periodo[]>(`${this.urlRest.urlapiNode}/periodos/facturacion-mensual`)
  }





}
