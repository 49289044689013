

<!-- Modal -->
<div class="modal fade" id="formControlSituacionEspecial" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header" style="padding: 5px 15px;">
        <h5 class="modal-title">Control Situacion Especial</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" *ngIf="socioSituacionEspecial">

      <div class="row" >
        <div class="col-md-4">  <label class="col-form-label col-form-label-sm"><b>Socio:</b> {{socioSituacionEspecial.codsocio}}/{{socioSituacionEspecial.subcodsocio}}</label>  </div>
        <div class="col-md-4"><label class="col-form-label col-form-label-sm"><b>Nombre:</b> {{socioSituacionEspecial.nombre}}</label></div>
        <div class="col-md-4"><label class="col-form-label col-form-label-sm"><b>Tarifa:</b> {{socioSituacionEspecial.tarifa}}</label></div>
      </div>
      <div class="row" >
        <div class="col-md-4"><label class="col-form-label col-form-label-sm"><b>Situacion Especial:</b> {{socioSituacionEspecial.situacion}}</label></div>
      </div>
      <div class="row">
        <div class="col-md-4"><label class="col-form-label col-form-label-sm"><b>Fecha Inicio:</b> {{socioSituacionEspecial.fechainicio | date:'dd/MM/yyyy'}}</label></div>
      </div>
      <div class="row">
        <div class="col-md-4"><label class="col-form-label col-form-label-sm"><b>Fecha Fin:</b> {{socioSituacionEspecial.fechafin | date:'dd/MM/yyyy'}}</label></div>
      </div>






      </div>
      <div class="modal-footer" style="padding: 5px 15px;">
        <div class="row modal-footer-row">
         <div class="col-md-6"><button type="button" class="btn btn-sm  btn-outline-danger" (click)="onQuitarSituacionEspecial()">Quitar situación especial</button></div>
         <div class="col-md-6"><button type="button" class="btn btn-sm btn-secondary float-right" data-dismiss="modal">Cancelar</button></div>
        </div>

      </div>
    </div>
  </div>
</div>


