import { Injectable } from '@angular/core';
import {Action, State, StateContext} from '@ngxs/store';
import { mergeMap } from 'rxjs/operators';
import { RolesPermisosService } from 'src/app/roles-permisos/roles-permisos.service';
import {  DeletePermiso, DeleteRol, EditPermiso, EditRol, GetPermisos,  GetRoles,  SavePermiso, SaveRol } from './actions';
import { Permiso, Rol } from './model';



export class RolesPermisosStateModel{
    permisos: Permiso[];   
    inicializado_permisos:boolean; 
    roles: Rol[];
    inicializado_roles:boolean;
}

@State<RolesPermisosStateModel>({
    name:'rolesPermisos',
    defaults:{
        permisos:[],
        inicializado_permisos:false,
        roles:[],
        inicializado_roles:false                
    }
})

@Injectable()
export class RolesPermisosState{
    constructor(private ps:RolesPermisosService){}

    
    @Action(GetPermisos)
    getPermisos(ctx: StateContext<RolesPermisosStateModel>, action:GetPermisos){

        const state = ctx.getState()
        let seguir = false
  
        if(!action.update && !state.inicializado_permisos){seguir=true}
        if(action.update && state.inicializado_permisos){seguir=true}

        if(seguir){
            return this.ps.getPermisos().subscribe(
                data=>{                    
                    ctx.patchState({
                        permisos: data,
                        inicializado_permisos:true
                    });
                }
            )
        }
        
    }

    @Action(SavePermiso)
    savePermiso(ctx: StateContext<RolesPermisosStateModel>, action:SavePermiso) {

      return this.ps.savePermiso(action.permiso)
      .pipe(
       mergeMap(()=> ctx.dispatch(new GetPermisos(true)))
     )

    }

    @Action(EditPermiso)
    editPermiso(ctx: StateContext<RolesPermisosStateModel>, action:EditPermiso) {

      return this.ps.editPermiso(action.idPermiso,action.permiso)
      .pipe(
       mergeMap(()=> ctx.dispatch(new GetPermisos(true)))
     )

    }

    @Action(DeletePermiso)
    deletePermiso(ctx: StateContext<RolesPermisosStateModel>, action:DeletePermiso) {

      return this.ps.deletePermiso(action.idPermiso, action.permiso)
      .pipe(
       mergeMap(()=> ctx.dispatch(new GetPermisos(true)))
     )

    }


    @Action(GetRoles)
    getRoles(ctx: StateContext<RolesPermisosStateModel>,action:GetRoles){

        const state = ctx.getState()
        let seguir = false
  
        if(!action.update && !state.inicializado_roles){seguir=true}
        if(action.update && state.inicializado_roles){seguir=true}

        if(seguir){ 
            return this.ps.getRoles().subscribe(
                data=>{
                ctx.patchState({
                        roles: data,
                        inicializado_roles:true
                    })
                }
            )
        }

        
    }

    @Action(SaveRol)
    saveRol(ctx: StateContext<RolesPermisosStateModel>, action:SaveRol) {
      return this.ps.saveRol(action.rol,action.permisos)
      .pipe(
       mergeMap(()=> ctx.dispatch(new GetRoles(true)))
     )

    }

    @Action(EditRol)
    editRol(ctx: StateContext<RolesPermisosStateModel>, action:EditRol) {
      return this.ps.editRol(action.idRol,action.rol,action.permisos)
      .pipe(
       mergeMap(()=> ctx.dispatch(new GetRoles(true)))
     )

    }

    @Action(DeleteRol)
    deleteRol(ctx: StateContext<RolesPermisosStateModel>, action:DeleteRol) {

      return this.ps.deleteRol(action.idRol)
      .pipe(
       mergeMap(()=> ctx.dispatch(new GetRoles(true)))
     )

    }


    
    
}