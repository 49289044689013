import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatNumber'
})
export class FormatNumberPipe implements PipeTransform {

  transform(value: any): any {

    let valor = value;

    if (valor !='-'){
      valor=parseInt(value).toLocaleString();
      if(valor.includes(',')){
        valor= valor.replace(',','.');
      }
    }

    if (valor == '0'){
      valor = '-';
    }

    return valor;
  }

}


@Pipe({
  name: 'formatDecimal'
})
export class FormatDecimalPipe implements PipeTransform {

  transform(value: any): any {
    let valor = value.toFixed(2);

    if (valor !='-'){

      //valor=parseInt(value).toLocaleString();
      if(valor.includes('.')){
        valor= valor.replace('.',',');
      }
    }



    return valor
  }

}


@Pipe({
  name: 'formatCurrency'
})
export class FormatCurrencyPipe implements PipeTransform {

  transform(value: any): any {

    let styleMoneda = {
      style: "currency",
      currency: "ARS"
    }



    return value.toLocaleString("es-AR",styleMoneda);
  }

}
