import { UntypedFormControl, ValidatorFn, AbstractControl, UntypedFormGroup } from '@angular/forms';
import  dayjs from 'dayjs'
export class Validacion {


  public isMayorCero(c: UntypedFormControl) {
    return +c.value>0 ? null : {
      isMayorCero: {
        valid: false
      }
    };
  }

  public isMayorIgualCero(c: UntypedFormControl) {
    return +c.value>=0 ? null : {
      isMayorCero: {
        valid: false
      }
    };
  }




  public esMes2digitos(c: UntypedFormControl) {
    let REGEXP = /01|02|03|04|05|06|07|08|09|10|11|12/

    return REGEXP.test(c.value) ? null : {
      esMes2digitos: {
        valid: false
      }
    };
  }

  public esSiNo(c: UntypedFormControl) {
    let REGEXP = /1|2/
    if(!c.value == false){
      return REGEXP.test(c.value) ? null : {
        esSiNo: {
          valid: false
        }
      };
    }else{

      return null
    }

  }

  public esNumeroXdigitos(numDigito: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {

      let exp = '^[0-9]{' + numDigito + '}$';
      let re = new RegExp(exp);
      const comparacion = re.test(control.value);

      return comparacion ? null : {
        esNumeroXdigitos: {
          valid: false
        }
      };
    };
  }

  public esValorEntre(valores: string[]): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {

      if (!control.value == false) {
        return valores.includes(control.value) ? null : {
          esValorEntre: {
            valid: false
          }
        };

      } else {
        return null;
      }
    };
  }

  public esNumeroEntero(c: UntypedFormControl) {
    if (!c.value == false) {
      let exp = '^[0-9]*$';
      let re = new RegExp(exp);
      const comparacion = re.test(c.value);
      return comparacion ? null : {
        esNumeroEntero: {
          valid: false
        }
      }
    } else {
      return null;
    }

  }

  public esNumeroDecimal(c: UntypedFormControl) {
    //permite hasta 2 digitos
    if (!c.value == false) {
      let exp = '^[0-9]+([.][0-9]{0,2})?$';
      let re = new RegExp(exp);
      const comparacion = re.test(c.value);
      return comparacion ? null : {
        esNumeroDecimal: {
          valid: false
        }
      }
    } else {
      return null;
    }

  }

  public esNumeroDecimalPositiveNegative(c: UntypedFormControl) {
    //permite hasta 2 digitos
    if (!c.value == false) {
      let exp = '^-?[0-9]+([.][0-9]{0,2})?$';
      
      let re = new RegExp(exp);
      const comparacion = re.test(c.value);
      return comparacion ? null : {
        esNumeroDecimal: {
          valid: false
        }
      }
    } else {
      return null;
    }

  }

  public esEmail(c: UntypedFormControl) {
    if (!c.value == false) {
      let exp = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
      let re = new RegExp(exp);
      const comparacion = re.test(c.value);
      return comparacion ? null : {
        esEmail: {
          valid: false
        }
      }
    } else {
      return null;
    }

  }





  public verificaTotalSuma(listaField: string[], total: string): ValidatorFn {
    return (form: UntypedFormGroup): { [key: string]: any } => {

      let totalSuma = 0;

      Object.keys(form.controls).forEach(field => {

        if (listaField.includes(field)) {
          const control = form.get(field);
          const valorControl = parseInt(control.value) || 0;

          totalSuma += valorControl;
        }
      });


      const valorTotal = parseInt(form.controls[total].value) || 0;
      return totalSuma == valorTotal ? null : {
        verificaTotalSuma: {
          valid: false
        }
      }

    };
  }


  public verificaTotalSumaControl(listaField: string[]): ValidatorFn {
    return (c: UntypedFormControl): { [key: string]: any } => {

      let totalSuma = 0;
      const group = c.parent;

      if (!group == false) {
        Object.keys(group.controls).forEach(field => {

          if (listaField.includes(field)) {
            const control = group.get(field);
            totalSuma += parseInt(control.value);
          }
        });

        return totalSuma == parseInt(c.value) ? null : {
          verificaTotalSumaControl: {
            valid: false
          }
        }
      } else {
        return null;
      }



    };
  }


  EsMenorQue(AC: AbstractControl) {

    let valorA = AC.get("periodoHasta").value; // to get value in input tag
    let valorB = AC.get("periodoDesde").value; // to get value in input tag
    if (+valorA < +valorB) {
      AC.get("periodoHasta").setErrors({ EsMenorQue: true });
    } else {
      return null;
    }
  }


 static EsMenorFecha(fromDateField: string, toDateField: string, errorName: string = 'esMenorFecha'): ValidatorFn {
      return (formGroup: AbstractControl): { [key: string]: boolean } | null => {

          const fromDate = formGroup.get(fromDateField).value;
          const toDate = formGroup.get(toDateField).value;

          if ((fromDate !== null && toDate !== null) && dayjs(fromDate).isAfter(dayjs(toDate),'day' )) {
              return {[errorName]: true};
          }
          return null;
      };
  }

  static EsValorDistinto(valueA: string, valueB: string, errorName: string = 'esValorDistinto'): ValidatorFn {
    return (formGroup: AbstractControl): { [key: string]: boolean } | null => {

        const valorA = formGroup.get(valueA).value;
        const valorB = formGroup.get(valueB).value;

        if ((valorA !== null && valorB !== null) && (valorA!==valorB) ) {
            return {[errorName]: true};
        }

        return null;
    };
}




  ///////// chequeos de error en campos ////////////////

  public chequearErrorPorCampoRequerido(form: UntypedFormGroup): boolean {
    let valorRetorno = false;
    const propiedades: string[] = Object.keys(form.controls);

    for (var field in propiedades) {

      const control = form.get(propiedades[field]);
      if (!control.valid) {
        if (!control.errors.required == false) {
          return true;
        }
      }

    }

    return false;
  }

  public chequearErrorPorCampoEntero(form: UntypedFormGroup): boolean {
    let valorRetorno = false;
    const propiedades: string[] = Object.keys(form.controls);

    for (var field in propiedades) {

      const control: any = form.get(propiedades[field]);

      if (!control.controls == false) {
        if (this.chequearErrorPorCampoEntero(control)) {
          return true;
        }
      } else {
        if (!control.valid) {
          if (!control.errors.esNumeroEntero == false) {
            return true;
          }
        }
      }

    }
    return false;
  }

  public chequearErrorPorCampoDecimal(form: UntypedFormGroup): boolean {
    let valorRetorno = false;
    const propiedades: string[] = Object.keys(form.controls);

    for (var field in propiedades) {

      const control: any = form.get(propiedades[field]);

      if (!control.controls == false) {
        if (this.chequearErrorPorCampoDecimal(control)) {
          return true;
        }
      } else {
        if (!control.valid) {
          if (!control.errors.esNumeroDecimal == false) {
            return true;
          }
        }
      }

    }
    return false;
  }




  ////////////// Mensajes  de errores///////////////

  // retorna una lista de los errores detectados
  obtenerMensajesError(c: AbstractControl): string {
    let mensajeError: string = "";

    if (!c.valid) {

      if (!c.errors.required == false) {
        mensajeError += "* El campo es obligatorio<br>"
      }

      if (!c.errors.esNumeroEntero == false) {
        mensajeError += "* Debe ingresar un número entero<br>"
      }

      if (!c.errors.esNumeroDecimal == false) {
        mensajeError += "* Debe ingresar un número decimal positivo, con hasta 2 decimales<br>"
      }

      if (!c.errors.esSiNo == false) {
        mensajeError += "* El valor ingresado no es válido<br>"
      }

      if (!c.errors.esEmail == false) {
        mensajeError += "* Dirección de email no válida<br>"
      }

      if (!c.errors.esValorEntre == false) {
        mensajeError += "* El valor ingresado no es válido<br>"
      }



      if (!c.errors.verificaTotalSumaControl == false) {
        mensajeError += "* Los valores ingresados no coinciden con el total<br>"
      }

    }
    return mensajeError

  }

}
