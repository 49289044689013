
<ng-container [formGroup]="socioForm">


<div id="lote"></div>
<div class="card shadow mt-4">
  <div class="card-body">
    <p style="border-left: solid 5px #17a2b8; padding-left: 5px"><b>Lote</b></p>
    <hr>
    <fieldset class="p-2" style="border: solid 1px #ddd">
      <legend style="font-size: 16px">Información catastral</legend>
      <div class="form-group row">
        <label for="inputNomenclaturaCatastral"
          class="col-sm-3 col-form-label col-form-label-sm  text-right">Nomenclatura</label>
        <div class="col">
          <input type="text" class="form-control form-control-sm" formControlName="nomenclaturaCatastral"
            id="inputNomenclaturaCatastral" maxlength="15" placeholder=""
            [ngClass]="{ 'is-invalid': isInvalidNomenclaturaCatastralField }" appNumberEnteroOnly>
          <div *ngIf="isInvalidNomenclaturaCatastralField" class="invalid-feedback">
            <div *ngIf="socioForm.controls['nomenclaturaCatastral'].errors.required">Campo obligatorio</div>
            <div *ngIf="socioForm.controls['nomenclaturaCatastral'].errors.esNumeroXdigitos">La nomenclatura
              catastral debe poseer 15 dígitos</div>
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label for="inputManzanaCatastral" class="col-sm-3 col-form-label col-form-label-sm  text-right">Manzana</label>
        <div class="col">
          <input type="text" class="form-control form-control-sm" formControlName="manzanaCatastral"
            maxlength="5" id="inputManzanaCatastral" placeholder=""
            [ngClass]="{ 'is-invalid':isInvalidManzanaCatastralField}">
          <div *ngIf="isInvalidManzanaCatastralField" class="invalid-feedback">
            Campo obligatorio
          </div>
        </div>
        <label for="inputLoteCatastral" class="col-sm-2 col-form-label col-form-label-sm  text-right">Lote</label>
        <div class="col">
          <input type="text" class="form-control form-control-sm" formControlName="loteCatastral"
            maxlength="5" id="inputLoteCatastral" placeholder=""
            [ngClass]="{ 'is-invalid': isInvalidLoteCatastralField }">
          <div *ngIf="isInvalidLoteCatastralField" class="invalid-feedback">
            Campo obligatorio
          </div>
        </div>
      </div>
    </fieldset>
    <br>
    <fieldset class="p-2" style="border: solid 1px #ddd">
      <legend style="font-size: 18px">Ruta</legend>
      <div class="form-group row">
        <label for="inputRuta" class="col-sm-3 col-form-label col-form-label-sm  text-right">Ruta numero.</label>
        <div class="col">
          <input type="text" maxlength="4" class="form-control form-control-sm" formControlName="ruta"
            id="inputRuta" placeholder="">
        </div>
      </div>
      <div class="form-group row">
        <label for="inputOrden" class="col-sm-3 col-form-label col-form-label-sm  text-right">Orden</label>
        <div class="col">
          <input type="text" maxlength="4" class="form-control form-control-sm" formControlName="orden"
            id="inputOrden" placeholder="">
        </div>
      </div>
    </fieldset>
  </div>
</div>
</ng-container>
