import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';

import { ListadoFacturasComponent } from './listado-facturas/listado-facturas.component';
import { AgGridModule } from 'ag-grid-angular';
import { ResumenTotalesComponent } from './resumen-totales/resumen-totales.component';
import { ResumenSelecccionComponent } from './resumen-selecccion/resumen-selecccion.component';
import { ResumenPagoComponent } from './resumen-pago/resumen-pago.component';
import { InformacionCajaComponent } from './informacion-caja/informacion-caja.component';
import { ImpresionListadoComponent } from './impresion-listado/impresion-listado.component';
import { FacturasComponent } from './facturas/facturas.component';
import { AutorizacionModule } from '../autorizacion/autorizacion.module';
import { ProcesoDeFacturacionComponent } from './proceso-de-facturacion/proceso-de-facturacion.component';
import { Routes, RouterModule } from '@angular/router';
import { ImprimirFacturasComponent } from './imprimir-facturas/imprimir-facturas.component';
import { ResumenFacturacionComponent } from './reportes/resumen-facturacion/resumen-facturacion.component';
import { DeudaPorPeriodoComponent } from './reportes/resumen-deuda/deuda-por-periodo/deuda-por-periodo.component';
import { ProcesoDeFacturacionMensualComponent } from './proceso-de-facturacion-mensual/proceso-de-facturacion-mensual.component';
import { ExitGuard } from '../guards/exit.guard';

const routes:Routes=[
  {path:'proceso-de-facturacion', canDeactivate: [ExitGuard],component:ProcesoDeFacturacionComponent},
  {path:'proceso-de-facturacion-mensual', canDeactivate: [ExitGuard], component:ProcesoDeFacturacionMensualComponent},
  {path:'imprimir', component:ImprimirFacturasComponent},
  {path:'resumen-facturacion', component:ResumenFacturacionComponent},
  {path:'resumen-deuda-por-periodo', component:DeudaPorPeriodoComponent}
]

@NgModule({
  declarations: [ ListadoFacturasComponent, ResumenTotalesComponent, ResumenSelecccionComponent, ResumenPagoComponent, InformacionCajaComponent, ImpresionListadoComponent, FacturasComponent, ProcesoDeFacturacionComponent, ImprimirFacturasComponent, ResumenFacturacionComponent, DeudaPorPeriodoComponent, ProcesoDeFacturacionMensualComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    ReactiveFormsModule,
    SharedModule ,
    AgGridModule,
    AutorizacionModule
  ],
  exports:[ListadoFacturasComponent,ResumenTotalesComponent,ResumenSelecccionComponent,ResumenPagoComponent, InformacionCajaComponent,FacturasComponent]
})
export class FacturaModule { }
