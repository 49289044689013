import { FormControl, ValidatorFn, AbstractControl, UntypedFormGroup, FormGroup } from '@angular/forms';
import { ItemsLista } from 'src/app/store/general/models';
import { Barrio } from 'src/app/store/barrio/models';


export class ValidacionFormularioSocio {

    bancos: ItemsLista[];
    barrios: Barrio[];
    calles:ItemsLista[];



    constructor(){ }

    public setBancos(bancos){
      this.bancos=bancos
    }

    public setBarrios(barrios){
      this.barrios=barrios
    }
    public setCalles(calles){
      this.calles=calles
    }


    public esBarrioPlottierValido(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {

          if (!control.value == false && this.barrios.length>0 ) {
            const barrioSeleccionado = this.barrios.filter(
              barrio =>  barrio.barrio.toLowerCase() == control.value.toLowerCase()
              );
            return barrioSeleccionado.length >0 ? null : {
                esBarrioPlottierValido: {
                valid: false
              }
            };

          } else {
            return null;
          }
        };
      }

    public esCallePlottierValida(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {

          if (!control.value == false && this.calles.length>0) {
            const calleSeleccionada = this.calles.filter(calle => calle.detalle.toLowerCase() == control.value.toLowerCase());
            return calleSeleccionada.length >0 ? null : {
                esCallePlottierValida: {
                valid: false
              }
            };

          } else {
            return null;
          }
        };
      }

    public esBancoValido(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {

          if (!control.value == false && this.bancos.length>0) {
            const bancoSeleccionado = this.bancos.filter(banco => banco.detalle.toLowerCase() == control.value.toLowerCase());
            return bancoSeleccionado.length >0 ? null : {
                esBancoValido: {
                valid: false
              }
            };

          } else {
            return null;
          }
        };
      }

      public esFacturaElectronicaValida(): ValidatorFn {
        return (control: UntypedFormGroup): { [key: string]: any } => {
          const envioFacturaElectronica = control.get('facturaElectronica');
          const mail = control.get('email');

         let valorRetorno = null;

          if (!envioFacturaElectronica.value == false) {

            if(envioFacturaElectronica.value){
              if(!mail.value==false && (mail.value=='' || mail.value.length<=0)){
                valorRetorno = {
                  esFacturaElectronicaValida: {
                  valid: false
                }
              }

            }

            };
        };
        return valorRetorno;
      }
    }

      public esSocioUsuarioNoExistente(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
          return null;
        };
      }

      static setErrorsInForm(form: UntypedFormGroup){
        const formControls = form.controls;
            for (const control in formControls) {
              if (formControls[control].invalid) {
                formControls[control].markAsTouched();
              }
            }
      }




}
