import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class SistemaConfiguracionService {


  urlRest = environment;

  constructor(private http: HttpClient) { }

  getEstaHabilitadoRecibosSemestrales():Observable<any>{
    return this.http.get(`${this.urlRest.urlapiNode}/configuracion/recibo-semestral/habilitado`).pipe(
      map(response =>  response[0])
    )
   }



   getInformacionRecargos():Observable<any>{
    return this.http.get(`${this.urlRest.urlapiNode}/configuracion/recargo/informacion`)
   }

   getProximoNumeroPagare():Observable<any>{
     return this.http.get(`${this.urlRest.urlapiNode}/configuracion/pagare/proximo-numero`)
    }

    updateProximoNumeroPagare():Observable<any>{
     return this.http.put(`${this.urlRest.urlapiNode}/configuracion/pagare/proximo-numero`,httpOptions)
    }

    getLeyendas():Observable<any>{
      return this.http.get(`${this.urlRest.urlapiNode}/configuracion/leyendas`)
     }

     saveLeyendas(leyendas:string):Observable<any>{
      return this.http.post(`${this.urlRest.urlapiNode}/configuracion/leyendas`,{leyendas})
     }

     getVersionSistema():Observable<any>{
      return this.http.get(`${this.urlRest.urlapiNode}/configuracion/version-sistema`)
     }

}
