import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { AutorizacionService } from './autorizacion.service';
declare var $:any;

@Component({
  selector: 'app-autorizacion',
  templateUrl: './autorizacion.component.html',
  styleUrls: ['./autorizacion.component.css']
})
export class AutorizacionComponent implements OnInit {

  @Output() retornoOperacion = new EventEmitter(); 
    
  @Input() mensajeAutorizacion :string = 'La operación requiere la autorización de un Superior...'
  @Input() id :string = "autorizacionModal"

  autorizacionForm: UntypedFormGroup;
  mostrarErrorNoAutorizado:boolean = false
  constructor(private formBuilder: UntypedFormBuilder, private as:AutorizacionService) { }

  ngOnInit() {
    this.initForm()
  }

  initForm(){
    this.autorizacionForm = this.formBuilder.group({
      usuario: ['', Validators.required],
      password: ['', Validators.required],      
    });
  }

  get usuarioField(){
    return this.autorizacionForm.controls.usuario
  }

  get passwordField(){
    return this.autorizacionForm.controls.password
  }

  get isInvalidUsuarioField(){
    return this.usuarioField.invalid && this.usuarioField.touched
  }

  get isInvalidPasswordField(){
    return this.passwordField.invalid && this.passwordField.touched
  }

  open(){
    $(`#${this.id}`).modal('show')
  }

  onAuthorize(event:Event){
    event.preventDefault()

    if(this.autorizacionForm.valid){
      this.as.habilitaOperacion(this.usuarioField.value,this.passwordField.value).subscribe(
        valhabilita=> {
          
          if(valhabilita.puedeAutorizar){
            $(`#${this.id}`).modal('hide')
            this.retornoOperacion.emit({'habilita':true,'usuarioAutorizante':this.usuarioField.value});
            this.autorizacionForm.reset()  
            this.mostrarErrorNoAutorizado = false 
          }else{
            this.mostrarErrorNoAutorizado = true            
          }
        }
      )
    }
    
  }

  cancelar(){
    $(`#${this.id}`).modal('hide')
    this.retornoOperacion.emit({'habilita':false,'usuarioAutorizante':null});
    this.autorizacionForm.reset()   
    this.mostrarErrorNoAutorizado = false
  }


}
