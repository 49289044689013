
<div  style="margin-top:15%" class="modal fade" tabindex="-1" id="operacion_loading" role="dialog"  aria-hidden="true" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog" role="document">      
          <div class="alert alert-dark" role="alert">
              <div class="d-flex align-items-center">
                  <strong>{{loadingTitle}} ...</strong>
                  <div class="spinner-border text-info ml-auto" role="status" aria-hidden="true"></div>
                </div>
               
            </div>      
    
  </div>
</div>