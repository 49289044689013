<div class="card" id="formsocio" >
  <div *ngIf="modoOperacion == 2" class="card-header" style="background-color:#17a2b8; color: white">Alta de Socios</div>
  <div *ngIf="modoOperacion == 1" class="card-header" style="background-color:#17a2b8; color: white">Actualizar Socio</div>
  <div class="card-body">
    <form (ngSubmit)="onSubmit()" (keydown.enter)="$event.preventDefault()" autocomplete="off"
      novalidate>

      <div class="row">
        <div class="col-3">
          <div class="card shadow fijo">
            <div class="card-body text-right">
              <ul class="menu_listado">
                <li><a href="javascript:;" (click)="scrollTo('datosSocio')">Datos del socio</a></li>
                <li><a href="javascript:;" (click)="scrollTo('datosConexion')">Datos de conexión</a></li>
                <li><a href="javascript:;" (click)="scrollTo('domicilioConexion')">Domicilio de conexión</a></li>
                <li><a href="javascript:;" (click)="scrollTo('domicilioLegal')">Domicilio legal</a></li>
                <li><a href="javascript:;" (click)="scrollTo('varios')">Varios</a></li>
                <li><a href="javascript:;" (click)="scrollTo('lote')">Lote</a></li>
                <li><a href="javascript:;" (click)="scrollTo('datosTecnicos')">Datos técnicos</a></li>
                <li><a href="javascript:;" (click)="scrollTo('debitoAutomatico')">Débito Automático</a></li>
              </ul>
              <hr>
              <button (click)="cancelar()" class="btn btn-outline-danger derecha">Cancelar</button>
              <button type="submit" class="btn btn-success" [disabled]="operationInProcess$|async">Guardar</button>
            </div>
          </div>
        </div>

    <ng-container *ngIf="socio$ | async as socio" >
      <div class="col-9">
        <!-- datos del socio container-->
        <app-socio-info
          [modoOperacion]="modoOperacion"
          [socio]="socio"
          [condicionesDeRegistro]="condicionesDeRegistro$ | async"
          [tiposDni]="tiposDni$ | async"
          [tiposIva]="tiposIva$ | async"
          [categorias]="categorias$ | async"
          [errorSocioUsuarioRegistrado]="errorSocioUsuarioRegistrado">
        </app-socio-info>
        <!-- fin datos del socio-->

        <!-- datos de la conexion container-->

          <app-datos-conexion-info
        [modoOperacion]="modoOperacion"
        [socio]="socio"
         [estados]="estados$ | async"
         [finSuspension]="finSuspension$ | async"
          [tarifas]="tarifas$ | async"
          [zonas]="zonas$ | async"
          [situacionesEspecial]="situacionesEspecial">
        </app-datos-conexion-info>



        <!-- Fin datos de la conexion container-->

        <!-- domicilio de la conexion container-->
        <app-domicilio-conexion-info
        [socio]="socio"
        [barrios]="barrios"
        [calles]="calles"
          (nuevaCalleEvent)="nuevaCalle()">
        </app-domicilio-conexion-info>
        <!-- Fin domicilio de la conexion container-->

        <!-- Inicio domicilio legal -->
        <app-domicilio-legal-info [modoOperacion]="modoOperacion" [socio]="socio" [provincias]="provincias$ | async" [barrios]="barrios">
        </app-domicilio-legal-info>
        <!--fin de domicilio legal-->
        <app-varios-info [modoOperacion]="modoOperacion" [socio]="socio" [tiposDni]="tiposDni$ | async"></app-varios-info>
        <!--fin de Varios-->
        <!-- Inicio Lote -->
        <app-lote-info [modoOperacion]="modoOperacion" [socio]="socio"></app-lote-info>
        <!--fin de Lote-->

        <!-- Inicio Datos tecnicos -->
        <app-datos-tecnicos-info
        [modoOperacion]="modoOperacion" [socio]="socio"
        [tiposMedianeras] = "tiposMedianeras$ | async"
        [tiposConexion] = "tiposConexion$ | async"
        [tiposMaterial] = "tiposMaterial$ | async"
        ></app-datos-tecnicos-info>
        <!--fin de datos técnicos-->

        <!-- Inicio debito automatico -->
          <app-debito-info
          [modoOperacion]="modoOperacion" [socio]="socio"
          [bancos] ="bancos"
          ></app-debito-info>
        <!-- Fin Inicio debito automatico -->

      </div>

    </ng-container>

      </div>
    </form>
  </div>
</div>


<app-calle-modal (retornoCalleModal)="retornoCalleModal()"></app-calle-modal>
