<ng-container [formGroup]="socioForm">


<div id="domicilioLegal"></div>

<div class="card shadow mt-4">
  <div class="card-body">
    <p style="border-left: solid 5px #17a2b8; padding-left: 5px"><b>Domicilio legal</b></p>
    <hr>
    <div class="form-group row">
      <label for="inputProvinciaDomicilioLegal" class="col-sm-2 col-form-label col-form-label-sm  text-right">Provincia</label>
      <div class="col-6">
        <select class="form-control form-control-sm" id="inputProvinciaDomicilioLegal"
          formControlName="provinciaDomicilioLegal"
          [ngClass]="{ 'is-invalid': isInvalidProvinciaDomicilioLegalField }">
          <option *ngFor="let provincia of provincias" [value]="provincia.id">{{provincia.detalle}}
          </option>
        </select>
        <div *ngIf="isInvalidProvinciaDomicilioLegalField" class="invalid-feedback">
          Campo obligatorio
        </div>
      </div>

    </div>
    <div class="form-group row">
      <label for="inputLocalidadDomicilioLegal" class="col-sm-2 col-form-label col-form-label-sm  text-right">Localidad</label>
      <div class="col">
        <input type="text" class="form-control form-control-sm" formControlName="localidadDomicilioLegal"
          id="inputLocalidadDomicilioLegal" (change)="verificarLocalidadLegal($event)" placeholder=""
          [ngClass]="{ 'is-invalid': isInvalidLocalidadDomicilioLegalField}">
        <div *ngIf="isInvalidLocalidadDomicilioLegalField" class="invalid-feedback">
          Campo obligatorio
        </div>
      </div>
      <label for="inputCodigoPostalDomicilioLegal" class="col-sm-2 col-form-label col-form-label-sm  text-right">Cód.
        Postal</label>
      <div class="col-sm-2">
        <input type="text" class="form-control form-control-sm" formControlName="codigoPostalDomicilioLegal"
          id="inputCodigoPostalDomicilioLegal" placeholder=""
          [ngClass]="{ 'is-invalid':isInvalidCodigoPostalDomicilioLegalField }">
        <div *ngIf="isInvalidCodigoPostalDomicilioLegalField" class="invalid-feedback">
          Campo obligatorio
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label for="inputBarrioDomicilioLegal" class="col-sm-1 col-form-label col-form-label-sm  text-right">Barrio</label>
      <div class="col-sm-6">

        <input type="text" class="form-control form-control-sm" list="barriosInput"
          formControlName="barrioDomicilioLegal"
          (change)="onSelectionBarrioChange($event,'id_barrioDomicilioLegal')"
          [ngClass]="{ 'is-invalid': isInvalidBarrioDomicilioLegalField }" />
        <datalist id="barriosInput">
          <option *ngFor="let barrio of barrios" [value]="barrio.barrio">{{barrio.barrio}}</option>
        </datalist>

        <div *ngIf="isInvalidBarrioDomicilioLegalField" class="invalid-feedback">
          <div *ngIf="socioForm.controls['barrioDomicilioLegal'].errors.required">Campo obligatorio</div>
          <div *ngIf="socioForm.controls['barrioDomicilioLegal'].errors.esBarrioPlottierValido">El barrio
            seleccionado no se encuentra registrado</div>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label for="inputCodCalleDomicilioLegal" class="col-sm-1 col-form-label col-form-label-sm  text-right">Calle</label>

      <div class="col-sm-6">
        <input type="text" class="form-control form-control-sm" formControlName="calleDomicilioLegal"
          id="inputCodCalleDomicilioLegal" placeholder=""
          [ngClass]="{ 'is-invalid':isInvalidCalleDomicilioLegalField }" />
        <div *ngIf="isInvalidCalleDomicilioLegalField" class="invalid-feedback">
          Campo obligatorio
        </div>
      </div>
      <label for="inputnumeroCalleDomicilioLegal" class="col-sm-2 col-form-label col-form-label-sm  text-right">número</label>
      <div class="col-sm-2">
        <input type="text" class="form-control form-control-sm" formControlName="numeroCalleDomicilioLegal"
          placeholder="" [ngClass]="{ 'is-invalid':isInvalidNumeroCalleDomicilioLegalField }"
          appNumberEnteroOnly>
        <div *ngIf="isInvalidNumeroCalleDomicilioLegalField" class="invalid-feedback">
          Campo obligatorio
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label for="inputPisoDepartamentoDomicilioLegal" class="col-sm-2 col-form-label col-form-label-sm ">Piso, Depto,
        etc.</label>
      <div class="col-sm-4">
        <input type="text" class="form-control form-control-sm"
          formControlName="pisoDepartamentoDomicilioLegal" id="inputPisoDepartamentoDomicilioLegal"
          placeholder="">
      </div>
    </div>
  </div>
</div>
</ng-container>
