export class GetCuentaCorriente{
  static readonly type = '[CuentaCorriente] SetCuentaCorriente'
  constructor( public cliente:number, public update:boolean){}
}



export class ResetCuentaCorriente{
  static readonly type = '[CuentaCorriente] ResetCuentaCorriente'
  constructor( ){}
}

