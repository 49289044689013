
import { Directive, ElementRef, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Store } from '@ngxs/store';
import { AuthState } from '../store/auth/state';




export interface PermisosModoRequerido{
  permisosRequerido:number[];
  modoRequerido:ModoChequeoRol;
}

export enum ModoChequeoRol{
  RequiereTodosLosPermisos=0,
  RequiereAlgunPermiso=1
}


@Directive({
  selector: '[appRole]'
})
export class RoleDirective implements OnInit {
  private currentUsersPermission: number[];
  private permisosModoRequerido: PermisosModoRequerido;
 

  constructor( private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef, private store:Store) {
   
   }

 

  ngOnInit(): void {

    const permisos = this.store.selectSnapshot(AuthState.usuarioPermisos);
     
    this.currentUsersPermission = permisos;

    
    this.updateView()


   
   
  }

  @Input()
  set appRole(val:PermisosModoRequerido) {
    this.permisosModoRequerido = val;
    this.updateView()
  }


  private updateView():void{
     this.viewContainer.clear();
    if(this.checkPermissions()){
      this.viewContainer.createEmbeddedView(this.templateRef)
  } 
   
  }

  private checkPermissions():boolean{

    

   if(this.currentUsersPermission && this.currentUsersPermission.length>0){
    let hasPermission

    let lengthPermisosRequerido= this.permisosModoRequerido.permisosRequerido.length
    let continuarChequeo = true
    let index = 0

    this.permisosModoRequerido.modoRequerido==ModoChequeoRol.RequiereAlgunPermiso?hasPermission=false:hasPermission=true

    while(index<lengthPermisosRequerido && continuarChequeo){
      if(this.currentUsersPermission.includes(this.permisosModoRequerido.permisosRequerido[index])){
        if(this.permisosModoRequerido.modoRequerido==ModoChequeoRol.RequiereAlgunPermiso){
          hasPermission = true
          continuarChequeo=false
        }
      }else{
        if(this.permisosModoRequerido.modoRequerido==ModoChequeoRol.RequiereTodosLosPermisos){
          hasPermission = false
          continuarChequeo=false
        }
      }
      index+=1;
    }


 
    return hasPermission;
   }else{
     return false
   }
    
    
  }
}



/**
 * La siguiente directiva se aplica a las opciones del menu principal que a su vez poseen opciones internas
 * si el usuario no posee permisos para ninguna opcion interna la directiva oculta la opcion principal(mediante el estilo display)
 */
@Directive({
  selector: '[appRoleAlgunPermiso]'
})
export class RoleAlgunPermisoDirective implements OnInit {

  private currentUsersPermission: number[];
  private permisosModoRequerido: PermisosModoRequerido;


  @Input()
  set appRoleAlgunPermiso(val:PermisosModoRequerido) {
    this.permisosModoRequerido = val;
    this.updateView()
  }


  constructor(private el: ElementRef,private store:Store) { }

   
   ngOnInit() {
    const permisos = this.store.selectSnapshot(AuthState.usuarioPermisos);  
    this.currentUsersPermission = permisos;
    this.updateView()
   }

   private updateView():void{

if(this.permisosModoRequerido.modoRequerido==ModoChequeoRol.RequiereAlgunPermiso){
  this.el.nativeElement.style.display="none"
  if(this.checkPermissions()){
    this.el.nativeElement.style.display="flex"
  }
  
}
  
 } 
     
   
  
 

 private checkPermissions():boolean{

  if(this.currentUsersPermission && this.currentUsersPermission.length>0){
   let hasPermission=false

   let lengthPermisosRequerido= this.permisosModoRequerido.permisosRequerido.length
   let continuarChequeo = true
   let index = 0

  

   while(index<lengthPermisosRequerido && continuarChequeo){
     if(this.currentUsersPermission.includes(this.permisosModoRequerido.permisosRequerido[index])){    
         hasPermission = true
         continuarChequeo=false
     }
     index+=1;
   }



   return hasPermission;
  }else{
    return false
  }
   
   
 }

}
