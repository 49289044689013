import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { Store } from '@ngxs/store';
import { takeUntil, map } from 'rxjs/operators';
import  moment from 'moment';
import { GetCuentaCorriente } from 'src/app/store/cuentacorriente/actions';
import { SocioState } from 'src/app/store/socio/states';

@Component({
  selector: 'app-cuenta-corriente',
  templateUrl: './cuenta-corriente.component.html',
  styleUrls: ['./cuenta-corriente.component.css']
})
export class CuentaCorrienteComponent implements OnInit,OnDestroy {

  @ViewChild('agGridCuentaCorriente', { static: true }) agGridCuentaCorriente: any;

  destroy$: Subject<boolean> = new Subject<boolean>();

  columnDefs = [
    { headerName: 'Comp. Caja', field: 'comprobante_caja', width:130  },
    { headerName: 'Factura/Recibo', field: 'factura', filter: true, width:140 },
    { headerName: 'Fecha', field: 'fecharegistro', filter: true, width:130 },
    { headerName: 'Descripción', field: 'descripcion',flex:1},
    { headerName: 'Débito', field: 'debito', width:100},
    { headerName: 'Crédito', field: 'credito', width:100},
    { headerName: 'Saldo', field: 'saldoregistro', width:100},
  ];

  rowData: any[]=[];

  constructor(private store:Store) { }

  ngOnInit() {

    const cliente = this.store.selectSnapshot(SocioState.cliente)
    this.store.dispatch(new GetCuentaCorriente(cliente,false))

    this.store.select(state => state.cuenta_corriente.cuenta_corriente).pipe(
      takeUntil(this.destroy$)
      ).subscribe(
      data => {
        this.rowData=data
      }
    );
  }




  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}
