import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CalleComponent } from './calle/calle.component';
import { Routes, RouterModule } from '@angular/router';
import { AgGridModule } from 'ag-grid-angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { CalleModalComponent } from './calle-modal/calle-modal.component';



const routes: Routes = [
  {
    path: 'calles',
    component: CalleComponent
  }
  ];


@NgModule({
  declarations: [CalleComponent, CalleModalComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild(routes),
   AgGridModule,
     SharedModule
  ],
  exports:[CalleModalComponent]
})
export class GeneralModule { }
