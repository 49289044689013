import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SrcImg } from 'src/app/shared/src-img';
import { SistemaConfiguracionService } from '../sistema-configuracion.service';
import packageInfo from 'package.json'

@Component({
  selector: 'app-actualizar-sistema',
  templateUrl: './actualizar-sistema.component.html',
  styleUrls: ['./actualizar-sistema.component.css']
})
export class ActualizarSistemaComponent implements OnInit {

  srcimg: SrcImg = new SrcImg();
  versionNumero= packageInfo.version

  constructor(private scs:SistemaConfiguracionService,private router: Router) { }

  ngOnInit(): void {

    this.scs.getVersionSistema().subscribe(
     versionData=> {
        if (versionData.version_sistema == this.versionNumero){
          this.router.navigate(['/login']);
        }
      }
    )
  }




}
