import {  Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup,  Validators } from '@angular/forms';
import { Validacion } from 'src/app/shared/validacion';
import { ItemsLista } from 'src/app/store/general/models';
import { SocioDetalle } from 'src/app/store/socio/models';
import  moment from "moment";
import { ValidacionFormularioSocio } from '../validacion-formulario-socio';
import { OperacionesABM } from 'src/app/shared/enums/enums';

@Component({
  selector: 'app-debito-info',
  templateUrl: './debito-info.component.html',
  styleUrls: ['./debito-info.component.css']
})
export class DebitoInfoComponent implements OnInit {

  @Input() modoOperacion: OperacionesABM;
  @Input() socio: SocioDetalle;
  @Input() bancos: ItemsLista[];

  public validadores: Validacion = new Validacion();
  public validadoresFormularioSocio: ValidacionFormularioSocio = new ValidacionFormularioSocio();

  socioForm: UntypedFormGroup
  constructor( private fb: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.validadoresFormularioSocio.setBancos(this.bancos);
    this.socioForm = this.initSocioFormModel();
    this.setearSocioForm();
  }

  initSocioFormModel(){
    return this.fb.group({
      debitoAutomatico: [this.socio.debitoAutomatico],
      bancoDebitoAutomatico: [
        { value: this.socio.banco, disabled: true },
        [
          Validators.required,
          this.validadoresFormularioSocio.esBancoValido(),
        ],
      ],

      codigoCuentaDebitoAutomatico: [{ value: "", disabled: true }],
      cbuCuentaDebitoAutomatico: [
        { value: "", disabled: true },
        [Validators.required, this.validadores.esNumeroXdigitos(19)],
      ],

      numeroTarjetaNaranja: [this.socio.nroTarjeta_TN],
      fechaAltaTarjetaNaranja: [
        moment(this.socio.fechaAlta_TN).format("YYYY-MM-DD"),
      ],
    })
  }

  setearSocioForm(){
    if (this.modoOperacion == OperacionesABM.editar) {
      if (this.socio.CBU?.length > 0 && !(this.socio.CBU === "")) {        
        this.socioForm
          .get("codigoCuentaDebitoAutomatico")
          .setValue(this.socio.CBU.substring(0, 3));
        this.socioForm
          .get("cbuCuentaDebitoAutomatico")
          .setValue(this.socio.CBU.substring(3, 22));

        this.socioForm.get("cbuCuentaDebitoAutomatico").enable();
        this.socioForm.get("bancoDebitoAutomatico").enable();
      }

    }
  }

  get bancoDebitoAutomaticoField(){
    return this.socioForm.controls.bancoDebitoAutomatico
  }

  get isInvalidBancoDebitoAutomaticoField(){
    return this.bancoDebitoAutomaticoField.touched && this.bancoDebitoAutomaticoField.invalid
  }


  get cbuCuentaDebitoAutomaticoField(){
    return this.socioForm.controls.cbuCuentaDebitoAutomatico
  }

  get isInvalidCbuCuentaDebitoAutomaticoField(){
    return this.cbuCuentaDebitoAutomaticoField.touched && this.cbuCuentaDebitoAutomaticoField.invalid
  }


  onSelectionAdhesionDebitoAutomaticoChange(event) {

    if (!!this.socioForm) {
      if (this.socioForm.get('debitoAutomatico').value) {
        this.socioForm.get('bancoDebitoAutomatico').enable();
        this.socioForm.get('cbuCuentaDebitoAutomatico').enable();
      } else {
        this.socioForm.get('bancoDebitoAutomatico').disable();
        this.socioForm.get('cbuCuentaDebitoAutomatico').disable();
        this.socioForm.get('bancoDebitoAutomatico').setValue('');
        this.socioForm.get('cbuCuentaDebitoAutomatico').setValue('');
        this.socioForm.get('codigoCuentaDebitoAutomatico').setValue('');
      }
    }

  }

  onSelectionBancoDebitoAutomaticoChange(event) {
    let bancoSeleccionado = this.bancos.filter(banco => banco.detalle == event.target.value)
    if (bancoSeleccionado.length > 0){
      this.socioForm.get('codigoCuentaDebitoAutomatico').setValue(bancoSeleccionado[0].id);
    }

  }

  get isValidForm(){
    return this.socioForm.valid
  }

  showInvalidControl(){
    ValidacionFormularioSocio.setErrorsInForm(this.socioForm);
  }

  get formValue(){
    return this.socioForm.getRawValue()
  }

}
