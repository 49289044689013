<div class="card">
  <div class="card-header" style="background-color:#17a2b8; color: white">Impresión de facturas</div>
  <div class="card-body">
    <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-8">
        <form (ngSubmit)="onImprimirFacturas($event)" [formGroup]="impresionForm" novalidate>


        <div class="card">
          <div class="card-body">
            <div class="row">
            <div class="col-md-6">
           <fieldset class="p-2" style="border: solid 1px #ddd">
                <legend style="font-size: 12px">Períodos de la última facturación</legend>

                <div class="form-check" style="margin-top: -13px;">
                  <input class="form-check-input" type="radio"  id="tipoImpresion1" value="1" formControlName="tipoImpresion">
                  <label class="form-check-label col-form-label-sm mr-4" for="tipoImpresion1">
                     Imprimir períodos completos</label>
                </div>

                <div class="form-check">
                  <input class="form-check-input" type="radio"  id="tipoImpresion2" value="2" formControlName="tipoImpresion">
                  <label class="form-check-label col-form-label-sm mr-4" for="tipoImpresion2">
                     Imprimir todas las facturas de una zona</label>
                </div>

                <div class="form-check">
                  <input class="form-check-input" type="radio"  id="tipoImpresion3" value="3" formControlName="tipoImpresion">
                  <label class="form-check-label col-form-label-sm mr-4" for="tipoImpresion3">
                     Imprimir todas las facturas de un barrio</label>
                </div>

                <div class="form-check  ml-3">
                  <input class="form-check-input" type="checkbox" id="zonasABarrioInput" formControlName="incluirZonasABarrio">
                  <label class="form-check-label" for="zonasABarrioInput">
                    Incluir las zonas que regularmente no se imprimen
                  </label>
                </div>

              </fieldset>
            </div>
            <div class="col-md-6">
              <fieldset class="p-2" style="border: solid 1px #ddd">
                <legend style="font-size: 12px">Socios/Facturas</legend>

                <div class="form-check">
                  <input class="form-check-input" type="checkbox" id="debitoAutomaticoInput" formControlName="sociosAdheridosDebitoAutomatico">
                  <label class="form-check-label" for="debitoAutomaticoInput">
                    Incluir Socios adheridos al débito automático
                  </label>
                </div>

                <div class="form-check">
                  <input class="form-check-input" type="checkbox" id="facturasPagasInput" formControlName="incluirFacturasPagas">
                  <label class="form-check-label" for="facturasPagasInput">
                    Incluir facturas pagas
                  </label>
                </div>

                <div class="form-check">
                  <input class="form-check-input" type="checkbox" id="facturaDigitalInput" formControlName="sociosAdheridosAFacturaDigital">
                  <label class="form-check-label" for="facturaDigitalInput">
                    Incluir Socios adheridos a la factura digítal
                  </label>
                </div>

                <div class="form-check">
                  <input class="form-check-input" type="checkbox" id="sinInteresPorVencimientoFieldInput" formControlName="imprimirSinInteresPorVencimiento">
                  <label class="form-check-label" for="sinInteresPorVencimientoFieldInput">
                    Imprimir facturas sin intereses por vencimiento
                  </label>
                </div>

                
              </fieldset>

            </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-6">
                <fieldset class="p-2" style="border: solid 1px #ddd">
                  <legend style="font-size: 12px">Alcance</legend>
                  <div class="mb-1 row">
                    <label for="barrioInput" class="col-sm-3 col-form-label-sm">Barrio</label>
                    <div class="col-sm-9">
                      <select id="barrioInput" class="form-control form-control-sm" formControlName="barrio">
                        <option value="" disabled>Seleccione un barrio</option>
                        <option *ngFor="let barrio of barrios$|async" [value]="barrio.id">{{barrio.barrio}}</option>
                      </select>
                    </div>
                  </div>
                  <div class="mb-1 row">
                    <label for="barrioInput" class="col-sm-3 col-form-label-sm">Zona</label>
                    <div class="col-sm-9">
                      <select id="zonaInput" class="form-control form-control-sm" formControlName="zona">
                        <option value="" disabled>Seleccione una zona</option>
                        <option *ngFor="let zona of zonas$|async" [value]="zona.id">{{zona.detalle}}</option>
                      </select>
                    </div>
                  </div>

                  <div class="mb-1 row">
                    <label for="barrioInput" class="col-sm-3 col-form-label-sm">Ordenar</label>
                    <div class="col-sm-9">
                      <select id="ordenarPor" class="form-control form-control-sm" formControlName="ordenarPor">
                        <option value="1">Barrio-Manzana-Lote</option>
                        <option value="2">Nº de Socio</option>
                        <option value="3">Nº de Factura</option>
                      </select>
                    </div>
                  </div>



                </fieldset>


              </div>

              <div class="col-md-6">
                <fieldset class="p-2" style="border: solid 1px #ddd">
                  <legend style="font-size: 12px">Periodos</legend>


                    <div class="mb-3 row">
                      <label for="periodoDesdeInput" class="col-sm-3 col-form-label-sm">Período</label>
                      <div class="col-sm-6">
                        <select id="periodoDesdeInput" class="form-control form-control-sm" formControlName="periodoDesde">
                          <option *ngFor="let periodo of periodos">{{periodo.id_periodo}}</option>
                        </select>
                      </div>
                    </div>

                    <div class="mb-3 row">
                      <div class="col-sm-3">
                        <div class="form-check">
                          <input class="form-check-input" type="checkbox" id="incluirHastaInput" formControlName="incluirPeriodoHasta">
                          <label class="form-check-label" for="incluirHastaInput">
                            Hasta
                          </label>
                        </div>
                      </div>

                      <div class="col-sm-6">
                        <select id="periodoHastaInput"
                        [class.is-invalid]="getValorPeriodoHastaIsInvalid"
                        class="form-control form-control-sm" formControlName="periodoHasta">
                          <option *ngFor="let periodo of periodos">{{periodo.id_periodo}}</option>
                        </select>
                      </div>

                      <div *ngIf="getValorPeriodoHastaIsInvalid" class="col-sm-12">
                        <div *ngIf="periodoHastaField.hasError('required'); else menorQue" class="invalid-feedback"> valor requerido!</div>
                        <ng-template #menorQue>
                          <div class="invalid-feedback"> <small>El Periodo Desde debe ser menor al Periodo Hasta</small></div>
                        </ng-template>
                    </div>
                    </div>

                    <div class="mb-3 row">
                      <div class="col-sm-12"><a style="color: #007bff;"href="javascript:;" (click)="onUltimoPeriodoFacturado()"><small>Establecer último periodo facturado</small></a></div>

                    </div>


                </fieldset>
              </div>

            </div>





            <div class="col-md-12 mt-4">


              <ng-container *ngIf="!estaProcesando; else procesando">
                <button type="submit" class="btn btn-success right" [disabled]="impresionForm.invalid">Imprimir</button>
            </ng-container>
            <ng-template #procesando>
                <button class="btn btn-info right" type="button" >
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    <span class="ml-2">Generando Impresión... La operación puede demorar algunos minutos ...</span>
                  </button>
            </ng-template>
            </div>

          </div>
        </div>



        </form>
      </div>



    </div>
  </div>
  </div>
</div>


<app-visor-impresion #visorImpresion [id]="'facturasPDFVisor'"></app-visor-impresion>
