import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
declare var $:any;

@Component({
  selector: 'app-operacion-exitosa',
  templateUrl: './operacion-exitosa.component.html',
  styleUrls: ['./operacion-exitosa.component.css']
})
export class OperacionExitosaComponent implements OnInit {

  @Input() contenidoMensaje:string;
  @Input() id:string="operacion_exitosa";

  @Output() aceptarMensaje = new EventEmitter();


  constructor() { }

  ngOnInit() {
  }

  open(){
    $(`#${this.id}`).modal('show')
  }

  aceptar(){
    $(`#${this.id}`).modal('hide')
    this.aceptarMensaje.emit(true);
  }

}
