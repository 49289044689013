<fieldset class="p-2" style="border: solid 1px #ddd">
    <legend style="font-size: 18px">Agregar Declaración/Plazo para presentación de documentación</legend>
    <form [formGroup]="plazosForm" (ngSubmit)="onSubmit()" (keydown.enter)="$event.preventDefault()"
      class="ml-3" novalidate>

      <div class="form-group">
        <label ><span class="badge badge-info">1</span> Indique la fecha de inicio del plazo</label>
        <div class="row ml-3">
            <div class="col-6">
                <input type="date"  formControlName="fechaInicioDelPlazo" class="form-control form-control-sm" placeholder="Fecha desde" (change)="actualizarFechaFin($event)" [ngClass]="{ 'is-invalid': plazosForm.controls['fechaInicioDelPlazo'].errors  }">
                <div *ngIf="plazosForm.controls['fechaInicioDelPlazo'].errors" class="invalid-feedback">
                    Campo obligatorio
                </div>
            </div>
        </div>
      </div>

      <div class="form-group">
        <label ><span class="badge badge-info">2</span> Indicar los
          días de plazo para la presentación de la documentación</label>
        <div class="row ml-3">
          <div class="col-6">
            <select class="form-control form-control-sm"  formControlName="plazoEnDias"
              (change)="actualizarFechaFin($event)">
              <option [value]="180">180</option>
              <option [value]="120">120</option>
              <option [value]="90">90</option>
              <option [value]="30">30</option>
              <option [value]="15">15</option>
              <option [value]="7">7</option>
            </select>
          </div>
          <div class="col-6 mt-2"> días</div>
        </div>


      </div>
      <div class="form-group">
        <label ><span class="badge badge-info">3</span> Fecha fín del plazo:
            {{plazosForm.controls['fechaFinDelPlazo'].value}}</label>
      </div>

      <div class="form-group">
        <label for="observacionPlazos"><span class="badge badge-info">4</span> Observación <small><sup>*</sup>no obligatorio</small></label>
        <textarea id="observacionPlazos" class="form-control form-control-sm" rows="3" maxlength="100" formControlName="observacion"
          style="resize: none;"></textarea>
      </div>
      <div class="row">
        <div class="col-12 text-right">
          <button [disabled]="!plazosForm.valid" type="submit" class="btn btn-success btn-sm ml-2">Agregar declaración/plazo</button>
        </div>
      </div>
    </form>
  </fieldset>

  <app-operacion-si-no [titulo]="'Documentación ' + tipoDocumentacion " [contenidoMensaje]="mensaje"
    (retornoOperacion)="retornoOperacionConsultaPlazos($event)" #siNoPlazos
    [id]="'siNoPlazos'+tipoDocumentacion"></app-operacion-si-no>
