import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import dayjs from 'dayjs';
import { Validacion } from "src/app/shared/validacion";
import { Observable } from 'rxjs';
import { ReciboService } from '../recibo-semestral.service';
import { filter } from 'rxjs/operators';
import Swal from 'sweetalert2'
import { AutorizacionComponent } from 'src/app/autorizacion/autorizacion.component';
import { ListaConceptosComponent } from './lista-conceptos/lista-conceptos.component';


@Component({
  selector: 'app-recibo-semestral-generar',
  templateUrl: './recibo-semestral-generar.component.html',
  styleUrls: ['./recibo-semestral-generar.component.css']
})
export class ReciboSemestralGenerarComponent implements OnInit {


  @ViewChild(AutorizacionComponent, { static: true })
  autorizacionComponent: AutorizacionComponent;

  @ViewChild(ListaConceptosComponent, { static: true }) listaConceptosComponent: ListaConceptosComponent;

  reciboSemestralForm: UntypedFormGroup;

  fechaActual:string;

  reglasCuadroTarifario$:Observable<any>

  isProcesando:boolean=false

  reciboSemetral:any

  mostrarListaConceptos:boolean=false

  isGenerandoReciboSemestral:boolean=false

  constructor(private  fb: UntypedFormBuilder, private rs:ReciboService) {
    this.reciboSemestralForm = this.fb.group({
      semestre:['',Validators.required],
      fechaEmision:[ dayjs().format('YYYY-MM-DD'),Validators.required],
      fechaVencimiento:['',Validators.required],
      aplicaDescuento:[true],
      descuento:['',Validators.required]

    },{validator:Validacion.EsMenorFecha('fechaEmision','fechaVencimiento')})
  }


  ngOnInit() {

    this.aplicaDescuentoField.valueChanges.subscribe(
      value=>{
        if(value){
          this.descuentoField.enable()
        }else{
          this.descuentoField.disable()
        }
      }
    )

   }




  get semestreField(){
    return this.reciboSemestralForm.controls.semestre
  }

  get fechaEmisionField(){
    return this.reciboSemestralForm.controls.fechaEmision
  }

  get fechaVencimientoField(){
    return this.reciboSemestralForm.controls.fechaVencimiento
  }

  get descuentoField(){
    return this.reciboSemestralForm.controls.descuento
  }


  get aplicaDescuentoField(){
    return this.reciboSemestralForm.controls.aplicaDescuento
  }

  get isInvalidSemestreValue(){
    return this.semestreField.touched && this.semestreField.invalid
  }

  get isInvalidFechaEmisionValue(){
    return this.fechaEmisionField.touched && this.fechaEmisionField.invalid
  }

  get isInvalidFechaVencimientoValue(){
    return this.fechaVencimientoField.touched && this.fechaVencimientoField.invalid
  }

  get isInvalidDescuentoValue(){
    return this.descuentoField.touched && this.descuentoField.invalid
  }





  onGenerarConceptosSemestrales(event:Event){
    event.preventDefault()

    if(this.reciboSemestralForm.valid){
      this.isProcesando=true
        this.rs.generarConceptosTarifasReciboSemestral(this.semestreField.value,this.fechaEmisionField.value,this.fechaVencimientoField.value,this.aplicaDescuentoField.value,this.descuentoField.value).pipe(
          filter(data=> !!data)
        ).subscribe(
          data=>{
            if(data.result){
              this.isProcesando=false; this.mostrarListaConceptos=true; this.reciboSemetral = data.value; this.reciboSemetral["aplicaDescuento"]  = this.aplicaDescuentoField.value}
            else{
              this.isProcesando=false;
              Swal.fire(
                '',
                data.message + '. No podrá realizar la operación!',
                'error'
              )
            }
          }
            ,
          error=>{this.isProcesando=false;this.mostrarListaConceptos=false}
        )
    }


  }


  onCancelar(){
    this.mostrarListaConceptos=false;
  }


  onGenerar(event){

    this.reciboSemetral['conceptosParaReciboSemestral']=event.conceptos;

      Swal.fire({
        title: '',
        text: "Desea generar los recibos semestrales?",
        icon: 'warning',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Si, generar recibos!',
        showCancelButton: true,
        cancelButtonText:'Cancelar',
        cancelButtonColor: '#d33',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          this.autorizacionComponent.open()
        }
      })
  }

  retornoAutorizacion(retorno){

    if(retorno.habilita){
      this.generarReciboSemestral()
    }
  }

  generarReciboSemestral(){

  this.isGenerandoReciboSemestral=true
    this.rs.generarReciboSemestral(this.reciboSemetral).subscribe(
      ()=>{
        Swal.fire(
          '',
          'Los recibos semestrales han sido generado de manera exitosa!',
          'success'
        ).then(
          ()=>{
            this.isGenerandoReciboSemestral=false
            this.resetForm()
          }
        )

       },
      (error)=>this.isGenerandoReciboSemestral=false
    )
  }

  resetForm(){
    this.reciboSemestralForm.reset()
    this.mostrarListaConceptos=false
    this.isProcesando=false;
  }

  onExit(){

    if (this.isProcesando){
      alert('No podrá salir hasta que el proceso finalice!')
      return false;
    }

    if (this.isGenerandoReciboSemestral){
      alert('No podrá salir hasta que la generación de recibos semestrales finalice!')
      return false;
    }

    if(this.mostrarListaConceptos){
      const    rta = confirm('Al salir se perderá la información de los conceptos que ha sido generada. Esta seguro de salir?')
      return rta;
    }

    return true;
  }


}
