import { State, Action, StateContext, Selector } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { InitFacturasNuevoConvenio, SetFacturasNuevoConvenio, GetValorMinimoCuotaConvenio, GetTotalAdeudadoPorConvenio, GetInformacionParaNuevoConvenio, ClearConvenio, SetCanonCobrado, SetCostoIntimacionCobrado, GetConveniosCliente,  NuevoConvenioCliente, ResetConvenios, TieneConvenioNoVolcadoCliente, UpdateConvenio, TieneDeudaConvenioCliente, DeleteConvenio } from './actions';
import { ConvenioService } from 'src/app/convenio/convenio.service';
import { InformacionNuevoConvenio, Convenio } from './convenio.model';
import { mergeMap } from 'rxjs/operators';
import { of, from } from 'rxjs';


export class ConvenioStateModel {
  convenios:Convenio[]
    facturas_nuevo_convenio:any[];
    valor_minimo_cuota:number;
    total_deuda_convenio:number;
    informacion_para_nuevo_convenio:InformacionNuevoConvenio;
    canonCobrado:boolean;
    costoIntimacionCobrado:boolean;
    inicializado_convenios:boolean;
    tieneDeudaConvenios:boolean;
    tieneConveniosNoVolcados:boolean;
}


@State<ConvenioStateModel>({
    name: 'convenio',
    defaults: {
        convenios:[],
        facturas_nuevo_convenio:[],
        valor_minimo_cuota:0,
        total_deuda_convenio:0,
        informacion_para_nuevo_convenio:null,
        canonCobrado:false    ,
        costoIntimacionCobrado:false,
        inicializado_convenios:false,
        tieneDeudaConvenios:false,
        tieneConveniosNoVolcados:false
    }
})

@Injectable()
export class ConvenioState {

    constructor(private cs: ConvenioService) { }

    @Selector()
    static canonCobrado(state: ConvenioStateModel) { return state.canonCobrado }

    @Selector()
    static costoIntimacionCobrado(state: ConvenioStateModel) { return state.costoIntimacionCobrado }

    @Action(GetConveniosCliente)
    getConveniosCliente(ctx: StateContext<ConvenioStateModel>, action:GetConveniosCliente) {
      const state = ctx.getState()
      let seguir = false

      if(!action.update && !state.inicializado_convenios){seguir=true}
      if(action.update && state.inicializado_convenios){seguir=true}

      if(seguir){
        this.cs.getConveniosCliente(action.cliente).subscribe(
          data=> {
            ctx.patchState({
                convenios: data,
                inicializado_convenios:true
              });
          }
      )
      }


    }

    @Action(TieneDeudaConvenioCliente)
    tieneDeudaConvenioCliente({ patchState }: StateContext<ConvenioStateModel>,actions:TieneDeudaConvenioCliente) {

      this.cs.getTieneDeudaConveniosCliente(actions.cliente).subscribe(
        data =>{
          patchState({
            tieneDeudaConvenios: data?.tieneDeudaConvenios || false
          });
        }
      )

    }


    @Action(TieneConvenioNoVolcadoCliente)
    tieneConvenioNoVolcadoCliente({ patchState }: StateContext<ConvenioStateModel>,actions:TieneConvenioNoVolcadoCliente) {

      this.cs.getTieneConveniosNoVolcadosCliente(actions.cliente).subscribe(
        data =>{
          patchState({
            tieneConveniosNoVolcados: data?.tieneConveniosNoVocados || false
          });
        }
      )

    }

    @Action(InitFacturasNuevoConvenio)
    initFacturasNuevoConvenio({ patchState }: StateContext<ConvenioStateModel>) {
                    patchState({
                        facturas_nuevo_convenio: []
                    });
    }


    @Action(SetFacturasNuevoConvenio)
    setFacturasNuevoConvenio({ patchState }: StateContext<ConvenioStateModel>,actions:SetFacturasNuevoConvenio) {
                    patchState({
                        facturas_nuevo_convenio: actions.facturas
                    });
    }

    @Action(NuevoConvenioCliente)
    nuevoConvenioCliente(ctx: StateContext<ConvenioStateModel>,actions:NuevoConvenioCliente) {
                return this.cs.nuevoConvenio(actions.cliente, actions.fechaConvenio, actions.fechaCaja, actions.caja, actions.montoTotal, actions.cuotas, actions.montoValorCuotas,
                  actions.cuotasAbonadas, actions.pseudoconvenio,actions.convenioJudicial,actions.tipoConvenio,actions.descuentoSobreIntereses,
                  actions.montoCostoFinanciero,actions.montoGastosAdministrativos,actions.montoReconexion,actions.facturas).pipe(
                    mergeMap(()=>ctx.dispatch(new GetConveniosCliente(actions.cliente,true)))
                  )
    }

    @Action(UpdateConvenio)
    updateConvenio(ctx: StateContext<ConvenioStateModel>, action:UpdateConvenio) {

     return this.cs.updateConvenio(action.cliente,action.convenio,action.cuotas,action.facturadas,action.observacion).pipe(
       mergeMap(()=> ctx.dispatch(new GetConveniosCliente(action.cliente,true)))
     )
  }

    @Action(GetValorMinimoCuotaConvenio)
    getValorMinimoCuotaConvenio({ patchState }: StateContext<ConvenioStateModel>,actions:GetValorMinimoCuotaConvenio) {
        this.cs.getValorMinimoCuota(actions.cliente).subscribe(
            data=> {

                    patchState({
                        valor_minimo_cuota: data?.ValorMinCuota||0
                    });

            }
        )
    }

    @Action(GetTotalAdeudadoPorConvenio)
    getTotalAdeudadoPorConvenio({ patchState }: StateContext<ConvenioStateModel>,actions:GetTotalAdeudadoPorConvenio) {
        this.cs.getTotalAdeudadoPorConvenio(actions.cliente).subscribe(
            data=> {

                    patchState({
                        total_deuda_convenio: data?.total_deuda_convenio||0
                    });

            }
        )
    }

    @Action(GetInformacionParaNuevoConvenio)
    getInformacionParaNuevoConvenio({ patchState }: StateContext<ConvenioStateModel>,actions:GetInformacionParaNuevoConvenio) {
        this.cs.getInformacionParaNuevoConvenio(actions.cliente).subscribe(
            data=> {
                    patchState({
                        informacion_para_nuevo_convenio: data
                    });
            }
        )
    }

    @Action(SetCanonCobrado)
    setCanonCobrado({ patchState}: StateContext<ConvenioStateModel>,actions:SetCanonCobrado) {

        patchState({
            canonCobrado:actions.valorCobrado
        });
    }

    @Action(SetCostoIntimacionCobrado)
    setCostoIntimacionCobrado({ patchState}: StateContext<ConvenioStateModel>,actions:SetCostoIntimacionCobrado) {

        patchState({
            costoIntimacionCobrado:actions.valorCostoIntimacionCobrado
        });
    }


    @Action(ClearConvenio)
    clearConvenio({ patchState}: StateContext<ConvenioStateModel>) {

        patchState({
            facturas_nuevo_convenio:[],
        valor_minimo_cuota:0,
        total_deuda_convenio:0,
        informacion_para_nuevo_convenio:null  ,
        canonCobrado:false ,
        costoIntimacionCobrado:false
        });
    }



    @Action(ResetConvenios)
    resetConvenios({ patchState }: StateContext<ConvenioStateModel>) {

        patchState({
          facturas_nuevo_convenio:[],
        valor_minimo_cuota:0,
        total_deuda_convenio:0,
        informacion_para_nuevo_convenio:null  ,
        canonCobrado:false ,
        costoIntimacionCobrado:false,
        inicializado_convenios:false
        })

        return from([]);
    }


    @Action(DeleteConvenio)
    deleteConvenio(ctx: StateContext<ConvenioStateModel>, action:DeleteConvenio) {

     return this.cs.deleteConvenio(action.convenio,action.usuarioAutorizante,action.observacion).pipe(
       mergeMap(()=> ctx.dispatch(new GetConveniosCliente(action.cliente,true)))
     )
  }



}
