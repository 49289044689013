import { SocioDetalle } from './models';


export class GetSocioDetalle{
    static readonly type = '[Socio] GetSocioDetalle'
    constructor(public codigo:string, public codsocio:string){}
}

export class StartBusquedaSocioDetalle{
    static readonly type = '[Socio] StartBusquedaSocioDetalle'
    constructor(){}
}

export class GetBusquedaSocios{
    static readonly type = '[Socio] GetBusquedaSocios'
    constructor(public textoBusqueda:string,public campoBusqueda:string){}
}

export class StartGetBusquedaSocios{
    static readonly type = '[Socio] StartGetBusquedaSocios'
    constructor(){}
}

export class ClearSocioParaEdicion{
    static readonly type = '[Socio] ClearSocioParaEdicion'
    constructor(){}
}

export class SetSocioParaEdicion{
    static readonly type = '[Socio] SetSocioParaEdicion'
    constructor(public socio:SocioDetalle){}
}


export class SetSocioAlta{
    static readonly type = '[Socio] SetSocioAlta'
    constructor(){}
}

export class StartEnProcesoSocioAltaModificacion{
  static readonly type = '[Socio] StartEnProcesoSocioAltaModificacion'
  constructor(){}
}

export class SocioUsuarioAlta{
    static readonly type = '[Socio] SocioUsuarioAlta'
    constructor( public socioUsuario:any){}
}

export class SocioUsuarioEdicion{
    static readonly type = '[Socio] SocioUsuarioEdicion'
    constructor( public socioUsuario:any, public idCliente:number){}
}



export class SetSocioUsuarioEmail{
    static readonly type = '[Socio] SetSocioUsuarioEmail'
    constructor( public cliente:number,public email:string){}
}

// Documentacion
export class GetDocumentacionCisterna{
    static readonly type = '[Socio] GetDocumentacionCisterna'
    constructor(public cliente:number){}
}

export class SetDocumentacionCisterna{
    static readonly type = '[Socio] SetDocumentacionCisterna'
    constructor(public cliente:number, public observacion:string){}
}

export class NewDocumentacionCisternaPlazo{
    static readonly type = '[Socio] NewDocumentacionCisternaPlazo'
    constructor(public cliente:number, public observacion:string,public fecha_desde:string,  public fecha_hasta:string, public dias_plazo:string){}
}

export class GetDocumentacionCisternaPlazos{
    static readonly type = '[Socio] GetDocumentacionCisternaPlazos'
    constructor(public cliente:number){}
}

export class DeleteDocumentacionPlazoCisterna{
    static readonly type = '[Socio] DeleteDocumentacionPlazoCisterna'
    constructor(public cliente:number,public plazo:number,public usuario:number,public usuario_autorizante:number, public observacion:string){}
}

export class DeleteDocumentacionCisterna{
    static readonly type = '[Socio] DeleteDocumentacionCisternaPlanos'
    constructor(public cliente:number,public documentacion:number,public usuario:number,public usuario_autorizante:number,public observacion:string){}
}

export class GetDocumentacionPlanos{
    static readonly type = '[Socio] GetDocumentacionPlanos'
    constructor(public cliente:number){}
}

export class SetDocumentacionPlanos{
    static readonly type = '[Socio] SetDocumentacionPlanos'
    constructor(public cliente:number, public observacion:string){}
}

export class NewDocumentacionPlanosPlazo{
    static readonly type = '[Socio] NewDocumentacionPlanosPlazo'
    constructor(public cliente:number, public observacion:string,public fecha_desde:string, public fecha_hasta:string, public dias_plazo:string){}
}

export class GetDocumentacionPlanosPlazos{
    static readonly type = '[Socio] GetDocumentacionPlanosPlazos'
    constructor(public cliente:number){}
}

export class DeleteDocumentacionPlazoPlanos{
    static readonly type = '[Socio] DeleteDocumentacionPlazoPlanos'
    constructor(public cliente:number,public plazo:number,public usuario:number,public usuario_autorizante:number,public observacion:string){}
}

export class DeleteDocumentacionPlanos{
    static readonly type = '[Socio] DeleteDocumentacionPlanos'
    constructor(public cliente:number,public documentacion:number,public usuario:number,public usuario_autorizante:number,public observacion:string){}
}

export class GetDocumentacionPlazosControl{
    static readonly type = '[Socio] GetDocumentacionPlazosControl'
    constructor(public tipo_documentacion:number,public estado_presentacion:string,public dias_a_vencer:number ){}
}


export class EnviarProcesoJudicial{
  static readonly type = '[Socio] EnviarProcesoJudicial'
  constructor(public cliente:number){}
}

export class RetirarProcesoJudicial{
  static readonly type = '[Socio] RetirarProcesoJudicial'
  constructor(public cliente:number){}
}

export class EnviarCODENE{
  static readonly type = '[Socio] EnviarCODENE'
  constructor(public cliente:number){}
}

export class RetirarCODENE{
  static readonly type = '[Socio] RetirarCODENE'
  constructor(public cliente:number){}
}

export class EnviarVERAZ{
  static readonly type = '[Socio] EnviarVERAZ'
  constructor(public cliente:number){}
}

export class RetirarVERAZ{
  static readonly type = '[Socio] RetirarVERAZ'
  constructor(public cliente:number){}
}
