import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormArray, Validators } from '@angular/forms';
import { Validacion } from "src/app/shared/validacion";

@Component({
  selector: 'app-lista-conceptos',
  templateUrl: './lista-conceptos.component.html',
  styleUrls: ['./lista-conceptos.component.css']
})
export class ListaConceptosComponent implements OnInit {

  @Input() reciboSemetral:any
  @Input() isGenerandoReciboSemestral:boolean=false

  @Output() cancelarEvent = new EventEmitter();
  @Output() generarRecibosEvent = new EventEmitter();

  conceptosForm:UntypedFormGroup;

  validacion: Validacion = new Validacion();



  constructor(private _fb:UntypedFormBuilder) {

    this.conceptosForm = this._fb.group({
      conceptos:this._fb.array([])
    })
   }

  ngOnInit(): void {
   if(!!this.reciboSemetral){
    this.initConceptosForm()
   }
  }

  get conceptosField(): UntypedFormArray{
    return (this.conceptosForm.controls.conceptos as UntypedFormArray)
  }

  initConceptosForm(){

    
    this.reciboSemetral.cuadroTarifario.forEach( (tarifaConcepto:any)=>{


      this.conceptosField.push( this._fb.group({
        regla:tarifaConcepto.regla,
        tarifa:tarifaConcepto.tarifa,
        conceptoDetalle1:tarifaConcepto.conceptoDetalle1,
        conceptoDetalle2:tarifaConcepto.conceptoDetalle2,
        conceptoDetalle3:tarifaConcepto.conceptoDetalle3,
        conceptoDetalle4:tarifaConcepto.conceptoDetalle4,
        conceptoValor1:tarifaConcepto.conceptoValor1,
        conceptoValor2:[{value:tarifaConcepto.conceptoValor2,disabled:!tarifaConcepto.habilita_edicion || !this.reciboSemetral.aplicaDescuento},Validators.required],
        conceptoValor4:[{value:tarifaConcepto.conceptoValor4,disabled:!tarifaConcepto.habilita_edicion || !this.reciboSemetral.aplicaDescuento},Validators.required],
        conceptoValor3: [tarifaConcepto.conceptoValor3,this.validacion.isMayorCero]
      }) )
    })
  }


  onChangeTarifaDescuentoAplicarDescuento(index){

    const controls = this.conceptosField.controls[index]

    if(controls.get('conceptoValor2').valid && controls.get('conceptoValor4').valid){
      controls.get('conceptoValor3').setValue( (controls.get('conceptoValor2').value*6 ) - (controls.get('conceptoValor4').value)  )
    }

  }



  onGenerarRecibosSemestrales(event: Event){
    event.preventDefault();

    if(this.conceptosForm.valid){
      this.generarRecibosEvent.emit(this.conceptosForm.value)
    }
  }

  onCancelar(){
    this.cancelarEvent.emit()
  }


}
