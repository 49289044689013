<div class="card" style="margin-bottom: 15px">
  <div class="card-header filtro" style="background-color:#17a2b8; color: white">
   Listado de Deuda/Pago - Por periodo
  </div>
  <div class="card-body">
    <div class="container">
      <div class="row justify-content-center">

        <div class="col-md-6">

          <form [formGroup]="resumenForm" (ngSubmit)="generarResumen($event)" novalidate>

            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-12">
                    <fieldset class="p-2" style="border: solid 1px #ddd">
                      <legend style="font-size: 12px">Período</legend>
                      <div class="row" style="margin-top: -15px;">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-form-label col-form-label-sm"  for="mesPeriodoInput">Mes</label>
                            <select  class="form-control form-control-sm" id="mesPeriodoInput" formControlName="mesPeriodo"
                            [ngClass]="{ 'is-invalid': valorMesPeriodoIsInvalid  }">
                                <option *ngFor="let mes of meses" [value]="mes.id">{{mes.mes}}</option>
                          </select>
                          <div *ngIf="valorMesPeriodoIsInvalid" class="invalid-feedback">
                            <small>Valor requerido</small>
                          </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-form-label col-form-label-sm"  for="anioPeriodoInput">Año</label>
                            <select  class="form-control form-control-sm" id="anioPeriodoInput" formControlName="anioPeriodo"
                            [ngClass]="{ 'is-invalid': valorAnioPeriodoIsInvalid  }">
                                <option *ngFor="let anio of aniosFacturado$|async" [value]="anio.anio">{{anio.anio}}</option>
                          </select>
                          <div *ngIf="valorAnioPeriodoIsInvalid" class="invalid-feedback">
                            <small>Valor requerido</small>
                          </div>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </div>

                <div class="row mt-1">
                  <div class="col-md-12">
                    <fieldset class="p-2" style="border: solid 1px #ddd">
                      <legend style="font-size: 12px">Tipo de listado</legend>

                        <label class="form-check-label col-form-label-sm mr-4" for="tipoListado6">
                           Listado de deuda por barrio o zona</label>


                      <div class="row ml-4">
                        <div class="row">

                            <div class="col-md-12">
                              <div class="form-check">
                                <input class="form-check-input" type="radio"  id="tipoListadoBarrioZona1" value="1" formControlName="tipoListadoPorBarrioZona">
                                <label class="form-check-label col-form-label-sm mr-4" for="tipoListadoBarrioZona1">
                                   Todos los barrios</label>
                              </div>
                            </div>



                            <div class="col-md-4">
                              <div class="form-check">
                                <input class="form-check-input" type="radio"  id="tipoListadoBarrioZona2" value="2" formControlName="tipoListadoPorBarrioZona">
                                <label class="form-check-label col-form-label-sm" for="tipoListadoBarrioZona2">
                                   Especificar un barrio</label>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <select  class="form-control form-control-sm" id="barrioInput" formControlName="barrio"
                                [ngClass]="{ 'is-invalid': valorBarrioIsInvalid  }">
                                   <option *ngFor="let barrio of barrios$|async" [value]="barrio.id">{{barrio.barrio}}</option>
                              </select>
                              <div *ngIf="valorBarrioIsInvalid" class="invalid-feedback">
                                <small>Valor requerido</small>
                              </div>
                              </div>
                            </div>

                        </div>

                        <div class="row mt-1">

                            <div class="col-md-12">
                              <div class="form-check">
                                <input class="form-check-input" type="radio"  id="tipoListadoBarrioZona3" value="3" formControlName="tipoListadoPorBarrioZona">
                                <label class="form-check-label col-form-label-sm mr-4" for="tipoListadoBarrioZona3">
                                   Todas las zonas</label>
                              </div>
                            </div>


                            <div class="col-md-4">
                              <div class="form-check">
                                <input class="form-check-input" type="radio"  id="tipoListadoBarrioZona4" value="4" formControlName="tipoListadoPorBarrioZona">
                                <label class="form-check-label col-form-label-sm" for="tipoListadoBarrioZona4">
                                   Especificar una zona</label>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <select  class="form-control form-control-sm" id="zonaInput" formControlName="zona"
                                [ngClass]="{ 'is-invalid': valorZonaIsInvalid  }">
                                <option *ngFor="let zona of zonas$|async" [value]="zona.id">{{zona.detalle}}</option>
                              </select>
                              <div *ngIf="valorZonaIsInvalid" class="invalid-feedback">
                                <small>Valor requerido</small>
                              </div>
                              </div>
                            </div>

                        </div>

                      </div>

                    </fieldset>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12" style="text-align:right;">
                    <button type="submit"  class="btn btn-sm btn-outline-primary mt-3 mb-3" [disabled]="loading || !resumenForm.valid">
                      <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                       Generar informe</button>
                  </div>
                </div>

              </div>
            </div>



          </form>

        </div>
      </div>
    </div>
  </div>
</div>


<app-visor-impresion #visorImpresion [id]="'listadoDeudaPeriodoPDFVisor'"></app-visor-impresion>
