import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {environment} from '../../environments/environment';
import { Observable } from 'rxjs';
import { NuevoCredito, DeleteCredito } from '../store/credito/models';


const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class CreditoService {

  urlRest = environment;

  constructor(private http: HttpClient) { }

  nuevoCredito(credito:NuevoCredito):Observable<boolean>{
    return this.http.post<boolean>(`${this.urlRest.urlapiNode}/creditos`,JSON.stringify({'total':credito.total,'observacion':credito.observacion,'cliente':credito.cliente,'usuario':credito.usuario,'usuario_autorizante':credito.usuarioAutorizante}),httpOptions)
   }

   getMontoTotalCredito(cliente:number):Observable<any>{
    return this.http.get<any>(`${this.urlRest.urlapiNode}/creditos/monto-total/${cliente}`)
   }

   getCreditosOtorgados(cliente:number):Observable<any>{
    return this.http.get(`${this.urlRest.urlapiNode}/creditos/${cliente}`)
   }

   getImputacionesCredito(cliente:number):Observable<any>{
    return this.http.get(`${this.urlRest.urlapiNode}/creditos/imputaciones/${cliente}`)
   }

   deleteCredito(credito:DeleteCredito):Observable<any>{
    return this.http.delete(`${this.urlRest.urlapiNode}/creditos/${credito.credito}/${credito.usuario}`)
   }


   getComprobanteCreditoOtorgado(credito): Observable<any>{

    return this.http.get(`${this.urlRest.urlapiNode}/creditos/comprobante/${credito}`,{
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      }),
      responseType: 'blob'
  })
   }

   getComprobanteCreditoSaldo(cliente): Observable<any>{

    return this.http.get(`${this.urlRest.urlapiNode}/creditos/comprobante/saldo/${cliente}`,{
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      }),
      responseType: 'blob'
  })


   }


   getListadoCreditoAImputar(): Observable<any>{
    return this.http.get(`${this.urlRest.urlapiNode}/creditos/listado/a-imputar`)

   }

   creditoImputarSobreFacturasAdeudadas(pagos:any,cliente:number,usuario:number,usuarioAutorizante:number): Observable<any>{
    return this.http.put(`${this.urlRest.urlapiNode}/creditos/imputar/facturas`,JSON.stringify({'pagos':pagos,'cliente':cliente,'usuario':usuario,'usuario_autorizante':usuarioAutorizante}),httpOptions)
  }

   imputarCreditos(): Observable<any>{
    return this.http.post(`${this.urlRest.urlapiNode}/creditos/imputar`,httpOptions)
   }

   deleteImputacion(cliente:number,imputacion:any[],periodo:string): Observable<any>{
    return this.http.post(`${this.urlRest.urlapiNode}/creditos/imputacion/delete`,JSON.stringify({'cliente':cliente,'imputacion':imputacion,'periodo':periodo}),httpOptions)
   }

}
