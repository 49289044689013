import { Component, OnInit, ViewChild, OnDestroy} from '@angular/core';
import { Store } from '@ngxs/store';

import { Subject } from 'rxjs';

import { BusquedaAvanzadaComponent } from './busqueda-avanzada/busqueda-avanzada.component';
import { SocioNavigationService } from '../socio-navigation.service';
import Swal from 'sweetalert2';

declare var $:any;

@Component({
  selector: 'app-busqueda-socio',
  templateUrl: './busqueda-socio.component.html',
  styleUrls: ['./busqueda-socio.component.css']
})
export class BusquedaSocioComponent implements OnInit,OnDestroy {

@ViewChild(BusquedaAvanzadaComponent, { static: true })
busquedaAvanzadaComponent: BusquedaAvanzadaComponent;


  socio_busqueda:string;




  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private store:Store, private sns:SocioNavigationService) {
   }

  ngOnInit() {


  }



  buscarSocio(){

    const divisionCodigoSubcodigo = this.socio_busqueda.split('/');
    let codigo;
    let subcodigo;


    if (divisionCodigoSubcodigo.length>1){
      codigo =  +divisionCodigoSubcodigo[0]
      subcodigo = +divisionCodigoSubcodigo[1]
    }else{

      codigo = +divisionCodigoSubcodigo[0];
      subcodigo = 0;
    }


   if (Number.isNaN(codigo) || Number.isNaN(subcodigo) ){
    Swal.fire(
      '',"El socio/usuario ingresado es incorrecto!",
      'error'
    )
   }else{

    this.sns.goToSocio(codigo,subcodigo)

   }




  }

  clienteSeleccionado(socio){

    $('#busquedaAvanzadaModal').modal('hide');

    this.sns.goToSocio(socio.codigo,socio.subCodigo)
  }


  openBusquedaAvanzada(){
    this.busquedaAvanzadaComponent.openBusquedaAvanzada()
  }


  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}
