<div class="card" style="margin-bottom: 15px">
    <div class="card-header filtro" style="background-color:#17a2b8; color: white">
     Comprobantes de Pago
    </div>
    <div class="card-body">

      <div class="row">
        <div class="col-md-12">
            <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="filtros" id="exampleRadios1" value="fecha" checked (change)="onSelectionChange($event)">
                <label class="form-check-label mr-4" for="exampleRadios1">
                    Por Fecha
                </label>
              </div>

              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="filtros" id="exampleRadios2"  value="socio" (change)="onSelectionChange($event)">
                <label class="form-check-label mr-4" for="exampleRadios2">
                    Por Socio
                </label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="filtros" id="exampleRadios3"  value="numeroComprobante" (change)="onSelectionChange($event)" >
                <label class="form-check-label" for="exampleRadios3">
                    Por N° Comprobante
                </label>
              </div>
              <hr>
              <form *ngIf="filtrarPorFecha" (ngSubmit)="aplicarFiltroFecha()" #fechaForm="ngForm"><!--Form fecha-->
                <div class="form-row align-items-center">
                  <div class="col-1 text-right">
                    <label for="fecha_desde_comprobante">Fecha desde</label>
                  </div>
                  <div class="col-2">
                    <input id="fecha_desde_comprobante" type="date" [(ngModel)]="filtroFecha.fecha_desde" name="fecha_desde" class="form-control" placeholder="Fecha desde" required>
                  </div>
                  <div class="col-1 text-right">
                    <label for="fecha_hasta_comprobante">Fecha hasta</label>
                  </div>
                  <div class="col-2">
                    <input id="fecha_hasta_comprobante" type="date" [(ngModel)]="filtroFecha.fecha_hasta" name="fecha_hasta" class="form-control" placeholder="Fecha hasta" required>
                  </div>
                  <div class="col-1">
                    <button type="submit" class="btn btn-info" [disabled]="!fechaForm.form.valid">Buscar</button>
                  </div>
                  <div class="col-1"> <div *ngIf="loadingBusquedaComprobante$ | async "><i class='fa fa-refresh fa-spin fa-2x'></i></div> </div>
                </div>
              </form>
              <br>
              <form *ngIf="filtrarPorSocio" (ngSubmit)="aplicarFiltroSocio()" #socioForm="ngForm"><!--Form Cod socio-->
                <div class="form-row">
                  <div class="col-2">
                    <input type="text" [(ngModel)]="filtroSocio.cod_socio" name="cod_socio" class="form-control" placeholder="Codigo de socio" required appNumberDecimalOnly>
                  </div>
                  <div class="col-1">
                    <input type="text" [(ngModel)]="filtroSocio.sub_cod_socio" name="sub_cod_socio" class="form-control" placeholder="Subcodigo" required appNumberDecimalOnly>
                  </div>
                  <div class="col-2">
                    <button type="submit" class="btn btn-info" [disabled]="!socioForm.form.valid">Buscar</button>
                  </div>
                  <div class="col-1"> <div *ngIf="loadingBusquedaComprobante$ | async "><i class='fa fa-refresh fa-spin fa-2x'></i></div> </div>
                </div>
              </form>
              <form *ngIf="filtrarPorNumeroComprobante" (ngSubmit)="aplicarFiltroNumeroComprobante()" #numeroComprobanteForm="ngForm"><!--For N| comprbante-->
                <div class="form-row">
                  <div class="col-3">
                    <input type="text" [(ngModel)]="filtroNumeroComprobante.numero_comprobante" name="numero_comprobante" class="form-control" placeholder="Ingrese N° de comprobante" required appNumberDecimalOnly>
                  </div>
                  <div class="col-2">
                    <button type="submit" class="btn btn-info" [disabled]="!numeroComprobanteForm.form.valid">Buscar</button>
                  </div>
                  <div class="col-1"> <div *ngIf="loadingBusquedaComprobante$ | async "><i class='fa fa-refresh fa-spin fa-2x'></i></div> </div>
                </div>
              </form>
        </div>
      </div>
      <br>
      <div class="row">
          <div class="col-md-12">
            <span><b>Total: {{total}}</b></span>
          </div>
        </div>
        <br>
        <div class="row">
            <div class="col-md-12">
                  <ag-grid-angular
                  style="height: 350px;"
                  class="ag-theme-alpine"
                  [rowData]="rowData"
                  [columnDefs]="columnDefs"
                  [colResizeDefault]="'fit'"
                  [rowSelection]= "'single'"
                  [components]="frameworkComponents"
                  [rowHeight]="40"
                  ></ag-grid-angular>
            </div>
          </div>



    </div>
  </div>

<!--comienzo del modal-->
<div *ngIf="!comprobanteSeleccionado==false" class="modal" tabindex="-1" role="dialog" id="detalle_comprobante">
  <div class="modal-dialog modal-dialog-scrollable modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header" style="background-color: #e6e6e6">
        <h5 class="modal-title">Comprobante Nº {{comprobanteSeleccionado.id_compcaja}}</h5>
      </div>
      <div class="modal-body">
        <table class="table table-borderless">
          <tbody>
              <tr>
                  <td><b>Socio:</b> {{comprobanteSeleccionado.facturas[0].codSocio + '/' + comprobanteSeleccionado.facturas[0].subCodSocio}}</td>
                <td><b>Nombre:</b> {{comprobanteSeleccionado.facturas[0].nombre}}</td>
                <td><b>Fecha:</b> {{comprobanteSeleccionado.fecha |  date :'dd/MM/yyyy h:mm:ss a '}}</td>
              </tr>
              <tr>
                <td><b>Total:</b> ${{comprobanteSeleccionado.total}}</td>
                <td><b>Descuento recargo:</b> {{comprobanteSeleccionado.descuento_recargo_porcentaje}}%</td>
                <td><b>Cobrado:</b> ${{comprobanteSeleccionado.cobrado}}</td>
              </tr>
              <tr>
                  <td><b>Cantidad de facturas abonadas: </b> {{comprobanteSeleccionado.facturas.length}}</td>
              </tr>
              <tr>
                  <td><b>Detalle de facturas:</b></td>
              </tr>
          </tbody>

        </table>

        <table class="table table-borderless table-striped">
          <thead class="bg-info" style="color: white">
            <th>Periodo</th>
            <th>Factura</th>
            <th>Total</th>
          </thead>
            <tbody>
                <tr *ngFor="let factura of comprobanteSeleccionado.facturas">
                   <td>{{factura.id_periodo}}</td>
                   <td>{{factura.id_factura}}</td>
                   <td>{{obtenerTotal(factura)}}</td>
                </tr>

            </tbody>

          </table>

      </div>
      <div class="modal-footer">
        <!-- <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button> -->
        <button type="button" class="btn btn-success" (click)="cerrarVisualizador()">Aceptar</button>
      </div>
    </div>
  </div>
</div>

<app-operacion-loading></app-operacion-loading>

<app-operacion-si-no [titulo]="'Cobranza Múltiple'" [contenidoMensaje]="'Desea anular la cobranza? Recuerde que al anular las facturas serán marcadas como impagas!'"
(retornoOperacion)="retornoOperacionSiNo($event)"></app-operacion-si-no>

<app-autorizacion (retornoOperacion)="retornoAutorizacion($event)"></app-autorizacion>


