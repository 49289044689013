import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccionesComponent } from './acciones.component';
import { AgGridModule } from 'ag-grid-angular';
import { NuevaAccionComponent } from './nueva-accion/nueva-accion.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { AutorizacionModule } from '../autorizacion/autorizacion.module';



@NgModule({
  declarations: [AccionesComponent, NuevaAccionComponent],
  imports: [
    CommonModule,
    AgGridModule,
    ReactiveFormsModule,
    SharedModule,
    AutorizacionModule
  ],
  exports:[AccionesComponent]
})
export class AccionesModule { }
