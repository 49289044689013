import { Acciones } from './model'

export class GetAccionesCliente{
  static readonly type = '[Acciones] GetAccionesCliente'
    constructor(public cliente:number, public update:boolean){}
}


export class AddAccionesCliente{
  static readonly type = '[Acciones] AddAccionesCliente'
    constructor(
      public cliente:number,
      public fechaSubscripcion:string,
      public cuotas:number,
      public valorCuota:number,
      public importeTotal:number,
      public cuotasAbonadas:number){}
}

export class UpdateAccionesCliente{
  static readonly type = '[Acciones] UpdateAccionesCliente'
    constructor(
      public accion:number,
      public cliente:number,
      public fechaSubscripcion:string,
      public cuotas:number,
      public valorCuota:number,
      public importeTotal:number,
      public cuotasAbonadas:number,
      public observacion:string
      ){}
}

export class DeleteAccionesCliente{
  static readonly type = '[Acciones] DeleteAccionesCliente'
    constructor(
      public cliente:number,
      public accion:number,
      public usuario_autorizante:number,
      public observacion:string
      ){}
}


export class ResetAccionesCliente{
  static readonly type = '[Acciones] ResetAccionesCliente'
  constructor( ){}
}
