
export class ExportarArchivo {

    exportarArchivo(contenido:any,nombreArchivo,extensionArchivo=''){

        let url = window.URL.createObjectURL(contenido);

        if(extensionArchivo!==''){
          extensionArchivo='.'+extensionArchivo
        }

         const anchor = document.createElement('a');
        anchor.download = nombreArchivo +extensionArchivo;
        anchor.href = url;
        anchor.style.display="none";
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);

    }

     static exportarArchivoToXLS(idTable:string,nameFile:string){
      var location = 'data:application/vnd.ms-excel;name=document.xls;base64,';
      var excelTemplate = '<html> ' +
        '<head > ' +
             '<meta http-equiv="content-type" content="text/plain; charset=UTF-8"/> ' +
        '</head> ' +
        '<body> ' +
        document.getElementById(`${idTable}`).innerHTML +
        '</body> ' +
        '</html>'


      var link = document.createElement("a");
		link.download = `${nameFile}.xls`;
		link.href = location + window.btoa(excelTemplate);

		document.body.appendChild(link);
		link.click();

		// Cleanup the DOM
		document.body.removeChild(link);

     }
}


