import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { CobranzaMultipleComponent } from './cobranza/cobranza-multiple/cobranza-multiple.component';


import { LayoutComponent } from './layout/layout.component';

import { ListadoComprobantesComponent } from './cobranza/listado-comprobantes/listado-comprobantes.component';

import { AuthGuard } from './login/auth/auth.guard';
import { SistemaVersionGuard } from './sistema-configuracion/sistema-version.guard';
import { ActualizarSistemaComponent } from './sistema-configuracion/actualizar-sistema/actualizar-sistema.component';




const routes: Routes = [

  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'caja/multiple',
        component: CobranzaMultipleComponent
      },
      {
        path: 'caja/comprobante',
        component: ListadoComprobantesComponent
      },
      {
        path: 'convenio-por-servicio',
        loadChildren: () => import('./convenio-de-pago-por-servicio/convenio-por-servicio.module').then(m => m.ConvenioPorServicioModule)
      },
      {
        path: 'bpn',
        loadChildren: () => import('./bpn/bpn.module').then(m => m.BpnModule)
      },
      {
        path: 'beneficio',
        loadChildren: () =>
          import('./beneficios/beneficios.routes')
              .then(m => m.BENEFICIOS_ROUTES)
      },
      {
        path: 'socio',      
        loadChildren: () => import('./socio-gestion/socio-gestion.module').then(m => m.SocioGestionModule)
      },
      {
        path: 'general',
        loadChildren: () => import('./general/general.module').then(m => m.GeneralModule)
      },
      {
        path: 'recibo-semestral',
        loadChildren: () => import('./recibo-semestral/recibo-semestral.module').then(m => m.ReciboSemestralModule)
      },
      {
        path: 'recibos',      
        loadChildren: () => import('./recibo-simple/recibo-simple.module').then(m => m.ReciboSimpleModule)
      },

      {
        path: 'manuales',
        loadChildren: () => import('./manuales/manuales.module').then(m => m.ManualesModule)
      },
      {
        path: 'red-link',
        loadChildren: () => import('./red-link/red-link.module').then(m => m.RedLinkModule)
      },
      {
        path: 'pago-facil',
        loadChildren: () => import('./pago-facil/pago-facil.module').then(m => m.PagoFacilModule)
      },
      {
        path: 'cobranzas',
        loadChildren: () => import('./cobranza/cobranza.module').then(m => m.CobranzaModule)
      },
      {
        path: 'utilidades',
        loadChildren: () => import('./utilidades/utilidades.module').then(m => m.UtilidadesModule)
      },
      {
        path: 'afip',
        loadChildren: () => import('./afip/afip.module').then(m => m.AfipModule)
      },
      {
        path: 'pago-mis-cuentas',
        loadChildren: () => import('./pago-mis-cuentas/pago-mis-cuentas.module').then(m => m.PagoMisCuentasModule)
      },

      {
        path: 'notas-de-credito',
        loadChildren: () => import('./notas-de-credito/notas-de-credito.module').then(m => m.NotasDeCreditoModule)
      },
      {
        path: 'facturas',
        loadChildren: () => import('./factura/factura.module').then(m => m.FacturaModule)
      }
      ,
      {
        path: 'factura-digital',
        loadChildren: () => import('./factura-digital/factura-digital.module').then(m => m.FacturaDigitalModule)
      }
      ,
      {
        path: 'servicio-medido',
        loadChildren: () => import('./servicio-medido/servicio-medido.module').then(m => m.ServicioMedidoModule)
      },
      {
        path: 'barrios',
        loadChildren: () => import('./barrio/barrio.module').then(m => m.BarrioModule)
      },
      {
        path: 'configuracion-sistema',
        loadChildren: () => import('./sistema-configuracion/sistema-configuracion.module').then(m => m.SistemaConfiguracionModule)
      }
      ,
      {
        path: 'socio-reportes',
        loadChildren: () => import('./socio-reportes/socio-reportes.module').then(m => m.SocioReportesModule)
      },
      {
        path: 'zonas',
        loadChildren: () => import('./zonas/zonas.module').then(m => m.ZonasModule)
      },
      {
        path: 'conceptos',
        loadChildren: () => import('./conceptos/conceptos.module').then(m => m.ConceptosModule)
      },
      {
        path: 'conexiones',
        loadChildren: () => import('./conexion/conexion.module').then(m => m.ConexionModule)
      },
      {
        path: 'roles-permisos',
        loadChildren: () => import('./roles-permisos/roles-permisos.module').then(m => m.RolesPermisosModule)
      },
      {
        path: 'usuarios',
        loadChildren: () => import('./usuario/usuario.module').then(m => m.UsuarioModule)
      },
      {path: 'cajas', 
      loadComponent: () => import('./cajas/cajas.component').then(mod => mod.CajasComponent)},
      {path: 'notificaciones', 
        loadComponent: () => import('./notificaciones/notificacion-buzon/notificacion-buzon.component').then(mod => mod.NotificacionBuzonComponent)},
        {
          path: 'tarifas',
          loadChildren: () =>
            import('./tarifas/tarifas.routes')
                .then(m => m.TARIFAS_ROUTES)
        },



    ]
  },

  {
    path: 'login',
    canActivate: [SistemaVersionGuard],
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'actualizar-sistema',
    component: ActualizarSistemaComponent
  },
  { path: '**', redirectTo: '' }
];



@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
