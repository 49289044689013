export const meses= [
  {mes:"enero",id:"01"},
  {mes:"febrero",id:"02"},
  {mes:"marzo",id:"03"},
  {mes:"abril",id:"04"},
  {mes:"mayo",id:"05"},
  {mes:"junio",id:"06"},
  {mes:"julio",id:"07"},
  {mes:"agosto",id:"08"},
  {mes:"septiembre",id:"09"},
  {mes:"octubre",id:"10"},
  {mes:"noviembre",id:"11"},
  {mes:"diciembre",id:"12"},
]


export enum OperacionesABM {
  alta,
  editar,
  baja,
  noCorresponde
}


export enum TipoPresentacion {
facturacion,cobranzas
}


