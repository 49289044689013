<div class="card  mt-3">
  <div class="card-header card-header-info">
    <b>Total Adeudado</b>
  </div>
  <div class="card-body" style="padding: 0px;">
  <table class="table table-responsive text-center" style="margin: 0px">
      <tr>
        <td>Facturas adeudadas</td>
        <td><b>Total recargo incluido</b></td>
        <td>Recargo</td>
      </tr>
      <tr>
        <td class="text-muted">
          <h5>{{totalFacturas}}</h5>
        </td>
        <td class="text-muted">
          <h5><b>${{totalAdeudado | formatDecimal}}</b></h5>
        </td>
        <td class="text-muted">
          <h5>${{totalRecargo | formatDecimal}}</h5>
        </td>
      </tr>
    </table>

  </div>
</div>
