import { Cesp } from './models';

export class GetCespList{
  static readonly type = '[Afip] GetCespList'
  constructor(){}
}

export class AddCesp{
    static readonly type = '[Afip] AddCesp'
    constructor(public cesp:string, public fechaProceso:string, public fechaValidoDesde:string, public fechaValidoHasta:string){}
}


export class UpdateCesp{
  static readonly type = '[Afip] UpdateCesp'
  constructor(public idCesp:number,public cesp:string, public fechaProceso:string, public fechaValidoDesde:string, public fechaValidoHasta:string){}
}

