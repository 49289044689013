export interface Permiso {
    id_permiso: number;
    permiso?: string;
}

export interface PermisoConSeleccion extends Permiso {
seleccionado:boolean
}



export enum TipoPermisos {
    ABMConvenios = 1,
    ImpresionListadoDeudaUsuarios = 2,
    ImpresionListadoDeDeudaPagoPorPeriodo = 3,
    ProcesoDeFacturacion = 4,
    ProcesoDeFacturacionMensual = 5,
    GeneracionReciboSemestral = 6,
    GeneracionNotasDeCreditos = 7,
    OpcionesAdministracion = 9,
    VerListadoGeneralDeSocios = 10,
    EliminarCreditosImputados = 11,
    ABMUsuarios = 12,
    HabilitaDescuentoSobreIntereses = 13,
    ABMSituacionEspecialConVencimiento=14
    
}

export interface Rol {
    id_rol: number;
    rol:string;
    permisos?: string;
}