import { Component, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngxs/store';
import { GetPeriodosFacturacionMensual } from 'src/app/store/periodo/actions';
import { Periodo } from 'src/app/store/periodo/model';
import { Observable } from 'rxjs';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { OperacionInformacionComponent } from 'src/app/shared/operacion-informacion/operacion-informacion.component';
import { OperacionSiNoComponent } from 'src/app/shared/operacion-si-no/operacion-si-no.component';
import { FacturaService } from '../factura.service';


@Component({
  selector: 'app-proceso-de-facturacion-mensual',
  templateUrl: './proceso-de-facturacion-mensual.component.html',
  styleUrls: ['./proceso-de-facturacion-mensual.component.css']
})
export class ProcesoDeFacturacionMensualComponent implements OnInit {

  @ViewChild("informacionProcesoFacturacionMensual", { static: true })
  operacionInformacionComponent: OperacionInformacionComponent;

  @ViewChild(OperacionSiNoComponent, { static: true })
  operacionSiNoComponent: OperacionSiNoComponent;

  periodosFacturacion$: Observable<Periodo[]>

  facturacionMensualForm: UntypedFormGroup
  fechaPrimerVencimiento:string
  fechaSegundoVencimiento:string



  mensajeInformacion:""

  valorProgresoFacturacion = 0

  estaProcesandoFacturacion=false
  esFacturacionExitosa=false
  totalFacturado = 0
  estaProcesandoGuard=false

  constructor(private store:Store,private fb:UntypedFormBuilder, private fs:FacturaService) { }

  ngOnInit(): void {
    this.store.dispatch(new GetPeriodosFacturacionMensual())
    this.periodosFacturacion$ = this.store.select(state => state.periodo.periodosFacturacionMensual);

    this.initForm();
  }

  initForm(): void{
    this.facturacionMensualForm = this.fb.group({
      periodoFacturacion:[null,Validators.required],
      fechaFacturacion:[new Date().toISOString().substring(0, 10),Validators.required]
    })


  }

  get periodoFacturacionField(){
    return this.facturacionMensualForm.controls.periodoFacturacion
  }

  get fechaFacturacionField(){
    return this.facturacionMensualForm.controls.fechaFacturacion
  }

  get periodoFacturacionIsInvalid(){
    return this.periodoFacturacionField.touched && this.periodoFacturacionField.invalid
  }

  get fechaFacturacionIsInvalid(){
    return this.fechaFacturacionField.touched && this.fechaFacturacionField.invalid
  }


  onSubmit(event){
    event.preventDefault();
    if(this.facturacionMensualForm.valid){
      this.operacionSiNoComponent.open()
    }
  }


  retornoOperacionSiNo(respuesta){

    if (respuesta) {
       // indicamos que comenzo el proceso de facturacion
    this.estaProcesandoFacturacion = true
    this.estaProcesandoGuard = true
    this.valorProgresoFacturacion = 0
    // seteamos un intervalo el cual cada 4 segundo incrementa la barra de progreso
    // lo hacemos para que el usuario note que se esta realizando la operacion
    let timerId = setInterval(() => {
      if(this.valorProgresoFacturacion===100){
        this.valorProgresoFacturacion=50
      }
      this.valorProgresoFacturacion+=1

    }, 8000);

    // generamos el objeto a enviar y desabilitamos los elementos para que no puedan ser modificados

    this.deshabilitarDatos()


    this.fs.generarFacturacionMensual({"ID_Periodo": this.periodoFacturacionField.value,"FechaFacturacion":this.fechaFacturacionField.value}).subscribe(
      (facturacionReturn => {
  
         clearInterval(timerId)
        if(facturacionReturn.success){
          this.valorProgresoFacturacion = 100
          this.esFacturacionExitosa = true
          this.totalFacturado = facturacionReturn.total_facturado
          this.estaProcesandoGuard = false
        }else{
          this.mensajeInformacion = facturacionReturn.message
          this.operacionInformacionComponent.open();
          this.habilitarDatos()
        }

      } ),
      (error=>{
        this.habilitarDatos()
      })
    )
    }
}


habilitarDatos(){
  this.valorProgresoFacturacion = 100
  this.estaProcesandoFacturacion = false
  this.estaProcesandoGuard = false
  this.fechaFacturacionField.enable()
  this.periodoFacturacionField.enable()
}

deshabilitarDatos(){

  this.fechaFacturacionField.disable()
  this.periodoFacturacionField.disable()

}

onExit(){

  if (this.estaProcesandoGuard){
   alert('No podrá salir hasta que el proceso de facturación finalice!')
  return false;
  }

  return true;
}


}
