import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import {
  SocioDetalle,
  SocioBusquedaAvanzada,
  PresentacionDocumentacion,
  PlazoPresentacionDocumentacion,
  SocioIdentificacion
} from "../store/socio/models";
import { Socio } from "./socio";
import { CuentaCorriente } from '../store/cuentacorriente/model';


const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
  }),
};

@Injectable({
  providedIn: "root",
})
export class SocioGestionService {
  urlRest = environment;
  private socio: Socio = new Socio();

  constructor(private http: HttpClient) {}

  getSociosListado(): Observable<any[]> {
    return this.http.get<any[]>(`${this.urlRest.urlapiNode}/clientes`);
  }

  getSocioDetalle(
    codSocio: string,
    subCodSocio: string
  ): Observable<SocioDetalle> {
    return this.http.get<SocioDetalle>(
      `${this.urlRest.urlapiNode}/clientes/detalle/${codSocio}/${subCodSocio}`
    );
  }

  getClienteObservaciones(idCliente: number): Observable<string> {
    return this.http.get<string>(
      `${this.urlRest.urlapiNode}/clientes/observaciones/${idCliente}`
    );
  }

  getClienteCuentaCorriente(
    idCliente: number
  ): Observable<CuentaCorriente[]> {
    return this.http.get<CuentaCorriente[]>(
      `${this.urlRest.urlapiNode}/clientes/cuenta-corriente/${idCliente}`
    );
  }

  getSocios(
    textoBusqueda: string,
    campoBusqueda: string
  ): Observable<SocioBusquedaAvanzada[]> {
    let params = new HttpParams()
      .set("textoBusqueda", textoBusqueda)
      .set("campoBusqueda", campoBusqueda);

    return this.http.get<SocioBusquedaAvanzada[]>(
      `${this.urlRest.urlapiNode}/clientes/busqueda`,
      { params: params }
    );
  }

  socioUsuarioChequearExistencia(
    codSocio: string,
    subCodSocio: string
  ): Observable<any> {
    return this.http.get(
      `${this.urlRest.urlapiNode}/clientes/existe/${codSocio}/${subCodSocio}`
    );
  }

  getSocioNuevo(): SocioDetalle {
    return this.socio.nuevoSocioUsuario();
  }

  altaSocioUsuario(socioUsuario: any) {
    return this.http.post(
      `${this.urlRest.urlapiNode}/clientes`,
      { cliente: socioUsuario },
      httpOptions
    );
  }

  edicionSocioUsuario(socioUsuario: any,idCliente:number): Observable<any> {
    return this.http.put(
      `${this.urlRest.urlapiNode}/clientes`,
      { cliente: socioUsuario,idCliente },
      httpOptions
    );
  }

  updateObservacionSocioUsuario(
    idCliente: number,
    nota: string,
    usuario: string
  ): Observable<boolean> {
    return this.http.put<boolean>(
      `${this.urlRest.urlapiNode}/clientes/observaciones`,
      { idCliente: idCliente, nota: nota, usuario: usuario },
      httpOptions
    );
  }

  getDocumentacionCisterna(
    cliente: number
  ): Observable<PresentacionDocumentacion> {
    return this.http.get<PresentacionDocumentacion>(
      `${this.urlRest.urlapiNode}/clientes/documentacion/cisterna/${cliente}`
    );
  }

  updateEmailSocioUsuario(cliente: number, email: string): Observable<boolean> {
    return this.http.put<boolean>(
      `${this.urlRest.urlapiNode}/clientes/email`,
      { cliente, email },
      httpOptions
    );
  }

  // DOCUMENTACION CISTERNA PLANOS

  setDocumentacionCisterna(
    cliente: number,
    observacion: string
  ): Observable<any> {
    return this.http.post(
      `${this.urlRest.urlapiNode}/clientes/documentacion/cisterna`,
      JSON.stringify({ cliente, observacion }),
      httpOptions
    );
  }

  newDocumentacionCisternaPlazo(
    cliente: number,
    observacion: string,
    fecha_desde: string,
    fecha_hasta: string,
    dias_plazo: string
  ): Observable<any> {
    return this.http.post(
      `${this.urlRest.urlapiNode}/clientes/documentacion/cisterna/plazo`,
      JSON.stringify({
        cliente,
        observacion,
        fecha_desde,
        fecha_hasta,
        dias_plazo,
      }),
      httpOptions
    );
  }

  getDocumentacionCisternaPlazos(
    cliente: number
  ): Observable<PlazoPresentacionDocumentacion[]> {
    return this.http.get<PlazoPresentacionDocumentacion[]>(
      `${this.urlRest.urlapiNode}/clientes/documentacion/cisterna/plazos/${cliente}`
    );
  }

  deleteDocumentacionPlazo(
    plazo: number,
    usuario: number,
    usuario_autorizante: number,
    observacion:string,
    cliente:number
  ): Observable<any> {
    let params = new HttpParams()
      .set("plazo", plazo.toString())
      .set("usuario", usuario.toString())
      .set("usuario_autorizante", usuario_autorizante.toString())
      .set("observacion", observacion)
      .set("cliente", cliente.toString());
    return this.http.delete(
      `${this.urlRest.urlapiNode}/clientes/documentacion/plazo`,
      { params: params }
    );
  }

  deleteDocumentacion(
    documentacion: number,
    usuario: number,
    usuario_autorizante: number,
    observacion:string,
    cliente:number
  ): Observable<any> {
    let params = new HttpParams()
      .set("documentacion", documentacion.toString())
      .set("usuario", usuario.toString())
      .set("usuario_autorizante", usuario_autorizante.toString())
      .set("observacion", observacion)
      .set("cliente", cliente.toString())
      ;
    return this.http.delete(
      `${this.urlRest.urlapiNode}/clientes/documentacion`,
      { params: params }
    );
  }

  createDeclaracionCisternaDocumentacionPlazo(
    cliente: number,
    plazo: number
  ): Observable<any> {
    return this.http.get(
      `${this.urlRest.urlapiNode}/clientes/documentacion/plazo/cisterna/declaracion/${cliente}/${plazo}`,
      { responseType: "blob" }
    );
  }

  getDocumentacionPlanos(
    cliente: number
  ): Observable<PresentacionDocumentacion> {
    return this.http.get<PresentacionDocumentacion>(
      `${this.urlRest.urlapiNode}/clientes/documentacion/planos/${cliente}`
    );
  }

  setDocumentacionPlanos(
    cliente: number,
    observacion: string
  ): Observable<any> {
    return this.http.post(
      `${this.urlRest.urlapiNode}/clientes/documentacion/planos`,
      JSON.stringify({ cliente, observacion }),
      httpOptions
    );
  }

  newDocumentacionPlanosPlazo(
    cliente: number,
    observacion: string,
    fecha_desde: string,
    fecha_hasta: string,
    dias_plazo: string
  ): Observable<any> {
    return this.http.post(
      `${this.urlRest.urlapiNode}/clientes/documentacion/planos/plazo`,
      JSON.stringify({
        cliente,
        observacion,
        fecha_desde,
        fecha_hasta,
        dias_plazo,
      }),
      httpOptions
    );
  }

  getDocumentacionPlanosPlazos(
    cliente: number
  ): Observable<PlazoPresentacionDocumentacion[]> {
    return this.http.get<PlazoPresentacionDocumentacion[]>(
      `${this.urlRest.urlapiNode}/clientes/documentacion/planos/plazos/${cliente}`
    );
  }

  createDeclaracionPlanosDocumentacionPlazo(
    cliente: number,
    plazo: number
  ): Observable<any> {
    return this.http.get(
      `${this.urlRest.urlapiNode}/clientes/documentacion/plazo/planos/declaracion/${cliente}/${plazo}`,
      { responseType: "blob" }
    );
  }

  getDocumentacionPlazosControl(
    tipo_documento: number,
    estado_presentacion: string,
    dias_a_vencer: number
  ): Observable<PlazoPresentacionDocumentacion[]> {
    return this.http.get<PlazoPresentacionDocumentacion[]>(
      `${this.urlRest.urlapiNode}/clientes/documentacion/cisterna/planos/control/${tipo_documento}/${estado_presentacion}/${dias_a_vencer}`
    );
  }

  enviarNotificacionDocumentacionPlazos(
    plazo: number,
    nombre: string,
    email: string,
    tipo_documentacion: string,
    fecha_hasta: string
  ): Observable<any> {
    return this.http.post(
      `${this.urlRest.urlapiNode}/clientes/documentacion/notificacion/enviar`,
      JSON.stringify({ plazo, nombre, email, tipo_documentacion, fecha_hasta }),
      httpOptions
    );
  }

  enviarProcesoJudicial(cliente: number): Observable<any> {
    return this.http.put(
      `${this.urlRest.urlapiNode}/observaciones/proceso-judicial/enviar`,
      { cliente },
      httpOptions
    );
  }
  retirarProcesoJudicial(cliente: number): Observable<any> {
    return this.http.put(
      `${this.urlRest.urlapiNode}/observaciones/proceso-judicial/retirar`,
      { cliente },
      httpOptions
    );
  }

  enviarCODENE(cliente: number): Observable<any> {
    return this.http.put(
      `${this.urlRest.urlapiNode}/observaciones/codene/enviar`,
      { cliente },
      httpOptions
    );
  }
  retirarCODENE(cliente: number): Observable<any> {
    return this.http.put(
      `${this.urlRest.urlapiNode}/observaciones/codene/retirar`,
      { cliente },
      httpOptions
    );
  }

  enviarVERAZ(cliente: number): Observable<any> {
    return this.http.put(
      `${this.urlRest.urlapiNode}/observaciones/veraz/enviar`,
      { cliente },
      httpOptions
    );
  }

  retirarVERAZ(cliente: number): Observable<any> {
    return this.http.put(
      `${this.urlRest.urlapiNode}/observaciones/veraz/retirar`,
      { cliente },
      httpOptions
    );
  }

  // Recibos

  getSocioIdentificacion(
    codSocio: number,
    subCodSocio:number
  ): Observable<SocioIdentificacion> {
    return this.http.get<SocioIdentificacion>(
      `${this.urlRest.urlapiNode}/clientes/identificacion/${codSocio}/${subCodSocio}`
    );
  }


  // libre deuda
  getLibreDeuda(cliente:number):Observable<any>{
    return this.http.get(`${this.urlRest.urlapiNode}/clientes/libre-deuda/${cliente}`,{responseType: 'blob'})
  }


}
