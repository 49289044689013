<div class="card">
    <div class="card-header" style="background-color:#17a2b8; color: white">Recibo Semestral - Generar Archivos de Rendición</div>
    <div class="card-body">
            <div class="col-6">
                    <ng-container *ngIf="!estaProcesando; else procesando">
                        <button type="button" class="btn btn-success" (click)="generarArchivosDeRendicion()">Generar archivos de rendición</button>
                    </ng-container>
                    <ng-template #procesando>
                        <button class="btn btn-info" type="button">
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            <span class="ml-2">Generando archivos. Esta operacion puede demorar algunos minutos ...</span>
                        </button>
                    </ng-template>
                </div>
    </div>
</div>
