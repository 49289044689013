
import  printJS from "print-js"

export class Imprimir {

    constructor() { }

    imprimir(contenido:any){

        let url = window.URL.createObjectURL(contenido);

        printJS(url);


    }

  openInNewWindows(contenido:any, offset=0){
    let url = window.URL.createObjectURL(contenido);
      window.open(url, '_blank', `width=1000,height=600,resizable,scrollbars=yes,status=1,top=${offset},left=${offset}`)

  }

    imprimirMultiple(contenido:any[]){

        /* let urls:SafeUrl[]=[];

        contenido.forEach(element=>
            urls.push(this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(element)))
            )

            console.log(urls)


        printJS({printable:urls, type: 'pdf'});   */


    }


}
