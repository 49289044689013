<div class="modal" tabindex="-1" role="dialog" id="calleModal">
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content">      
      <div class="modal-body">
       <app-calle></app-calle>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" (click)="finalizar()">
        Finalizar
        </button>        
      </div>
    </div>
  </div>
</div>
