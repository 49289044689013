import { Component, ViewChild } from '@angular/core';
import { Store } from '@ngxs/store';
import { OperacionLoadingComponent } from 'src/app/shared/operacion-loading/operacion-loading.component';
import { SocioState } from 'src/app/store/socio/states';
import Swal from 'sweetalert2';
import { ReciboService } from '../../recibo-semestral.service';
import { HttpResponse } from '@angular/common/http';
import { Imprimir } from 'src/app/shared/imprimir';
import moment from 'moment';

@Component({
  selector: 'app-recibo-semestral-impresiones',
  templateUrl: './recibo-semestral-impresiones.component.html',
  styleUrls: ['./recibo-semestral-impresiones.component.css']
})
export class ReciboSemestralImpresionesComponent {


  @ViewChild(OperacionLoadingComponent, { static: true })
  operacionLoadingComponent: OperacionLoadingComponent;

  public imprimir = new Imprimir();

  
  constructor(private store:Store, private rs:ReciboService) {
    
  }


  imprimirComprobante({numero_recibo, estado_pago}){
    
    if (estado_pago=='Pagado'){
    this.imprimirComprobantePago(numero_recibo)
    }else{
      Swal.fire(
        '',
        'El recibo no ha sido abonado!',
        'info'
      )
    }
  }

  imprimirComprobantePago(comprobante){


    this.operacionLoadingComponent.loadingTitle="Generando comprobante de pago";
    this.operacionLoadingComponent.open();

    const cliente = this.store.selectSnapshot(SocioState.cliente)

    this.rs.getReciboSemestralComprobantePago(comprobante,cliente)
    .subscribe(
      (contenido: HttpResponse<Blob>)=>{
          // reiniciar facturas adeudadas
          this.imprimir.imprimir(contenido);
          this.operacionLoadingComponent.close();
      },
      error=>{
        Swal.fire(
          '',
          'Error al imprimir el comprobante, vuelva a intentarlo!',
          'error'
        )
        this.operacionLoadingComponent.close();       }
      );
  }


  imprimirReciboDesdeLista(reciboSemestral){
    

    if(this.isReciboSemestralHabilitadoParaOperar(reciboSemestral.fecha_emision)){
      this.generarImpresion(reciboSemestral)
    }else{
      this.displayErrorReciboNoHabilitadoParaOperar()
    }
  }

  isReciboSemestralHabilitadoParaOperar(fecha){
    if(moment().isSameOrAfter(moment(fecha),'day')){
      return true
    }else{
      return false
    }
  }

  generarImpresion(reciboSemestral){
    this.operacionLoadingComponent.loadingTitle="";
    this.operacionLoadingComponent.open();

    this.rs.imprimirReciboSemestral(reciboSemestral.numero_recibo).subscribe(
      (contenido: HttpResponse<Blob>)=>{
        this.imprimir.imprimir(contenido);
    },
    ()=>this.operacionLoadingComponent.close(),
    ()=>this.operacionLoadingComponent.close()
    )
  }

  displayErrorReciboNoHabilitadoParaOperar(){
    Swal.fire(
      '',
      'El recibo semestral no se encuentra habilitado para su impresión. Se habilitará en la fecha de emisión correspondiente!',
      'error'
    )
  }
  
}
