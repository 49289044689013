import { State, Action, StateContext } from '@ngxs/store';
import { AddCesp, GetCespList, UpdateCesp } from './actions';
import { Injectable } from '@angular/core';
import { AfipService } from 'src/app/afip/afip.service';
import { Cesp } from './models';
import { mergeMap } from 'rxjs/operators';

export class AfipStateModel {
  cespList:Cesp[];
}


@State<AfipStateModel>({
    name: 'afip',
    defaults: {
      cespList:[]
    }
})

@Injectable()
export class AfipState {

    constructor(private as: AfipService) { }

    @Action(GetCespList)
    getCespList(ctx: StateContext<AfipStateModel>, action:GetCespList) {

      const state = ctx.getState()

          this.as.getCespList().subscribe(
            cespR=>{
              ctx.patchState({
                  cespList: cespR
                });
            }
          )

    }

    @Action(AddCesp)
    addCesp(ctx: StateContext<AfipStateModel>, action:AddCesp) {
      return this.as.addCesp(action.cesp,action.fechaProceso,action.fechaValidoDesde,action.fechaValidoHasta).pipe(
        mergeMap(()=>ctx.dispatch(new GetCespList())
        ))

    }



    @Action(UpdateCesp)
    updateCesp(ctx: StateContext<AfipStateModel>, action:UpdateCesp) {
      return this.as.updateCesp(action.idCesp,action.cesp,action.fechaProceso,action.fechaValidoDesde,action.fechaValidoHasta).pipe(
        mergeMap(()=>ctx.dispatch(new GetCespList())
        ))

    }



}

