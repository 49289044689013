export class FiltroFecha{    
    fecha_desde:string;
    fecha_hasta:string;    
}


export class FiltroSocio{    
    cod_socio:number;
    sub_cod_socio:number;    
}


export class FiltroNumeroComprobante{    
    numero_comprobante:number;    
}