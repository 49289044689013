

<div class="row">
    <div class="col-md-7">
        <app-nuevo-convenio-pago #nuevoConvenioPago (seleccionarFacturasEvent)="habilitarFacturasDisponibles()" (crearConvenioPagoEvent)="habilitarConvenioPago($event)" (cancelarEvent)="cancelarNuevoConvenio()"></app-nuevo-convenio-pago>
    </div>

    <div class="col-md-5">
        <app-listado-facturas-disponibles *ngIf="habilitar[0]" (cancelarEvent)="cancelarFacturasDisponibles()"></app-listado-facturas-disponibles>
        <app-datos-convenio *ngIf="habilitar[1]"
         [fechaConvenio]="datoConvenio.fechaConvenio"
         [importeDeuda]="datoConvenio.importeDeuda"
         [cantidadCuotas]="datoConvenio.cantidadCuotas"
         [valorCuota]="datoConvenio.valorCuota"
         [desde]="datoConvenio.desde"
         [hasta]="datoConvenio.hasta"
         [importeCanonMunicipal]="datoConvenio.importeCanonMunicipal"
         [importeIntimacion]="datoConvenio.importeIntimacion" (salirEvent)="salirDatosConvenio()"></app-datos-convenio>
    </div>
</div>
