import {  Component,  OnInit,  Input,    Output,  EventEmitter,  AfterViewInit,  ViewChild } from "@angular/core";
import { UntypedFormGroup} from "@angular/forms";
import { Store } from "@ngxs/store";
import { SocioDetalle } from "src/app/store/socio/models";
import { ItemsLista, ItemsListaConFin } from "src/app/store/general/models";
import { Validacion } from "src/app/shared/validacion";
import { ValidacionFormularioSocio } from "./validacion-formulario-socio";
import { SocioGestionService } from "../../socio-gestion.service";
import { ActivatedRoute } from "@angular/router";
import { GetCalles } from "src/app/store/general/actions";
import { CalleModalComponent } from "src/app/general/calle-modal/calle-modal.component";
import { Barrio } from "src/app/store/barrio/models";
import { OperacionesABM } from "src/app/shared/enums/enums";
import { Observable } from "rxjs";
import { SocioInfoComponent } from "./socio-info/socio-info.component";
import { DatosConexionInfoComponent } from "./datos-conexion-info/datos-conexion-info.component";
import { DatosTecnicosInfoComponent } from "./datos-tecnicos-info/datos-tecnicos-info.component";
import { DebitoInfoComponent } from "./debito-info/debito-info.component";
import { DomicilioConexionInfoComponent } from "./domicilio-conexion-info/domicilio-conexion-info.component";
import { DomicilioLegalInfoComponent } from "./domicilio-legal-info/domicilio-legal-info.component";
import { LoteInfoComponent } from "./lote-info/lote-info.component";
import { VariosInfoComponent } from "./varios-info/varios-info.component";
import Swal from "sweetalert2";


declare var $: any;

@Component({
  selector: "app-formulario-socio",
  templateUrl: "./formulario-socio.component.html",
  styleUrls: ["./formulario-socio.component.css"],
})
export class FormularioSocioComponent
  implements OnInit,  AfterViewInit
{
  @Input() modoOperacion: OperacionesABM; // 1-edicion 2-alta
  @Input() irASeccion: string;
  @Output() cancelarOperacion: EventEmitter<any> = new EventEmitter();
  @Output() guardarOperacion: EventEmitter<UntypedFormGroup> = new EventEmitter();

  @ViewChild(CalleModalComponent, { static: true }) calleModalComponent: CalleModalComponent;

  @ViewChild(SocioInfoComponent, { static: false }) socioInfoComponent:SocioInfoComponent;
  @ViewChild(DatosConexionInfoComponent, { static: false }) datosConexionInfoComponent:DatosConexionInfoComponent;
  @ViewChild(DomicilioConexionInfoComponent, { static: false }) domicilioConexionInfoComponent:DomicilioConexionInfoComponent;
  @ViewChild(DomicilioLegalInfoComponent, { static: false }) domicilioLegalInfoComponent:DomicilioLegalInfoComponent;
  @ViewChild(VariosInfoComponent, { static: false }) variosInfoComponent:VariosInfoComponent;
  @ViewChild(LoteInfoComponent, { static: false }) loteInfoComponent:LoteInfoComponent;
  @ViewChild(DatosTecnicosInfoComponent, { static: false }) datosTecnicosInfoComponent:DatosTecnicosInfoComponent;
  @ViewChild(DebitoInfoComponent, { static: false }) debitoInfoComponent:DebitoInfoComponent;


  socio$: Observable<SocioDetalle>;

  tiposDni$: Observable<ItemsLista[]>;
  tiposIva$: Observable<ItemsLista[]>;
  categorias$: Observable<ItemsLista[]>;
  estados$: Observable<ItemsLista[]>;
  finSuspension$: Observable<ItemsLista[]>;
  tarifas$: Observable<ItemsListaConFin[]>;
  zonas$: Observable<ItemsLista[]>;
  situacionesEspecial: ItemsListaConFin[];
  barrios: Barrio[];
  calles: ItemsLista[];
  provincias$: Observable<ItemsLista[]>;
  tiposMedianeras$: Observable<ItemsLista[]>;
  tiposConexion$: Observable<ItemsLista[]>;
  tiposMaterial$: Observable<ItemsLista[]>;
  prestadoresDebitoAutomatico$: Observable<ItemsLista[]>;
  condicionesDeRegistro$: Observable<ItemsLista[]>;
  bancos: ItemsLista[];



  public validadores: Validacion = new Validacion();
  public validadoresFormularioSocio: ValidacionFormularioSocio = new ValidacionFormularioSocio();



  errorSocioUsuarioRegistrado = false;

  operationInProcess$: Observable<boolean>;

  socioForm: UntypedFormGroup;
  formIsValid$: Observable<boolean>;

  constructor(   private store: Store,    private sgs: SocioGestionService, private route: ActivatedRoute  ) {

  }

  ngOnInit() {

    this.calles = this.route.snapshot.data.calles;
    this.barrios = this.route.snapshot.data.barrios;
    this.bancos = this.route.snapshot.data.bancos;
    this.situacionesEspecial= this.route.snapshot.data.situacionesEspeciales;

    this.iniciarListas();
    this.socio$ =this.store.select((state) => state.socio.socio_abm)
    this.operationInProcess$ = this.store.select((state) => state.socio.en_proceso_socio_alta_modificacion);
  }

  iniciarListas() {
    this.condicionesDeRegistro$ = this.store.select((state) => state.general.condicionesRegistro );
    this.tiposDni$ = this.store.select((state) => state.general.tiposDni );
    this.tiposIva$ = this.store.select( (state) => state.general.tiposIva  );
    this.categorias$ = this.store.select( (state) => state.general.categorias );
    this.estados$ = this.store.select( (state) => state.general.estados );
    this.finSuspension$ = this.store.select((state) => state.general.finSuspension );
    this.tarifas$ = this.store.select( (state) => state.general.tarifas );
    this.zonas$ = this.store.select( (state) => state.general.zonas );
    this.provincias$ = this.store.select( (state) => state.general.provincias );
    this.tiposMedianeras$ = this.store.select( (state) => state.general.tiposMedianeras );
    this.tiposConexion$ = this.store.select(  (state) => state.general.tiposConexion );
    this.tiposMaterial$ = this.store.select((state) => state.general.tiposMaterial  );
    this.prestadoresDebitoAutomatico$ = this.store.select( (state) => state.general.prestadoresDebitoAutomatico );
  }

  onSubmit() {

    if (!this.isValidForm()) {
      this.displayFormError();
      this.setFocusInFirstError();
    } else {

      if (this.modoOperacion == OperacionesABM.alta) {
         this.sgs
          .socioUsuarioChequearExistencia(
            this.socioInfoComponent.codigoField.value,
            this.socioInfoComponent.subcodigoField.value
          )
          .subscribe((resultado) => {
            if (resultado.existe) {
              this.errorSocioUsuarioExistente();
            } else {
              this.guardarOperacion.emit(this.getFormsValues());
            }
          });
      } else {

        this.guardarOperacion.emit(this.getFormsValues());
      }
    }
  }

  isValidForm(){

    const socioInfoFormStatus = this.socioInfoComponent.isValidForm
    const  datosConexionFormStatus= this.datosConexionInfoComponent.isValidForm
    const  domicilioConexionFormStatus= this.domicilioConexionInfoComponent.isValidForm
    const  domicilioLegalFormStatus= this.domicilioLegalInfoComponent.isValidForm
    const  variosFormStatus= this.variosInfoComponent.isValidForm
    const loteFormStatus = this.loteInfoComponent.isValidForm
    const  datosTecnicosFormStatus= this.datosTecnicosInfoComponent.isValidForm
    const  debitoFormStatus= this.debitoInfoComponent.isValidForm

    return socioInfoFormStatus && datosConexionFormStatus && domicilioConexionFormStatus && domicilioLegalFormStatus && variosFormStatus && loteFormStatus && datosTecnicosFormStatus && debitoFormStatus
  }

  displayFormError(){
    this.socioInfoComponent.showInvalidControl();
    this.datosConexionInfoComponent.showInvalidControl();
    this.domicilioConexionInfoComponent.showInvalidControl();
    this.domicilioLegalInfoComponent.showInvalidControl();
    this.variosInfoComponent.showInvalidControl();
    this.loteInfoComponent.showInvalidControl();
    this.datosTecnicosInfoComponent.showInvalidControl();
    this.debitoInfoComponent.showInvalidControl();
  }

  setFocusInFirstError(){
    let invalid: any

    setTimeout(() => {
     invalid=document.getElementsByClassName("is-invalid")
     const elemt = `[formControlName=${invalid[0].getAttribute("formcontrolname")}]`;
    $(elemt)[0].focus();
    }, 100);

  }

  errorSocioUsuarioExistente() {
    const elemt = "[formControlName=codigo]";
    $(elemt)[0].focus();

    this.errorSocioUsuarioRegistrado = true;

    Swal.fire(
      '',"El socio/usuario ingresado ya se encuentra registrado en la cooperativa!",
      'error'
    )
  }

  getFormsValues(){

    return {...this.socioInfoComponent.formValue, ...this.datosConexionInfoComponent.formValue, ...this.domicilioConexionInfoComponent.formValue, ...this.domicilioLegalInfoComponent.formValue, ...this.variosInfoComponent.formValue, ...this.loteInfoComponent.formValue, ...this.datosTecnicosInfoComponent.formValue, ...this.debitoInfoComponent.formValue}
  }

  cancelar() {
    this.cancelarOperacion.emit();
  }

  scrollTo(cuadro) {
    document.querySelector("#" + cuadro).scrollIntoView({ behavior: "smooth" });
  }

  nuevaCalle() {
    this.calleModalComponent.open();
  }

  retornoCalleModal() {
    this.store.dispatch(new GetCalles());
    const elemt = "[formControlName=calleConexion]";
    $(elemt)[0].focus();
  }

  ngAfterViewInit() {

    if (this.modoOperacion == OperacionesABM.editar) {
      this.scrollTo(this.irASeccion);
    }
  }


}
