import { Component, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngxs/store';
import { GetTiposDni, GetTiposIva, GetCategorias, GetEstados, GetFinSupension, GetTarifas, GetZonas,  GetProvincias, GetTiposMedianeras, GetTiposConexion, GetTiposMaterial, GetPrestadoresDebitoAutomatico, GetCondicionRegistro, GetBancos } from 'src/app/store/general/actions';
import { SetSocioAlta, SocioUsuarioAlta, StartEnProcesoSocioAltaModificacion, StartBusquedaSocioDetalle } from 'src/app/store/socio/actions';
import { Router } from '@angular/router';
import { UntypedFormGroup } from '@angular/forms';
import { Socio } from '../../socio';
import { OperacionExitosaComponent } from 'src/app/shared/operacion-exitosa/operacion-exitosa.component';
import { SocioGestionService } from '../../socio-gestion.service';
import { ResetCuentaCorriente } from 'src/app/store/cuentacorriente/actions';
import { ResetReciboSemestral } from 'src/app/store/recibo/actions';
import { ResetObservaciones } from 'src/app/store/observacion/actions';
import { ResetCredito } from 'src/app/store/credito/actions';
import { ResetAccionesCliente } from 'src/app/store/acciones/actions';
import { ResetFacturas } from 'src/app/store/factura/actions';
import { ResetConvenios } from 'src/app/store/convenio/actions';
import { OperacionesABM } from 'src/app/shared/enums/enums';
import Swal from 'sweetalert2';



@Component({
  selector: 'app-alta-socio',
  templateUrl: './alta-socio.component.html',
  styleUrls: ['./alta-socio.component.css']
})
export class AltaSocioComponent implements OnInit {

  @ViewChild(OperacionExitosaComponent, { static: true })
  operacionExitosaComponent: OperacionExitosaComponent;


  socio: Socio = new Socio();

  modoOperacion: OperacionesABM= OperacionesABM.alta

  constructor(private store: Store,private router: Router , private sgs:SocioGestionService ) {
  }

  ngOnInit() {



this.store.dispatch(new GetTiposDni());
this.store.dispatch(new GetTiposIva());
this.store.dispatch(new GetCategorias());
this.store.dispatch(new GetEstados());
this.store.dispatch(new GetFinSupension());
this.store.dispatch(new GetTarifas());
this.store.dispatch(new GetZonas());
this.store.dispatch(new GetProvincias());
this.store.dispatch(new GetTiposMedianeras());
this.store.dispatch(new GetTiposConexion());
this.store.dispatch(new GetTiposMaterial());
this.store.dispatch(new GetPrestadoresDebitoAutomatico());
this.store.dispatch(new GetCondicionRegistro());


this.store.dispatch(new SetSocioAlta());
  }

  cancelarOperacion(){
    this.router.navigate(['/socio/gestion']);
  }

  altaSocioUsuario(event:UntypedFormGroup){



    this.store.dispatch([new StartEnProcesoSocioAltaModificacion(),new StartBusquedaSocioDetalle(),
      new ResetCuentaCorriente(),new ResetReciboSemestral()
      ,new ResetObservaciones(), new ResetCredito()
      , new ResetAccionesCliente(), new ResetFacturas(), new ResetConvenios()])

    this.store.dispatch(new SocioUsuarioAlta(this.socio.generarSocioUsuarioAltaEdicion(event))).subscribe(
      () => {

        Swal.fire(
          '','El Socio/Usuario ha sido dado de alta de manera exitosa!',
          'success'
        ).then(()=>this.router.navigate(['/socio']))

      }
    );

  }


}
