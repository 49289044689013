import { Acciones } from './model';
import { State, StateContext, Action } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { AccionesService } from 'src/app/acciones/acciones.service';
import { GetAccionesCliente, AddAccionesCliente, DeleteAccionesCliente, UpdateAccionesCliente, ResetAccionesCliente } from './actions';
import { mergeMap } from 'rxjs/operators';
import { of, from } from 'rxjs';


export class AccionesStateModel {
  acciones:Acciones[];
  inicializado:boolean;
}


@State<AccionesStateModel>({
  name: 'acciones',
  defaults: {
      acciones:[],
      inicializado:false
  }
})


@Injectable()
export class AccionesState {
  constructor(private as: AccionesService) { }

  @Action(GetAccionesCliente)
  getAccionesCliente(ctx: StateContext<AccionesStateModel>, action:GetAccionesCliente) {

    const state = ctx.getState()
    let seguir = false

    if(!action.update && !state.inicializado){seguir=true}
    if(action.update && state.inicializado){seguir=true}

    if(seguir){
      this.as.getAccionesCliente(action.cliente).subscribe(
        data=> {
            ctx.patchState({
              acciones: data,
              inicializado:true
            });
        }
    )
    }

  }

  @Action(AddAccionesCliente)
  addAccionesCliente( ctx : StateContext<AccionesStateModel>, action:AddAccionesCliente) {
     return this.as.addAccionesCliente(action.cliente,action.fechaSubscripcion,action.cuotas,action.valorCuota,action.importeTotal,action.cuotasAbonadas)
     .pipe(
      mergeMap(()=> ctx.dispatch(new GetAccionesCliente(action.cliente,true)))
    )
  }

  @Action(UpdateAccionesCliente)
  updateAccionesCliente(ctx: StateContext<AccionesStateModel>, action:UpdateAccionesCliente) {

     return this.as.updateAccionesCliente(action.accion,action.fechaSubscripcion,action.cuotas,action.valorCuota,action.importeTotal,action.cuotasAbonadas,action.observacion).pipe(
       mergeMap(()=> ctx.dispatch(new GetAccionesCliente(action.cliente,true)))
     )
  }

  @Action(DeleteAccionesCliente)
  deleteAccionesCliente( ctx : StateContext<AccionesStateModel>, action:DeleteAccionesCliente) {
     return this.as.deleteAccionesCliente(action.accion,action.usuario_autorizante,action.observacion).pipe(
      mergeMap(()=> ctx.dispatch(new GetAccionesCliente(action.cliente,true)))
    )
  }

  @Action(ResetAccionesCliente)
  resetAccionesCliente({ patchState }: StateContext<ResetAccionesCliente>) {

      patchState({
        acciones:[],
        inicializado:false
      })

      return from([]);
  }
}
