import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
declare var $:any;

@Component({
  selector: 'app-operacion-si-no-observacion',
  templateUrl: './operacion-si-no-observacion.component.html',
  styleUrls: ['./operacion-si-no-observacion.component.css']
})
export class OperacionSiNoObservacionComponent implements OnInit {


  @Input() titulo:string;
  @Input() contenidoMensaje:string;  
  @Input() observacionPorDefecto:string="";
  @Input() observacionRequerida:boolean=true;
  @Input() id:string="operacion_si_no_observacion"; 

  

  @Output() retornoOperacion = new EventEmitter();  

  siNoObservacionForm: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder) { }

  ngOnInit() {
    this.initForm()
  }

  initForm(){

    this.siNoObservacionForm = this.formBuilder.group({
      observacion: ['', this.observacionRequerida?Validators.required:[]],      
  });

  }

  open(){
    $(`#${this.id}`).modal('show')
  }

  aceptar(){
    $(`#${this.id}`).modal('hide')
    this.retornoOperacion.emit({'opcion_seleccion':true,'observacion':this.siNoObservacionForm.get('observacion').value});
    this.siNoObservacionForm.reset()   
  }

  cancelar(){
    $(`#${this.id}`).modal('hide')
    this.retornoOperacion.emit({'opcion_seleccion':false,'observacion':''});
    this.siNoObservacionForm.reset()   
  }


}
