import { State, Action, StateContext, Selector } from '@ngxs/store';
import { SistemaConfiguracionService } from 'src/app/sistema-configuracion/sistema-configuracion.service';
import { GetReciboSemestralHabilitado, GetInformacionRecargos, GetProximoNumeroPagare, UpdateProximoNumeroPagare, GetLeyendas, SaveLeyendas } from './actions';
import { Injectable } from '@angular/core';
import { mergeMap } from 'rxjs/operators';


export class SistemaConfiguracionStateModel {
    recibo_semestral_habilitado:boolean;
    recargo_diario_actual:number;
    recargo_diario_minimo:number;
    vigencia_recargo_minimo:string;
    proximo_numero_pagare:number;
    leyendas:string;

}

@State<SistemaConfiguracionStateModel>({
    name: 'sistemaConfiguracion',
    defaults: {
    recibo_semestral_habilitado:false  ,
    recargo_diario_actual:null,
    recargo_diario_minimo:null,
    vigencia_recargo_minimo:null,
    proximo_numero_pagare:null,
    leyendas:null

    }
})

@Injectable()
export class SistemaConfiguracionState {

    constructor(private scs: SistemaConfiguracionService) { }

    @Selector()
    static habilitarRecibosSemestrales(state: SistemaConfiguracionStateModel) { return state.recibo_semestral_habilitado }



    @Action(GetReciboSemestralHabilitado)
    getReciboSemestralHabilitado({ patchState }: StateContext<SistemaConfiguracionStateModel>) {

           this.scs.getEstaHabilitadoRecibosSemestrales().subscribe(
                data=> {
                    patchState({
                        recibo_semestral_habilitado: data.habilitar == 1
                    });
                }
            )
    }



    @Action(GetInformacionRecargos)
    getInformacionRecargos({ patchState }: StateContext<SistemaConfiguracionStateModel>) {
           this.scs.getInformacionRecargos().subscribe(
                data=> {

                    patchState({
                      recargo_diario_actual:data?.RecargoDiarioActual||null,
                      recargo_diario_minimo:data?.RecargoMinimo||null,
                      vigencia_recargo_minimo:data?.VigenciaRecMin||null,
                  });

                }
            )
    }

    @Action(GetProximoNumeroPagare)
    getProximoNumeroPagare({ patchState }: StateContext<SistemaConfiguracionStateModel>) {
           this.scs.getProximoNumeroPagare().subscribe(
                data=> {
                    patchState({
                      proximo_numero_pagare:data?.ProxPagare||null
                  });
                }
            )
    }

    @Action(UpdateProximoNumeroPagare)
    updateProximoNumeroPagare(ctx: StateContext<SistemaConfiguracionStateModel>) {
           this.scs.updateProximoNumeroPagare().pipe(
             mergeMap(()=>ctx.dispatch(new GetProximoNumeroPagare()))
           ).subscribe()
    }

    @Action(GetLeyendas)
    getLeyendas({ patchState }: StateContext<SistemaConfiguracionStateModel>) {
           this.scs.getLeyendas().subscribe(
                data=> {
                    patchState({
                      leyendas:data?.obsComun||null
                  });
                }
            )
    }

    @Action(SaveLeyendas)
    saveLeyendas(ctx: StateContext<SistemaConfiguracionStateModel>,action: SaveLeyendas) {
          return this.scs.saveLeyendas(action.leyendas).pipe(
             mergeMap(()=>ctx.dispatch(new GetLeyendas()))
           )
    }

    GetEstaHabilitadoConvenio


}
