import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReciboSemestralComponent } from './recibo-semestral/recibo-semestral.component';
import { SharedModule } from '../shared/shared.module';
import { ReciboAnularComponent } from './recibo-anular/recibo-anular.component';
import { Routes, RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { ReciboSemestralGenerarRendicionComponent } from './recibo-semestral-generar-rendicion/recibo-semestral-generar-rendicion.component';
import { AgGridModule } from 'ag-grid-angular';
import { AutorizacionModule } from '../autorizacion/autorizacion.module';
import { ReciboSemestralGenerarComponent } from './recibo-semestral-generar/recibo-semestral-generar.component';
import { ListaConceptosComponent } from './recibo-semestral-generar/lista-conceptos/lista-conceptos.component';
import { ExitGuard } from '../guards/exit.guard';
import { ReciboSemestralListadoComponent } from './recibo-semestral/recibo-semestral-listado/recibo-semestral-listado.component';
import { ReciboSemestralImpresionesComponent } from './recibo-semestral/recibo-semestral-impresiones/recibo-semestral-impresiones.component';
import { ReciboSemestralCobranzaComponent } from './recibo-semestral/recibo-semestral-cobranza/recibo-semestral-cobranza.component';




const routes: Routes = [
  {
    path: 'generar',
    component: ReciboSemestralGenerarComponent,
    canDeactivate: [ExitGuard]
    },

  {
  path: 'anulacion',
  component: ReciboAnularComponent
  },
  {
    path: 'rendicion-generar',
    component: ReciboSemestralGenerarRendicionComponent
    }
]


@NgModule({
  declarations: [ReciboSemestralComponent, ReciboAnularComponent,ReciboSemestralGenerarRendicionComponent, ReciboSemestralGenerarComponent, ListaConceptosComponent, ReciboSemestralListadoComponent, ReciboSemestralImpresionesComponent, ReciboSemestralCobranzaComponent],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild(routes) ,
    ReactiveFormsModule,
    AutorizacionModule,
    AgGridModule
  ],
  exports:[ReciboSemestralComponent]
})
export class ReciboSemestralModule { }
