import { State, StateContext, Action, Selector, Store } from "@ngxs/store";
import { SocioDetalle, SocioBusquedaAvanzada,  PresentacionDocumentacion, PlazoPresentacionDocumentacion } from './models';
import { SocioGestionService } from 'src/app/socio-gestion/socio-gestion.service';
import { GetSocioDetalle, StartBusquedaSocioDetalle, SetSocioParaEdicion, ClearSocioParaEdicion, SetSocioAlta,
    SocioUsuarioAlta, SocioUsuarioEdicion, GetBusquedaSocios, StartGetBusquedaSocios,
     GetDocumentacionCisterna, SetDocumentacionCisterna, NewDocumentacionCisternaPlazo,
    GetDocumentacionCisternaPlazos, GetDocumentacionPlanos, SetDocumentacionPlanos, NewDocumentacionPlanosPlazo,
    GetDocumentacionPlanosPlazos, GetDocumentacionPlazosControl, SetSocioUsuarioEmail, DeleteDocumentacionPlazoCisterna,
    DeleteDocumentacionPlazoPlanos, DeleteDocumentacionCisterna, DeleteDocumentacionPlanos, EnviarProcesoJudicial, RetirarProcesoJudicial, EnviarCODENE, EnviarVERAZ, RetirarCODENE, RetirarVERAZ, StartEnProcesoSocioAltaModificacion } from './actions';
import { catchError, tap, mergeMap } from 'rxjs/operators';
import {   of, throwError, from} from 'rxjs';
import { Injectable } from '@angular/core';





export class SocioStateModel {

    socio_detalle: SocioDetalle;


    loading_busqueda: boolean;
    en_proceso_socio_alta_modificacion: boolean;
    socio_abm: SocioDetalle;
    socios_busqueda_avanzada: SocioBusquedaAvanzada[];
    socios_busqueda_avanzada_loading: boolean;

    socio_documentacion_cisterna: PresentacionDocumentacion
    socio_plazos_documentacion_cisterna: PlazoPresentacionDocumentacion[];

    socio_documentacion_planos: PresentacionDocumentacion
    socio_plazos_documentacion_planos: PlazoPresentacionDocumentacion[];

    socio_plazos_control: any[]
}


@State<SocioStateModel>({
    name: 'socio',
    defaults: {

        socio_detalle: null,
        loading_busqueda: false,
        en_proceso_socio_alta_modificacion:false,
        socio_abm: null,
        socios_busqueda_avanzada: [],
        socios_busqueda_avanzada_loading: false,
        socio_documentacion_cisterna: null,
        socio_plazos_documentacion_cisterna: [],
        socio_documentacion_planos: null,
        socio_plazos_documentacion_planos: [],
        socio_plazos_control: []
    }
})

@Injectable()
export class SocioState {

    constructor(private sgs: SocioGestionService, private store:Store) { }



    @Selector()
    static cliente(state: SocioStateModel) { return state.socio_detalle.id_socio }

    @Selector()
    static codigoCliente(state: SocioStateModel) { return state.socio_detalle.codSocio }

    @Selector()
    static subCodigoCliente(state: SocioStateModel) { return state.socio_detalle.subCodSocio }

    @Selector()
    static email(state: SocioStateModel) { return state.socio_detalle.email }

    @Selector()
    static adhesionDebitoAutomatico(state: SocioStateModel) { return state.socio_detalle.debitoAutomatico && +state.socio_detalle.id_zona == 2 }

    


    @Action(GetSocioDetalle)
    getSocioDetalle(ctx: StateContext<SocioStateModel>, action: GetSocioDetalle) {
     this.sgs.getSocioDetalle(action.codigo, action.codsocio).pipe(
          tap(
            data => {
              ctx.patchState({
                socio_detalle: data,
                loading_busqueda: false
            })
            }
        )
        ).subscribe()
    }


    @Action(GetBusquedaSocios)
    getBusquedaSocios({ patchState }: StateContext<SocioStateModel>, action: GetBusquedaSocios) {

        this.sgs.getSocios(action.textoBusqueda,action.campoBusqueda).subscribe(
            data => {
                patchState({
                    socios_busqueda_avanzada: data,
                    socios_busqueda_avanzada_loading: false
                });
            }
        )
    }

    @Action(StartGetBusquedaSocios)
    startGetBusquedaSocios({ patchState }: StateContext<SocioStateModel>) {

        patchState({
            socios_busqueda_avanzada_loading: true
        });
    }



    @Action(StartEnProcesoSocioAltaModificacion)
    startEnProcesoSocioAltaModificacion({ patchState }: StateContext<StartEnProcesoSocioAltaModificacion>) {

        patchState({
          en_proceso_socio_alta_modificacion: true
        });
    }




    @Action(StartBusquedaSocioDetalle)
    startBusquedaSocioDetalle({ patchState }: StateContext<SocioStateModel>) {

        patchState({
            loading_busqueda: true
        });

        return from([]);;

    }


    @Action(SetSocioParaEdicion)
    setSocioParaEdicion({ patchState }: StateContext<SocioStateModel>, action: SetSocioParaEdicion) {

        patchState({
            socio_abm: action.socio
        });
    }


    @Action(ClearSocioParaEdicion)
    clearSocioParaEdicion({ patchState }: StateContext<SocioStateModel>) {
        patchState({
            socio_abm: null
        });
    }



    @Action(SetSocioAlta)
    setSocioAlta({ patchState }: StateContext<SocioStateModel>) {
        patchState({
            socio_abm: this.sgs.getSocioNuevo()
        });
    }



    @Action(SocioUsuarioAlta)
    socioUsuarioAlta(ctx: StateContext<SocioStateModel>, action: SocioUsuarioAlta) {
        return this.sgs.altaSocioUsuario(action.socioUsuario).pipe(
          tap(()=>  ctx.patchState({
            en_proceso_socio_alta_modificacion: false
            })),
            mergeMap(() => ctx.dispatch(new StartBusquedaSocioDetalle())),
            mergeMap(() => ctx.dispatch(new GetSocioDetalle(action.socioUsuario.codSocio,action.socioUsuario.subCodSocio))),
            catchError((x, caught) => {

                ctx.patchState({
                    en_proceso_socio_alta_modificacion: false
                    })

                    return throwError(x);
            })
        )
    }


    @Action(SocioUsuarioEdicion)
    socioUsuarioEdicion(ctx: StateContext<SocioStateModel>, action: SocioUsuarioEdicion) {

        return this.sgs.edicionSocioUsuario(action.socioUsuario,action.idCliente).pipe(
          tap(()=>  ctx.patchState({
            en_proceso_socio_alta_modificacion: false
            })),
            mergeMap(() => ctx.dispatch(new StartBusquedaSocioDetalle())),
            mergeMap(() => ctx.dispatch(new GetSocioDetalle(ctx.getState().socio_detalle.codSocio,ctx.getState().socio_detalle.subCodSocio)) ),
            catchError((x, caught) => {

                ctx.patchState({
                    en_proceso_socio_alta_modificacion: false
                    })
                    return throwError(x);
            })
        )
    }





    @Action(SetSocioUsuarioEmail)
    setSocioUsuarioEmail(ctx: StateContext<SocioStateModel>, action: SetSocioUsuarioEmail) {
        return this.sgs.updateEmailSocioUsuario(action.cliente,action.email)
    }



    /// DOCUMENTACION PLANO CISTERNA

    @Action(GetDocumentacionCisterna)
    getDocumentacionCisterna({ patchState }: StateContext<SocioStateModel>, action: GetDocumentacionCisterna) {

        this.sgs.getDocumentacionCisterna(action.cliente).subscribe(
            data => {
                patchState({
                    socio_documentacion_cisterna: data,
                });
            }
        )


    }

    @Action(SetDocumentacionCisterna)
    setDocumentacionCisterna(ctx: StateContext<SocioStateModel>, action: SetDocumentacionCisterna) {
        const state = ctx.getState();
        return this.sgs.setDocumentacionCisterna(action.cliente, action.observacion).pipe(
            mergeMap(() =>
                ctx.dispatch(new GetDocumentacionCisterna(action.cliente))

            )
        )
    }

    @Action(NewDocumentacionCisternaPlazo)
    newDocumentacionCisternaPlazo(ctx: StateContext<SocioStateModel>, action: NewDocumentacionCisternaPlazo) {
        const state = ctx.getState();
        return this.sgs.newDocumentacionCisternaPlazo(action.cliente, action.observacion,  action.fecha_desde,action.fecha_hasta, action.dias_plazo).pipe(
            mergeMap( () =>
                ctx.dispatch(new GetDocumentacionCisternaPlazos(action.cliente))

            )
        )
    }


    @Action(GetDocumentacionCisternaPlazos)
    getDocumentacionCisternaPlazos({ patchState }: StateContext<SocioStateModel>, action: GetDocumentacionCisternaPlazos) {

        this.sgs.getDocumentacionCisternaPlazos(action.cliente).subscribe(
            data => {
                patchState({
                    socio_plazos_documentacion_cisterna: data,
                });
            }
        )


    }


    @Action(DeleteDocumentacionPlazoCisterna)
    deleteDocumentacionPlazoCisterna(ctx: StateContext<SocioStateModel>, action: DeleteDocumentacionPlazoCisterna) {
        const state = ctx.getState();
        return this.sgs.deleteDocumentacionPlazo(action.plazo, action.usuario, action.usuario_autorizante,action.observacion,action.cliente).pipe(
            mergeMap(() =>
                ctx.dispatch(new GetDocumentacionCisternaPlazos(action.cliente))

            )
        )


    }

    @Action(DeleteDocumentacionCisterna)
    deleteDocumentacionCisterna(ctx: StateContext<SocioStateModel>, action: DeleteDocumentacionCisterna) {
        const state = ctx.getState();
        return this.sgs.deleteDocumentacion(action.documentacion, action.usuario, action.usuario_autorizante,action.observacion,state.socio_detalle.id_socio).pipe(
            mergeMap(() =>
                ctx.dispatch(new GetDocumentacionCisterna(action.cliente))

            )
        )
    }



    @Action(GetDocumentacionPlanos)
    getDocumentacionPlanos({ patchState }: StateContext<SocioStateModel>, action: GetDocumentacionPlanos) {

        this.sgs.getDocumentacionPlanos(action.cliente).subscribe(
            data => {
                patchState({
                    socio_documentacion_planos: data,
                });
            }
        )


    }

    @Action(SetDocumentacionPlanos)
    setDocumentacionPlanos(ctx: StateContext<SocioStateModel>, action: SetDocumentacionPlanos) {
        const state = ctx.getState();
        return this.sgs.setDocumentacionPlanos(action.cliente, action.observacion).pipe(
          mergeMap(() =>
                ctx.dispatch(new GetDocumentacionPlanos(action.cliente))

            )
        )
    }

    @Action(NewDocumentacionPlanosPlazo)
    newDocumentacionPlanosPlazo(ctx: StateContext<SocioStateModel>, action: NewDocumentacionPlanosPlazo) {
        const state = ctx.getState();
        return this.sgs.newDocumentacionPlanosPlazo(action.cliente, action.observacion,action.fecha_desde, action.fecha_hasta, action.dias_plazo).pipe(
            mergeMap(() =>  ctx.dispatch(new GetDocumentacionPlanosPlazos(action.cliente))
            )
        )
    }


    @Action(GetDocumentacionPlanosPlazos)
    getDocumentacionPlanosPlazos({ patchState }: StateContext<SocioStateModel>, action: GetDocumentacionPlanosPlazos) {

        this.sgs.getDocumentacionPlanosPlazos(action.cliente).subscribe(
            data => {
                patchState({
                    socio_plazos_documentacion_planos: data,
                });
            }
        )


    }

    @Action(DeleteDocumentacionPlazoPlanos)
    deleteDocumentacionPlazoPlanos(ctx: StateContext<SocioStateModel>, action: DeleteDocumentacionPlazoPlanos) {
        const state = ctx.getState();
        return this.sgs.deleteDocumentacionPlazo(action.plazo, action.usuario, action.usuario_autorizante,action.observacion,state.socio_detalle.id_socio).pipe(
          mergeMap(() =>  ctx.dispatch(new GetDocumentacionPlanosPlazos(action.cliente))            )
        )


    }

    @Action(DeleteDocumentacionPlanos)
    deleteDocumentacionPlanos(ctx: StateContext<SocioStateModel>, action: DeleteDocumentacionPlanos) {
        const state = ctx.getState();
        return this.sgs.deleteDocumentacion(action.documentacion, action.usuario, action.usuario_autorizante,action.observacion,state.socio_detalle.id_socio).pipe(
          mergeMap(() => ctx.dispatch(new GetDocumentacionPlanos(action.cliente)) )
        )
    }


    @Action(GetDocumentacionPlazosControl)
    getDocumentacionPlazosControl({ patchState }: StateContext<SocioStateModel>, action: GetDocumentacionPlazosControl) {
        this.sgs.getDocumentacionPlazosControl(action.tipo_documentacion,action.estado_presentacion, action.dias_a_vencer).subscribe(
            data => {
                patchState({
                    socio_plazos_control: data,
                });
            }
        )

    }


    @Action(EnviarProcesoJudicial)
    enviarProcesoJudicial( ctx : StateContext<SocioStateModel>, action: EnviarProcesoJudicial) {

      const state = ctx.getState();
       return this.sgs.enviarProcesoJudicial(action.cliente).pipe(
         tap(()=>{
                 ctx.patchState({
                        socio_detalle:{...state.socio_detalle,enprocjudicial:true}
                      });
         })
       )
    }


    @Action(RetirarProcesoJudicial)
    retirarProcesoJudicial(ctx: StateContext<SocioStateModel>, action: RetirarProcesoJudicial) {
      const state = ctx.getState();
       return this.sgs.retirarProcesoJudicial(action.cliente).pipe(
        tap(()=>{
          ctx.patchState({
                 socio_detalle:{...state.socio_detalle,enprocjudicial:false}
               });
      })
      )
    }


    @Action(EnviarCODENE)
    enviarCODENE(ctx: StateContext<SocioStateModel>, action: EnviarCODENE) {
      const state = ctx.getState();
       return this.sgs.enviarCODENE(action.cliente).pipe(
        tap(()=>{
          ctx.patchState({
                 socio_detalle:{...state.socio_detalle,encodene:true}
               });
  })
      )
    }


    @Action(EnviarVERAZ)
    enviarVERAZ(ctx: StateContext<SocioStateModel>, action: EnviarVERAZ) {
      const state = ctx.getState();
       return this.sgs.enviarVERAZ(action.cliente).pipe(
        tap(()=>{
          ctx.patchState({
                 socio_detalle:{...state.socio_detalle,enveraz:true}
               });
          })
      )
    }


    @Action(RetirarCODENE)
    retirarCODENE(ctx: StateContext<SocioStateModel>, action: RetirarCODENE) {
      const state = ctx.getState();
       return this.sgs.retirarCODENE(action.cliente).pipe(
        tap(()=>{
          ctx.patchState({
                 socio_detalle:{...state.socio_detalle,encodene:false}
               });
        })
      )
    }

    @Action(RetirarVERAZ)
    retirarVERAZ(ctx: StateContext<SocioStateModel>, action: RetirarVERAZ) {
      const state = ctx.getState();
       return this.sgs.retirarVERAZ(action.cliente).pipe(
        tap(()=>{
          ctx.patchState({
                 socio_detalle:{...state.socio_detalle,enveraz:false}
               });
          })
      )
    }
}
