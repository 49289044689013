<form (ngSubmit)="buscarSocio()" #busquedaSocio="ngForm" class="form-inline my-2 my-lg-0">
  <input id="buscadorSocio" class="form-control form-control-sm mr-sm-2" type="text" [(ngModel)]="socio_busqueda" name="socio_busqueda" autocomplete="off" placeholder="Buscar socio" aria-label="Search" required>
  <div class="btn-group btn-group-sm" role="group" aria-label="Button group with nested dropdown">
  <button class="btn btn-outline-info my-2 my-sm-0" type="submit" [disabled]="!busquedaSocio.form.valid">Buscar</button>
<button type="button" class="btn btn-outline-info my-2 my-sm-0" title="busqueda avanzada" (click)="openBusquedaAvanzada()" ><i class="fas fa-search"></i></button>
  </div>
</form>


<app-busqueda-avanzada (clienteSeleccionado)="clienteSeleccionado($event)"></app-busqueda-avanzada>

<app-operacion-loading ></app-operacion-loading>



