import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Permiso, Rol } from '../store/roles-permisos/model';

@Injectable({
  providedIn: 'root'
})
export class RolesPermisosService {

  constructor(private http:HttpClient){}


  getPermisos():Observable<Permiso[]> {
    return this.http.get<Permiso[]>(`${environment.urlapiNode}/permisos`);
   }

   savePermiso(permiso:string):Observable<any> {
    return this.http.post(`${environment.urlapiNode}/permisos`,{permiso});
   }

   editPermiso(idPermiso:number,permiso:string):Observable<any> {
    return this.http.put(`${environment.urlapiNode}/permisos`,{idPermiso,permiso});
   }

   deletePermiso(idPermiso:number, permiso:string):Observable<any> {
    return this.http.delete(`${environment.urlapiNode}/permisos`,{params:{idPermiso,permiso}});
   }


   getRoles():Observable<Rol[]> {
    return this.http.get<Rol[]>(`${environment.urlapiNode}/roles`);
   }

   saveRol(rol:string,permisos:number[]):Observable<any> {
    return this.http.post(`${environment.urlapiNode}/roles`,{rol,permisos});
   }

   editRol(idRol:number,rol:string,permisos:number[]):Observable<any> {
    return this.http.put(`${environment.urlapiNode}/roles`,{idRol,rol,permisos});
   }

   deleteRol(idRol:number):Observable<any> {
    return this.http.delete(`${environment.urlapiNode}/roles`,{params:{idRol}});
   }

  
}
