import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ConvenioPago } from '../store/covenio-pago/models';

@Injectable({
  providedIn: 'root'
})
export class ConvenioPorServicioService {

  constructor(private http: HttpClient) { }

  getConvenioPagoList():Observable<ConvenioPago[]>{
    return this.http.get<ConvenioPago[]>(`${environment.urlapiNode}/convenio-pago`)
   }

   getConvenioPagoDetail(convenio:number):Observable<any>{
    return this.http.get(`${environment.urlapiNode}/convenio-pago/${convenio}`)
   }

  nuevoConvenioPago(socio:number,concepto:number,importe:number,cuotas:number,valorCuota:number,fecha:Date):Observable<any>{
    return this.http.post(`${environment.urlapiNode}/convenio-pago`,{socio,concepto,importe,cuotas,valorCuota,fecha})
   }

   cobranzaConvenioPago(recibo:number, convenio:number):Observable<any>{
    return this.http.post(`${environment.urlapiNode}/convenio-pago/recibo/cobranza`,{recibo,convenio})
   }

   getComprobanteCobranzaReciboConvenioPago(numeroComprobante:number):Observable<any>{
    return this.http.get(`${environment.urlapiNode}/convenio-pago/recibo/cobranza/comprobante/${numeroComprobante}`,{ responseType: 'blob'})
    }

    getImpresionReciboConvenioPago(convenio:number,recibo:number):Observable<any>{
      return this.http.get(`${environment.urlapiNode}/convenio-pago/recibo/impresion/${convenio}/${recibo}`,{ responseType: 'blob'})
      }


      deleteConvenioPago(convenio:number,observacion:string,usuarioAutorizante:number):Observable<any>{
        let params = new HttpParams()
        .set('convenio',convenio.toString())
        .set('observacion',observacion.toString())
        .set('usuarioAutorizante',usuarioAutorizante.toString())        
        return this.http.delete(`${environment.urlapiNode}/convenio-pago`,{params})
      }
  



}
