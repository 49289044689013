<div class="modal" tabindex="-1" role="dialog" id="impresionListadoModel" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title"><i class="fa fa-list "></i> Impresion Listado de Facturas</h5>
        </div>
  
        <form  [formGroup]="impresionListadoForm" (ngSubmit)="imprimir()" novalidate>
          <div class="modal-body">      
            <div class="form-group">
                <label>Incluir en el listado</label>
                <div class="form-check">                    
                    <input class="form-check-input" type="radio" name="opcionListadoFacturas"  id="adeudadas"  value="adeudadas"  (change)="onTipoListadoChange($event)" checked>
                    <label class="form-check-label" for="adeudadas">
                    Facturas adeudadas
                    </label>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="radio"  name="opcionListadoFacturas" id="pagas"  value="pagas" (change)="onTipoListadoChange($event)">
                    <label class="form-check-label" for="pagas">
                      Facturas pagas
                    </label>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" name="opcionListadoFacturas" id="todo" value="todo" (change)="onTipoListadoChange($event)">
                    <label class="form-check-label" for="todo">
                      Todo
                    </label>
                  </div>
            </div>
            
            <div class="form-group">
            <div class="form-check">
                <input class="form-check-input" type="checkbox" id="limitePeriodoSiNo" (change)="onLimitePeriodos($event)" >
                <label class="form-check-label" for="limitePeriodoSiNo">
                  Limitar a los periodos
                </label>
              </div>
            </div>
            <div class="form-row">
                <ng-container *ngIf="periodos$|async as periodos">
                    <div class="form-group col-md-4">
                        <label for="periodoDesde">Desde</label>
                        <select id="periodoDesde" class="form-control"  formControlName="periodoDesde" >
                          <option *ngFor="let periodo of periodos" [value]="periodo.id_periodo" >{{periodo.id_periodo}}</option>
                        </select>
                      </div>
                      <div class="form-group col-md-4">
                        <label for="periodoHasta">Hasta</label>
                        <select id="periodoHasta" class="form-control"  formControlName="periodoHasta">
                          <option *ngFor="let periodo of periodos" [value]="periodo.id_periodo">{{periodo.id_periodo}}</option>
                        </select>
                      </div>
                </ng-container>
                
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-outline-danger" (click)="cancelar()">Cancelar</button>
            <button [disabled]="!impresionListadoForm.valid" type="submit" class="btn btn-success">Imprimir</button>
          </div>
  
        </form>
  
  
      </div>
    </div>
  </div>

  <app-operacion-loading></app-operacion-loading>