<div class="card">
  <div class="card-header" style="background-color:#17a2b8; color: white">Anulación de Recibos</div>
  <div class="card-body">

    <div class="row">
      <div class="col-md-12">
          <form class="form-inline"  [formGroup]="reciboForm" (ngSubmit)="onSubmit()" novalidate>

              <div class="form-group">
                  <label for="inputRecibo" class="col-sm-12 col-form-label"><span class="badge badge-pill badge-info">1</span> &nbsp;<span>Ingrese el número del recibo</span></label>
                </div>
                <div class="form-group">
                <div class="col-sm-4">
                    <input type="text" formControlName="numeroRecibo" class="form-control" id="inputRecibo" placeholder="Recibo número" maxlength="8"  appNumberEnteroOnly>
                </div>
              </div>

              <div class="col-4">
                <ng-container *ngIf="!estaProcesando; else procesando">
                  <button type="submit" class="btn btn-success" [disabled]="!reciboForm.valid">Buscar</button>
              </ng-container>
              <ng-template #procesando>
                  <button class="btn btn-info" type="button" >
                      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      <span class="ml-2">Buscado...</span>
                    </button>
              </ng-template>
              </div>

            </form>
      </div>

    </div>

    <div class="row justify-content-center">
        <div class="col-sm-6 mt-5">
            <div *ngIf="!reciboSemestral == false ; else sinRecibosParaMostrar" class="card shadow listadas">

                  <ul class="list-group list-group-flush">
                    <li class="list-group-item text-secondary" style="background-color: #ced6e0">
                      <div class="row">
                        <div class="col-10"><b> Pago Semestral -
                            <span *ngIf="+reciboSemestral.semestre==1"> Primer Semestre</span>
                            <span *ngIf="+reciboSemestral.semestre==2"> Segundo Semestre</span>
                        </b> </div>
                        <div class="col-2">
                          <span *ngIf="reciboSemestral.estado_pago==3" class="badge badge-success">Pagada</span>
                        </div>
                      </div>

                  </li>
                    <li class="list-group-item text-secondary">
                      <div class="row">
                          <div class="col-6">Recibo:  {{reciboSemestral.numero_recibo}}</div>
                          <div class="col-6">Vencimiento : {{reciboSemestral.fecha_vencimiento}}</div>
                      </div>

                    </li>
                    <li class="list-group-item text-secondary">
                        <div class="row">
                            <div class="col-6">Comprobante de Pago:  {{reciboSemestral.comprobante_pago}}</div>
                            <div class="col-6">Fecha de Pago : {{reciboSemestral.fecha_pago}}</div>
                        </div>

                      </li>

                      <li class="list-group-item text-secondary">
                          <div class="row">
                              <div class="col-6">Importe abonado: $ {{reciboSemestral.valor}}</div>
                              <div class="col-6">Cliente:  {{reciboSemestral.codSocio}}/{{reciboSemestral.subCodSocio}}  </div>
                          </div>

                        </li>

                    <li class="list-group-item">
                        <div class="row">
                            <div class="col-md-6"><button type="button" class="btn btn-warning btn-sm" (click)="anularRecibo()" >Anular Recibo</button></div>
                          </div>
                    </li>
                  </ul>

              </div>

              <ng-template  #sinRecibosParaMostrar>

                  <div class="row justify-content-md-center mt-5" *ngIf="busquedaRealizada" >
                    <div class="alert alert-info" role="alert">
                      Recibo inexistente o no abonado ...
                    </div>
                  </div>
              </ng-template>

          </div>
      </div>




  </div>
</div>

<app-operacion-si-no [titulo]="'Anulación de Recibo Semestral'" [contenidoMensaje]="'Desea realizar la operación? recuerde que el proceso anulará el recibo y marcará como impagas a las facturas del semestre correspondiente.'"
(retornoOperacion)="retornoOperacionSiNo($event)"></app-operacion-si-no>



     <app-operacion-loading></app-operacion-loading>


