<ag-grid-angular
#agGridRecibosSemestrales
style="width: 100%; height: 250px;"
class="ag-theme-alpine"
[rowData]="reciboSemestralListado"
[columnDefs]="columnDefs"
[colResizeDefault]="'fit'"
[rowSelection]= "'single'"
[rowHeight]="25"
[components]="frameworkComponents"
></ag-grid-angular>
