<ng-container [formGroup]="socioForm">

     <div id="datosSocio"></div>
    <div class="card shadow" >
      <div class="card-body">
          <p style="border-left: solid 5px #17a2b8; padding-left: 5px"><b> Datos del socio</b></p>
          <hr>
          <div class="form-group row">
              <label for="inputCondicionRegistro" class="col-sm-3 col-form-label col-form-label-sm  text-right">Condición de registro en Cooperativa</label>
              <div class="col">
                  <select  class="form-control form-control-sm" id="inputCondicionRegistro" formControlName="condicionRegistro"  [ngClass]="{ 'is-invalid': isInvalidCondicionesRegistroField }">
                      <option *ngFor="let condicionRegistroItem of condicionesDeRegistro" [value]="condicionRegistroItem.id">{{condicionRegistroItem.detalle}}</option>
                  </select>
                <div *ngIf="isInvalidCondicionesRegistroField" class="invalid-feedback">
                  Campo obligatorio
                </div>
              </div>
            </div>
        <div class="form-group row">
          <label for="inputCodigo" class="col-sm-3 col-form-label col-form-label-sm  text-right">Código</label>
          <div class="col">
            <input type="text" formControlName="codigo" class="form-control form-control-sm" id="inputCodigo" placeholder="Código" maxlength="6" [ngClass]="{ 'is-invalid': isInvalidCodigoField || errorSocioUsuarioRegistrado }" autocomplete="off" appNumberEnteroOnly>
            <div *ngIf="isInvalidCodigoField" class="invalid-feedback">
              Debe ingresar el código del socio
            </div>
          </div>
          <label for="inputSubCodigo" class="col-sm-2 col-form-label col-form-label-sm  text-right">Subcódigo</label>
          <div class="col">
            <input type="text" class="form-control form-control-sm" formControlName="subcodigo" id="  " placeholder="Sub código" value="00" maxlength="2" [ngClass]="{ 'is-invalid': isInvalidSubcodigoField || errorSocioUsuarioRegistrado }" autocomplete="off" appNumberEnteroOnly>
            <div *ngIf="isInvalidSubcodigoField" class="invalid-feedback">
             Debe ingresar el sub código del socio
            </div>
          </div>
        </div>

        <div class="form-group row">
          <label for="inputApellidoNombre" class="col-sm-3 col-form-label col-form-label-sm  text-right">Apellido y nombre</label>
          <div class="col">
            <input type="text" class="form-control form-control-sm" formControlName="apellidoNombre" maxlength="80" id="inputApellidoNombre" placeholder="" [ngClass]="{ 'is-invalid': isInvalidApellidoNombreField }">
            <div *ngIf="isInvalidApellidoNombreField" class="invalid-feedback">
            Campo obligatorio
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label for="inputTipoDocumento" class="col-sm-3 col-form-label col-form-label-sm  text-right">Documento</label>
          <div class="col-sm-3">
            <select  class="form-control form-control-sm" id="inputTipoDocumento" formControlName="tipoDocumento" [ngClass]="{ 'is-invalid': isInvalidTipoDocumentoField }">
              <option *ngFor="let tipoDniItem of tiposDni" [value]="tipoDniItem.id">{{tipoDniItem.detalle}}</option>
          </select>
            <div *ngIf="isInvalidTipoDocumentoField" class="invalid-feedback">
              Campo obligatorio
            </div>
          </div>
          <div class="col">
            <input type="text"  class="form-control form-control-sm" formControlName="documento" id="inputDocumento" maxlength="9" placeholder="" [ngClass]="{ 'is-invalid': isInvalidDocumentoField }" appNumberEnteroOnly>
            <div *ngIf="isInvalidDocumentoField" class="invalid-feedback">
              Campo obligatorio
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label for="inputCuilCuit" class="col-sm-3 col-form-label col-form-label-sm  text-right">Cuil/Cuit</label>
          <div class="col-2">
            <select  class="form-control form-control-sm" id="inputCuilCuit" formControlName="tipoCuilCuit">
              <option value="20" >20</option>
              <option value="23" >23</option>
              <option value="24" >24</option>
              <option value="27" >27</option>
              <option value="30" >30</option>
              <option value="33" >33</option>
              <option value="34" >34</option>
          </select>
          </div>
          <div class="col-3">
            <input type="text" class="form-control form-control-sm" formControlName="numeroCuilCuit"  placeholder="">
          </div>
          <div class="col-2">
            <select  class="form-control form-control-sm" id="inputVerificadorCuilCuit" formControlName="digitoVerificadorCuilCuit">
              <option value="0" >0</option>
              <option value="1" >1</option>
              <option value="2" >2</option>
              <option value="3" >3</option>
              <option value="4" >4</option>
              <option value="5" >5</option>
              <option value="6" >6</option>
              <option value="7" >7</option>
              <option value="8" >8</option>
              <option value="9" >9</option>
          </select>
          </div>
        </div>
        <div class="form-group row">
            <label for="inputIva" class="col-sm-3 col-form-label col-form-label-sm  text-right">IVA</label>
            <div class="col">
              <select  class="form-control form-control-sm" id="inputIva" formControlName="iva" [ngClass]="{ 'is-invalid': isInvalidIvaField }">
                <option *ngFor="let tipoIvaItem of tiposIva" [value]="tipoIvaItem.id">{{tipoIvaItem.detalle}}</option>
            </select>
            <div *ngIf="isInvalidIvaField" class="invalid-feedback">
              Campo obligatorio
            </div>
            </div>
          </div>
          <div class="form-group row">
              <label for="inputCategoria" class="col-sm-3 col-form-label col-form-label-sm  text-right">Categoria</label>
              <div class="col">
               <select  class="form-control form-control-sm" id="inputCategoria" formControlName="categoria" [ngClass]="{ 'is-invalid': isInvalidCategoriaField}">
                 <option *ngFor="let categoriaItem of categorias" [value]="categoriaItem.id">{{categoriaItem.detalle}}</option>
            </select>
                <div *ngIf="isInvalidCategoriaField" class="invalid-feedback">
                 Campo obligatorio
                </div>
              </div>
            </div>
            <div class="form-group row">
                <label for="inputTelefonoFijo" class="col-sm-3 col-form-label col-form-label-sm  text-right">Telefono fijo</label>
                <div class="col">
                  <input type="text" class="form-control form-control-sm" maxlength="60" formControlName="telefonoFijo" id="inputTelefonoFijo" placeholder="" autocomplete="off">
                  <small id="emailHelp" class="form-text text-muted">Puede ingresar más de un número. Utilice algún tipo de separador para difereciar. Ej: -</small>
                </div>
              </div>
              <div class="form-group row">
                  <label for="inputCelular" class="col-sm-3 col-form-label col-form-label-sm  text-right">Celular</label>
                  <div class="col">
                    <input type="text" class="form-control form-control-sm" maxlength="60" formControlName="celular" id="inputCelular" placeholder="">
                    <small id="emailHelp" class="form-text text-muted">Puede ingresar más de un número. Utilice algún tipo de separador para difereciar. Ej: -</small>
                  </div>
              </div>
              <div class="form-group row">
                  <label for="inputEmail" class="col-sm-3 col-form-label col-form-label-sm  text-right">Email</label>
                  <div class="col">
                    <input type="text" class="form-control form-control-sm" maxlength="80" formControlName="email" id="inputEmail" placeholder="" [ngClass]="{ 'is-invalid':isInvalidEmailField }" autocomplete="off">
                  <div *ngIf="isInvalidEmailField" class="invalid-feedback">
                    <div *ngIf="socioForm.controls['email'].errors.email">Email no válido</div>
                    <div *ngIf="!socioForm.controls['email'].errors.required==false">Campo obligatorio</div>
                  </div>
                  </div>
                </div>
                <div class="form-group row">
                    <label for="inputFacturaElectronica" class="col-sm-4 col-form-label col-form-label-sm  text-right">Adhesión a la factura electrónica</label>
                    <div class="col-1">
                      <input type="checkbox" class="form-control form-control-sm" id="inputFacturaElectronica" (change)="onSelectionFacturaElectronicaChange($event)" formControlName="facturaElectronica" placeholder="" >
                    </div>
                  </div>

                  <div class="form-group row">
                    <label for="inputFechaDeAlta" class="col-3 col-form-label col-form-label-sm  text-right">Fecha de alta</label>
                    <div class="col">
                      <input type="date" class="form-control form-control-sm" formControlName="fechaDeAlta" id="inputFechaDeAlta" placeholder="" [ngClass]="{ 'is-invalid': isInvalidFechaDeAltaField }">
                    <div *ngIf="isInvalidFechaDeAltaField" class="invalid-feedback">
                      Campo obligatorio
                    </div>
                    </div>
                </div>
      </div>
    </div>


</ng-container>
