import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-ag-button-render',
  template: `
    <button type="button"  style="padding: 0.1px 7px;" class="btn btn-sm"
    [ngClass]="{
        'btn-outline-warning':classBtn === 'warning',
        'btn-outline-info':classBtn === 'info' || classBtn === 'edit' ,
        'btn-outline-primary':classBtn === 'primary',
        'btn-outline-danger':classBtn === 'danger' || classBtn === 'email',
        'btn-outline-success':classBtn === 'success'
      }"
     (click)="onClick($event)" [attr.title]="label"><i class="fas"
     [ngClass]="{
        'fa-download':classBtn === 'warning',
        'fa-eye':classBtn === 'primary',
        'fa-trash-alt':classBtn === 'danger',
        'fa-print':classBtn === 'success' || classBtn === 'info'  ,
        'fa-envelope-square':classBtn === 'email',
        'fa-pencil':classBtn === 'edit'
      }"
     ></i></button>
    `
})
export class AgButtonRenderComponent implements ICellRendererAngularComp {

  params;
  label: string;
  classBtn: string;

  agInit(params): void {
    this.params = params;
    this.label = this.params.label || null;
    this.classBtn = this.params.classBtn || null;
  }

  refresh(params?: any): boolean {
    return true;
  }

  onClick($event) {
    if (this.params.onClick instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: $event,
        rowData: this.params.node.data
        // ...something
      }
      this.params.onClick(params);

    }
  }

}
