import { NgModule, Injectable, ErrorHandler } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {AuthInterceptorService, AuthInterceptorServiceResponse} from '../login/auth/auth-interceptor.service';


const routes:Routes = [
  {
      path: '',
      component: LoginComponent

  },

]

@NgModule({
  declarations: [LoginComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule
  ],
  providers:[{
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptorService,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptorServiceResponse,
    multi: true
},{provide:ErrorHandler}]
})
export class LoginModule { }
