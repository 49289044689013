import { Component, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngxs/store';
import { takeUntil, map, mergeMap, filter } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { OperacionSiNoObservacionComponent } from 'src/app/shared/operacion-si-no-observacion/operacion-si-no-observacion.component';
import { SocioGestionService } from '../socio-gestion.service';
import { AuthState } from 'src/app/store/auth/state';
import { EnviarProcesoJudicial, EnviarCODENE, EnviarVERAZ, RetirarProcesoJudicial, RetirarCODENE, RetirarVERAZ } from 'src/app/store/socio/actions';
import { SocioState } from 'src/app/store/socio/states';
import { GetObservaciones } from 'src/app/store/observacion/actions';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-observaciones',
  templateUrl: './observaciones.component.html',
  styleUrls: ['./observaciones.component.css']
})
export class ObservacionesComponent implements OnInit {

  @ViewChild(OperacionSiNoObservacionComponent, { static: true })
  operacionSiNoObservacionComponent: OperacionSiNoObservacionComponent;

  destroy$: Subject<boolean> = new Subject<boolean>();
  observaciones:string[]=[]


  enProcesoJudicial:boolean
  enCODENE:boolean
  enVERAZ:boolean

  cliente : number


  constructor(private store:Store,
     private ss:SocioGestionService) {
      }

  ngOnInit() {
    this.cliente = this.store.selectSnapshot(SocioState.cliente)
    this.store.dispatch(new GetObservaciones(this.cliente,false))

    this.store.select(state => state.observacion.socio_observaciones).pipe(takeUntil(this.destroy$),
    filter(data=>!!data),
    map(data =>
      data.observaciones?.split('\r\n').filter(Boolean))
    ).subscribe(
      data => {

                this.observaciones=data || [];
      }
    );

    this.store.select(state=>state.socio.socio_detalle).pipe(
      takeUntil(this.destroy$),
      filter(socio=>!!socio)).subscribe(
      socio => {this.enProcesoJudicial=socio.enprocjudicial; this.enCODENE=socio.encodene; this.enVERAZ=socio.enveraz}
    )

  }

  agregarObservacion(){
    this.operacionSiNoObservacionComponent.open();
  }

  retornoOperacionSiNo(retorno){
     if(retorno.opcion_seleccion){
      const usuarioNombre = this.store.selectSnapshot(AuthState.usuarioNombre);

      this.ss.updateObservacionSocioUsuario(this.cliente,retorno.observacion,usuarioNombre).subscribe(
        _ => {
          Swal.fire(
            '','La observación ha sido agregada!',
            'success'
          )
          this.store.dispatch(new GetObservaciones(this.cliente,true))}
      )

     }

  }

  enviarProcesoJudicial(){

    this.store.dispatch(new EnviarProcesoJudicial(this.cliente)).pipe(
      mergeMap(()=> this.store.dispatch(new GetObservaciones(this.cliente,true)))
    ).subscribe()
  }

  enviarCodene(){

    this.store.dispatch(new EnviarCODENE(this.cliente)).pipe(
      mergeMap(()=> this.store.dispatch(new GetObservaciones(this.cliente,true)))
    ).subscribe()
  }

  enviarVeraz(){

    this.store.dispatch(new EnviarVERAZ(this.cliente)).pipe(
      mergeMap(()=> this.store.dispatch(new GetObservaciones(this.cliente,true)))
    ).subscribe()
  }


  retirarProcesoJudicial(){

    this.store.dispatch(new RetirarProcesoJudicial(this.cliente)).pipe(
      mergeMap(()=> this.store.dispatch(new GetObservaciones(this.cliente,true)))
    ).subscribe()
  }

  retirarCodene(){

    this.store.dispatch(new RetirarCODENE(this.cliente)).pipe(
      mergeMap(()=> this.store.dispatch(new GetObservaciones(this.cliente,true)))
    ).subscribe()
  }

  retirarVeraz(){

    this.store.dispatch(new RetirarVERAZ(this.cliente)).pipe(
      mergeMap(()=> this.store.dispatch(new GetObservaciones(this.cliente,true)))
    ).subscribe()
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }


}
