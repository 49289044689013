import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { ItemsListaCaja } from 'src/app/store/general/models';
import { Store } from '@ngxs/store';
import { GetCajas } from 'src/app/store/general/actions';
import { AuthState } from 'src/app/store/auth/state';
import { CobranzaService } from '../../cobranza.service';
import { Imprimir } from 'src/app/shared/imprimir';


@Component({
  selector: 'app-control-recaudacion',
  templateUrl: './control-recaudacion.component.html',
  styleUrls: ['./control-recaudacion.component.css']
})
export class ControlRecaudacionComponent implements OnInit {

  formControlRecaudacion: UntypedFormGroup;

  cajas$:Observable<ItemsListaCaja[]>

  usuariologin:string

  loading:boolean = false

  imprimir:Imprimir= new Imprimir()

  constructor(private cs:CobranzaService,private store:Store, private fb:UntypedFormBuilder) {

    this.formControlRecaudacion = this.fb.group({
      tipoListado:['1', Validators.required], //1- todas las cajas, 2- por caja, 3- caja en particular
      caja:[{value:'', disabled: true}, Validators.required],
      origenResumen:['1', Validators.required], //1- todas las cobranzas, 2- lo cobrado por
      fechaListado: [ new Date().toISOString().substring(0, 10), Validators.required],
      ordenarPor: ['1', Validators.required] //1- socio, 2- factura
    })
   }

   ngOnInit(): void {
     this.store.dispatch(new GetCajas())
    this.cajas$ = this.store.select(state => state.general.cajas)

    this.usuariologin = this.store.selectSnapshot(AuthState.usuarioLogin);

    this.getTipoListadoField.valueChanges.subscribe(
      valor=> {
        if (+valor ==3){
          this.getCajaField.enable()
        }else{
          this.getCajaField.disable()
          this.getCajaField.setValue('')
        }
      }
    )
  }



  getControlRecaudacion(event:Event){
    event.preventDefault();

    if(this.formControlRecaudacion.valid){
      this.loading = true;
      this.cs.getControlRecaudacionPDF(
        this.getTipoListadoField.value, this.getCajaField.value,
        this.getOrigenResumenField.value, this.getFechaListadoField.value, this.getOrdenarPorField.value
        )
     .subscribe(
       (contenido: any)=>{
        const a = new Blob([contenido], { type: 'application/pdf' });

        this.imprimir.openInNewWindows(a)
             
         this.loading = false;

       },
       ()=>this.loading=false);
    }

  }

  get getTipoListadoField(){
    return this.formControlRecaudacion.controls.tipoListado
  }

  get getCajaField(){
    return this.formControlRecaudacion.controls.caja
  }

  get getOrigenResumenField(){
    return this.formControlRecaudacion.controls.origenResumen
  }

  get getFechaListadoField(){
    return this.formControlRecaudacion.controls.fechaListado
  }

  get getOrdenarPorField(){
    return this.formControlRecaudacion.controls.ordenarPor
  }


  get valorCajaIsInvalid(){
    return (this.getCajaField.errors && this.getCajaField.touched)
  }

  get valorFechaListadoIsInvalid(){
    return (this.getFechaListadoField.errors && this.getFechaListadoField.touched)
  }


}
