import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild, signal } from "@angular/core";
import { Store } from "@ngxs/store";
import moment from "moment";
import { Subject } from "rxjs";
import { mergeMap, takeUntil, tap } from "rxjs/operators";
import { AutorizacionComponent } from "src/app/autorizacion/autorizacion.component";
import { OperacionLoadingComponent } from "src/app/shared/operacion-loading/operacion-loading.component";
import { OperacionSiNoComponent } from "src/app/shared/operacion-si-no/operacion-si-no.component";
import { GetCreditoImputado, GetCreditoMontoTotal, GetCreditosOtorgados } from "src/app/store/credito/actions";
import { GetCuentaCorriente } from "src/app/store/cuentacorriente/actions";
import {
  GetFacturasImpagasActualizadas,
  GetFacturasPagas,
} from "src/app/store/factura/actions";
import { GetObservaciones } from "src/app/store/observacion/actions";
import {
  GetInformacionFacturasPagasReciboSemestreActivo,
  GetInformacionReciboSemestreActivo,
  GetListadoReciboSemestral,
  GetReciboSemestralParaPago,
} from "src/app/store/recibo/actions";
import {
  FacturasSemestrePagas,
  InformacionReciboSemestralActual,
  ReciboSemestralPago,
} from "src/app/store/recibo/models";
import { SocioState } from "src/app/store/socio/states";
import Swal from "sweetalert2";
import { ReciboService } from "../../recibo-semestral.service";
import { DescuentoRecibo } from "../model";
import { getDecimales, getUnidad } from "src/app/utils/number";

@Component({
  selector: "app-recibo-semestral-cobranza",
  templateUrl: "./recibo-semestral-cobranza.component.html",
  styleUrls: ["./recibo-semestral-cobranza.component.css"],
})
export class ReciboSemestralCobranzaComponent implements OnInit, OnDestroy {

  @Output() imprimirReciboEvent = new EventEmitter()
  @Output() imprimirComprobantePagoCobranzaEvent = new EventEmitter()
  
  @ViewChild(OperacionLoadingComponent, { static: true })
  operacionLoadingComponent: OperacionLoadingComponent;

  @ViewChild(OperacionSiNoComponent, { static: true })
  operacionSiNoComponent: OperacionSiNoComponent;

  @ViewChild(OperacionSiNoComponent, { static: true })
  operacionSiNoAnularComponent: OperacionSiNoComponent;

  @ViewChild(AutorizacionComponent, { static: true })
  autorizacionComponent: AutorizacionComponent;

  destroy$: Subject<boolean> = new Subject<boolean>();

  reciboSemestral: ReciboSemestralPago;

  numeroDeComprobante: String;

  pagoEnProceso: boolean = false;

  reciboInformacionSemestral: InformacionReciboSemestralActual;

  deshabilitarPago: boolean = false;

  cliente: number;
  estaSocioAdheridoDebitoAutomatico = signal<boolean>(false)

  montoCreditoRegistrado: number = 0;
  mostrarDescuentoPorCreditoAFavor: boolean = false;
  montoADescontarPorCreditoAFavor: number = 0;
  

  facturasSemestresPreviamenteAbonadas: FacturasSemestrePagas[];
  montoFacturasSemestresPreviamenteAbonadas: number = 0;
  mostrarDescuentoFacturasSemestresPreviamenteAbonadas: boolean = false;
  montoADescontarPorFacturasSemestresPreviamenteAbonadas: number = 0;

  
  mostrarDescuentoPorDebitoAutomatico: boolean = false;
  montoADescontarPorDebitoAutomatico: number = 0;

  
  mostrarDescuentoPorAjustePorRedondeo: boolean = false;
  montoADescontarPorAjustePorRedondeo: number = 0;

  totalReciboAPagar: number;

  constructor(private store: Store, private rs: ReciboService) { }

  ngOnInit() {
    
    this.cliente = this.store.selectSnapshot(SocioState.cliente);
    this.estaSocioAdheridoDebitoAutomatico.set(this.store.selectSnapshot(SocioState.adhesionDebitoAutomatico))

    this.store.dispatch(new GetInformacionReciboSemestreActivo());
    this.store.dispatch(new GetReciboSemestralParaPago(this.cliente, false));

    //  busco creditos otorgados para determinar si se le puede o no aplicar descuento en el pago del recibo
    this.store.dispatch(new GetCreditoMontoTotal(this.cliente, false));

    // busco facturas del semestre que el socio haya abonado para aplicar el descuento en el recibo
    this.store.dispatch(
      new GetInformacionFacturasPagasReciboSemestreActivo(this.cliente, false)
    );

    this.store
      .select((state) => state.recibo.recibo_semestral_pago)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.reciboSemestral = data;
        this.totalReciboAPagar = this.totalReciboSemestral();
      });

    this.store
      .select((state) => state.recibo.informacion_recibo_semetral_activo)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.reciboInformacionSemestral = data;
      });

    this.store
      .select((state) => state.credito.credito_monto_total)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.montoCreditoRegistrado = data?.monto;
      });

    this.store
      .select((state) => state.recibo.recibo_semestral_facturas_pagas)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
       
        this.montoFacturasSemestresPreviamenteAbonadas = 0;
        if (data.length > 0) {
          this.facturasSemestresPreviamenteAbonadas = data;
          this.habilitarDescuentoPorFacturasDelSemestrePreviamenteAbonada();
        }
      });
  }

  habilitarDescuentoPorFacturasDelSemestrePreviamenteAbonada() {
    this.facturasSemestresPreviamenteAbonadas.forEach(
      (facturaPaga) =>
        (this.montoFacturasSemestresPreviamenteAbonadas += facturaPaga.importe)
    );

    this.montoFacturasSemestresPreviamenteAbonadas = Number(
      this.montoFacturasSemestresPreviamenteAbonadas.toFixed(2)
    );

  }

  

  onAplicarDescuentoPorCreditoAFavor(){
    this.aplicarDescuentoConfirmacion(this.montoCreditoRegistrado,'crédito a favor','y se actualizará el valor del crédito a favor',DescuentoRecibo.descuentoPorCreditoAFavor)
    
  }

  onAplicarDescuentoPorFacturasDelSemestreAbonadas(){
    this.aplicarDescuentoConfirmacion(this.montoFacturasSemestresPreviamenteAbonadas,'facturas del semestre previamente abonadas','',DescuentoRecibo.descuentoFacturasSemestresPreviamenteAbonadas)
    
  }

  onAplicarDescuentoPorDebitoAutomatico(){  
    this.aplicarDescuentoConfirmacion( +((this.totalReciboSemestral() * 10 / 100).toFixed(2)),'10% de descuento por adhesión al débito automático','. Porcentaje calculado sobre "Importe total recibo"',DescuentoRecibo.descuentoPorDebitoAutomatico)
    
  }

  getMontoPorRedondeo(importe:number){
    return getUnidad(importe)+getDecimales(importe)
  }

  onAplicarDescuentoPorRedondeo(){
    
    this.aplicarDescuentoConfirmacion(this.getMontoPorRedondeo(this.totalReciboAPagar) ,'ajuste por redondeo','. Redondeo aplicado sobre "Total a pagar" ',DescuentoRecibo.descuentoPorRedondeo)
  }

  aplicarDescuentoConfirmacion(montoDescuento, concepto,informacionAdicional, tipoDescuento:DescuentoRecibo) {
    Swal.fire({
      title: "",
      text: `El socio/usuario posee $${montoDescuento} en concepto de ${concepto}, desea aplicar dicho monto como descuento? En caso afirmativo, se aplicará el descuento posible ${informacionAdicional}.`,
      icon: "info",
      confirmButtonColor: "#3085d6",
      confirmButtonText: "Si, aplicar descuento!",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      cancelButtonColor: "#d33",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        this.aplicarDescuento(montoDescuento,tipoDescuento);     
      }
    });
  }

  aplicarDescuento(montoDescuento,tipoDescuento) {
    // verifico cual es el monto posible a descontar
    
    let montoADescontar

    montoADescontar = +montoDescuento;
    if (+montoDescuento > +this.totalReciboAPagar) {
      montoADescontar = +this.totalReciboAPagar;      

    }

   

    if (DescuentoRecibo.descuentoPorCreditoAFavor== tipoDescuento){
      this.mostrarDescuentoPorCreditoAFavor = true;
      this.montoADescontarPorCreditoAFavor = montoADescontar
    }

    if (DescuentoRecibo.descuentoFacturasSemestresPreviamenteAbonadas== tipoDescuento){
      this.mostrarDescuentoFacturasSemestresPreviamenteAbonadas = true;
      this.montoADescontarPorFacturasSemestresPreviamenteAbonadas = montoADescontar
    }

    if (DescuentoRecibo.descuentoPorDebitoAutomatico== tipoDescuento){      
      this.mostrarDescuentoPorDebitoAutomatico = true;
      this.montoADescontarPorDebitoAutomatico = montoADescontar;
    }

    if (DescuentoRecibo.descuentoPorRedondeo== tipoDescuento){      
      this.mostrarDescuentoPorAjustePorRedondeo = true;
      this.montoADescontarPorAjustePorRedondeo = montoADescontar;
    }

    if (DescuentoRecibo.descuentoPorRedondeo== tipoDescuento){      
      this.mostrarDescuentoPorAjustePorRedondeo = true;
     this.montoADescontarPorAjustePorRedondeo = montoADescontar;      
    }

    this.actualizarTotalReciboAPagar(tipoDescuento)
    
  }

  onQuitarDescuentoPorCreditoAFavor() {
    this.mostrarDescuentoPorCreditoAFavor = false;
    this.montoADescontarPorCreditoAFavor = 0
   this.actualizarTotalReciboAPagar(DescuentoRecibo.descuentoPorCreditoAFavor)
  
  }

  onQuitarDescuentoPorFacturasDelSemestreAbonadas(){
    this.mostrarDescuentoFacturasSemestresPreviamenteAbonadas = false;  
    this.montoADescontarPorFacturasSemestresPreviamenteAbonadas= 0
    this.actualizarTotalReciboAPagar(DescuentoRecibo.descuentoFacturasSemestresPreviamenteAbonadas)
  
  }

  onQuitarDescuentoPorDebitoAutomatico(){
    this.mostrarDescuentoPorDebitoAutomatico = false;   
    this.montoADescontarPorDebitoAutomatico = 0;
   this.actualizarTotalReciboAPagar(DescuentoRecibo.descuentoPorDebitoAutomatico)
  
  }

  onQuitarDescuentoPorRedondeo(){
    this.mostrarDescuentoPorAjustePorRedondeo = false;   
    this.montoADescontarPorAjustePorRedondeo = 0
    this.actualizarTotalReciboAPagar(DescuentoRecibo.descuentoPorRedondeo)    
  }


  actualizarTotalReciboAPagar(tipoDescuento:number){  
    let subtotalSinRedondeo = Number((this.totalReciboSemestral() - this.montoADescontarPorCreditoAFavor -  this.montoADescontarPorFacturasSemestresPreviamenteAbonadas -  this.montoADescontarPorDebitoAutomatico).toFixed(2))

    //verificamos si debemos recalcular el descuento por redondeo
    if (DescuentoRecibo.descuentoPorRedondeo!== tipoDescuento && this.mostrarDescuentoPorAjustePorRedondeo){
      this.montoADescontarPorAjustePorRedondeo = this.getMontoPorRedondeo(subtotalSinRedondeo)
     }

    this.totalReciboAPagar = Number( (subtotalSinRedondeo-this.montoADescontarPorAjustePorRedondeo).toFixed(2))    
  }


  totalReciboSemestral() {
    if (this.reciboSemestral) {
        return this.reciboSemestral.importe_total;
    }
  }

  pagar() {
    if (
      moment().isSameOrAfter(moment(this.reciboSemestral.fecha_emision), "day")
    ) {
      this.operacionSiNoComponent.open();
      this.pagoEnProceso = true;
    } else {
      Swal.fire(
        "",
        "El recibo semestral no se encuentra habilitado para su cobro. Se habilitará en la fecha de emisión correspondiente!",
        "error"
      );
    }
  }

  retornoOperacionSiNo(retorno) {
    if (retorno) {
      // controlo si el recibo ha vencido. En caso de ser asi la operación de pago debe ser autorizada
      if (
        moment().isAfter(moment(this.reciboSemestral.fecha_vencimiento), "day")
      ) {
        this.autorizacionComponent.open();
      } else {
        this.ejecutarPagoRecibo(null);
      }
    } else {
      this.pagoEnProceso = false;
    }
  }

  retornoAutorizacion(e) {
    if (e.habilita) {
      this.ejecutarPagoRecibo(e.usuarioAutorizante);
    } else {
      this.pagoEnProceso = false;
    }
  }

  ejecutarPagoRecibo(usuarioAutorizante) {
    // 2 semestre 2020 no tiene descuento

    this.deshabilitarPago = true;

    let descuentos: any[]=[];

    if (this.montoADescontarPorCreditoAFavor>0) {
      let descuento:any= new Object()
      descuento.tipoDescuento = DescuentoRecibo.descuentoPorCreditoAFavor
      descuento.importeDescuento = this.montoADescontarPorCreditoAFavor
      descuentos.push(descuento)
    }

    if (this.montoADescontarPorFacturasSemestresPreviamenteAbonadas>0) {
      let descuento:any= new Object()
      descuento.tipoDescuento = DescuentoRecibo.descuentoFacturasSemestresPreviamenteAbonadas
      descuento.importeDescuento = this.montoADescontarPorFacturasSemestresPreviamenteAbonadas
      descuentos.push(descuento)
    }

    if (this.montoADescontarPorDebitoAutomatico>0) {
      let descuento:any= new Object()
      descuento.tipoDescuento = DescuentoRecibo.descuentoPorDebitoAutomatico
      descuento.importeDescuento = this.montoADescontarPorDebitoAutomatico
      descuentos.push(descuento)
    }

    if (this.montoADescontarPorAjustePorRedondeo>0) {
      let descuento:any= new Object()
      descuento.tipoDescuento = DescuentoRecibo.descuentoPorRedondeo
      descuento.importeDescuento = this.montoADescontarPorAjustePorRedondeo
      descuentos.push(descuento)
    }

    

    let fechaActual = moment().format("YYYY-MM-DD");

    this.rs
      .pagarReciboSemestral(
        this.reciboSemestral.numero_recibo,
        this.cliente,
        this.totalReciboAPagar,
        descuentos,
        1,
        fechaActual,
        null,
        null,
        usuarioAutorizante
      )
      .pipe(
        mergeMap(() =>
          this.store.dispatch(
            new GetReciboSemestralParaPago(this.cliente, true)
          )
        ),
        mergeMap(() =>
          this.store.dispatch(new GetListadoReciboSemestral(this.cliente, true))
        ),
        mergeMap(() =>
          this.store.dispatch(new GetObservaciones(this.cliente, true))
        ),
        mergeMap(() =>
          this.store.dispatch(new GetFacturasPagas(this.cliente, true))
        ),
        mergeMap(() =>
          this.store.dispatch(
            new GetFacturasImpagasActualizadas(this.cliente, true)
          )
        ),
        mergeMap(() =>
          this.store.dispatch(new GetCuentaCorriente(this.cliente, true))
        )
        ,
        mergeMap(() =>
          this.store.dispatch(new GetCreditoMontoTotal(this.cliente,true))
        )
        ,
        mergeMap(() =>
          this.store.dispatch(new GetCreditosOtorgados(this.cliente,true))
        )
        ,
        mergeMap(() =>
          this.store.dispatch(new GetCreditoImputado(this.cliente,true))
        )

       
      )
      .subscribe(
        (comprobante) => {
          Swal.fire(
            "El Cobro ha sido realizado con éxito!",
            "Al aceptar aguarde un instante mientras se genera el comprobante de pago!",
            "success"
          ).then(() => {
            this.pagoEnProceso = false;          
            this.mostrarDescuentoPorCreditoAFavor = false;
            this.mostrarDescuentoFacturasSemestresPreviamenteAbonadas = false;
            this.mostrarDescuentoPorDebitoAutomatico = false;   
            this.mostrarDescuentoPorAjustePorRedondeo = false;  

            this.imprimirComprobantePagoCobranzaEvent.emit(this.reciboSemestral.numero_recibo)
          });
        },
        (err) => {
          this.pagoEnProceso = false;
          this.deshabilitarPago = false;
        }
      );
  }

  imprimirReciboSemestral(){

this.imprimirReciboEvent.emit({fecha_emision:this.reciboSemestral.fecha_emision,numero_recibo:this.reciboSemestral.numero_recibo })
  }

  imprimirComprobantePago(){
    this.imprimirComprobantePagoCobranzaEvent.emit(this.reciboSemestral.numero_recibo)
  }



  generarReciboCliente(){

    this.rs.generarReciboSemestralCliente(this.reciboInformacionSemestral.recibo,this.cliente).pipe(
      tap(result=>{
        if(!result.result){
          Swal.fire(
            '',result.message,
            'error'
          )

        }else{

          Swal.fire(
            '','El recibo semestral ha sido generado con éxito!',
            'success'
          )
        }
      }),
      mergeMap(()=> this.store.dispatch(new GetListadoReciboSemestral(this.cliente,true))),
      mergeMap(()=> this.store.dispatch(new GetReciboSemestralParaPago(this.cliente,true)))
     ).subscribe(
    )


  }


  

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
