import { Usuario } from "./model"

export class GetUsuarios{
    static readonly type = '[Usuario] GetUsuarios'
    constructor(public update:boolean=false){}
}

export class SaveUsuario{
    static readonly type = '[Usuario] SaveUsuario'
    constructor(
        public nombre:string,
        public apellido:string,
        public username:string,
        public estaActivo:boolean,
        public rol:number,
        public permisos:number[],
        public password:string
        ){}
  }


  export class UpdateUsuario{
    static readonly type = '[Usuario] UpdateUsuario'
    constructor(
        public id_usuario:number,
        public nombre:string,
        public apellido:string,
        public username:string,
        public estaActivo:boolean,
        public rol:number,
        public permisos:number[]
        ){}
  }


  export class SetUsuarioEdicion{
    static readonly type = '[Usuario] SetUsuarioEdicion'
    constructor(public usuario:Usuario){}
}

export class UpdatePassword{
    static readonly type = '[Usuario] UpdatePassword'
    constructor(public idUsuario:number,public newPassword:string){}
}


