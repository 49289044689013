import {  Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup,  Validators } from '@angular/forms';
import { OperacionesABM } from 'src/app/shared/enums/enums';
import { Validacion } from 'src/app/shared/validacion';
import { SocioDetalle } from 'src/app/store/socio/models';
import { ValidacionFormularioSocio } from '../validacion-formulario-socio';

@Component({
  selector: 'app-lote-info',
  templateUrl: './lote-info.component.html',
  styleUrls: ['./lote-info.component.css']
})
export class LoteInfoComponent implements OnInit {

  @Input() modoOperacion: OperacionesABM;
  @Input() socio: SocioDetalle;

  public validadores: Validacion = new Validacion();

  socioForm: UntypedFormGroup
  constructor( private fb: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.socioForm = this.initSocioFormModel();
    this.setearSocioForm();
  }

  initSocioFormModel(){
      return this.fb.group({
        nomenclaturaCatastral: [this.socio.nomenclatura],
        manzanaCatastral: [this.socio.manzana, Validators.required],
        loteCatastral: [this.socio.lote, Validators.required],
        ruta: [this.socio.ruta],
        orden: [this.socio.orden],
      })
   }

   setearSocioForm(){

    if (this.modoOperacion == OperacionesABM.alta) {
      this.socioForm
      .get("nomenclaturaCatastral")
      .setValidators([
        Validators.required,
        this.validadores.esNumeroXdigitos(15),
      ]);
    }

   }

  get nomenclaturaCatastralField(){
    return this.socioForm.controls.nomenclaturaCatastral
  }

  get isInvalidNomenclaturaCatastralField(){
    return this.nomenclaturaCatastralField.touched && this.nomenclaturaCatastralField.invalid
  }

  get manzanaCatastralField(){
    return this.socioForm.controls.manzanaCatastral
  }

  get isInvalidManzanaCatastralField(){
    return this.manzanaCatastralField.touched && this.manzanaCatastralField.invalid
  }

  get loteCatastralField(){
    return this.socioForm.controls.loteCatastral
  }

  get isInvalidLoteCatastralField(){
    return this.loteCatastralField.touched && this.loteCatastralField.invalid
  }

  get isValidForm(){
    return this.socioForm.valid
  }

  showInvalidControl(){
    ValidacionFormularioSocio.setErrorsInForm(this.socioForm);
  }

  get formValue(){
    return this.socioForm.getRawValue()
  }

}
