<div class="card">
    <div class="card-header card-header-info">
        <i class="fas fa-handshake"></i> Convenios
    </div>
    <div class="card-body">
        <div class="row" *ngIf="!habilitarNuevoConvenio">
            <div class="col-md-7" >
                    <app-lista-convenio-pago (nuevoConvenioEvent)="nuevoConvenio()" (editarConvenioEvent)="editarConvenio($event)" (eliminarConvenioEvent)="onEliminarConvenio($event)"></app-lista-convenio-pago>
            </div>
            <div class="col-md-5" >
              <app-editar-convenio *ngIf="estaHabilitadaEdicion" (edicionConvenioReturn)="finzalizarEdicion()" [convenioAEditar]="convenioEditar" [observacionEdicion]="observacionEdicionEliminacion"></app-editar-convenio>
            </div>
          </div>
          <div class="row" *ngIf="habilitarNuevoConvenio">
            <div class="col-md-12" >
              <app-nuevo-convenio (cancelarEvent)="cancelarNuevoConvenio()" ></app-nuevo-convenio>
          </div>
          </div>

    </div>
</div>

<app-operacion-si-no-observacion [id]="'operacion_si_no_observacion_convenio'" [titulo]="tituloOperacionSiNo"
[contenidoMensaje]="mensajeOperacionSiNo"
[observacionPorDefecto]="'Además, tenga en cuenta que ¡¡¡ LOS CAMBIOS NO SE PUEDEN DESHACER !!! Está seguro de querer continuar ?'"
(retornoOperacion)="retornoOperacionSiNo($event)"></app-operacion-si-no-observacion>

<app-autorizacion (retornoOperacion)="retornoAutorizacion($event)"></app-autorizacion>

