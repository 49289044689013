
<nav class="navbar navbar-expand-lg navbar-dark bg-dark">
  <div class="row">
    <div class="col-2">
        <a class="navbar-brand mr-1" [routerLink]="[]" style="color: #049ed5"><img src="{{srcimg.srcImg}}assets/images/gota.png" width="30px" alt="">CAPP</a>
    </div>

  <div class="col-10" style="width: 600px;"><h3 style="color: white; margin-left: 30px;">Sistema de Gestión y Facturación <small>v{{versionNumero}}</small></h3></div>
</div>

</nav>


<div class="row justify-content-md-center">

<div class="col-md-4" style="margin-top: 5%;margin-bottom: 10%;">
  <h4>Ingreso</h4>
  <br>
  <form (ngSubmit)="onSubmit()" #loginForm="ngForm" style="border: solid 1px rgba(7, 136, 155, 0.31); padding: 15px; border-radius: 8px; background-color: rgba(255,255,255,0.4);" novalidate>
    <div class="form-group">
      <label for="iduser">Usuario</label>
      <input type="text" class="form-control" id="iduser"
             required
             [(ngModel)]="usuario" name="user"
             autocomplete="off"
             #user="ngModel" autofocus >
             
      <div [hidden]="user.valid || user.pristine"
           class="alert alert-danger">
        Ingresar Usuario
      </div>
    </div>

    <div class="form-group">
      <label for="idpassword">Contraseña</label>
      <input type="password" class="form-control" id="idpassword" required
             [(ngModel)]="password"
             autocomplete="off" name="pass" #pass="ngModel">
      <div [hidden]="pass.valid || pass.pristine"
           class="alert alert-danger">
        Ingresar Contraseña
      </div>
    </div>
    <button type="submit" class="btn btn-success" [disabled]="!loginForm.form.valid">
        <span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true" *ngIf="loading$ | async"></span>
        <span>Ingresar</span>
    </button>

  </form>
  <br>

  <br>
   <div class="alert alert-danger" role="alert" *ngIf="errorLogin">
   <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
   <span class="sr-only">Error:</span>
   Usuario o contraseña incorrectos!
  </div>

  <!-- <div *ngIf="sesionExpirada" class="alert alert-danger" role="alert">
    Su sesión ha expirado. Por favor vuelva a ingresar.
  </div> -->

 </div>

</div>


