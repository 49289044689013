import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import  moment from 'moment'
import { Store } from '@ngxs/store';
import { GetBancos, GetCajas } from 'src/app/store/general/actions';
import { Observable, Subscription } from 'rxjs';
import { ItemsLista, ItemsListaCaja } from 'src/app/store/general/models';
declare var $:any

@Component({
  selector: 'app-informacion-caja',
  templateUrl: './informacion-caja.component.html',
  styleUrls: ['./informacion-caja.component.css']
})
export class InformacionCajaComponent implements OnInit,OnDestroy {

  @Input() tabindex:string="-1"

  caja=1
  fechaCaja = moment().format("YYYY-MM-DD")
  fechaCobranza= moment().format("YYYY-MM-DD")
  banco = null
  numeroTransferencia = null

  opcionesCajaForm: UntypedFormGroup;

  bancos$:Observable<ItemsLista[]>
  cajas$:Observable<ItemsListaCaja[]>

  facturas:Subscription

  constructor(private formBuilder: UntypedFormBuilder, private store:Store) { }

  ngOnInit(): void {
    this.store.dispatch(new GetBancos())
    this.store.dispatch(new GetCajas())
    this.bancos$ = this.store.select(state => state.general.bancos)
    this.cajas$ = this.store.select(state => state.general.cajas)

    this.facturas= this.store.select(state => state.factura.facturas_impagas).subscribe(
      ()=> {
        this.resetValues()
      }
    )

    this.initOpcionesCajaForm();
  }

  resetValues(){
    this.caja=1
    this.fechaCobranza= moment().format("YYYY-MM-DD")
    this.banco = null
    this.numeroTransferencia = null
  }

  initOpcionesCajaForm(){
    this.opcionesCajaForm = this.formBuilder.group({
      caja:[],
      fechaCaja: [],
      fechaCobranzaCaja: [],
      bancoTransferencia: [] ,
      numeroTransferenciaCaja: [] ,
    });
}

onSelectionCajaChange(event){
  this.controlCaja(event.target.value)

}

controlCaja(caja){
  if (caja==18){
    this.opcionesCajaForm.get('bancoTransferencia').enable()
    this.opcionesCajaForm.get('numeroTransferenciaCaja').enable()
  }else{
    this.opcionesCajaForm.get('bancoTransferencia').setValue('')
    this.opcionesCajaForm.get('numeroTransferenciaCaja').setValue('')
    this.opcionesCajaForm.get('bancoTransferencia').disable()
    this.opcionesCajaForm.get('numeroTransferenciaCaja').disable()
  }
}


modificarOpcionesCaja(){
this.caja = this.opcionesCajaForm.get('caja').value
this.fechaCobranza = this.opcionesCajaForm.get('fechaCobranzaCaja').value
this.banco = this.opcionesCajaForm.get('bancoTransferencia').value
this.numeroTransferencia = this.opcionesCajaForm.get('numeroTransferenciaCaja').value
$('#cambioCajaModal').modal('hide')
}

openOpcionesCaja(){
  this.opcionesCajaForm.get('caja').setValue(this.caja)
  this.opcionesCajaForm.get('fechaCaja').setValue(this.fechaCaja)
  this.opcionesCajaForm.get('fechaCobranzaCaja').setValue(this.fechaCobranza)
  this.opcionesCajaForm.get('bancoTransferencia').setValue(this.banco)
  this.opcionesCajaForm.get('numeroTransferenciaCaja').setValue(this.numeroTransferencia)
  this.controlCaja(this.caja)
  $('#cambioCajaModal').modal('show')
}

ngOnDestroy() {
  this.facturas.unsubscribe()
}

getOpcionesPago(){
  return { 'caja': this.caja,
    'fechaCaja':this.fechaCaja,
    'fechaCobranza':this.fechaCobranza,
    'banco':this.banco,
    'numeroTransferencia':this.numeroTransferencia}
}

}
