import { Injectable } from '@angular/core';
import { User } from './user';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  
  

  constructor(private http: HttpClient) { }

    
  
  login(user: User):Observable<any>{
    if (user.username !== '' && user.password !== '' ) { 
      return this.http.post(`${environment.urlapiNode}/auth/login`,{'username':user.username,'password':user.password})
    }
  } 
 

 
}
