<div class="row">
<div class="col-md-12 text-list">
  <p><b>Periodos Semestre:</b> {{reciboSemetral.periodosSemestre}}</p>
  <p><b>Fecha de Emisión:</b> {{reciboSemetral.fechaEmision | date:'d/M/yyyy'}}</p>
  <p><b>Fecha de Vencimiento:</b> {{reciboSemetral.fechaVencimiento | date:'d/M/yyyy' }}</p>
  <p><b>Porcentaje de descuento:</b> {{reciboSemetral.porcentajeDescuento}}%</p>
</div>


</div>


  <form [formGroup]="conceptosForm" novalidate (ngSubmit)="onGenerarRecibosSemestrales($event)">

    <div class="row">
    <ng-container formArrayName="conceptos">
  <ng-container *ngFor="let conceptoForm of conceptosField.controls; let i=index">

    <div class="col-6" [formGroup]="conceptoForm">
      <div class="card border-info mb-3">

        <div class="card-body">

          <h6 class="card-title">{{conceptoForm.get('tarifa').value}}</h6>
          <div class="row">
            <div class="col-9 text-list ">{{conceptoForm.get('conceptoDetalle1').value}}:</div>
            <div class="col-3 text-list">{{conceptoForm.get('conceptoValor1').value |formatCurrency}}</div>
          </div>
          <div class="row">
            <div class="col-9 text-list">{{conceptoForm.get('conceptoDetalle2').value}}:</div>
            <div class="col-3 text-list">
             <input type="text" class="form-control form-control-sm" formControlName="conceptoValor2"
             [ngClass]="{ 'is-invalid': (conceptoForm.get('conceptoValor2').invalid &&  conceptoForm.get('conceptoValor2').touched)}"
             (change)="onChangeTarifaDescuentoAplicarDescuento(i)"
              appNumberEnteroOnly>
              <div *ngIf="conceptoForm.get('conceptoValor2').errors"  class="invalid-feedback">
                Valor requerido.
              </div>
            </div>
          </div>
          <div class="row mt-1">
            <div class="col-9 text-list">Ajuste sobre el total para redondeo <span class="text-danger">(-) </span> :</div>
            <div class="col-3 text-list">
             <input type="text" class="form-control form-control-sm" formControlName="conceptoValor4"
             [ngClass]="{ 'is-invalid': (conceptoForm.get('conceptoValor4').invalid &&  conceptoForm.get('conceptoValor4').touched)}"
             (change)="onChangeTarifaDescuentoAplicarDescuento(i)"
              appNumberEnteroOnly>
              <div *ngIf="conceptoForm.get('conceptoValor4').errors"  class="invalid-feedback">
                Valor requerido.
              </div>
            </div>
          </div>
          <div class="row mt-1">
            <div class="col-9 text-list">{{conceptoForm.get('conceptoDetalle3').value}}:</div>
            <div class="col-3 text-list">{{conceptoForm.get('conceptoValor3').value |formatCurrency}}
              <div *ngIf="conceptoForm.get('conceptoValor3').errors"  class="invalid-feedback">
                Valor no válido.
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>




  </ng-container>
</ng-container>
</div>
<div class="row">
  <div class="col-md-12">
    <div class="float-right mr-3 mt-3 mb-2">
      <button (click)="onCancelar()" class="btn  btn-sm btn-outline-danger" [disabled]="isGenerandoReciboSemestral">Cancelar</button>&nbsp;&nbsp;
      <button class="btn btn-success btn-sm" type="submit" [disabled]="conceptosForm.invalid || isGenerandoReciboSemestral">
        <span *ngIf="isGenerandoReciboSemestral" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        Generar Recibos Semestrales</button>

  </div>
  </div>


            </div>

</form>





