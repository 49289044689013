import { State, StateContext, Action } from "@ngxs/store";
import { FacturaService } from 'src/app/factura/factura.service';
import { GetFacturasImpagasActualizadas, PagarFacturas, SetSearchFacturasImpagasActualizadas,  GetFacturasPagas, GetAdeudaFacturas, ResetFacturas } from './actions';
import { Injectable } from '@angular/core';
import { tap, mergeMap } from 'rxjs/operators';
import { of, from } from "rxjs";

export class FacturaStateModel {
     facturas_impagas:any[];
     facturas_pagas:any[];
     search_facturas_impagas:boolean;
     tiene_facturas_impagas:boolean;
     numeroComprobantePagoGenerado:string;
     numeroComprobanteSolicitudAnulacionGenerado:string;
     inicializado_facturas_impagas:boolean;
     inicializado_facturas_pagas:boolean

}


@State<FacturaStateModel>({
    name: 'factura',
    defaults: {
        facturas_impagas:[],
        facturas_pagas:[],
        tiene_facturas_impagas:false,
        search_facturas_impagas:false,
        numeroComprobantePagoGenerado:null,
        numeroComprobanteSolicitudAnulacionGenerado:null,
        inicializado_facturas_impagas:false,
        inicializado_facturas_pagas:false
    }
})


@Injectable()
export class FacturaState {

    constructor(private fs: FacturaService) { }

    @Action(GetAdeudaFacturas)
    getAdeudaFacturas({ patchState }: StateContext<FacturaStateModel>, action:GetAdeudaFacturas) {
           this.fs.getAdeudaFacturas(action.cliente).subscribe(
                data=> {
                            patchState({
                                tiene_facturas_impagas: data.adeuda_factura,
                            });
                }
            )
    }

    @Action(GetFacturasImpagasActualizadas)
    getFacturasImpagasActualizadas(ctx: StateContext<FacturaStateModel>, action:GetFacturasImpagasActualizadas) {

      const state = ctx.getState()
      let seguir = false

      if(!action.update && !state.inicializado_facturas_impagas){seguir=true}
      if(action.update && state.inicializado_facturas_impagas){seguir=true}

      if(seguir){
        this.fs.getFacturasSociosImpagasActualizadas(action.socio).pipe(

            tap(
                data => {
                    ctx.patchState({
                        facturas_impagas: data,                         
                        search_facturas_impagas: false,
                        inicializado_facturas_impagas:true
                    });
                }
                ),
            mergeMap(() => ctx.dispatch(new GetAdeudaFacturas(action.socio))),
        ).subscribe( )
      }



    }

    @Action(GetFacturasPagas)
    getFacturasPagas( ctx: StateContext<FacturaStateModel>, action:GetFacturasPagas) {

      const state = ctx.getState()
      let seguir = false

      if(!action.update && !state.inicializado_facturas_pagas){seguir=true}
      if(action.update && state.inicializado_facturas_pagas){seguir=true}

      if(seguir){
        this.fs.getFacturasPagas(action.socio).subscribe(
          data=> {
                      ctx.patchState({
                          facturas_pagas: data,
                          inicializado_facturas_pagas:true
                      });
          }
      )
      }

    }


    @Action(SetSearchFacturasImpagasActualizadas)
    setSearchFacturasImpagasActualizadas({ patchState }: StateContext<FacturaStateModel>) {
        patchState({
            search_facturas_impagas:true
        });
    }



    @Action(PagarFacturas)
    pagarFacturas(ctx: StateContext<FacturaStateModel>, action:PagarFacturas) {

       return this.fs.facturasPagar(action.facturas,action.cliente,action.totalAPagarEnFacturas,action.totalACobrar
        ,action.descuentoTotalEnPorcentaje,action.caja,action.fechaCobranza,action.bancoCobranza,action.numeroTransferenciaCobranza).pipe(
            tap(
                data => {

                    const state = ctx.getState();
                    ctx.setState({
                        ...state,
                        numeroComprobantePagoGenerado:data.numeroComprobanteCaja
                      });

                }
                ),
                mergeMap(() => ctx.dispatch(new GetFacturasImpagasActualizadas(action.cliente,true))),
                mergeMap(() => ctx.dispatch(new GetFacturasPagas(action.cliente,true)))
              );
    }


    @Action(ResetFacturas)
    resetFacturas({ patchState }: StateContext<ResetFacturas>) {

        patchState({
          facturas_impagas:[],
          facturas_pagas:[],
          inicializado_facturas_impagas:false,
          inicializado_facturas_pagas:false
        })

        return from([]);
    }


}
