import { Component, OnInit, ViewChild, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Convenio } from 'src/app/store/convenio/convenio.model';
import { Subscription } from 'rxjs';
import { Store } from '@ngxs/store';
import { TipoPermisos } from 'src/app/store/roles-permisos/model';
import { ModoChequeoRol, PermisosModoRequerido } from 'src/app/shared/role.directive';

@Component({
  selector: 'app-lista-convenio-pago',
  templateUrl: './lista-convenio-pago.component.html',
  styleUrls: ['./lista-convenio-pago.component.css']
})
export class ListaConvenioPagoComponent implements OnInit,OnDestroy {

  @ViewChild('agGridConvenios', { static: true }) agGridConvenios: any;
  @Output() nuevoConvenioEvent = new EventEmitter();
  @Output() editarConvenioEvent = new EventEmitter<Convenio>();
  @Output() eliminarConvenioEvent = new EventEmitter<Convenio>();

  rowData:Convenio[]

  columnDefs=[
    {checkboxSelection: true, width:15},
    {field:'id_convenio', hide:true},
    {headerName:"Fecha",field:'fecha', width:110,resizable:true},
    {headerName:"Importe",field:'importe', width:95,resizable:true,valueFormatter:this.formatNumber},
    {headerName:"Cuotas",field:'cuotas', width:75},
    {headerName:"Valor Cuota",field:'valorcuota', width:95,resizable:true,valueFormatter:this.formatNumber},
    {headerName:"Facturadas",field:'facturadas', width:95},
    {headerName:"Terminado",field:'cancelado', width:87,
    cellStyle: function(params) {
      if (params.value=='SI') {
          //mark police cells as red
          return {color: 'green'};
      } else {
        return {color: 'red'};
      }
  }},
    {headerName:"NV",field:'novolcado', width:70}
  ]

  

conveniosCliente:Subscription

permisoModoRequeridoABMConvenios:PermisosModoRequerido={permisosRequerido:[TipoPermisos.ABMConvenios],
  modoRequerido:ModoChequeoRol.RequiereTodosLosPermisos}


  constructor(private store:Store) { }

  ngOnInit(): void {

   
    this.conveniosCliente = this.store.select(state=> state.convenio.convenios).subscribe(
      data => this.rowData = data
    )
  }

  formatNumber(params){

    return params.value?.toLocaleString("es-AR") || ""
  }


  nuevoConvenio(){
    this.nuevoConvenioEvent.emit()
  }

  editarConvenio(){
    if(this.agGridConvenios.api.getSelectedRows().length>0){
      this.editarConvenioEvent.emit(this.agGridConvenios.api.getSelectedRows()[0])
    }
  }

  onEliminarConvenio(){
    if(this.agGridConvenios.api.getSelectedRows().length>0){
      this.eliminarConvenioEvent.emit(this.agGridConvenios.api.getSelectedRows()[0])
    }
  }

  ngOnDestroy() {
    this.conveniosCliente.unsubscribe()
  }


}
