import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler, APP_INITIALIZER} from '@angular/core';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutModule } from './layout/layout.module';
import { LoginModule } from './login/login.module';
import { NgxsModule } from '@ngxs/store';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { CobranzaState } from './store/cobranza/states';
import { GeneralState } from './store/general/states';
import { SocioState } from './store/socio/states';
import { FacturaState } from './store/factura/states';
import { ReciboState } from './store/recibo/states';
import { AuthState } from './store/auth/state';
import { SistemaConfiguracionState } from './store/sistema-configuracion/states';
import { CreditoState } from './store/credito/states';
import { AfipState } from './store/afip/states';
import { AccionesState } from './store/acciones/states';
import { ConvenioState } from './store/convenio/states';
import { PeriodoState } from './store/periodo/states';
import { CuentaCorrienteState } from './store/cuentacorriente/states';
import { ObservacionState } from './store/observacion/states';
import { BarrioState } from './store/barrio/states';
import { environment } from 'src/environments/environment';
import { RolesPermisosState } from './store/roles-permisos/states';
import { UsuarioState } from './store/usuario/states';
import { ConvenioPagoState } from './store/covenio-pago/states';
import { Router } from "@angular/router";

import * as Sentry from "@sentry/angular-ivy";
import { LoadingIndicatorComponent } from './loading/loading-indicator/loading-indicator.component';
import { LoadingInterceptor } from './loading/loading-interceptor.service';





@NgModule({ declarations: [
        AppComponent
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        AppRoutingModule,
        LayoutModule,
        LoginModule,
        NgxsModule.forRoot([AuthState, CobranzaState, ConvenioState, GeneralState, SocioState, FacturaState,
            ReciboState, SistemaConfiguracionState, CreditoState, AfipState, AccionesState,
            PeriodoState, CuentaCorrienteState, ObservacionState,
            BarrioState, RolesPermisosState, UsuarioState,
            ConvenioPagoState], { developmentMode: !environment.production }),
    
            NgxsStoragePluginModule.forRoot({ keys: ['auth.token'] }),
            LoadingIndicatorComponent], 
            
            providers: [
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false,
            }),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => { },
            deps: [Sentry.TraceService],
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LoadingInterceptor,
            multi: true,
          },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule { }
