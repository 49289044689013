import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Store } from '@ngxs/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AgButtonRenderComponent } from 'src/app/shared/ag-button-render/ag-button-render.component';
import { Time } from 'src/app/utils/time';
import { GetListadoReciboSemestral } from 'src/app/store/recibo/actions';
import { ReciboSemestralListado } from 'src/app/store/recibo/models';
import { SocioState } from 'src/app/store/socio/states';

import { ReciboService } from '../../recibo-semestral.service';

const formatDate = (params)=>{
  if (params.value!==''){
    return  Time.formatDate(params.value,"DD/MM/YYYY")
  }else{
    return null
  }
}


@Component({
  selector: 'app-recibo-semestral-listado',
  templateUrl: './recibo-semestral-listado.component.html',
  styleUrls: ['./recibo-semestral-listado.component.css']
})
export class ReciboSemestralListadoComponent  implements OnInit,OnDestroy {

  @Output() imprimirComprobantePagoEvent = new EventEmitter()
  @Output() imprimirReciboEvent = new EventEmitter()

  reciboSemestralListado:ReciboSemestralListado[];

  frameworkComponents: any;

  destroy$: Subject<boolean> = new Subject<boolean>();

  cliente:number


  columnDefs:any = [
    {headerName: 'Recibo', field: 'numero_recibo', width:100 },
    {headerName: 'Semestre', field: 'semestre', width:150 },
    {headerName: 'Total', field:'valor', width:100 },
    {headerName: 'Estado', field: 'estado_pago', width:100 },
    {headerName: 'Cobrado', field: 'importe_cobrado', width:100 },
    {headerName: 'Descuento', field: 'descuento', width:100 },
    {headerName: 'Fecha pago', field:'fecha_pago', width:130,
    valueFormatter: formatDate },
    {headerName: 'Fecha emisión', field:'fecha_emision', width:130 ,
    valueFormatter: formatDate
     },
    {headerName: 'Fecha vencimiento', field:'fecha_vencimiento', width:150,
    valueFormatter: formatDate
   },
    {
      headerName: 'Comprobante',
      cellRenderer: 'buttonRenderer',
      editable:false,
      width: 150,
      cellRendererParams: {
        onClick: this.imprimirComprobante.bind(this),
        label: 'imprimir comprobante pago',
        classBtn: 'success'
      }
    },
    {
      headerName: 'Recibo',
      cellRenderer: 'buttonRenderer',
      editable:false,
      width: 150,
      cellRendererParams: {
        onClick: this.imprimirReciboDesdeLista.bind(this),
        label: 'imprimir recibo',
        classBtn: 'info'
      }
    }
  ];

  constructor(private store:Store, private rs:ReciboService) {
    this.frameworkComponents = {
      buttonRenderer: AgButtonRenderComponent,
    }
  }



  ngOnInit() {

    this.cliente = this.store.selectSnapshot(SocioState.cliente)

    this.store.dispatch( new GetListadoReciboSemestral(this.cliente,false))

    this.store.select(state => state.recibo.recibo_semestral_listado).pipe(takeUntil(this.destroy$)).subscribe(
      data=>{
        this.reciboSemestralListado = data

      }
    );

  }

  imprimirComprobante(e){
    const datosRecibo = e.rowData
    this.imprimirComprobantePagoEvent.emit({numero_recibo:datosRecibo.numero_recibo,estado_pago: datosRecibo.estado_pago})
  }

  imprimirReciboDesdeLista(e){
    const  datosRecibo = e.rowData
    this.imprimirReciboEvent.emit({fecha_emision:datosRecibo.fecha_emision,numero_recibo:datosRecibo.numero_recibo })
  }


  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }


  

}
