import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
declare var $:any;

@Component({
  selector: 'app-operacion-informacion',
  templateUrl: './operacion-informacion.component.html',
  styleUrls: ['./operacion-informacion.component.css']
})
export class OperacionInformacionComponent implements OnInit {

  @Input() contenidoMensaje:string;
  @Input() titulo:string = 'Gestión CAPP';
  @Input() id:string="operacion_informacion"; 
  @Output() aceptarMensaje = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  open(){
    $(`#${this.id}`).modal('show')
  }

  aceptar(){
    $(`#${this.id}`).modal('hide')
    this.aceptarMensaje.emit(true);
  }

}
