import { Component, inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import { NotificacionesStateService } from 'src/app/store/notificaciones/notificaciones-state.service';

@Component({
  selector: 'app-notificacion-badge',
  standalone: true,
  imports: [RouterLink],
  templateUrl: './notificacion-badge.component.html',
  styleUrl: './notificacion-badge.component.css'
})
export class NotificacionBadgeComponent {

  private _notificaciones=inject(NotificacionesStateService);

  totalNotificaciones = this._notificaciones.getTotalNotificaciones();
  isLoading = this._notificaciones.getIsLoading();

}
