<div class="parent">

<app-top-bar></app-top-bar>
<div class="aside-y-contenido">
    <app-left-bar></app-left-bar>

    <div class="content-wrapper">
        <app-content></app-content>
    </div>

</div>

</div>



