import { Component,  ViewChild } from '@angular/core';
import { ReciboSemestralImpresionesComponent } from './recibo-semestral-impresiones/recibo-semestral-impresiones.component';


@Component({
  selector: 'app-recibo-semestral',
  templateUrl: './recibo-semestral.component.html',
  styleUrls: ['./recibo-semestral.component.css']
})
export class ReciboSemestralComponent  {
  
  @ViewChild(ReciboSemestralImpresionesComponent, { static: true })
  reciboSemestralImpresionesComponent: ReciboSemestralImpresionesComponent;

  constructor() {
   
  }

  imprimirComprobantePago(comprobantePago){    
    this.reciboSemestralImpresionesComponent.imprimirComprobante(comprobantePago)
  }

  imprimirRecibo(reciboSemestral){
    this.reciboSemestralImpresionesComponent.imprimirReciboDesdeLista(reciboSemestral)
  }

  imprimirComprobantePagoCobranza(reciboSemestral){
    this.reciboSemestralImpresionesComponent.imprimirComprobantePago(reciboSemestral)
  }
 

}
