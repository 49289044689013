import { ReciboSimple } from './models'

export class GetInformacionReciboSemestreActivo{
  static readonly type = '[Recibo] GetInformacionReciboSemestreActivo'
  constructor(){}
}

export class GetListadoReciboSemestral{
    static readonly type = '[Recibo] GetListadoReciboSemestral'
    constructor(public cliente:number, public update:boolean){}
}

export class GetReciboSemestralParaPago{
    static readonly type = '[Recibo] GetReciboSemestralParaPago'
    constructor(public cliente:number, public update:boolean){}
}


export class GetDetalleReciboSemestralPagado{
    static readonly type = '[Recibo] GetDetalleReciboSemestralPagado'
    constructor(public cliente:number){}
}

export class GetInformacionFacturasPagasReciboSemestreActivo{
  static readonly type = '[Recibo] GetInformacionFacturasPagasReciboSemestreActivo'
  constructor(public cliente:number,public update:boolean){}
}

export class AnularCobroRecibo{
    static readonly type = '[Recibo] AnularCobroRecibo'
    constructor(public recibo:number,public comprobanteCaja:number,public cliente:number,public usuario:number){}
}


export class GenerarReciboSemestralCliente{
  static readonly type = '[Recibo] GenerarReciboSemestralCliente'
  constructor(public recibo:number,public cliente:number){}
}

export class ResetReciboSemestral{
  static readonly type = '[Recibo] ResetReciboSemestral'
  constructor(){}
}







