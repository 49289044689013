<div class="modal" tabindex="-1" role="dialog" [attr.id]="id" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{titulo}}</h5>
      </div>

      <form [formGroup]="siNoObservacionForm" novalidate>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-2"><i class="fa fa-question-circle fa-2x" style="color:cadetblue" aria-hidden="true"></i>
            </div>
            <div class="col-md-10">
              <p style="text-align: justify;" [innerHTML]="contenidoMensaje"></p>
              <div style="overflow-y: auto; max-height: 150px;">
                <p><small>{{observacionPorDefecto}}</small></p>
              </div>

            </div>
          </div>
          <br>
          <div class="form-group">
            <label>Se debe indicar el motivo</label>
            <textarea class="form-control form-control-sm" rows="3" formControlName="observacion" style="resize: none;"
              [ngClass]="{ 'is-invalid': siNoObservacionForm.controls['observacion'].errors }"></textarea>
            <div *ngIf="!siNoObservacionForm.valid" class="invalid-feedback">Campo obligatorio</div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-sm btn-outline-danger" (click)="cancelar()">Cancelar</button>
          <button [disabled]="!siNoObservacionForm.valid" type="submit" class="btn btn-sm btn-success"
            (click)="aceptar()">Aceptar</button>
        </div>

      </form>


    </div>
  </div>
</div>
