import { Component, OnInit, Input, AfterViewInit, ViewChild, ElementRef, OnChanges, EventEmitter, Output } from '@angular/core';
import { ListViewElement } from './list-view';
import { fromEvent } from 'rxjs';
import { map, filter, debounceTime, distinctUntilChanged, mergeMap } from 'rxjs/operators';


@Component({
  selector: 'app-list-view',
  templateUrl: './list-view.component.html',
  styleUrls: ['./list-view.component.css']
})



export class ListViewComponent implements OnInit,AfterViewInit,OnChanges {

  @ViewChild('agGridListView', { static: true }) agGridListView: any;

  @ViewChild('busquedaSearchInput', { static: true }) busquedaSearchInput: ElementRef;

  @Input() colDefs:ListViewElement[]
  @Input() data:any[]

  @Input() style : any = {width:"80%"}
  @Input() height : string = "350px"

  @Input() displayButtonExport : boolean = false

  @Output() seleccionEvent = new EventEmitter();


  dataGrid:any[]=[]
  loading_busqueda:boolean=false


  constructor() { }

  ngOnInit(): void {


    fromEvent(this.busquedaSearchInput.nativeElement, 'keyup').pipe(
      // get value
      map((event: any) => {
       return event.target.value;
     })
     // Time in milliseconds between key events
     , debounceTime(200)
      // If previous query is diffent from current
      , distinctUntilChanged()
      // subscription for response
   ).subscribe(
     data => this.filterData(data)
   )

  }

  ngOnChanges(){
    this.dataGrid = this.data
  }

  iniciarColumnas():void{
    this.agGridListView.api.setColumnDefs(this.colDefs);
  }


  filterData(data){
    if(data.length === 0){
      this.dataGrid = this.data
      return
    }

    this.dataGrid = this.data.filter(elem=>
      {let valorRetorno:boolean = false;
         this.colDefs.forEach(def=>{
          if(!!elem[def.field]){
            valorRetorno = valorRetorno || (elem[def.field].toString().toLowerCase()).includes(data.toLowerCase())
          }
            
         })
         return valorRetorno;
        }
      )
  }


  onSelectionChanged(){
    this.seleccionEvent.emit(this.agGridListView.api.getSelectedRows()[0])
  }


  onBtExport(){
    this.agGridListView.api.exportDataAsCsv({ fileName: 'listado',columnSeparator:';' })
  }


  ngAfterViewInit(){
    this.iniciarColumnas()
  }

}
