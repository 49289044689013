<div class="card" style="margin-bottom: 15px">
    <div class="card-header filtro" style="background-color:#17a2b8; color: white">
     Control Cobranzas
    </div>
    <div class="card-body">

        <div class="row">
            <div class="col-4 mt-2"><span class="badge badge-pill badge-info">1</span> &nbsp; <span> Período de Control</span></div>
          </div>
        <div class="row">
            <div class="col-md-12">

                  <hr>
                  <form (ngSubmit)="aplicarFiltroFecha()" #fechaForm="ngForm"><!--Form fecha-->
                    <div class="form-row align-items-center">
                      <div class="col-2 text-right">
                        <label for="fecha_desde_control_cobranza">Fecha desde</label>
                      </div>
                      <div class="col-2">
                        <input id="fecha_desde_control_cobranza" type="date" [(ngModel)]="filtroFecha.fecha_desde" name="fecha_desde" class="form-control form-control-sm" placeholder="Fecha desde" required>
                      </div>
                      <div class="col-1 text-right">
                        <label for="fecha_hasta_control_cobranza">Fecha hasta</label>
                      </div>
                      <div class="col-2">
                        <input id="fecha_hasta_control_cobranza" type="date" [(ngModel)]="filtroFecha.fecha_hasta" name="fecha_hasta" class="form-control form-control-sm" placeholder="Fecha hasta" required>
                      </div>
                      <div class="col-1">
                        <button type="submit" class="btn btn-sm btn-info" [disabled]="!fechaForm.form.valid">Buscar</button>
                      </div>
                      <div class="col-1"> <div  *ngIf="loadingBusquedaComprobante$ | async "><i class='fa fa-refresh fa-spin fa-2x'></i></div> </div>
                    </div>
                  </form>

            </div>
          </div>

          <br>
          <div class="row">
            <div class="col-md-12 ">
              <div class="float-right mb-2">
                <button type="button" class="btn btn-outline-danger btn-sm" (click)="clearFilter()" title="Quitar filtros"><i class="fas fa-eraser"></i></button>
                &nbsp;
                <button type="button" class="btn btn-outline-success btn-sm"
                  (click)="onBtExportCobranzas()" title="Exportar a Excel"><i class="fas fa-file-excel"></i></button></div>
            </div>
              <div class="col-md-6">
                <span><b>Total cobranzas: {{filas}}</b></span>
              </div>
              <div class="col-md-6">
                <span><b>Total Importe: {{importeTotal| formatCurrency}}</b></span>
              </div>
            </div>
            <br>
            <div class="row">
                <div class="col-md-12">
                      <ag-grid-angular
                      style="height: 350px;"
                      class="ag-theme-alpine"
                      [rowData]="rowData"
                      [columnDefs]="columnDefs"
                      [colResizeDefault]="'fit'"
                      [rowSelection]= "'single'"
                      [rowHeight]="40"
                      (gridReady)="onGridReadyCobranzas($event)"
                      (filterChanged)="onFilterChanged()"
                      ></ag-grid-angular>
                </div>
              </div>
    </div>


</div>
