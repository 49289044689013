export class GetPeriodos{
    static readonly type = '[General] GetPeriodos'
    constructor(){}
}


export class GetTiposDni{
    static readonly type = '[General] GetTiposDni'
    constructor(){}
}
export class GetTiposIva{
    static readonly type = '[General] GetTiposIva'
    constructor(){}
}
export class GetCategorias{
    static readonly type = '[General] GetCategorias'
    constructor(){}
}
export class GetEstados{
    static readonly type = '[General] GetEstados'
    constructor(public update:boolean=false){}
}

export class SaveEstado{
  static readonly type = '[General] SaveEstado'
  constructor(public estado:string, public facturar:boolean){}
}

export class EditEstado{
  static readonly type = '[General] EditEstado'
  constructor( public idEstado:number, public estado:string, public facturar:boolean){}
}

export class DeleteEstado{
  static readonly type = '[General] DeleteEstado'
  constructor(public idEstado:number, public estado:string, public facturar:boolean){}
}



export class GetFinSupension{
    static readonly type = '[General] GetFinSupension'
    constructor(){}
}
export class GetTarifas{
    static readonly type = '[General] GetTarifas'
    constructor(){}
}
export class GetFinTarifas{
    static readonly type = '[General] GetFinTarifas'
    constructor(){}
}
export class GetZonas{
    static readonly type = '[General] GetZonas'
    constructor(public update:boolean=false){}
}

export class SaveZonas{
  static readonly type = '[General] SaveZona'
  constructor(public zona:string, public imprimir:boolean){}
}

export class EditZonas{
  static readonly type = '[General] EditZonas'
  constructor( public idZona:number, public zona:string, public imprimir:boolean){}
}

export class DeleteZonas{
  static readonly type = '[General] DeleteZonas'
  constructor(public idZona:number, public zona:string, public imprimir:boolean){}
}

export class GetSituacionesEspecial{
    static readonly type = '[General] GetSituacionesEspecial'
    constructor(){}
}

export class GetCalles{
    static readonly type = '[General] GetCalles'
    constructor(){}
}
export class GetProvincias{
    static readonly type = '[General] GetProvincias'
    constructor(){}
}
export class GetTiposMedianeras{
    static readonly type = '[General] GetTiposMedianeras'
    constructor(){}
}
export class GetTiposConexion{
    static readonly type = '[General] GetTiposConexion'
    constructor(){}
}
export class GetTiposMaterial{
    static readonly type = '[General] GetTiposMaterial'
    constructor(){}
}
export class GetPrestadoresDebitoAutomatico{
    static readonly type = '[General] GetPrestadoresDebitoAutomatico'
    constructor(){}
}


export class GetCondicionRegistro{
    static readonly type = '[General] GetCondicionRegistro'
    constructor(){}
}

export class GetBancos{
    static readonly type = '[General] GetBancos'
    constructor(){}
}

export class GetCajas{
    static readonly type = '[General] GetCajas'
    constructor(){}
}


export class SaveCalles{
    static readonly type = '[General] SaveCalles'
    constructor(public idCalle:number, public nombre:string){}
}


export class UpdateCalles{
    static readonly type = '[General] UpdateCalles'
    constructor(public idCalle:number, public nombre:string){}
}

export class DeleteCalles{
    static readonly type = '[General] DeleteCalles'
    constructor(public idCalle:number){}
}
