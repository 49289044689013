import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { AuthState } from 'src/app/store/auth/state';
import { Logout } from 'src/app/store/auth/actions';

declare var $: any;

@Component({
  selector: 'app-left-bar',
  templateUrl: './left-bar.component.html',
  styleUrls: ['./left-bar.component.css']
})
export class LeftBarComponent implements OnInit {

  usuario:string;

  constructor(private store: Store,private router: Router) { }

  ngOnInit() {
    const usuarioNombre = this.store.selectSnapshot(AuthState.usuarioNombre);
    this.usuario =usuarioNombre
  }

  /* ngAfterViewInit(){
    $('a.dropdown-toggle').on('click', function(e) {
      if (!$(this).next().hasClass('show')) {
        $(this).parents('.dropdown-menu').first().find('.show').removeClass("show");
      }
      var $subMenu = $(this).next(".dropdown-menu");
      $subMenu.toggleClass('show');
    
    
      $(this).parents('li.nav-item.dropdown.show').on('hidden.bs.dropdown', function(e) {
        $('.dropdown-submenu .show').removeClass("show");
      });
    
    
      return false;
    });
    
  } */

  logout(){
    this.store.dispatch(new Logout());    
    this.router.navigate(['/login']) 
  }

}
