import {  Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup,  Validators } from '@angular/forms';
import { OperacionesABM } from 'src/app/shared/enums/enums';
import { ItemsLista } from 'src/app/store/general/models';
import { SocioDetalle } from 'src/app/store/socio/models';
import { Time } from 'src/app/utils/time';
import { ValidacionFormularioSocio } from '../validacion-formulario-socio';

@Component({
  selector: 'app-varios-info',
  templateUrl: './varios-info.component.html',
  styleUrls: ['./varios-info.component.css']
})
export class VariosInfoComponent implements OnInit {
  @Input() modoOperacion: OperacionesABM;
  @Input() socio: SocioDetalle;
  @Input() tiposDni: ItemsLista[];

  socioForm: UntypedFormGroup
  constructor( private fb: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.socioForm = this.initSocioFormModel();
    this.setearForm();
  }

  initSocioFormModel(){

    return this.fb.group({
      profesion: [this.socio.profesion],
      nombreConyuge: [this.socio.conyuge_nombre],
      tipoDocumentoConyuge: [
        { value: this.socio.conyuge_tipodoc, disabled: true },
        Validators.required,
      ],
      numeroDocumentoConyuge: [
        { value: this.socio.conyuge_documento, disabled: true },
        Validators.required,
      ],
      numeroSocioEnCooperativa: [this.socio.id_coopluz],
      fechaDeAltaConvenio: [
        {
          value: Time.formatDate(this.socio.fechaConvCoopLuz,"YYYY-MM-DD"),
          disabled: true,
        },
        Validators.required,
      ],
    })
  }

  setearForm(){

    if (this.modoOperacion == OperacionesABM.editar) {
         // si esta cargado el nombre del conyuge se le debe pedir el dni
      if (
        this.socio.conyuge_nombre?.length > 0 &&
        !(this.socio.conyuge_nombre === "")
      ) {
        this.socioForm.get("tipoDocumentoConyuge").enable();
        this.socioForm.get("numeroDocumentoConyuge").enable();
      }

        // si tiene convenio habilito la fecha de alta
        if (
          this.socio.id_coopluz?.length > 0 &&
          !(this.socio.id_coopluz === "")
        ) {
          this.socioForm.get("fechaDeAltaConvenio").enable();
        }

    }



    }

  get tipoDocumentoConyugeField(){
    return this.socioForm.controls.tipoDocumentoConyuge
  }

  get isInvalidTipoDocumentoConyugeField(){
    return this.tipoDocumentoConyugeField.touched && this.tipoDocumentoConyugeField.invalid
  }

  get numeroDocumentoConyugeField(){
    return this.socioForm.controls.numeroDocumentoConyuge
  }

  get isInvalidNumeroDocumentoConyugeField(){
    return this.numeroDocumentoConyugeField.touched && this.numeroDocumentoConyugeField.invalid
  }

  get fechaDeAltaConvenioField(){
    return this.socioForm.controls.fechaDeAltaConvenio
  }

  get isInvalidFechaDeAltaConvenioField(){
    return this.fechaDeAltaConvenioField.touched && this.fechaDeAltaConvenioField.invalid
  }

  verificarNombreConyuge(event) {

    if (event.target.value.length > 0 && !(event.target.value === '')) {

      this.socioForm.get('tipoDocumentoConyuge').enable();
      this.socioForm.get('numeroDocumentoConyuge').enable();
    } else {
      this.socioForm.get('tipoDocumentoConyuge').disable();
      this.socioForm.get('numeroDocumentoConyuge').disable();
      this.socioForm.get('tipoDocumentoConyuge').setValue('');
      this.socioForm.get('numeroDocumentoConyuge').setValue('');
    }
  }

  verificarNumeroSocioConvenio(event) {
    if (event.target.value.length > 0 && !(event.target.value === '')) {
      this.socioForm.get('fechaDeAltaConvenio').enable();
    } else {
      this.socioForm.get('fechaDeAltaConvenio').disable();
      this.socioForm.get('fechaDeAltaConvenio').setValue('');
    }
  }

  get isValidForm(){
    return this.socioForm.valid
  }

  showInvalidControl(){
    ValidacionFormularioSocio.setErrorsInForm(this.socioForm);
  }

  get formValue(){
    return this.socioForm.getRawValue()
  }

}
