
export class GetConveniosCliente{
  static readonly type = '[Convenio] GetConveniosCliente'
    constructor(public cliente:number, public update:boolean){}
}

export class InitFacturasNuevoConvenio{
    static readonly type = '[Convenio] InitFacturasNuevoConvenio'
    constructor(){}
}
export class SetFacturasNuevoConvenio{
    static readonly type = '[Convenio] SetFacturasNuevoConvenio'
    constructor(public facturas:any[]){}
}

export class NuevoConvenioCliente{
  static readonly type = '[Convenio] NuevoConvenioCliente'
  constructor(
    public cliente:number,
    public fechaConvenio:string,
    public fechaCaja:string,
    public caja:number,
    public montoTotal:number,
    public cuotas:number,
    public montoValorCuotas:number,
    public cuotasAbonadas:number,
    public pseudoconvenio: boolean,
    public convenioJudicial: boolean,
    public tipoConvenio:number,
    public descuentoSobreIntereses:number,
    public montoCostoFinanciero:number,
    public montoGastosAdministrativos:number,
    public montoReconexion:number,
    public facturas:any[]
    ){}
}

export class UpdateConvenio{
  static readonly type = '[Convenio] UpdateConvenio'
    constructor(
      public cliente:number,
      public convenio:number,
      public cuotas:number,
      public facturadas:number,
      public observacion:string
      ){}
}


export class GetValorMinimoCuotaConvenio{
    static readonly type = '[Convenio] GetValorMinimoCuotaConvenio'
    constructor(public cliente:number){}
}

export class GetTotalAdeudadoPorConvenio{
    static readonly type = '[Convenio] GetTotalAdeudadoPorConvenio'
    constructor(public cliente:number){}
}

export class GetInformacionParaNuevoConvenio{
    static readonly type = '[Convenio] GetInformacionParaNuevoConvenio'
    constructor(public cliente:number){}
}

export class SetCanonCobrado{
    static readonly type = '[Convenio] SetCanonCobrado'
    constructor(public valorCobrado:boolean){}
}

export class SetCostoIntimacionCobrado{
    static readonly type = '[Convenio] SetCostoIntimacionCobrado'
    constructor(public valorCostoIntimacionCobrado:boolean){}
}


export class TieneDeudaConvenioCliente{
  static readonly type = '[Convenio] TieneDeudaConvenioCliente'
  constructor(public cliente:number){}
}

export class TieneConvenioNoVolcadoCliente{
  static readonly type = '[Convenio] TieneConvenioNoVolcadoCliente'
  constructor(public cliente:number){}
}

export class ClearConvenio{
    static readonly type = '[Convenio] ClearConvenio'
}

export class ResetConvenios{
  static readonly type = '[Convenio] ResetConvenios'
  constructor( ){}
}

export class DeleteConvenio{
  static readonly type = '[Convenio] DeleteConvenio'
  constructor( public cliente:number,public convenio:number, public usuarioAutorizante:number,public observacion:string ){}
}

