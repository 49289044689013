
<div class="col-md-12 mt-4">
    <div class="row" *ngIf="habilitarFacturasPagas">
        <div class="col-md-12">
            <div class="form-check mb-2 mr-sm-2">
                <input class="form-check-input" type="checkbox" id="facturasPagasOption" (change)="mostrarSiNoFacturasPagas($event)" [(ngModel)]="verFacturasPagas">
                <label class="form-check-label" for="facturasPagasOption">
                  Ver facturas pagas
                </label>
              </div>
        </div>
    </div>
    <div class="row mt-2">
        <div class="col-md-12">
        <ag-grid-angular #grid [style]="'width: 100%; height:' + height" class="ag-theme-alpine"
        [rowData]="lista_facturas" [columnDefs]="columnDefs" [colResizeDefault]="'fit'"
        [rowHeight]="25"
        [rowSelection]="rowSelectionOpcion" [rowMultiSelectWithClick]="true"

        (selectionChanged)="onSelectionChanged($event)" (gridReady)="onGridReady($event)">
    </ag-grid-angular>
        </div>
    </div>

</div>


