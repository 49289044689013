import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlSituacionEspecialOM2312Socio } from '../../../beneficios/beneficios.model';
import Swal from 'sweetalert2'
declare var $:any;


@Component({
  selector: 'app-formulario-control-situacion-especial',
  templateUrl: './formulario-control-situacion-especial.component.html',
  styleUrls: ['./formulario-control-situacion-especial.component.css']
})
export class FormularioControlSituacionEspecialComponent implements OnInit {

  @Input() socioSituacionEspecial: ControlSituacionEspecialOM2312Socio

  @Output() onQuitarSituacionEspecialEvent = new EventEmitter()

  constructor() { }

  ngOnInit(): void {
  }

  public open(){
    $("#formControlSituacionEspecial").modal('show')
  }

 async onQuitarSituacionEspecial(){

Swal.fire({
      title: 'Desea quitar la situación especial del socio?',
     text: "Al quitar la situación especial el socio pasará a la situacion especial 'Ninguna' y, en caso de corresponder, la próxima facturación se realizará sin el descuento correspondiente. La operación quedará registrada!",
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText:'Cancelar',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, quitar situación!'

    }).then((result) => {
      if (result.isConfirmed) {
        $("#formControlSituacionEspecial").modal('hide')
        this.onQuitarSituacionEspecialEvent.emit( {socio:this.socioSituacionEspecial.id_socio, situacion_especial_control:this.socioSituacionEspecial.id_situacionespecial_socio_control} )
      }})



  }



}
