<div class="modal" tabindex="-1" role="dialog" [attr.id]="id" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
        </div>
        <div class="modal-body">
            <div style="width: 100%; height: 450px">
              <ng2-pdfjs-viewer
                 #pdfViewerAutoLoad ></ng2-pdfjs-viewer>            

                 
            </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" (click)="cerrar()">Cerrar</button>
        </div>
      </div>
    </div>
  </div>
