import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { Subscription, Observable } from 'rxjs';
import { GetPeriodos, GetZonas } from 'src/app/store/general/actions';
import { filter } from 'rxjs/operators';
import {meses} from '../../../shared/enums/enums'
import { FacturaService } from '../../factura.service';
import { VisorImpresionComponent } from 'src/app/shared/visor-impresion/visor-impresion.component';
import { HttpResponse } from '@angular/common/http';
import { GetUltimoPeriodoFacturado, GetAniosPeriodoFacturado } from 'src/app/store/periodo/actions';
import { GetBarrios } from 'src/app/store/barrio/actions';



@Component({
  selector: 'app-resumen-facturacion',
  templateUrl: './resumen-facturacion.component.html',
  styleUrls: ['./resumen-facturacion.component.css']
})

export class ResumenFacturacionComponent implements OnInit,OnDestroy {

  @ViewChild('visorImpresion', { static: true }) visorImpresion:VisorImpresionComponent;

  resumenForm: UntypedFormGroup;

  loading: boolean = false;

  aniosFacturado$:Observable<any[]>

  subcriptionPeriodo: Subscription;
  subcriptionUltimoPeriodo: Subscription;


  meses = meses;

  barrios$: Observable<any[]>
  zonas$: Observable<any[]>

  periodoSeleccionado:string


  constructor(private fb: UntypedFormBuilder, private store:Store, private fs:FacturaService) {

    this.resumenForm = this.fb.group({
      mesPeriodo:['',Validators.required],
      anioPeriodo:['',Validators.required],
      tipoListado:['1',Validators.required],
      tipoListadoPorBarrioZona:[{value:'1',disabled:true},Validators.required],
      barrio:[{value:'',disabled:true},Validators.required],
      zona:[{value:'',disabled:true},Validators.required]
    })

   }

  ngOnInit(): void {

    this.store.dispatch(new GetPeriodos())
    this.store.dispatch(new GetBarrios())
    this.store.dispatch(new GetZonas())
    this.store.dispatch(new GetUltimoPeriodoFacturado())
    this.store.dispatch(new GetAniosPeriodoFacturado())


    this.aniosFacturado$ = this.store.select(state=>state.periodo.anios_periodo_facturado)
    this.barrios$ = this.store.select(state => state.barrio.barrios)
    this.zonas$ = this.store.select(state => state.general.zonas)

    this.subcriptionUltimoPeriodo =this.store.select(state => state.periodo.ultimo_periodo_facturado)
    .pipe(
      filter(data => !!data)
      )
    .subscribe(
      ultimoPeriodoReturn => {
        this.mesPeriodoField.setValue(ultimoPeriodoReturn.substring(4,6))
        this.anioPeriodoField.setValue(ultimoPeriodoReturn.substring(0,4))
      }
    )

    this.initForm()
  }

  initForm(){


    this.tipoListadoField.valueChanges.subscribe(value=>{
      if ([5,6].includes(+value)){
        this.tipoListadoPorBarrioZonaField.enable()
      } else{
        this.tipoListadoPorBarrioZonaField.disable()
        this.barrioField.disable()
        this.barrioField.setValue('')
        this.zonaField.disable()
        this.zonaField.setValue('')
      }

    })

    this.tipoListadoPorBarrioZonaField.valueChanges.subscribe(value=>{
      if (+value==2){
        this.barrioField.enable()
        this.zonaField.disable()
        this.zonaField.setValue('')
      }else if(+value==4){
        this.zonaField.enable()
        this.barrioField.disable()
        this.barrioField.setValue('')
      }else{
        this.barrioField.disable()
        this.barrioField.setValue('')
        this.zonaField.disable()
        this.zonaField.setValue('')
      }

    })
  }


  get mesPeriodoField(){
    return this.resumenForm.controls.mesPeriodo
  }

  get anioPeriodoField(){
   return  this.resumenForm.controls.anioPeriodo
  }

  get tipoListadoField(){
    return  this.resumenForm.controls.tipoListado
  }

  get tipoListadoPorBarrioZonaField(){
    return  this.resumenForm.controls.tipoListadoPorBarrioZona
  }

  get barrioField(){
    return this.resumenForm.controls.barrio
  }

  get zonaField(){
    return this.resumenForm.controls.zona
  }




  get valorMesPeriodoIsInvalid(){
    return this.mesPeriodoField.touched && this.mesPeriodoField.invalid
  }

  get valorAnioPeriodoIsInvalid(){
    return this.anioPeriodoField.touched && this.anioPeriodoField.invalid
  }

  get valorBarrioIsInvalid(){
    return this.barrioField.touched && this.barrioField.invalid
  }

  get valorZonaIsInvalid(){
    return this.zonaField.touched && this.zonaField.invalid
  }






  generarResumen(event:Event){
    event.preventDefault()

    if(this.resumenForm.valid){

      this.periodoSeleccionado = this.anioPeriodoField.value+this.mesPeriodoField.value

      if(+this.tipoListadoField.value==1){
        this.getResumenPorConceptos(-1,-1)
      }

      if(+this.tipoListadoField.value==2){
        this.getResumenPorTarifa()
      }

      if(+this.tipoListadoField.value==3){
        this.getResumenPorBarrio()
      }

      if(+this.tipoListadoField.value==4){
        this.getResumenSubdiario(-1,-1)
      }

      if(+this.tipoListadoField.value==5){

        if(+this.tipoListadoPorBarrioZonaField.value==1){
          this.getResumenPorConceptos(1,-1)
        }

        if(+this.tipoListadoPorBarrioZonaField.value==2){
          this.getResumenPorConceptos(1,this.barrioField.value)
        }

        if(+this.tipoListadoPorBarrioZonaField.value==3){
          this.getResumenPorConceptos(2,-1)
        }

        if(+this.tipoListadoPorBarrioZonaField.value==4){
          this.getResumenPorConceptos(2,this.zonaField.value)
        }


      }

      if(+this.tipoListadoField.value==6){

        if(+this.tipoListadoPorBarrioZonaField.value==1){
          this.getResumenTarifaZonBarrio(1,-1)
        }

        if(+this.tipoListadoPorBarrioZonaField.value==2){
          this.getResumenTarifaZonBarrio(1,this.barrioField.value)
        }

        if(+this.tipoListadoPorBarrioZonaField.value==3){
          this.getResumenTarifaZonBarrio(2,-1)
        }

        if(+this.tipoListadoPorBarrioZonaField.value==4){
          this.getResumenTarifaZonBarrio(2,this.zonaField.value)
        }


      }

    }
  }


  getResumenPorConceptos(division:number,filtroDivision:number){

    this.inhabilitarForm()
    this.fs.getResumenGeneralPorConceptos(
         this.periodoSeleccionado,division,filtroDivision
         )
      .subscribe(
        (contenido: HttpResponse<Blob>)=>{
          this.visorImpresion.imprimir(contenido);
          this.habilitarForm();
        },
        ()=>this.habilitarForm());

  }

  getResumenPorTarifa(){

    this.inhabilitarForm()
    this.fs.getResumenGeneralPorTarifa(
         this.periodoSeleccionado
         )
      .subscribe(
        (contenido: HttpResponse<Blob>)=>{
          this.visorImpresion.imprimir(contenido)
          this.habilitarForm();
        },
        ()=>this.habilitarForm());

  }

  getResumenPorBarrio(){

    this.inhabilitarForm()
    this.fs.getResumenGeneralPorBarrio(
         this.periodoSeleccionado
         )
      .subscribe(
        (contenido: HttpResponse<Blob>)=>{
          this.visorImpresion.imprimir(contenido)
          this.habilitarForm();
        },
        ()=>this.habilitarForm());

  }

  getResumenSubdiario(division:number,filtroDivision:number){

  this.inhabilitarForm()
    this.fs.getResumenGeneralSubdiario(
         this.periodoSeleccionado,division,filtroDivision
         )
      .subscribe(
        (contenido: HttpResponse<Blob>)=>{
          this.visorImpresion.imprimir(contenido)
          this.habilitarForm()
        },
        ()=>this.habilitarForm());
  }

  getResumenTarifaZonBarrio(division:number,filtroDivision:number){

    this.inhabilitarForm()
      this.fs.getResumenGeneralFacturacionBarrioZona(
           this.periodoSeleccionado,division,filtroDivision
           )
        .subscribe(
          (contenido: HttpResponse<Blob>)=>{
            this.visorImpresion.imprimir(contenido)
            this.habilitarForm()
          },
          ()=>this.habilitarForm());
    }

  inhabilitarForm(){
    this.loading=true;
    this.mesPeriodoField.disable()
    this.anioPeriodoField.disable()
    this.tipoListadoField.disable()
  }

  habilitarForm(){
    this.loading = false;
    this.mesPeriodoField.enable()
    this.anioPeriodoField.enable()
    this.tipoListadoField.enable()
  }


  ngOnDestroy(){
    this.subcriptionUltimoPeriodo.unsubscribe()
  }

}
