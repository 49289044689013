import { Component } from '@angular/core';
declare var $:any;

@Component({
  selector: 'app-operacion-loading',
  templateUrl: './operacion-loading.component.html',
  styleUrls: ['./operacion-loading.component.css']
})
export class OperacionLoadingComponent  {


  public loadingTitle:string= '';

  constructor() { }

  open(){
    $('#operacion_loading').modal('show')
  }

  close(){
    setTimeout(()=>{
      $('#operacion_loading').modal('hide')
    },1000)

  }



}
