

export const getEntero = (numero:number)=>{
    return   Math.floor(numero) 
} 

export const getUnidad = (numero:number)=>{
    let numeroEntero = getEntero(numero)
  return   Number(numeroEntero.toString().charAt(numeroEntero.toString().length-1))
} 

export const getDecimales = (numero:number)=>{
    let numeroEntero = getEntero(numero)
   return  Number((numero - numeroEntero).toFixed(2))
}
