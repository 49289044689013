<div class="card" style="margin-bottom: 15px">
  <div class="card-header filtro" style="background-color:#17a2b8; color: white">
   Resumen diario de Cobranzas
  </div>
  <div class="card-body">

    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-6">
          <form [formGroup]="formResumenDiario" novalidate class="needs-validation">

            <div class="card">
              <div class="card-body">
                <fieldset class="p-2" style="border: solid 1px #ddd">
                  <legend style="font-size: 12px">Emitir resumen ...</legend>

                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio"  id="periodoResumen1" value="1" formControlName="periodoResumen">
                    <label class="form-check-label col-form-label-sm mr-4" for="periodoResumen1">
                       del día
                    </label>
                  </div>

                  <div class="form-check form-check-inline" >
                    <input class="form-check-input" type="radio"  id="periodoResumen2" value="2" formControlName="periodoResumen">
                    <label class="form-check-label col-form-label-sm mr-4" for="periodoResumen2">
                      entre días
                    </label>
                  </div>



    <div class="row mt-3">

        <label for="idFechaDesdeResumen" class="col-sm-2 col-form-label col-form-label-sm" *ngIf="getPeriodoResumenField.value == 2">del día</label>
        <div class="col-sm-4"><input type="date" class="col form-control form-control-sm"
          id="idFechaDesdeResumen" formControlName="fechaDesdeResumen"
          [ngClass]="{ 'is-invalid': valorFechaDesdeIsInvalid  }">
          <div *ngIf="valorFechaDesdeIsInvalid" class="invalid-feedback">
            <small>Valor requerido</small>
          </div>
        </div>


          <ng-container *ngIf="getPeriodoResumenField.value == 2">
            <label for="idFechaHastaResumen" class="col-sm-2 col-form-label col-form-label-sm">al día</label>
            <div class="col-sm-4"><input type="date" class="col form-control form-control-sm"
              id="idFechaHastaResumen" formControlName="fechaHastaResumen"
              [ngClass]="{ 'is-invalid': valorFechaHastaIsInvalid  }">
          <div *ngIf="valorFechaHastaIsInvalid" class="invalid-feedback">
            <small>Valor requerido</small>
          </div>
            </div>
          </ng-container>




    </div>



                </fieldset>
               <fieldset class="p-2 mt-3" style="border: solid 1px #ddd">
                  <legend style="font-size: 12px">Tipo de resumen ...</legend>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio"  id="Radios1" value="1" formControlName="tipoResumen">
                    <label class="form-check-label col-form-label-sm mr-4" for="Radios1">
                       Todas las cajas por separado
                    </label>
                  </div>

                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio"  id="Radios2"  value="2" formControlName="tipoResumen">
                    <label class="form-check-label col-form-label-sm mr-4" for="Radios2">
                        Todas las cajas juntas
                    </label>
                  </div>
                </fieldset>

                <div class="row">
                  <div class="col-md-6">
                    <fieldset class="p-2 mt-3" style="border: solid 1px #ddd">
                      <legend style="font-size: 12px">Emitir resumen de caja de ...</legend>
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio"  id="Radios3" value="1" formControlName="origenResumen">
                        <label class="form-check-label col-form-label-sm mr-4" for="Radios3">
                           Todas las cobranzas
                        </label>
                      </div>

                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio"  id="Radios4"  value="2" formControlName="origenResumen">
                        <label class="form-check-label col-form-label-sm mr-4" for="Radios4">
                            Lo cobrado por <b>{{usuariologin}}</b>
                        </label>
                      </div>

                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="excel" formControlName="incluirExcel">
                        <label class="form-check-label col-form-label-sm" for="excel">
                          Exportar resumen diario a Excel
                        </label>
                      </div>
                    </fieldset>
                  </div>
                  <div class="col-md-6">
                    <fieldset class="p-2 mt-3" style="border: solid 1px #ddd; text-align: center;">
                      <div class="row">
                        <div class="col-md-12">
                          <button type="submit"  class="btn btn-sm btn-outline-primary mt-3 mb-3" [disabled]="loading || !formResumenDiario.valid"
                          (click)="getResumenDiario($event)">
                            <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                             generar resumen diario</button>
                        </div>

                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <button type="submit"  class="btn btn-sm btn-outline-primary mt-3 mb-3" [disabled]="loadingRecibo || !formResumenDiario.valid"
                          (click)="getDetallesRecibos($event)">
                            <span *ngIf="loadingRecibo" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                             generar detalle de recibos</button>
                        </div>

                      </div>

                    </fieldset>
                  </div>
                </div>



                 </div>

            </div>


          </form>
        </div>
      </div>




    </div>
  </div>

 <app-visor-impresion #visorImpresion [id]="'resumenDiarioPDFVisor'"></app-visor-impresion>


