

<!-- Modal -->
<div class="modal fade" [attr.id]="id" tabindex="-1" role="dialog" >
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header" style="padding: 5px 15px;">
        <h5 class="modal-title">Busqueda Avanzada</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">


<div class="row">
          <label for="sociosSearchInput"  class="col-sm-2 col-form-label ml-4">Texto de busqueda</label>

          <div class="col-md-4">
              <input type="text" id="sociosSearchInput" #sociosSearchInput class="form-control form-control-sm" placeholder="Ingrese el valor a buscar" />
          </div>
          <div class="col-md-2">
            <div *ngIf="loading_socios_busqueda_anvanzada" ><i class='fa fa-refresh fa-spin fa-2x ml-4'></i></div>
          </div>

        </div>

        <div class="row mt-3">
          <div class="col-md-3">
            <label  class="form-label" style="font-size: small;">Buscar en el campo ...</label>
              <ng-container *ngFor="let campo of camposBusqueda; let i = index">
                <div  class="form-check">
                  <input class="form-check-input" [(ngModel)]="radioSelected"  type="radio" id="{{campo}}" value="{{campo}}" name="camposBusqueda" (change)="onItemChange()">
                  <label class="form-check-label" for="{{campo}}">
                    {{campo}}</label>
                </div>
              </ng-container>
          </div>
          <div class="col-md-9">
            <label  class="form-label" style="font-size: small;">Resultado de la busqueda: {{rowData.length}} registros</label>
            <ag-grid-angular
            
            #agGridSocios
            style="width: 100%; height: 430px;"
            class="ag-theme-alpine"
            [rowData]="rowData"
            [columnDefs]="columnDefs"
            [colResizeDefault]="'fit'"
            [rowSelection]= "'single'"
            [components]="frameworkComponents"
            [rowHeight]="26"
            (rowDoubleClicked)="onRowDoubleClicked($event)"
            >
        </ag-grid-angular>
          </div>
        </div>

      </div>
      <div class="modal-footer" style="padding: 5px 15px;">
        <button type="button" class="btn btn-sm btn-secondary" data-dismiss="modal">Cancelar</button>
      </div>
    </div>
  </div>
</div>
