import { Component, OnInit, Output, EventEmitter, ViewChild, OnDestroy } from '@angular/core';
import { Store } from '@ngxs/store';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { NuevoConvenioPagoComponent } from './nuevo-convenio-pago/nuevo-convenio-pago.component';

@Component({
  selector: 'app-nuevo-convenio',
  templateUrl: './nuevo-convenio.component.html',
  styleUrls: ['./nuevo-convenio.component.css']
})
export class NuevoConvenioComponent implements OnInit,OnDestroy {

  @ViewChild("nuevoConvenioPago", { static: true })
  nuevoConvenioPago: NuevoConvenioPagoComponent;

  @Output() cancelarEvent = new EventEmitter();

  habilitar:boolean[] = [false,false]

  datoConvenio:any;

  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private store:Store) { }

  ngOnInit(): void {
    this.store.select(state => state.convenio.facturas_nuevo_convenio).pipe(takeUntil(this.destroy$)).subscribe(
      data=>{
        this.habilitar[0]=false
      }
    )
  }

  habilitarFacturasDisponibles(){
    this.habilitar[1]=false
    this.habilitar[0]=true
  }

  cancelarFacturasDisponibles(){
    this.habilitar[0]=false
  }

  habilitarConvenioPago(convenioDatos){
    this.habilitar[0]=false
    this.habilitar[1]=true
    this.datoConvenio = convenioDatos
  }

  salirDatosConvenio(){
    this.habilitar[1]=false
    this.nuevoConvenioPago.habilitarControlesNuevoConvenio()
  }

  cancelarNuevoConvenio(){
    this.habilitar[0]=false
    this.habilitar[1]=false
    this.cancelarEvent.emit()
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }


}
