import { Injectable, inject } from '@angular/core';
import { ControlSituacionEspecialOM2312Socio } from "./beneficios.model";
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class BeneficiosService {

  private _http= inject(HttpClient)
 
  urlRest = environment;



  constructor() { }

  getSociosConSituacionEspecialConVencimiento():Observable<ControlSituacionEspecialOM2312Socio[]>{
    return this._http.get<ControlSituacionEspecialOM2312Socio[]>(`${this.urlRest.urlapiNode}/clientes/situacion-especial/om-2312`)
  }

  getSociosConSituacionEspecialOM2312ProximoVencer():Observable<ControlSituacionEspecialOM2312Socio[]>{
    return this._http.get<ControlSituacionEspecialOM2312Socio[]>(`${this.urlRest.urlapiNode}/clientes/situacion-especial/om-2312/proximo-a-vencer`)
  }

  quitarASocioSituacionEspecialConVencimiento(cliente: number, situacion_especial_control:number): Observable<any> {
    let params = new HttpParams()
    .set('socio',cliente.toString())
    .set('situacion_especial_control',situacion_especial_control.toString())

    return this._http.delete(
      `${this.urlRest.urlapiNode}/clientes/situacion-especial/om-2312`,
      {params}
    );

    }


    updateSocioConSituacionEspecialOM2312ProximoVencer(cliente:number, accion:number):Observable<any>{
      return this._http.put(`${this.urlRest.urlapiNode}/clientes/situacion-especial/om-2312/proximo-a-vencer/cliente`,{cliente,accion})
    }

}





