<nav class="navbar navbar-expand-lg navbar-dark bg-dark">
  <div class="row">
    <div class="col-2">
        <a class="navbar-brand mr-1" [routerLink]="[]" style="color: #049ed5"><img src="{{srcimg.srcImg}}assets/images/gota.png" width="30px" alt="">CAPP</a>
    </div>

    <div class="col-10" style="width: 600px;"><h3 style="color: white; margin-left: 30px;">Sistema de Gestión y Facturación <small>v{{versionNumero}}</small></h3></div>
</div>

</nav>


<div class="row justify-content-md-center">


<div class="row mt-5">
  <div class="col-md-12">
    <div class="row justify-content-md-center">
      <div class="alert alert-info" role="alert">
        <p><i class="fas fa-exclamation-circle"></i> &nbsp;Se ha detectado una nueva versión del sistema!</p>
        <p> Para poder continuar deberá presionar las teclas CTRL+F5 o CTRL+R</p>
      </div>
    </div>
  </div>

</div>

</div>
