<div class="card">
  <div class="card-header" style="background-color:#17a2b8; color: white">Calles</div>
  <div class="card-body">

  <div class="row">
    <div class="col-6">

        <div class="card shadow mb-2">
            <div class="card-header text-muted">

               <div class="btn-group mr-2" role="group" aria-label="First group">
                  <button type="button" class="btn btn-outline-info" (click)="nuevo()" title="Nueva calle"><i class="fas fa-plus-square"></i></button>
                  <button type="button" class="btn btn-outline-info" (click)="editar()" title="Editar calle"><i class="far fa-edit"></i></button>
                  <button type="button" class="btn btn-outline-info" (click)="eliminar()" title="Eliminar calle"><i class="far fa-trash-alt"></i></button>

                </div>


            </div>
            <form  [formGroup]="calleForm" (ngSubmit)="onSubmit()" (keydown.enter)="$event.preventDefault()" novalidate>
            <div class="card-body">

                    <div class="form-group row">
                        <label for="inputCodigoCalle" class="col-sm-3 col-form-label text-right">Código</label>
                        <div class="col">
                            <input type="text" formControlName="codigo" class="form-control" id="inputCodigoCalle"  maxlength="6" [ngClass]="{ 'is-invalid': (submitted && (calleForm.controls['codigo'].errors) || errorCalleRegistrada  ) }" appNumberEnteroOnly>
                            <div *ngIf="submitted && calleForm.controls['codigo'].errors" class="invalid-feedback">
                              <div *ngIf="calleForm.controls['codigo'].errors.required">Debe ingresar el código</div>
                            </div>

                        </div>
                      </div>
                      <div class="form-group row">
                          <label for="inputCodigo" class="col-sm-3 col-form-label text-right">Calle</label>
                          <div class="col">
                              <input type="text" formControlName="calle" class="form-control" id="inputCalle"  maxlength="200" [ngClass]="{ 'is-invalid': (submitted && calleForm.controls['calle'].errors)}">
                              <div *ngIf="submitted && calleForm.controls['calle'].errors" class="invalid-feedback">
                                <div *ngIf="calleForm.controls['calle'].errors.required">Debe ingresar el nombre de la calle</div>
                              </div>
                          </div>
                        </div>

            </div>
            <div class="card-footer" *ngIf="modo !== 0">
                <button (click)="cancelar()" class="btn btn-outline-danger">Cancelar</button> &nbsp;
                <button type="submit" class="btn btn-success">Guardar</button>
              </div>
            </form>
          </div>

    </div>
    <div class="col-6">

        <div class="card shadow mb-2">
            <div class="card-body">
                <div class="form-group row">
                    <label for="busqueda" class="col-sm-2 col-form-label">Buscar</label>
                    <div class="col-sm-5">
                        <input type="text" class="form-control input-sm" id="textFiltro" [(ngModel)]="filtroText" (change)="onFilterTextBoxChanged()">
                    </div>
                  </div>

                 <div class="row">
                    <div class="col-md-12">
                        <ag-grid-angular
                        #agGridCalles
                        style="width: 500px; height: 250px;"
                        class="ag-theme-alpine"
                        [rowData]="rowData"
                        [columnDefs]="columnDefs"
                        [colResizeDefault]="'fit'"
                        [rowSelection]= "'single'"
                        [rowHeight]="25"
                        (selectionChanged)="onSelectionChanged()"

                        >
                    </ag-grid-angular>
                    </div>
                 </div>

            </div>
          </div>


    </div>

  </div>



  </div>
</div>


<app-operacion-si-no [titulo]="'Calles'" [contenidoMensaje]="'Desea eliminar la calle?'"
(retornoOperacion)="retornoOperacionSiNo($event)"></app-operacion-si-no>
