<div class="card">
    <div class="card-header" style="background-color:#17a2b8; color: white">Control socios con Situacion Especial</div>
    <div class="card-body">

      <div class="row">      
        <div class="col-md-12">
          <button type="button" class="btn btn-sm btn-outline-success float-right ml-3" (click)="onBtExport()" title="Exportar a Excel"><i class="fas fa-file-excel"></i></button>
          <button type="button" class="btn btn-sm btn-outline-danger float-right" (click)="clearFilter()" title="Quitar filtros"><i class="fas fa-eraser"></i></button>
          
      </div>

        </div>


        <div class="row mt-3">
            <div class="col-md-12">
             <ag-grid-angular
                #agGridControlSituacionEspecial
                style="width: 100%; height: 450px;"
                class="ag-theme-alpine"
                [rowData]="rowData$ | async"
                [columnDefs]="columnDefs"
                [colResizeDefault]="'fit'"
                [rowSelection]= "'single'"
                [rowHeight]="27"
                [components]="frameworkComponents"
                [pagination]="true"
                >
            </ag-grid-angular>
            </div>

          </div>


          <div class="row mt-4">
            <div class="col-md-6">
              <a routerLink="/beneficio/om-2312/proximo-a-vencer" style="color:#007bff !important; font-size: 0.9rem;">Ir a la gestión de beneficios por vencer ➡️  </a>
            </div>
          </div>
    

    </div>
  </div>


  <app-formulario-control-situacion-especial
  [socioSituacionEspecial]="socioSituacionEspecialSelect"
  (onQuitarSituacionEspecialEvent)="onQuitarSituacionEspecial($event)"
  ></app-formulario-control-situacion-especial>
