import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
  Input,
} from "@angular/core";
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  AbstractControl,
} from "@angular/forms";
import { Validacion } from "src/app/shared/validacion";
import { Store } from "@ngxs/store";
import {
  AddAccionesCliente, UpdateAccionesCliente
} from "src/app/store/acciones/actions";
import { SocioState } from "src/app/store/socio/states";
import { Acciones } from "src/app/store/acciones/model";
import { mergeMap } from 'rxjs/operators';
import { GetObservaciones } from 'src/app/store/observacion/actions';
import  dayjs from 'dayjs'
import Swal from "sweetalert2";


@Component({
  selector: "app-nueva-accion",
  templateUrl: "./nueva-accion.component.html",
  styleUrls: ["./nueva-accion.component.css"],
})
export class NuevaAccionComponent implements OnInit {
  @ViewChild("cuotasInput", { static: true }) cuotasInput: ElementRef;
  @ViewChild("valorCuotasInput", { static: true }) valorCuotasInput: ElementRef;

  @Input() accionAEditar: Acciones;
  @Input() modoEdicion: Boolean = false;
  @Input() observacionEdicion: string = null;

  @Output() newSubscripcionReturn = new EventEmitter();

  altaAccionForm: UntypedFormGroup;

  validacion: Validacion = new Validacion();


  constructor(
    private store: Store,
    private fb: UntypedFormBuilder
  ) {
    this.buildForm();
  }

  ngOnInit(): void {
    this.getCuotasField.valueChanges.subscribe(() =>
      this.actualizarImporteTotal()
    );
    this.getValorCuotaField.valueChanges.subscribe(() =>
      this.actualizarImporteTotal()
    );

    if (this.modoEdicion) {
      this.setearDatosModoEdicion();
    }
  }

  buildForm() {
    this.altaAccionForm = this.fb.group(
      {
        fechaSubscripcion: [
          dayjs().format("YYYY-MM-DD"),
          Validators.required,
        ],
        cuotas: ["", [Validators.required, this.validacion.isMayorCero]],
        valorCuota: ["", [Validators.required, this.validacion.isMayorCero]],
        importeTotal: [{ value: "", disabled: true }, Validators.required],
        cuotasAbonadas: [0, Validators.required],
      },
      { validator: this.EsMenorQue }
    );
  }

  setearDatosModoEdicion() {
    let fecha = new Date(
      +this.accionAEditar.fecha.substr(-4),
      +this.accionAEditar.fecha.substr(3, 2) - 1,
      +this.accionAEditar.fecha.substr(0, 2)
    );
    this.altaAccionForm
      .get("fechaSubscripcion")
      .setValue(fecha.toISOString().substring(0, 10));
    this.altaAccionForm.get("cuotas").setValue(this.accionAEditar.cuotas);
    this.altaAccionForm
      .get("valorCuota")
      .setValue(this.accionAEditar.valorcuota);
    this.altaAccionForm
      .get("importeTotal")
      .setValue(this.accionAEditar.importe);
    this.altaAccionForm
      .get("cuotasAbonadas")
      .setValue(this.accionAEditar.facturadas);
  }

  actualizarImporteTotal() {
    let total = +(
      +this.getCuotasField.value * +this.getValorCuotaField.value
    ).toFixed(2);
    this.altaAccionForm.get("importeTotal").setValue(total);
  }

  get getFechaSubscripcionField() {
    return this.altaAccionForm.get("fechaSubscripcion");
  }
  get valorFechaIsInvalid() {
    return (
      this.getFechaSubscripcionField.errors &&
      this.getFechaSubscripcionField.touched
    );
  }

  get getCuotasField() {
    return this.altaAccionForm.get("cuotas");
  }

  get cuotasIsInvalid() {
    return this.getCuotasField.errors && this.getCuotasField.touched;
  }

  get getValorCuotaField() {
    return this.altaAccionForm.get("valorCuota");
  }

  get valorCuotaIsInvalid() {
    return this.getValorCuotaField.errors && this.getValorCuotaField.touched;
  }

  get getCuotasAbonadasField() {
    return this.altaAccionForm.get("cuotasAbonadas");
  }

  get valorCuotasAbonadasIsInvalid() {
    return (
      this.getCuotasAbonadasField.errors && this.getCuotasAbonadasField.touched
    );
  }

  save(event: Event) {
    event.preventDefault();
    if (this.altaAccionForm.valid) {
      if(!this.modoEdicion){
        this.saveNewSubcripcion()
      }else{
        this.saveUpdateSubcripcion()
      }

    }
  }

  saveNewSubcripcion(){
    const cliente = this.store.selectSnapshot(SocioState.cliente);
    this.store
    .dispatch(
      new AddAccionesCliente(
        cliente,
        this.getFechaSubscripcionField.value,
        this.getCuotasField.value,
        this.getValorCuotaField.value,
        this.altaAccionForm.get("importeTotal").value,
        this.getCuotasAbonadasField.value
      )
    )
    .subscribe((_) => {

      Swal.fire(
        'Nueva acción','La operación ha sido realizada con éxito!',
        'success'
      ).then(
        () => this.cancelar()
      )


    });
  }

  saveUpdateSubcripcion(){
    const cliente = this.store.selectSnapshot(SocioState.cliente);
    this.store
    .dispatch(
      new UpdateAccionesCliente(
        this.accionAEditar.id_accion,
        cliente,
        this.getFechaSubscripcionField.value,
        this.getCuotasField.value,
        this.getValorCuotaField.value,
        this.altaAccionForm.get("importeTotal").value,
        this.getCuotasAbonadasField.value,
        this.observacionEdicion
      )
    ).pipe(
      mergeMap(()=>   this.store.dispatch(new GetObservaciones(cliente,true)))
    )
    .subscribe((_) => {
      Swal.fire(
        'Actualizar acción','La operación ha sido realizada con éxito!',
        'success'
      ).then(
        () => this.cancelar()
      )

    });
  }



  cancelar() {
    this.altaAccionForm.reset();
    this.newSubscripcionReturn.emit();
  }

  EsMenorQue(AC: AbstractControl) {
    let valorA = AC.get("cuotas").value || 0; // to get value in input tag
    let valorB = AC.get("cuotasAbonadas").value || 0; // to get value in input tag
    if (+valorA < +valorB) {
      AC.get("cuotasAbonadas").setErrors({ EsMenorQue: true });
    } else {
      return null;
    }
  }
}
