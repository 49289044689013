import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { Store } from '@ngxs/store';
import { ReciboSemestralDetalle } from 'src/app/store/recibo/models';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { GetDetalleReciboSemestralPagado, AnularCobroRecibo } from 'src/app/store/recibo/actions';
import { OperacionExitosaComponent } from 'src/app/shared/operacion-exitosa/operacion-exitosa.component';
import { OperacionSiNoComponent } from 'src/app/shared/operacion-si-no/operacion-si-no.component';
import { OperacionLoadingComponent } from 'src/app/shared/operacion-loading/operacion-loading.component';
import { AuthState } from 'src/app/store/auth/state';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-recibo-anular',
  templateUrl: './recibo-anular.component.html',
  styleUrls: ['./recibo-anular.component.css']
})
export class ReciboAnularComponent implements OnInit,OnDestroy {

  @ViewChild(OperacionExitosaComponent, { static: true })
  operacionExitosaComponent: OperacionExitosaComponent;

  @ViewChild(OperacionSiNoComponent, { static: true })
  operacionSiNoComponent: OperacionSiNoComponent;

  @ViewChild(OperacionLoadingComponent, { static: true })
  operacionLoadingComponent: OperacionLoadingComponent;

  reciboForm: UntypedFormGroup;
  reciboSemestral:ReciboSemestralDetalle;

  destroy$: Subject<boolean> = new Subject<boolean>();

  busquedaRealizada=false

  estaProcesando:boolean =false



  constructor(private formBuilder: UntypedFormBuilder, private store:Store) {
    }

  ngOnInit() {
    this.iniciarForm()

    this.store.select(state => state.recibo.recibo_semestral_detalle).pipe(takeUntil(this.destroy$)).subscribe(
      data => {
        this.estaProcesando = false
        if (data.length>0){this.reciboSemestral = data[0]}else{
          this.reciboSemestral = null
        }
      }
    );

  }

  iniciarForm(){
    this.reciboForm = this.formBuilder.group({
      numeroRecibo:['',Validators.required]
    })

  }

  onSubmit(){
    this.estaProcesando = true
    this.busquedaRealizada = false

    const self = this;
    setTimeout(function(){
      self.store.dispatch(new GetDetalleReciboSemestralPagado(self.reciboForm.get('numeroRecibo').value));
      self.busquedaRealizada = true
    }, 2000)


  }

  anularRecibo(){
    this.operacionSiNoComponent.open();
  }

  retornoOperacionSiNo(retorno){

    if(retorno){
      const usuario = this.store.selectSnapshot(AuthState.usuario);
     this.store.dispatch(new AnularCobroRecibo(this.reciboSemestral.numero_recibo,this.reciboSemestral.comprobante_pago,this.reciboSemestral.id_cliente,usuario)).subscribe(
       _ =>
       {
        Swal.fire(
          '','El recibo semestral ha sido dado de baja con éxito!',
          'success'
        )
       this.store.dispatch(new GetDetalleReciboSemestralPagado(this.reciboForm.get('numeroRecibo').value));
      },
      (error)=> console.log(error)
     )
    }
  }



  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }




}
