import { NuevoCredito, DeleteCredito } from './models';

export class NewCredito{
    static readonly type = '[Credito] NewCredito'
    constructor(public credito:NuevoCredito){}
}

export class GetCreditoMontoTotal{
    static readonly type = '[Credito] GetCreditoMontoTotal'
    constructor(public cliente:number, public update:boolean){}
}


export class GetCreditosOtorgados{
    static readonly type = '[Credito] GetCreditosOtorgados'
    constructor(public cliente:number,public update:boolean){}
}

export class GetCreditoImputado{
    static readonly type = '[Credito] GetCreditoImputado'
    constructor(public cliente:number,public update:boolean){}
}

export class DeleteCreditoOtorgado{
    static readonly type = '[Credito] DeleteCreditoOtorgado'
    constructor(public credito:DeleteCredito){}
}

export class UpdateCreditoOtorgadoImputarFacturasAdeudadas{
    static readonly type = '[Credito] UpdateCreditoOtorgadoImputarFacturasAdeudadas'
    constructor(public pagos:any,public cliente:number,public usuario:number,public usuarioAutorizante:number ){}
}


export class ResetCredito{
  static readonly type = '[Credito] ResetCredito'
  constructor( ){}
}
