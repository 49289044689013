<ng-container [formGroup]="socioForm">
  <div id="datosTecnicos"></div>
  <div class="card shadow mt-4">
    <div class="card-body">
      <p style="border-left: solid 5px #17a2b8; padding-left: 5px"><b>Datos técnicos</b></p>
      <hr>
      <fieldset class="p-2" style="border: solid 1px #ddd">
        <legend style="font-size: 16px">Medidor</legend>
        <div class="form-group row">
          <label for="inputNumeroMedidor" class="col-sm-3 col-form-label col-form-label-sm  text-right">Número</label>
          <div class="col">
            <input type="text" class="form-control form-control-sm" formControlName="numeroMedidor"
              maxlength="20" id="inputNumeroMedidor" (change)="verificarNumeroMedidor($event)" placeholder="">
          </div>
        </div>
        <div class="form-group row">
          <label for="inputMarcaMedidor" class="col-sm-3 col-form-label col-form-label-sm  text-right">Marca</label>
          <div class="col">
            <input type="text" class="form-control form-control-sm" formControlName="marcaMedidor"
              maxlength="50" id="inputMarcaMedidor" placeholder=""
              [ngClass]="{ 'is-invalid':isInvalidMarcaMedidorField }">
            <div *ngIf="isInvalidMarcaMedidorField" class="invalid-feedback">
              Campo obligatorio
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label for="inputEstadoMedidor" class="col-sm-3 col-form-label col-form-label-sm  text-right">Estado</label>
          <div class="col">
            <input type="text" class="form-control form-control-sm" maxlength="4"
              formControlName="estadoMedidor" id="inputEstadoMedidor" placeholder=""
              [ngClass]="{ 'is-invalid': isInvalidEstadoMedidorField }" autocomplete="off" appNumberEnteroOnly>
            <div *ngIf="isInvalidEstadoMedidorField" class="invalid-feedback">
              Campo obligatorio
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label for="inputUltimaLecturaMedidor" class="col-sm-3 col-form-label col-form-label-sm  text-right">Última lect.</label>
          <div class="col">
            <input type="date" class="form-control form-control-sm" formControlName="ultimaLecturaMedidor"
              id="inputUltimaLecturaMedidor" placeholder=""
              [ngClass]="{ 'is-invalid': isInvalidUltimaLecturaMedidorField }">
            <div *ngIf="isInvalidUltimaLecturaMedidorField" class="invalid-feedback">
              Campo obligatorio
            </div>
          </div>
        </div>
      </fieldset>
      <br>
      <fieldset class="p-2" style="border: solid 1px #ddd">
        <legend style="font-size: 16px">Ubicación servicio domiciliario</legend>
        <div class="form-group row">
          <label for="inputDistanciaAMedianera" class="col-sm-3 col-form-label col-form-label-sm  text-right">Dist. a medianera (en
            mts)</label>
          <div class="col">
            <input type="text" maxlength="9" class="form-control form-control-sm"
              formControlName="distanciaAMedianera" id="inputDistanciaAMedianera" placeholder=""
              [ngClass]="{ 'is-invalid':isInvalidDistanciaAMedianeraField }" appNumberDecimalOnly>
            <div *ngIf="isInvalidDistanciaAMedianeraField" class="invalid-feedback">
              Falta la distancia de la conexión a la medianera
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label for="inputMedianera" class="col-sm-3 col-form-label col-form-label-sm  text-right">Medianera</label>
          <div class="col">
            <select class="form-control form-control-sm" id="inputMedianera" formControlName="medianera"
              [ngClass]="{ 'is-invalid': isInvalidMedianeraField}">
              <option *ngFor="let tipoMedianera of tiposMedianeras" [value]="tipoMedianera.id">
                {{tipoMedianera.detalle}}</option>
            </select>
            <div *ngIf="isInvalidMedianeraField" class="invalid-feedback">
              Falta la medianera de referencia para la distancia
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label for="inputTipoConexion" class="col-sm-3 col-form-label col-form-label-sm  text-right">Tipo conexión</label>
          <div class="col">
            <select class="form-control form-control-sm" id="inputTipoConexion" formControlName="tipoConexion"
              [ngClass]="{ 'is-invalid': isInvalidTipoConexionField }">
              <option *ngFor="let tipoConexion of tiposConexion" [value]="tipoConexion.id">
                {{tipoConexion.detalle}}</option>
            </select>
            <div *ngIf="isInvalidTipoConexionField" class="invalid-feedback">
              Falta el tipo de conexión
            </div>
          </div>
        </div>
      </fieldset>
      <br>
      <fieldset class="p-2" style="border: solid 1px #ddd">
        <legend style="font-size: 16px">Red troncal</legend>
        <div class="form-group row">
          <label for="inputDistanciaLineaMunicipalRedTroncal" class="col-sm-3 col-form-label col-form-label-sm  text-right">Dist a
            linea municip. (en mts)</label>
          <div class="col">
            <input type="text" maxlength="9" class="form-control form-control-sm"
              formControlName="distanciaLineaMunicipalRedTroncal" id="inputDistanciaLineaMunicipalRedTroncal"
              placeholder="" [ngClass]="{ 'is-invalid': isInvalidDistanciaLineaMunicipalRedTroncalField }"
              appNumberDecimalOnly>
            <div *ngIf="isInvalidDistanciaLineaMunicipalRedTroncalField" class="invalid-feedback">
              Falta la distancia a la red troncal
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label for="inputProfundidadRedTroncal" class="col-sm-3 col-form-label col-form-label-sm  text-right">Profundidad (en
            mts)</label>
          <div class="col">
            <input type="text" maxlength="9" class="form-control form-control-sm"
              formControlName="profundidadRedTroncal" id="inputProfundidadRedTroncal" placeholder=""
              [ngClass]="{ 'is-invalid': isInvalidProfundidadRedTroncalField }" appNumberDecimalOnly>
            <div *ngIf="isInvalidProfundidadRedTroncalField" class="invalid-feedback">
              Falta la profundidad de la red troncal
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label for="inputTipoMaterialRedTroncal" class="col-sm-3 col-form-label col-form-label-sm  text-right">Tipo de
            material</label>
          <div class="col">
            <select class="form-control form-control-sm" id="inputTipoMaterialRedTroncal"
              formControlName="tipoMaterialRedTroncal"
              [ngClass]="{ 'is-invalid': isInvalidTipoMaterialRedTroncalField }">
              <option *ngFor="let tipoMaterial of tiposMaterial" [value]="tipoMaterial.id">
                {{tipoMaterial.detalle}}</option>
            </select>
            <div *ngIf="isInvalidTipoMaterialRedTroncalField" class="invalid-feedback">
              Falta el tipo de material de la red troncal
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label for="inputDiametroRedTroncal" class="col-sm-3 col-form-label col-form-label-sm  text-right">Diámetro (en
            mm)</label>
          <div class="col">
            <input type="text" maxlength="9" class="form-control form-control-sm"
              formControlName="diametroRedTroncal" id="inputDiametroRedTroncal" placeholder=""
              [ngClass]="{ 'is-invalid': isInvalidDiametroRedTroncalField}" appNumberDecimalOnly>
            <div *ngIf="isInvalidDiametroRedTroncalField" class="invalid-feedback">
              Falta el diámetro de la red troncal
            </div>
          </div>
        </div>
      </fieldset>
    </div>
  </div>
</ng-container>
