import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { ModoChequeoRol, PermisosModoRequerido } from 'src/app/shared/role.directive';
import { TipoPermisos } from 'src/app/store/roles-permisos/model';



@Component({
  selector: 'app-menu-abm-socio',
  templateUrl: './menu-abm-socio.component.html',
  styleUrls: ['./menu-abm-socio.component.css']
})
export class MenuAbmSocioComponent implements OnInit {

  permisoModoRequeridoVerListadoGeneralDeSocios:PermisosModoRequerido={permisosRequerido:[TipoPermisos.VerListadoGeneralDeSocios],
    modoRequerido:ModoChequeoRol.RequiereTodosLosPermisos}

  
  constructor(private store:Store) { }

  ngOnInit() {
  
  }

  
}
