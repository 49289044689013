<div class="card">
    <div class="card-header" style="background-color:#17a2b8; color: white">Control Cisternas y Planos
    </div>

    <div class="card-body">
        <div class="row">
            <div class="col-4 mt-2"><span class="badge badge-pill badge-info">1</span> &nbsp; <span> Filtros de Control</span></div>
          </div>
        <div class="row">
            <div class="col-md-12">

                  <hr>
                  <form [formGroup]="filtroForm" (ngSubmit)="onSubmit()" (keydown.enter)="$event.preventDefault()" novalidate>
                    <div class="form-row align-items-center">



                      <div class="col-2 text-right">
                        <label for="tipoDocumentacionId">Tipo documentación</label>
                      </div>
                      <div class="col-1">
                        <select class="form-control form-control-sm" id="tipoDocumentacionId" formControlName="tipoDocumentacion">
                            <option [value]="0">Todos</option>
                            <option [value]="1">Cisterna</option>
                            <option [value]="2">Planos</option>
                        </select>
                      </div>
                      <div class="col-2 ml-5">
                        <div class="form-check">
                          <input class="form-check-input" type="radio" name="estadoDocumentacionOption" id="estadoDocumentacionSinPresentar"  value="sinPresentar" (change)="habilitarDesabilitarVencimiento($event)" checked>
                          <label class="form-check-label" for="estadoDocumentacionSinPresentar">
                            Sin presentar
                          </label>
                        </div>
                        <div class="form-check">
                          <input  class="form-check-input" type="radio" name="estadoDocumentacionOption" id="estadoDocumentacionPresentada"  value="presentada" (change)="habilitarDesabilitarVencimiento($event)">
                          <label class="form-check-label" for="estadoDocumentacionPresentada">
                            Presentada
                          </label>
                        </div>
                      </div>
                      <div class="col-3">
                        <label for="diasVencimientoId">Dias para vencimiento de plazo menor a</label>
                      </div>
                      <div class="col-1">
                        <select class="form-control form-control-sm" id="diasVencimientoId" formControlName="diasVencimiento">
                            <option [value]="0">Vencido</option>
                            <option [value]="7">7</option>
                            <option [value]="15">15</option>
                            <option [value]="30">30</option>
                            <option [value]="90">90</option>
                            <option [value]="180">180</option>
                        </select>
                      </div>
                      <div class="col-1 ml-4">
                        <button type="submit" class="btn btn-info">Buscar</button>
                      </div>
                      <div class="col-1"> <div  *ngIf="estaProcesando"><i class='fa fa-refresh fa-spin fa-2x'></i></div> </div>
                    </div>
                  </form>

            </div>
          </div>
<br>
<br>
<div class="row mt-1">
  <div class="col-md-3">
     <p class="text-muted ml-3">Total: {{rowData.length}}</p>
  </div>
  <div class="col-md-4">
    <p class="text-muted">Seleccionadas: {{totalSeleccionadas}}</p>
   </div>
   <div class="col-md-4">
    <div class="float-right mb-2"><button type="button" class="btn btn-outline-success btn-sm"
        (click)="onBtExportListado()" title="Exportar a Excel"><i class="fas fa-file-excel"></i></button></div>
  </div>
</div>
          <div class="row ">

            <div class="col-md-12">
                <ag-grid-angular
                #grid
                style="width: 100%; height: 400px;"
                class="ag-theme-alpine"
                [rowData]="rowData"
                [columnDefs]="columnDefs"
                [colResizeDefault]="'fit'"
                [rowSelection]= "'multiple'"
                [rowMultiSelectWithClick]="true"
                [components]="frameworkComponents"
                [rowHeight]="25"
                (selectionChanged)="onSelectionChanged($event)"
                (gridReady)="onGridReady($event)"
                >
            </ag-grid-angular>
            </div>

        </div>

        <br>
        <div class="row">
          <div class="col-md-6">
            <ng-container *ngIf="!estaProcesandoNotificacion; else procesando">
              <button type="button" class="btn btn-outline-info btn-sm" (click)="notificarViaEmail()" [disabled]="!habilitarNotificacionMail"> <i class="fas fa-envelope-square"></i> Notificar vía email</button>
          </ng-container>
          <ng-template #procesando>
              <button class="btn btn-info" type="button">
                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  <span class="ml-2">Enviando notificaciones. Esta operación puede demorar algunos segundos ...</span>
              </button>
          </ng-template>

          </div>
        </div>




    </div>
</div>

<app-actualizar-email (retornoOperacion)="retornoOperacionActualizarEmail($event)"></app-actualizar-email>

<app-operacion-informacion [titulo]="'Control Plazos'"
    [contenidoMensaje]="mensaje"
    #informacionControlPlazos [id]="'informacionControlPlazos'"></app-operacion-informacion>



    <app-visor-impresion #visorImpresion [id]="'ayuda_control_cobranzas'"></app-visor-impresion>
