<div class="modal" tabindex="-1" role="dialog" [attr.id]="id" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Actualizar Email</h5>
        </div>

        <form [formGroup]="emailForm" novalidate>
          <div class="modal-body">
            <div class="form-group row">
                <label class="col-sm-3 col-form-label text-right">Email</label>
                <div class="col">
                  <input type="text" class="form-control" maxlength="80" formControlName="email" placeholder="Ingrese el email" [ngClass]="{ 'is-invalid': emailForm.controls['email'].errors  }">
                <div *ngIf="emailForm.controls['email'].errors" class="invalid-feedback">
                  <div *ngIf="emailForm.controls['email'].errors.email">Email no válido</div>
                  <div *ngIf="!emailForm.controls['email'].errors.required==false">Campo obligatorio</div>
                </div>
                </div>
              </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-outline-danger" (click)="cancelar()">Cancelar</button>
            <button [disabled]="!emailForm.valid" type="submit" class="btn btn-success"
              (click)="aceptar()">Aceptar</button>
          </div>

        </form>


      </div>
    </div>
  </div>

