  <div class="card  mt-3">

    <div class="card-header card-header-info"><b>Caja</b></div>
    <div class="card-body" style="padding: 0px;">
        <table class="table table-responsive text-center" style="margin: 0px">
            <tr>
              <td>Caja</td>
              <td>Fecha Caja</td>
              <td>Fecha Cobranza</td>
              <td>Banco</td>
              <td>Nº Transferencia</td>
            </tr>
            <tr>
              <td class="text-muted">
                <h5>{{caja}}</h5>
              </td>
              <td class="text-muted">
               {{fechaCaja | date: 'dd/MM/yyyy'}}
              </td>
              <td class="text-muted">
                {{fechaCobranza | date: 'dd/MM/yyyy'}}
              </td>
              <td class="text-muted">
                {{banco || '-'}}
              </td>
              <td class="text-muted">
                {{numeroTransferencia || '-'}}
              </td>
            </tr>
          </table>

        </div>
        <div class="card-footer" style="padding: 0px; text-align: center;">
            <button type="button" (click)="openOpcionesCaja()" class="btn btn-outline-info btn-sm mt-2" [attr.tabindex]="tabindex">Cambiar opciónes de caja</button>
        </div>
  </div>


  <!-- Modal cambio de caja-->
<div class="modal fade" id="cambioCajaModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Opciones Caja</h5>
        </div>
        <form [formGroup]="opcionesCajaForm" (ngSubmit)="modificarOpcionesCaja()" (keydown.enter)="$event.preventDefault()" novalidate >

          <div class="modal-body">


            <div class="form-group">
              <label class="col-form-label col-form-label-sm"  for="cajasCobranzas">Caja</label>
              <select   class="form-control form-control-sm" id="cajasCobranzas" formControlName="caja" (change)="onSelectionCajaChange($event)">
                <ng-container *ngFor="let caja of cajas$|async">
                  <option *ngIf="caja.cobromanual" [value]="caja.id">{{caja.id}} - {{caja.detalle}}</option>
                </ng-container>
            </select>
            </div>
            <div class="form-group">
              <label class="col-form-label col-form-label-sm" for="fechaCaja">Fecha de caja</label>
              <input type="text" class="form-control form-control-sm" formControlName="fechaCaja" id="fechaCaja"  readonly>
            </div>
            <div class="form-group">
              <label class="col-form-label col-form-label-sm" for="fechaCobranzaCaja">Fecha de cobranza</label>
              <input type="date" class="form-control form-control-sm"  formControlName="fechaCobranzaCaja" id="fechaCobranzaCaja">
            </div>
            <div class="form-group">
              <label class="col-form-label col-form-label-sm" for="listabancos">Banco</label>
              <input type="text" class="form-control form-control-sm" id="listabancos" list="bancosInput" maxlength="100"  formControlName="bancoTransferencia"/>
              <datalist id="bancosInput">
                  <option *ngFor="let banco of bancos$|async" [value]="banco.detalle">{{banco.detalle}}</option>
                </datalist>
            </div>
            <div class="form-group">
              <label class="col-form-label col-form-label-sm" for="numeroTransferenciaCaja">Nº Transferencia</label>
              <input type="text" class="form-control form-control-sm" id="numeroTransferenciaCaja" maxlength="50" formControlName="numeroTransferenciaCaja">
            </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal">Cancelar</button>
          <button type="submit" class="btn btn-primary btn-sm" >Aceptar</button>
        </div>
      </form>
      </div>
    </div>
  </div>
