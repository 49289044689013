<div class="card">


<div class="card-header card-header-info">
  <i class="fas fa-file-invoice-dollar"></i> Facturas
</div>
<div class="row">
<div class="col-md-8">
    <app-listado-facturas [rowSelectionOpcion]="'multiple'" [sorteableOpcion]="true" [visualizacionExpandida]="true" [mostrarDetalleSeleccion]="true"
    [habilitarFacturasPagas]='true' (seleccionRetorno)="onSeleccionFacturas($event)" ></app-listado-facturas>
</div>
<div class="col-md-4">
    <div class="row mt-3">
<div class="col-md-3">
<button type="button" class="btn btn-outline-info btn-sm" (click)="onImprimirFacturas()"><i class="fas fa-print"></i><br><span style="font-size: 12px;">Imprimir facturas</span></button>
</div>
<div class="col-md-3">
<button type="button" class="btn btn-outline-info btn-sm" (click)="imprimirListadoFacturas()"><i class="fa fa-list"></i><br><span style="font-size: 12px;">Imprimir listado</span></button>
</div>
<div class="col-md-3">
    <button type="button" class="btn btn-outline-danger btn-sm" (click)="onAnularFacturas()"><i class="far fa-trash-alt"></i><br><span style="font-size: 12px;">Anular facturas</span></button>
    </div>
    <div class="col-md-3">
        <button type="button" class="btn btn-outline-danger btn-sm" (click)="onAnularPagos()"><i class="fas fa-dollar-sign"></i><br><span style="font-size: 12px;">Anular Pagos</span></button>
        </div>
    </div>

    <div class="row mt-3">
        <div class="col-md-12">
            <app-resumen-totales></app-resumen-totales>
        </div>

    </div>



</div>
</div>
</div>
<!-- <app-lista-facturas-adeudadas [modoListadoDeuda]="true"></app-lista-facturas-adeudadas> -->

<app-impresion-listado></app-impresion-listado>

<app-operacion-loading></app-operacion-loading>

<app-operacion-si-no-observacion [id]="'operacion_si_no_observacion_facturas'" [titulo]="tituloAnulacion" [contenidoMensaje]="mensajeAnulacion"
[observacionPorDefecto]="observacionPeriodosSeleccionados"
(retornoOperacion)="retornoOperacionSiNo($event)"></app-operacion-si-no-observacion>

<app-autorizacion (retornoOperacion)="retornoAutorizacion($event)"></app-autorizacion>




