import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from "@angular/core";
import { Store } from "@ngxs/store";
import {  Subject } from "rxjs";
import  dayjs from "dayjs";
import { takeUntil, mergeMap } from "rxjs/operators";
import { UntypedFormGroup,  UntypedFormBuilder } from "@angular/forms";
import * as _ from "lodash";
import { InformacionNuevoConvenio } from "src/app/store/convenio/convenio.model";
import { OperacionInformacionComponent } from "src/app/shared/operacion-informacion/operacion-informacion.component";
import { OperacionSiNoComponent } from "src/app/shared/operacion-si-no/operacion-si-no.component";
import { AutorizacionComponent } from "src/app/autorizacion/autorizacion.component";
import {
  ClearConvenio,
  SetCostoIntimacionCobrado,
  SetCanonCobrado,
  NuevoConvenioCliente,
} from "src/app/store/convenio/actions";

import { NuevoConvenio } from "./nuevo-convenio.class";
import { ConvenioState } from "src/app/store/convenio/states";
import { SocioState } from "src/app/store/socio/states";
import {
  GetFacturasImpagasActualizadas,
  GetFacturasPagas,
} from "src/app/store/factura/actions";
import { GetCuentaCorriente } from "src/app/store/cuentacorriente/actions";
import Swal from "sweetalert2";

@Component({
  selector: "app-nuevo-convenio-pago",
  templateUrl: "./nuevo-convenio-pago.component.html",
  styleUrls: ["./nuevo-convenio-pago.component.css"],
})
export class NuevoConvenioPagoComponent implements OnInit, AfterViewInit {
  @ViewChild("informacionNuevoConvenio", { static: true })
  operacionInformacionComponent: OperacionInformacionComponent;

  @ViewChild("siNoNuevoConvenio", { static: true })
  operacionSiNoComponent: OperacionSiNoComponent;

  @ViewChild(AutorizacionComponent, { static: true })
  autorizacionComponent: AutorizacionComponent;

  @Output() seleccionarFacturasEvent = new EventEmitter();
  @Output() crearConvenioPagoEvent: EventEmitter<any> = new EventEmitter();
  @Output() cancelarEvent = new EventEmitter();



  constructor(
    private store: Store,
    private formBuilder: UntypedFormBuilder,
    private el: ElementRef

  ) {

  }

  facturasNuevoConvenio: any[] = [];

  destroy$: Subject<boolean> = new Subject<boolean>();

  dateNow = dayjs().format("YYYY-MM-DD");

  convenioForm: UntypedFormGroup;
  submitted = false;

  //intimacion
  costoIntimacionCobrado: boolean = true;

  valorMinimoCuota: number = 0;

  tituloCostoFinanciero: string;

  nuevoConvenio: NuevoConvenio = new NuevoConvenio();

  mensajeError: string;
  mensajeConsulta: string;

  operacionSiNo: number; // 0-Operacion Pocentaje Mayor a 50, 1-Convenio Judicial , 2-convenio de pago firmado, 3-ingresar cobranza de facturas cobradas

  estaEnProceso:boolean=false
  estaHabilitadoNuevoConvenio:boolean=true

  ngOnInit(): void {
    this.initForm();
    this.tituloCostoFinanciero = this.nuevoConvenio.tituloCostoFinanciero;
    // no estamos trabajando con moratoria sino deberiamos habilitar el tab(1) del frmNuevoConvenio (Convenio de moratoria)
    // no trabajanmos con moratoria ya que la fecha de moratoria desde y moratoria hasta de la base tiene como año 2014

    this.store
      .select((state) => state.convenio.informacion_para_nuevo_convenio)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        if (data) {
          this.actualizarInformacionConvenio(data);
        }
      });

    this.store
      .select((state) => state.convenio.facturas_nuevo_convenio)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.facturasNuevoConvenio = data;
        this.actualizarValoresFacturas();
      });
  }

  ngAfterViewInit() {
    this.nuevoConvenio.setMensajeInformacion(
      this.operacionInformacionComponent
    );
  }

  initForm() {
    this.convenioForm = this.nuevoConvenio.convenioFormGroup;
  }

  controlCaja(cajaEvent) {
    this.nuevoConvenio.controlCaja();
  }

  actualizarConvenioNoVolcado(event) {
    this.actualizarValoresNuevoConvenio();
  }

  actualizarGastosAdministrativos(event) {
    this.actualizarValoresNuevoConvenio();
  }

  actualizarReconexion(event) {
    this.actualizarValoresNuevoConvenio();
  }

  actualizarCuotas(event) {
    this.actualizarValoresNuevoConvenio();
  }

  actualizarTipoConvenio(event) {
    this.convenioForm.get("descuentoSobreIntereses").setValue(0);
    if (this.convenioForm.get("tipoConvenio").value == "2") {
      this.convenioForm.get("descuentoSobreIntereses").enable();
    } else {
      this.convenioForm.get("descuentoSobreIntereses").disable();
    }

    this.actualizarValoresNuevoConvenio();
  }

  actualizarInformacionConvenio(dataConvenio: InformacionNuevoConvenio) {
    this.nuevoConvenio.setRecargoDiario(dataConvenio.RecargoDiarioActual);
    this.nuevoConvenio.setValorMinimoCuota(dataConvenio.ValorMinCuota);
    this.nuevoConvenio.setTotalIntimacion(dataConvenio.TotalIntimacion);
    // indico que el recibo del costo de intimacion no ha sido cobrado aún
    this.store.dispatch(new SetCostoIntimacionCobrado(false));

    if (dataConvenio.TotalIntimacion > 0) this.costoIntimacionCobrado = false;

    this.nuevoConvenio.setTotalConveniosAdeudados(
      dataConvenio.TotalDeudaConvenio
    );

    //Verifica si hay deuda de convenio para marcar por defecto la caja 7
    // caja 7 - convenios refinanciados
    let cajaOperacion;
    if (dataConvenio.TotalDeudaConvenio > 0) {
      cajaOperacion = 7;
      this.convenioForm?.get("caja").setValue(cajaOperacion);
    }

    this.actualizarValoresNuevoConvenio();
  }

  actualizarValoresFacturas() {
    let totalFacturasConRecargo = 0;
    let totalFacturasSinRecargo = 0;
    let totalCanonMunicipal = 0;

    let totalRecargo = 0;
    let totalIvaRecargo = 0;
    let totalImporteTotalEnFacturas = 0;

    this.facturasNuevoConvenio.forEach((factura) => {
      totalFacturasConRecargo += factura.Total;
      totalFacturasSinRecargo +=
        factura.Total - (factura.Recargo + factura.IVARec);
      totalCanonMunicipal += factura.Importe14;

      totalRecargo += factura.Recargo
      totalIvaRecargo += factura.IVARec
      totalImporteTotalEnFacturas += factura.ImporteTotal

    });

    this.nuevoConvenio.setTotalFacturasConRecargo(totalFacturasConRecargo);
    this.nuevoConvenio.setTotalFacturasSinRecargo(totalFacturasSinRecargo);
    this.nuevoConvenio.setTotalCanonMunicipal(totalCanonMunicipal);
    this.nuevoConvenio.setValoresRecargoImporteTotalEnFacturas(+totalRecargo.toFixed(2),+totalIvaRecargo.toFixed(2),+totalImporteTotalEnFacturas.toFixed(2));

    // indico que el recibo del canon municipal no ha sido cobrado aún
    this.store.dispatch(new SetCanonCobrado(false));
    this.actualizarValoresNuevoConvenio();
  }

  actualizarValoresNuevoConvenio() {
    this.nuevoConvenio.actualizarTotalesConvenio();
    this.tituloCostoFinanciero = this.nuevoConvenio.tituloCostoFinanciero;
  }

  seleccionarFacturas() {
    this.seleccionarFacturasEvent.emit();
  }

  onBlurPorcentaje() {
    if (this.convenioForm.get("descuentoSobreIntereses").valid){
      if (+this.convenioForm.get("descuentoSobreIntereses").value > 50) {
        this.operacionSiNo = 0;
        this.mensajeConsulta =
          "Está seguro que desea efectuar un descuento superior al 50% ?";
        this.operacionSiNoComponent.open();
        this.nuevoConvenio.actualizarTotalesConvenio();
      }else{
        this.nuevoConvenio.actualizarTotalesConvenio();
      }
    }

  }

  convenioJudicialControl(event) {
    if (event.target.checked) {
      this.operacionSiNo = 1;
      this.mensajeConsulta =
        "Los convenios judiciales no se incluyen en la facturación.<br>Este convenio se realizó por via judicial ?";
      this.operacionSiNoComponent.open();
    }
  }

  crearConvenioDePago() {

    this.deshabilitarControlesNuevoConvenio()

    this.crearConvenioPagoEvent.emit({
      fechaConvenio: this.convenioForm.get("fechaConvenio").value,
      importeDeuda: this.convenioForm.get("montoTotal").value,
      cantidadCuotas: this.convenioForm.get("cuotas").value,
      valorCuota: this.convenioForm.get("montoValorCuotas").value,
      desde: "",
      hasta: "",
      importeCanonMunicipal: this.convenioForm.get("montoCanonMunicipal").value,
      importeIntimacion: this.convenioForm.get("montoCostoIntimacion").value,
    });
  }

  deshabilitarControlesNuevoConvenio(){
    this.nuevoConvenio.deshabilitarNuevoConvenio()
    this.estaHabilitadoNuevoConvenio = false
  }

  habilitarControlesNuevoConvenio(){
    this.nuevoConvenio.habilitarNuevoConvenio()
    this.estaHabilitadoNuevoConvenio = true
  }


  aceptarConvenio() {
    this.controlAutorizacion();
  }

  controlAutorizacion() {
    if (
      this.convenioForm.get("cuotas").value > 12 ||
      [1, 2].includes(this.convenioForm.get("tipoConvenio").value)
    ) {
      this.autorizacionComponent.open();
    } else {
      this.chequearConvenio();
    }
  }

  retornoAutorizacion(retorno) {
    if (!retorno.habilita) {
      this.mensajeError = "NO se ha autorizado la creación del convenio!";
      this.operacionInformacionComponent.open();
    } else {
      this.chequearConvenio();
    }
  }

  chequearConvenio() {
    const canonCobrado = this.store.selectSnapshot(ConvenioState.canonCobrado);
    const costoIntimacionCobrado = this.store.selectSnapshot(
      ConvenioState.costoIntimacionCobrado
    );
    this.nuevoConvenio.setCanonCobrado(canonCobrado);
    this.nuevoConvenio.setCostoIntimacionCobrado(costoIntimacionCobrado);

    if (this.nuevoConvenio.verificarConvenio()) {
      if (!this.nuevoConvenio.pseudoConvenio) {
        this.operacionSiNo = 2;
        this.mensajeConsulta = "Está firmado el convenio de pago?";
        this.operacionSiNoComponent.open();
      } else {
        this.operacionSiNo = 3;
        this.mensajeConsulta =
          "Desea registrar en la cobranza diaria las facturas seleccionadas?";
        this.operacionSiNoComponent.open();
      }
    }
  }

  registrarConvenio() {
    if (this.convenioForm.valid) {
      this.estaEnProceso = true

      let descuentoConvenio;

      if (this.convenioForm.get("tipoConvenio").value == 0) {
        descuentoConvenio = 0;
      } else if (this.convenioForm.get("tipoConvenio").value == 1) {
        descuentoConvenio = 100;
      } else {
        descuentoConvenio = +this.convenioForm.get("descuentoSobreIntereses")
          .value;
      }

      const cliente = this.store.selectSnapshot(SocioState.cliente);

      this.store
        .dispatch(
          new NuevoConvenioCliente(
            cliente,
            this.convenioForm.controls.fechaConvenio.value,
            this.convenioForm.controls.fechaCaja.value,
            this.convenioForm.controls.caja.value,
            this.convenioForm.controls.montoTotal.value,
            this.convenioForm.controls.cuotas.value,
            this.convenioForm.controls.montoValorCuotas.value,
            this.convenioForm.controls.cuotasAbonadas.value,
            this.nuevoConvenio.pseudoConvenio,
            this.convenioForm.controls.convenioJudicial.value,
            this.convenioForm.controls.tipoConvenio.value,
            descuentoConvenio,
            this.convenioForm.controls.montoCostoFinanciero.value,
            this.convenioForm.controls.montoGastosAdministrativos.value,
            this.convenioForm.controls.montoReconexion.value,
            this.facturasNuevoConvenio
          )
        )
        .pipe(
          mergeMap(() =>
            this.store.dispatch(
              new GetFacturasImpagasActualizadas(cliente, true)
            )
          ),
          mergeMap(() =>
            this.store.dispatch(new GetFacturasPagas(cliente, true))
          ),
          mergeMap(() =>
            this.store.dispatch(new GetCuentaCorriente(cliente, true))
          )
        )
        .subscribe(
          (_) => {
            Swal.fire(
              '','El convenio ha sido generado con éxito!',
              'success'
            ).then(()=>this.cancelarEvent.emit())

          },
          (error) => {this.estaEnProceso =false}
        );
    }
  }

  retornoOperacionConsultaSiNo(retornoSiNo) {
    let control: any;
    if (!retornoSiNo) {
      switch (this.operacionSiNo) {
        case 0:
          control = this.el.nativeElement.querySelector(
            '[formcontrolname="descuentoSobreIntereses"]'
          );
          control.focus();
          break;
        case 1:
          control = this.el.nativeElement.querySelector(
            '[formcontrolname="convenioJudicial"]'
          );
          control.checked = false;
          break;
        case 2:
          this.mensajeError = "Se debe firmar el convenio de pago!";
          this.operacionInformacionComponent.open();
          break;
        case 3:
          this.mensajeError =
            "Las facturas deben registrarse en la cobranza diaria!";
          this.operacionInformacionComponent.open();
          break;
      }
    } else {
      switch (this.operacionSiNo) {
        case 2:
        case 3:
          this.registrarConvenio();
          break;
      }
    }
  }

  retornoOperacionInformacion(event) {}

  cancelar() {
    this.cancelarEvent.emit();
  }

  ngOnDestroy() {
    this.store.dispatch(new ClearConvenio());
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
