import { Component, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngxs/store';
import { Periodo } from 'src/app/store/general/models';
import { Observable } from 'rxjs';
import { GetPeriodos } from 'src/app/store/general/actions';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { FacturaService } from '../factura.service';
import { SocioState } from 'src/app/store/socio/states';
import { Imprimir } from 'src/app/shared/imprimir';
import { HttpResponse } from '@angular/common/http';
import { OperacionLoadingComponent } from 'src/app/shared/operacion-loading/operacion-loading.component';
declare var $:any

@Component({
  selector: 'app-impresion-listado',
  templateUrl: './impresion-listado.component.html',
  styleUrls: ['./impresion-listado.component.css']
})
export class ImpresionListadoComponent implements OnInit {

  @ViewChild(OperacionLoadingComponent, { static: true })
  operacionLoadingComponent: OperacionLoadingComponent;

  periodos$:Observable<Periodo>
  limitarPeriodo:boolean=false

  impresionListadoForm:UntypedFormGroup

  imprimirUtils:Imprimir = new Imprimir()

  constructor(private store:Store, private fb:UntypedFormBuilder, private fs:FacturaService) { }

  ngOnInit(): void {
    this.store.dispatch(new GetPeriodos());
    this.periodos$ = this.store.select(state=>state.general.periodos)
    this.initForm()
  }

  initForm(){
    this.impresionListadoForm= this.fb.group(
      {
        tipoListado: ['adeudadas'],
        limitarPeriodo: [false],
        periodoDesde: [{ value: '', disabled: true }, Validators.required],
        periodoHasta: [{ value: '', disabled: true }, Validators.required]
      }
    )
  }


  open(){
    $('#impresionListadoModel').modal('show')
  }

  cancelar(){
    $('#impresionListadoModel').modal('hide')
  }

  onTipoListadoChange(event){
    this.impresionListadoForm.get('tipoListado').setValue(event.target.value)
  }

  onLimitePeriodos(event){

    this.impresionListadoForm.get('limitarPeriodo').setValue(event.target.checked)
    if(event.target.checked){
      this.impresionListadoForm.get('periodoDesde').enable()
      this.impresionListadoForm.get('periodoHasta').enable()
    }else{
      this.impresionListadoForm.get('periodoDesde').disable()
      this.impresionListadoForm.get('periodoHasta').disable()
    }

  }



  imprimir(){

    this.operacionLoadingComponent.loadingTitle="Generando listado ...";
    this.operacionLoadingComponent.open();

    const cliente = this.store.selectSnapshot(SocioState.cliente)


  this.fs.getListadoFacturas(cliente,
    this.impresionListadoForm.get('tipoListado').value,
    this.impresionListadoForm.get('limitarPeriodo').value,
    this.impresionListadoForm.get('periodoDesde').value,
    this.impresionListadoForm.get('periodoHasta').value
  ).subscribe(
    (v: HttpResponse<Blob>)=>{
      this.imprimirUtils.imprimir(v);
      this.operacionLoadingComponent.close();
    },
    ()=>{this.operacionLoadingComponent.close();}
  )

  this.cancelar()

  }



}
